import moment from 'moment-timezone';

export const getUTCDateTime = (inputDate: moment.Moment, timeZone: string, startOfRange: boolean = false): string => {
  const momentDate = moment.tz(inputDate, timeZone);
  const date = startOfRange ? momentDate.startOf('day') : momentDate.add(1, 'day').startOf('day').subtract(1, 'second');
  return date.utc().format();
};

export const getSupportedTimezones = () => {
  const supportedContinents = ['America'];
  const allTimeZones = moment.tz.names();
  const filteredTimeZones = allTimeZones.filter((tz) => {
    return supportedContinents.some((continent) => tz.startsWith(continent));
  });
  return filteredTimeZones;
};

export const localDateTimeString = (dateString: string): string => {
  const date = new Date(dateString);
  return `${date.toLocaleDateString()} ${date.toLocaleTimeString('en-US', {
    hour: '2-digit',
    minute: '2-digit'
  })}`;
};
