import { FormControlLabel, TextField, Checkbox } from '@material-ui/core';
import { TowerSettings } from '../../../../../common/Kiosk/KioskSettings';
import styles from './KioskTowerEditCard.module.css';

interface IKioskTowerEditCard {
  tower: TowerSettings;
  onInventoryChange: (tower: TowerSettings) => unknown;
  onEnabledChange: (tower: TowerSettings) => unknown;
  onActiveChange:(tower: TowerSettings) => unknown;
  disabled: boolean;
}

const KioskTowerEditCard: React.FC<IKioskTowerEditCard> = ({ tower, onInventoryChange, onEnabledChange, onActiveChange, disabled }) => {
  const handleInventoryChange = (value: number) => {
    const update = { ...tower };
    update.inventory = value;
    onInventoryChange(update);
  };

  const handleEnabledChange = (enabled: boolean) => {
    const update = { ...tower };
    update.enabled = enabled;
    onEnabledChange(update);
  };

  const handleActiveChange = (active: boolean) => {
    const update = { ...tower };
    update.active = active;
    onActiveChange(update);
  };

  return (
    <div className={styles.KioskTowerEditCard}>
      <div className={styles.alignCenter}>
        <strong>Tower {tower.id}</strong>
      </div>
      <TextField
        inputProps={{
          min: 0,
          style: {
            textAlign: 'center',
            fontSize: '20px',
          },
        }}
        InputLabelProps={{
          shrink: true,
        }}
        variant='outlined'
        label='Bottles'
        value={tower.inventory?.toFixed(0)}
        className={styles.towerAmountInput}
        onChange={(e) => handleInventoryChange(+e.target.value)}
        id={`${tower.id}`}
        type='number'
        disabled={disabled}
        fullWidth
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={tower.enabled}
            onChange={(e) => handleEnabledChange(e.target.checked)}
            name={`${tower.id}`}
          />
        }
        label="Enabled"
        style={{ color: '#333' }}
        disabled={disabled}
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={tower.active}
            onChange={(e) => handleActiveChange(e.target.checked)}
            name={`${tower.id}`}
          />
        }
        label="Active"
        style={{ color: '#333' }}
        disabled={disabled}
      />
    </div>
  );
};

export default KioskTowerEditCard;
