import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import IUser from './IUser';
import UserService from './UserService';

interface RouteParams {
  uuid: string;
}

const useUserDataAccess = (): { user: IUser | undefined; error: Error | undefined } => {
  const [user, setUser] = useState<IUser>();
  const [error, setError] = useState<Error>();
  const { uuid } = useParams<RouteParams>();

  useEffect(() => {
    const getUser = async () => {
      if (user) return;
      setError(undefined);
      const userService = new UserService();
      try {
        const userResult = await userService.getUser(uuid);
        setUser(userResult);
      } catch (error) {
        setError(error as Error);
      }
    };
    if (!user && uuid) {
      getUser();
    }
  }, [uuid, user]);

  return { user, error };
};

export default useUserDataAccess;
