import { useContext } from 'react';
import { KioskSettingsContext } from './KioskSettingsContext';
import KioskStatus from '../../../../common/Kiosk/KioskStatus';
import EnumSelectInput from '../../../../components/Form/Input/EnumSelectInput';

const KioskStatusSelect: React.FC = () => {
  const kioskSettings = useContext(KioskSettingsContext);
  const defaultKioskStatus = KioskStatus.Deployed;

  return (
    <EnumSelectInput<KioskStatus>
      value={kioskSettings?.state.settings?.kioskStatus ?? defaultKioskStatus}
      onChange={(val) => kioskSettings?.handleSettingsChange('kioskStatus', val as KioskStatus)}
      enumValues={KioskStatus}
      label='Kiosk Status'
      disabled={!kioskSettings?.deviceStatus?.ready}
    />
  );
};

export default KioskStatusSelect;
