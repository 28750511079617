import sortCaret from '../../components/table/ColumnSortIcon';
import DataColumn from '../../components/table/DataColumn';
import IOrder from '../../services/Orders/IOrder';
import OrderColumnKeys from './OrdersColumnKeys';

const OrdersTableConfig: DataColumn<IOrder, OrderColumnKeys>[] = [
  {
    dataField: 'id',
    text: 'ID',
  },
  {
    dataField: 'status',
    text: 'Status',
  },
  {
    dataField: 'promo',
    text: 'Promotion',
  },
  {
    dataField: 'date_created',
    text: 'Date',
    sort: true,
    sortCaret: (order: 'asc' | 'desc') => sortCaret(order),
    sortFunc: (a, b, order, dataField) => {
      if (order === 'asc') {
        return new Date(a).getTime() - new Date(b).getTime();
      }
      return new Date(b).getTime() - new Date(a).getTime();
    },
    formatter: (cell: string) => new Date(cell).toLocaleDateString('en-US'),
  },
  {
    dataField: 'time_created',
    text: 'Time',
    sort: true,
    formatter: (_cell: string, row) =>
      new Date(row.date_created).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' }),
  },
  {
    dataField: 'kiosk_ID',
    text: 'Kiosk',
  },
  {
    dataField: 'drink_price',
    text: 'Price',
    sort: true,
    sortCaret: sortCaret,
    sortFunc: (a, b, order, dataField) => {
      if (order === 'asc') {
        return parseFloat(a) - parseFloat(b);
      }
      return parseFloat(b) - parseFloat(a); // desc
    },
    formatter: (cell: number, row) => {
      return `$${cell.toFixed(2)}`;
    },
  },
  {
    dataField: 'salePrice',
    text: 'Sale Price',
    sort: true,
    sortCaret: sortCaret,
    sortFunc: (a, b, order, dataField) => {
      if (order === 'asc') {
        return parseFloat(a) - parseFloat(b);
      }
      return parseFloat(b) - parseFloat(a); // desc
    },
    formatter: (cell: number, row) => {
      return cell !== undefined ? `$${cell.toFixed(2)}` : 'no value';
    },
  },
  {
    dataField: 'flavor',
    text: 'Flavor',
    sort: true,
    sortCaret: sortCaret,
  },
  {
    dataField: 'temperature',
    text: 'Temperature',
    sort: true,
    sortCaret: sortCaret,
  },
  {
    dataField: 'carbonation',
    text: 'Carbonation',
    sort: true,
    sortCaret: sortCaret,
  },
  {
    dataField: 'order_type',
    text: 'Type',
  },
  {
    dataField: 'volume',
    text: 'Volume',
    sort: true,
    sortCaret: sortCaret,
    sortFunc: (a, b, order, dataField) => {
      if (order === 'asc') {
        return parseFloat(a) - parseFloat(b);
      }
      return parseFloat(b) - parseFloat(a);
    },
    formatter: (cell: number) => {
      return `${Math.round(cell).toLocaleString()} ml`;
    },
  },
];

export default OrdersTableConfig;
