enum DeckCalibrationFactor {
  FlowRateCorrection = 0,
  CapperVerifyMin1 = 1,
  CapperVerifyMax1 = 2,
  CapperVerifyMin2 = 3,
  CapperVerifyMax2 = 4,
  CapperThreshold = 5,
}

export default DeckCalibrationFactor;
