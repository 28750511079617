import IconProps from './IconProps';
import SvgIcon from './SvgIcon';

const IconArrowRightTwoTone: React.FC<IconProps> = ({
  className,
  width,
  height,
}) => (
  <SvgIcon className={className} width={width} height={height} fill="#555">
    <path
      fill="#000000"
      d="M4 12c0-4.41 3.59-8 8-8s8 3.59 8 8-3.59 8-8 8-8-3.59-8-8m8-1H8v2h4v3l4-4-4-4z"
      opacity=".3"
    />
    <path
      fill="#000000"
      d="M4 12c0-4.41 3.59-8 8-8s8 3.59 8 8-3.59 8-8 8-8-3.59-8-8m-2 0c0 5.52 4.48 10 10 10s10-4.48 10-10S17.52 2 12 2 2 6.48 2 12m10-1H8v2h4v3l4-4-4-4z"
    />
  </SvgIcon>
);
export default IconArrowRightTwoTone;
