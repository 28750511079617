import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { getSupportedTimezones, getUTCDateTime } from '../../utils/dateUtility';
import ReactLoading from 'react-loading';
import Typography from '@material-ui/core/Typography';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import '../../css/date_picker.css';
import ExpandingTableRow from '../../components/orders/ExpandingTableRow';
import OrdersTableConfig from './OrdersTableConfig';
import OrdersListState from './OrdersListState';
import OrdersService from '../../services/Orders/OrdersService';
import IOrdersQuery from '../../services/Orders/IOrdersQuery';
import TransactionType from '../../common/TransactionType';
import useTransactionType from './useTransactionType';
import FilterList from '../../components/Filter/OrdersFilter/OrdersFilter';
import OrderType from '../../common/OrderType';
import OrdersFiltersState from '../../components/Filter/OrdersFilter/OrdersFiltersState';
import IconLoading from '../../components/ui/Icons/IconLoading';
import { useAppSelector } from '../../hooks/storeHooks';
import useCompanies from '../../services/Company/useCompany';
import CompanyFilterTags from '../../components/Tags/CompanyFilterTags';
import FilterTags from '../../components/Tags/FilterTags';
import OrdersXlsExport from './OrdersXlsExport';
import IOrder from '../../services/Orders/IOrder';
import DataTable from '../../components/table/DataTable';
import OrderColumnKeys from './OrdersColumnKeys';
import { Box, Paper } from '@material-ui/core';

const OrdersList: React.FC = () => {
  const initialState: OrdersListState = {
    orders: [],
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    companies: [],
    kiosks: [],
    summaries: [],
    showFilters: false,
    filterAnchor: false,
    loading: false,
  };
  const [state, setState] = useState<OrdersListState>(initialState);
  const initialFilters = {
    kiosks: [],
    companies: [],
    orderTypes: [],
    transactionTypes: [],
  };
  const [filters, setFilters] = useState<OrdersFiltersState>(initialFilters);
  const initialTransactionType = useTransactionType();
  const [dates, setDates] = useState<{ startDate: string; endDate: string }>({
    startDate: getUTCDateTime(moment().subtract(3, 'days'), state.timeZone, true),
    endDate: getUTCDateTime(moment(), state.timeZone, false),
  });
  const user = useAppSelector((store) => store.auth?.user);
  const companies = useCompanies();

  useEffect(() => {
    if (!initialTransactionType) return;
    setFilters((prevFilters) => ({
      ...prevFilters,
      transactionTypes: [initialTransactionType as TransactionType],
    }));
  }, [initialTransactionType]);

  const getData = useCallback(async () => {
    const ordersService = new OrdersService();
    try {
      setState((prevState) => ({
        ...prevState,
        error: undefined,
        loading: true,
      }));
      const orders = await ordersService.getOrders({
        ...dates,
        ...filters,
      });
      const summaries = await ordersService.getKiosksSummary({
        ...dates,
        ...filters,
      });
      const uniqueKioskIds = [...new Set(orders.map((order) => order.kiosk_ID))] as string[];
      setState((prevState) => ({
        ...prevState,
        orders,
        kiosks: uniqueKioskIds,
        summaries,
        loading: false,
      }));
      console.log(`startDate: ${dates.startDate} - endDate: ${dates.endDate}`);
    } catch (error) {
      setState((prevState) => ({
        ...prevState,
        error: error instanceof Error ? error.message : JSON.stringify(error),
        loading: false,
      }));
    }
  }, [dates, filters]);

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    if (!companies || companies?.length === 0) return;
    setState((prevState) => ({
      ...prevState,
      companies,
    }));
  }, [companies]);

  const setStartDate = (date) => {
    setDates({
      ...dates,
      startDate: getUTCDateTime(moment(date), state.timeZone, true),
    });
  };

  const setEndDate = (date) => {
    setDates({
      ...dates,
      endDate: getUTCDateTime(moment(date), state.timeZone, false),
    });
  };

  const _onChangeTimezone = (_event, timezoneString: string | null) => {
    if (!timezoneString) {
      console.warn(`no timezoneString after timezone change ${timezoneString}`);
      return;
    }
    setDates({
      ...dates,
      startDate: getUTCDateTime(moment(dates.startDate), timezoneString, true),
      endDate: getUTCDateTime(moment(dates.endDate), timezoneString, false),
    });
    setState({
      ...state,
      timeZone: timezoneString,
    });
  };

  const _onClearFilters = () => {
    setFilters(initialFilters);
  };

  const _onFilterClick = (filterType: keyof IOrdersQuery, value: string | OrderType | TransactionType) => {
    const filterValues = filters[filterType] as (string | OrderType | TransactionType)[];
    if (!filterValues) return;
    if (!Array.isArray(filterValues)) return;
    setFilters({
      ...filters,
      [filterType]: filterValues.includes(value)
        ? filterValues.filter((item) => item !== value)
        : [...filterValues, value],
    });
  };

  const toggleDrawer = () => () => {
    setState({ ...state, filterAnchor: !state.filterAnchor });
  };

  const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
  const checkedIcon = <CheckBoxIcon fontSize='small' />;

  return (
    <div className='valign-wrapper  animate__animated animate__slideInLeft'>
      {state.orders?.length === 0 && (
        <div>
          <ReactLoading
            type={'spinningBubbles'}
            color={'#58cbe1'}
            height='20%'
            width='20%'
            className='center-align dash-progress-spinner'
          />
        </div>
      )}
      <div className='row' style={{ width: '100%' }}>
        <div style={{}} className='col s12 overview-header'>
          <div className='col s12' style={{ padding: '0px', marginTop: '15px' }}>
            <Typography
              style={{
                fontWeight: '400',
                fontSize: '24px',
                font: "'Open Sans', sans-serif'",
                paddingRight: '0px',
                paddingLeft: '0px',
              }}
              className='col s4 l10 m10 left'
            >
              All Orders
            </Typography>
            <div className='col s2 l2 m2 right-align' style={{ marginBottom: '10px' }}>
              <OrdersXlsExport
                startDate={dates.startDate}
                endDate={dates.endDate}
                orders={state.orders}
                summaries={state.summaries}
              />
            </div>
          </div>
          <div className='col s12 l12 m12'>
            <div className='col s4 l10 valign-wrapper'>
              {state.timeZone && <FilterTags tags={[state.timeZone]} label='Timezone' />}
              {filters.companies && <CompanyFilterTags companies={state.companies} companyIds={filters.companies} />}
              {filters.kiosks && <FilterTags tags={filters.kiosks} label='Kiosks' />}
              {filters.orderTypes && <FilterTags tags={filters.orderTypes} label='Order Type' />}
              {filters.transactionTypes && <FilterTags tags={filters.transactionTypes} label='Transaction Type' />}
              {state.loading && <IconLoading width={30} height={30} />}
            </div>
            <div className='col s2 l2 m2 right-align filter-label'>
              <Button onClick={toggleDrawer()} className='filter-button right-align'>
                Filters
              </Button>
              <Drawer className='filter-drawer' anchor={'right'} open={state.filterAnchor} onClose={toggleDrawer()}>
                <FilterList
                  state={state}
                  filters={filters}
                  _onFilterClick={_onFilterClick}
                  _onClearFilters={_onClearFilters}
                  toggleDrawer={toggleDrawer()}
                  userRole={user?.role}
                />
              </Drawer>
            </div>
          </div>
          <div
            className='col l2 m6 s12 overview-header-orders'
            style={{ padding: '0px !important', marginTop: '15px', marginBottom: '10px' }}
          >
            <DatePicker
              selected={new Date(dates.startDate)}
              onChange={setStartDate}
              startDate={new Date(dates.startDate)}
              endDate={new Date(dates.endDate)}
              style={{ width: '100%' }}
              selectsStart
              showMonthDropdown
              showYearDropdown
              todayButton='Today'
              maxDate={new Date(new Date())}
              className='date-dropdown'
            />
          </div>
          <div
            className='col l2 m6 s12 overview-header-orders'
            style={{ padding: '0px !important', marginTop: '15px' }}
          >
            <DatePicker
              selected={new Date(dates.endDate)}
              onChange={setEndDate}
              selectsEnd
              showMonthDropdown
              showYearDropdown
              todayButton='Today'
              startDate={new Date(dates.startDate)}
              endDate={new Date(dates.endDate)}
              minDate={new Date(dates.startDate)}
              maxDate={new Date()}
              className='date-dropdown'
            />
          </div>
          <div className='col s12 l4 m4  filter-item' style={{ borderWidth: '10px', marginLeft: '0px' }}>
            <Autocomplete
              options={getSupportedTimezones()}
              getOptionLabel={(option) => option}
              defaultValue={''}
              limitTags={5}
              value={state.timeZone}
              size='small'
              autoComplete={true}
              fullWidth={false}
              disablePortal={true}
              className='left'
              renderOption={(option, { selected }) => (
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                  }}
                >
                  <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 0 }} checked={selected} />
                  {option}
                </Box>
              )}
              PaperComponent={({ children }) => (
                <Paper style={{ width: '300px' }} elevation={1}>
                  {children}
                </Paper>
              )}
              renderInput={(params) => (
                <div ref={params.InputProps.ref}>
                  <input
                    style={{
                      backgroundColor: '#FFFFFF',
                      height: '45px',
                      width: '122%',
                      marginTop: '15px',
                      boxShadow: '0box-shadow: 0px 4px 20px rgba(54, 56, 68, 0.08)',
                      borderRadius: '8px',
                      textAlign: 'left',
                    }}
                    type='text'
                    {...params.inputProps}
                  />
                </div>
              )}
              onChange={_onChangeTimezone}
            />
          </div>
        </div>
        <DataTable<IOrder, OrderColumnKeys>
          data={state.orders}
          loading={state.loading}
          expandingRow={ExpandingTableRow}
          totalSize={state.orders.length}
          contextName='Orders'
          dataColumnConfig={OrdersTableConfig}
        />
      </div>
    </div>
  );
};

export default OrdersList;
