import RequestMethod from './RequestMethod';
import axios from 'axios';

export default class ApiService {
  constructor(public host: string) {}

  public request = async (
    path: string,
    method: RequestMethod = 'GET',
    params: URLSearchParams = new URLSearchParams(),
    body: object = {}
  ): Promise<Response | undefined> => {
    try {
      const response = (
        await axios.request({
          method,
          url: `${this.host}/${path}`,
          params,
          data: body,
        })
      ).data;
      return response;
    } catch (error) {
      throw error instanceof Error ? Error(error.message) : Error(JSON.stringify(error));
    }
  };
}
