interface ITableHeader {
  headers: [string, string][];
}

const TableHeader: React.FC<ITableHeader> = ({ headers }) => (
  <thead>
    <tr>
      {headers.map(([header, headerClass], index) => (
        <th key={index} className={headerClass}>
          {header}
        </th>
      ))}
    </tr>
  </thead>
);

export default TableHeader;
