import React, { useState } from 'react';
import IconLoading from '../../../../../components/ui/Icons/IconLoading';
import GantryControlsStrings from '../GantryControls/GantryControlsStrings';
import styles from './KioskFeedbackImage.module.css';
import IconRotateRight from '../../../../../components/ui/Icons/IconRotateRight';
import IconEnlarge from '../../../../../components/ui/Icons/IconEnlarge';

interface IKioskFeedbackImage {
  imageUrl?: string;
  onChangeRefreshImageEnabled: (value: boolean) => void;
  refreshImageEnabled: boolean;
  loading?: boolean;
  lastUpdate?: string;
  errorString?: string;
}

const KioskFeedbackImage: React.FC<IKioskFeedbackImage> = ({
  imageUrl,
  refreshImageEnabled,
  onChangeRefreshImageEnabled,
  loading,
  lastUpdate,
  errorString
}) => {
  const [rotation, setRotation] = useState(0);
  const [enlargeImage, setEnlargeImage] = useState<boolean>(false);

  const rotateImage = (angle: number) => {
    setRotation((prevRotation) => prevRotation + angle);
  };

  return (
    <div className={styles.KioskFeedbackImage} style={{ opacity: loading ? 0.5 : 1.0 }}>
      <div className={styles.imageContainer}>
        {imageUrl && refreshImageEnabled ? (
          <img
            src={imageUrl}
            alt={GantryControlsStrings.KioskFeedbackImageAlt}
            className={styles.rotatableImage}
            style={{ transform: `rotate(${rotation}deg)`, objectFit: enlargeImage ? 'cover' : 'contain' }}
          />
        ) : (
          <div className={styles.skeleton} />
        )}
        <div className={styles.rotationButtons}>
          <button onClick={() => rotateImage(90)}>
            <IconRotateRight width='20px' height='20px' />
          </button>
          <button onClick={() => setEnlargeImage(!enlargeImage)}>
            <IconEnlarge width='20px' height='20px' />
          </button>
        </div>
        <div className={styles.loadingContainer}>
          {loading && refreshImageEnabled && <IconLoading width={30} height={30} />}
        </div>
        {lastUpdate && <div className={styles.lastUpdate}>Updated: {lastUpdate}</div>}
        {errorString && <div className={styles.errorMessage}>Error: {errorString}</div>}
      </div>
      <label>
        <input
          type='checkbox'
          checked={refreshImageEnabled}
          onChange={(e) => onChangeRefreshImageEnabled(e.target.checked)}
        />
        <span>{GantryControlsStrings.RefreshImagePrompt}</span>
      </label>
    </div>
  );
};

export default KioskFeedbackImage;
