import is from 'check-types';
import { ReactNode } from 'react';
import UserRole from '../../common/UserRole';

interface IAuthGuard {
  authorizedRoles: UserRole[];
  children: ReactNode;
  userRole: UserRole;
}
const AuthGuard: React.FC<React.PropsWithChildren<IAuthGuard>> = ({ authorizedRoles, children, userRole }) => {
  if (!authorizedRoles || is.emptyArray(authorizedRoles)) return <>{children}</>;
  if (authorizedRoles.includes(userRole) === false) return null;
  return <>{children}</>;
};

export default AuthGuard;
