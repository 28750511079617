import React from 'react';

interface IFilterTags {
  tags: string[];
  label: string;
}

const FilterTags: React.FC<IFilterTags> = ({ tags, label }) => {
  if (tags?.length === 0) return null;
  return (
    <div className='chip chosen-tags'>
      <strong>{label}: </strong>
      {tags.join(', ')}
    </div>
  );
};

export default FilterTags;
