import UserRole from '../../../common/UserRole';

interface IKiosksList {
  kioskIds: string[];
  role: UserRole;
}

const KiosksList: React.FC<IKiosksList> = ({ kioskIds, role }) => {
  const notApplicableRoles: UserRole[] = [UserRole.DropSuperAdmin, UserRole.MachineSuperAdmin];
  if (notApplicableRoles.includes(role)) return <>N/A</>;
  return (
    <>
      {kioskIds?.length > 0 && (
        <ul>
          {kioskIds.map((kioskId) => (
            <li key={kioskId}>{kioskId}</li>
          ))}
        </ul>
      )}
      {(kioskIds?.length === 0 || !kioskIds) && <>No Kiosks Selected</>}
    </>
  );
};

export default KiosksList;
