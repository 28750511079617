import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import 'font-awesome/css/font-awesome.min.css';
import { logoutUser } from '../../actions/authActions';
import M from 'materialize-css';
import { connect } from 'react-redux';
import logo from '../../images/Drop-Logo-Black.png';

class FloatingActionButton extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isExpanded: false
		};
	}

	componentDidMount() {
		var elems = document.querySelectorAll('.fixed-action-btn');
		M.FloatingActionButton.init(elems, {
			direction: 'top'
		});
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.errors) {
			this.setState({
				errors: nextProps.errors
			});
		}
	}

	render() {
		return (
		<div>
      {(this.props.auth.isAuthenticated) && (
			<div className="fixed-action-btn">
				<button className="btn-floating wave btn-large blue" style={{ backgroundColor: '#148F77' }}>
					<i className="fa fa-bars" />
				</button>
				<ul>
					<li>
						<a
							className="btn-floating blue social twitter"
							href="https://twitter.com/dropwater"
							target="_blank"
							rel="noopener noreferrer"
						>
							<i className="fa fa-twitter" />
						</a>
					</li>
					<li>
						<a
							className="btn-floating red"
							href="https://www.instagram.com/dropwaterco/"
							target="_blank"
							rel="noopener noreferrer"
						>
							<i className="fa fa-instagram" />
						</a>
					</li>
					<li>
						<a
							className="btn-floating blue social facebook"
							href="https://www.facebook.com/dropwaterco"
							target="_blank"
							rel="noopener noreferrer"
						>
							<i className="fa fa-facebook" />
						</a>
					</li>
					<li>
						<a
							className="btn-floating white"
							href="https://dropwater.co"
							target="_blank"
							rel="noopener noreferrer"
						>
							<img border="0" alt="DropWater Logo" src={logo} width="40" height="40" />
						</a>
					</li>
				</ul>
			</div>

      )}
      </div>
		);
	}
}

const mapStateToProps = (state) => ({
	errors: state.errors,
	auth: state.auth
});

export default connect(mapStateToProps, {
	logoutUser
})(withRouter(FloatingActionButton));
