import React from 'react';
import SvgIcon from './SvgIcon';
import IIconAdditive from './IIconAdditive';

const IconAdditiveTeaPlusLemonade: React.FC<IIconAdditive> = ({
  className,
}) => (
  <SvgIcon
    className={className}
    width="112"
    height="112"
    fill="none"
    viewBox="0 0 112 112"
  >
    <path
      fill="#9ec953"
      d="M55.51,9.87v91.73c20.51,0,37.13-18.09,37.13-40.4,0-31.45-37.13-51.32-37.13-51.32Z"
    />
    <path
      fill="#5c8a1e"
      d="M55.51,9.87v91.73c-20.51,0-37.13-18.09-37.13-40.4,0-31.45,37.13-51.32,37.13-51.32Z"
    />
    <path
      fill="#386200"
      d="M55.51,85.82l-21.6-27.42s11.65,3.64,21.6,16.75v10.68h0Z"
    />
    <path
      fill="#386200"
      opacity="0.5"
      d="M55.51,85.82l21.6-27.42s-11.65,3.64-21.6,16.75v10.68h0Z"
    />
    <path
      fill="#386200"
      d="M55.51,55.73l-15.53-19.72s8.38,2.62,15.53,12.04v7.68Z"
    />
    <path
      fill="#386200"
      opacity="0.5"
      d="M55.51,55.73l15.53-19.72s-8.38,2.62-15.53,12.04v7.68Z"
    />
    <path
      fill="#e9f45b"
      d="M59.29,78.66c0,12.66,10.27,22.93,22.93,22.93v-45.86c-12.66,0-22.93,10.27-22.93,22.93h0Z"
    />
    <path
      fill="#e1e52e"
      d="M105.15,78.66c0-12.66-10.27-22.93-22.93-22.93v45.86c12.66,0,22.93-10.27,22.93-22.93h0Z"
    />
    <path
      fill="#fffea3"
      d="M62.6,78.66c0,10.84,8.79,19.62,19.62,19.62v-39.24c-10.84,0-19.62,8.79-19.62,19.62h0Z"
    />
    <path
      fill="#f9fb86"
      d="M101.84,78.66c0-10.84-8.79-19.62-19.62-19.62v39.24c10.84,0,19.62-8.79,19.62-19.62h0Z"
    />
    <path
      fill="#fffff3"
      d="M69.36,78.66c0,7.1,5.75,12.86,12.86,12.86v-25.71c-7.1,0-12.86,5.75-12.86,12.86Z"
    />
    <path
      fill="#fefdda"
      d="M95.07,78.66c0-7.1-5.75-12.86-12.86-12.86v25.71c7.1,0,12.86-5.75,12.86-12.86Z"
    />
    <path
      fill="#e9f45b"
      d="M68.43,70.15l5.27-5.27c.45-.45,1.2-.29,1.44.29l3.73,9c.3.73-.43,1.46-1.16,1.16l-9-3.73c-.58-.24-.73-1-.29-1.44Z"
    />
    <path
      fill="#e9f45b"
      d="M67.67,74.12l9,3.73c.73.3.73,1.33,0,1.63l-9,3.73c-.58.24-1.22-.19-1.22-.82v-7.46c0-.63.64-1.06,1.22-.82h0Z"
    />
    <path
      fill="#e9f45b"
      d="M68.72,85.92l9-3.73c.73-.3,1.46.43,1.16,1.16l-3.73,9c-.24.58-1,.73-1.44.29l-5.27-5.27c-.45-.45-.29-1.2.29-1.44Z"
    />
    <path
      fill="#e9f45b"
      d="M82.22,62.9h-3.73c-.63,0-1.06.64-.82,1.22l3.73,9c.15.36.48.55.82.55v-10.77Z"
    />
    <path
      fill="#e9f45b"
      d="M82.22,83.84c-.33,0-.67.18-.82.55l-3.73,9c-.24.58.19,1.22.82,1.22h3.73v-10.77Z"
    />
    <path
      fill="#e1e52e"
      d="M86.71,82.19l9,3.73c.58.24.73,1,.29,1.44l-5.27,5.27c-.45.45-1.2.29-1.44-.29l-3.73-9c-.3-.73.43-1.46,1.16-1.16Z"
    />
    <path
      fill="#e1e52e"
      d="M87.76,78.03l9-3.73c.58-.24,1.22.19,1.22.82v7.46c0,.63-.64,1.06-1.22.82l-9-3.73c-.73-.3-.73-1.33,0-1.63h0Z"
    />
    <path
      fill="#e1e52e"
      d="M90.73,65.07l5.27,5.27c.45.45.29,1.2-.29,1.44l-9,3.73c-.73.3-1.46-.43-1.16-1.16l3.73-9c.24-.58,1-.73,1.44-.29Z"
    />
    <path
      fill="#e1e52e"
      d="M82.22,94.62h3.73c.63,0,1.06-.64.82-1.22l-3.73-9c-.15-.36-.48-.55-.82-.55v10.77Z"
    />
    <path
      fill="#e1e52e"
      d="M82.22,73.67c.33,0,.67-.18.82-.55l3.73-9c.24-.58-.19-1.22-.82-1.22h-3.73v10.77Z"
    />
  </SvgIcon>
);

export default IconAdditiveTeaPlusLemonade;
