import { ClearOutlined, RefreshOutlined } from '@material-ui/icons';
import IconButtonWithLabel from '../../Button/IconButtonWithLabel/IconButtonWithLabel';
import styles from './MessageFailureOptions.module.css';
import ChatStrings from '../ChatStrings';
import { ChatParticipant } from '../ChatWidget/ChatWidget';

interface IMessageFailureOptions {
  failedMessage?: { message: string; recipients: ChatParticipant[] };
  error?: string;
  onResend: (message: string, recipients: ChatParticipant[]) => void;
  onCancel: () => void;
}

const MessageFailureOptions: React.FC<IMessageFailureOptions> = ({ failedMessage, error, onResend, onCancel }) => {
  if (!error) return null;
  return (
    <div className={styles.MessageFailureOptions}>
      <span className={styles.errorMessage}>{error}</span>
      {failedMessage && <blockquote>{failedMessage.message}</blockquote>}
      {failedMessage && (
        <div className={styles.optionsRow}>
          <IconButtonWithLabel
            icon={<RefreshOutlined />}
            label={ChatStrings.MessageFailureRetryButtonLabel}
            disabled={!error}
            onClick={() => onResend(failedMessage.message, failedMessage.recipients)}
          />
          <IconButtonWithLabel
            icon={<ClearOutlined />}
            label={ChatStrings.MessageFailureCancelButtonLabel}
            disabled={!error}
            onClick={onCancel}
          />
        </div>
      )}
    </div>
  );
};

export default MessageFailureOptions;
