import { ReactElement } from 'react';
import IconAdditiveCaffeine from './IconAdditiveCaffeine';
import IconAdditiveCaffeine20 from './IconAdditiveCaffeine20';
import IconAdditiveCaffeine40 from './IconAdditiveCaffeine40';
import IconAdditiveCaffeine70 from './IconAdditiveCaffeine70';
import IconAdditiveWater from './IconAdditiveWater';
import IconAdditiveSanitizer from './IconAdditiveSanitizer';
import IconAdditiveTropicalEnergy from './IconAdditiveTropicalEnergy';
import AdditiveReference from '../../../common/Kiosk/AdditiveReference';
import IconAdditiveLemonadeV3 from './IconAdditiveLemonadeV3';
import IconAdditiveBlackberry from './IconAdditiveBlackberry';
import IconAdditiveMintMojito from './IconAdditiveMintMojito';
import IconAdditiveOrange from './IconAdditiveOrange';
import IconAdditivePassionOrangeGuava from './IconAdditivePassionOrangeGuava';
import IconAdditivePeachTea from './IconAdditivePeachTea';
import IconAdditivePomegranateAcai from './IconAdditivePomegranateAcai';
import IconAdditiveRaspberryLychee from './IconAdditiveRaspberryLychee';
import IconAdditiveStrawberryKiwi from './IconAdditiveStrawberryKiwi';
import IconAdditiveTeaPlusLemonade from './IconAdditiveTeaPlusLemonade';
import IconAdditiveYuzuGinger from './IconAdditiveYuzuGinger';

const additiveIconMap = (additiveName: AdditiveReference, styles: string): ReactElement => {
  const map = {
    water: <IconAdditiveWater className={styles} />,
    caffeine: <IconAdditiveCaffeine className={styles} />,
    caffeine20: <IconAdditiveCaffeine20 className={styles} />,
    caffeine40: <IconAdditiveCaffeine40 className={styles} />,
    caffeine70: <IconAdditiveCaffeine70 className={styles} />,
    sanitizer: <IconAdditiveSanitizer className={styles} />,
    tropicalEnergy: <IconAdditiveTropicalEnergy className={styles} />,
    lemonadeV3: <IconAdditiveLemonadeV3 className={styles} />,
    orange: <IconAdditiveOrange className={styles} />,
    passionOrangeGuava: <IconAdditivePassionOrangeGuava className={styles} />,
    peachTea: <IconAdditivePeachTea className={styles} />,
    pomegranateAcai: <IconAdditivePomegranateAcai className={styles} />,
    raspberryLychee: <IconAdditiveRaspberryLychee className={styles} />,
    strawberryKiwi: <IconAdditiveStrawberryKiwi className={styles} />,
    teaPlusLemonade: <IconAdditiveTeaPlusLemonade className={styles} />,
    blackberry: <IconAdditiveBlackberry className={styles} />,
    mintMojito: <IconAdditiveMintMojito className={styles} />,
    yuzuGinger: <IconAdditiveYuzuGinger className={styles} />
  };
  if (!map[additiveName]) {
    return <></>;
  }
  return map[additiveName];
};

export default additiveIconMap;
