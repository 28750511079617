import CommandUI from '../FirmwareCommunication/CommandUI';
import CalibrationAction from '../FirmwareCommunication/Messaging/Calibration/CalibrationAction';
import CommandCode from '../FirmwareCommunication/Messaging/CommandCode';
import PeripheralIdentifier from '../PeripheralIdentifier';
import ReceptionAction from './ReceptionAction';
import ReceptionCalibrationFactor from './ReceptionCalibrationFactor';
import {
  ReceptionMotionDevice,
  ReceptionMotionPosition,
} from './ReceptionMotion';

const ReceptionCommands: { [key in CommandCode]?: CommandUI } = {
  [CommandCode.Identify]: {
    name: 'Identify',
    identifier: PeripheralIdentifier.Reception,
    code: CommandCode.Identify,
    args: [],
  },
  [CommandCode.ResetHardware]: {
    name: 'Reset Hardware',
    identifier: PeripheralIdentifier.Reception,
    code: CommandCode.ResetHardware,
    args: [],
  },
  [CommandCode.ResetProcessor]: {
    name: 'Reset Processor',
    identifier: PeripheralIdentifier.Reception,
    code: CommandCode.ResetProcessor,
    args: [],
  },
  [CommandCode.Provision]: {
    name: 'Provision',
    identifier: PeripheralIdentifier.Reception,
    code: CommandCode.Provision,
    args: [
      {
        name: 'PCB Version',
        type: 'decimal',
        options: [],
      },
      {
        name: 'BOM Version',
        type: 'decimal',
        options: [],
      },
      {
        name: 'Serial Number',
        type: 'decimal',
        options: [],
      },
    ],
  },
  [CommandCode.Query]: {
    name: 'Query',
    identifier: PeripheralIdentifier.Reception,
    code: CommandCode.Query,
    args: [],
  },
  [CommandCode.Action]: {
    name: 'Action',
    identifier: PeripheralIdentifier.Reception,
    code: CommandCode.Action,
    args: [
      {
        name: 'Action',
        type: 'select',
        options: [
          {
            name: ReceptionAction[ReceptionAction.Stop],
            value: ReceptionAction.Stop,
          },
        ],
      },
    ],
  },
  [CommandCode.Motion]: {
    name: 'Motion',
    identifier: PeripheralIdentifier.Reception,
    code: CommandCode.Motion,
    args: [
      {
        name: 'Device',
        type: 'select',
        options: [
          {
            name: ReceptionMotionDevice[ReceptionMotionDevice.Door],
            value: ReceptionMotionDevice.Door,
          },
          {
            name: ReceptionMotionDevice[ReceptionMotionDevice.Slider],
            value: ReceptionMotionDevice.Slider,
          },
          {
            name: ReceptionMotionDevice[ReceptionMotionDevice.DoorLock],
            value: ReceptionMotionDevice.DoorLock,
          },
        ],
      },
      {
        name: 'Position',
        type: 'select',
        options: [
          {
            name: ReceptionMotionPosition[
              ReceptionMotionPosition.DoorAndLockClose
            ],
            value: ReceptionMotionPosition.DoorAndLockClose,
          },
          {
            name: ReceptionMotionPosition[
              ReceptionMotionPosition.DoorAndLockOpen
            ],
            value: ReceptionMotionPosition.DoorAndLockOpen,
          },
          {
            name: ReceptionMotionPosition[
              ReceptionMotionPosition.SliderGarbage
            ],
            value: ReceptionMotionPosition.SliderGarbage,
          },
          {
            name: ReceptionMotionPosition[
              ReceptionMotionPosition.SliderDropOff
            ],
            value: ReceptionMotionPosition.SliderDropOff,
          },
          {
            name: ReceptionMotionPosition[
              ReceptionMotionPosition.SliderAndDoorReset
            ],
            value: ReceptionMotionPosition.SliderAndDoorReset,
          },
        ],
      },
      {
        name: 'Steps',
        type: 'decimal',
        options: [],
      },
    ],
  },
  [CommandCode.EnterBootLoader]: {
    name: 'Enter BootLoader',
    identifier: PeripheralIdentifier.Reception,
    code: CommandCode.EnterBootLoader,
    args: [],
  },
  [CommandCode.Calibrate]: {
    name: 'Calibrate',
    identifier: PeripheralIdentifier.Reception,
    code: CommandCode.Calibrate,
    args: [
      {
        name: 'Calibration Factor',
        type: 'select',
        options: [
          {
            name: ReceptionCalibrationFactor[
              ReceptionCalibrationFactor.SliderGarbagePosition
            ],
            value: ReceptionCalibrationFactor.SliderGarbagePosition,
          },
          {
            name: ReceptionCalibrationFactor[
              ReceptionCalibrationFactor.BottleSensorThreshold
            ],
            value: ReceptionCalibrationFactor.BottleSensorThreshold,
          },
          {
            name: ReceptionCalibrationFactor[
              ReceptionCalibrationFactor.DoorOpenPosition
            ],
            value: ReceptionCalibrationFactor.DoorOpenPosition,
          },
        ],
      },
      {
        name: 'Action',
        type: 'select',
        options: [
          {
            name: CalibrationAction[CalibrationAction.CalculateAndStore],
            value: CalibrationAction.CalculateAndStore,
          },
          {
            name: CalibrationAction[CalibrationAction.RetrieveStored],
            value: CalibrationAction.RetrieveStored,
          },
        ],
      },
    ],
  },
};

export default ReceptionCommands;
