import styles from '../GantryControls.module.css';

const GantryJogConfigInputField: React.FC<{
  label: string;
  type: 'range' | 'number';
  value: number;
  min: number;
  max: number;
  onChange: (value: number) => void;
  uom: 'steps' | 'seconds';
}> = ({ label, type, value, min, max, onChange, uom }) => (
  <div>
    <p>
      <strong>{label}:</strong> <span>{value.toLocaleString()}</span> {uom}
    </p>
    <input
      type={type}
      value={value}
      min={min}
      max={max}
      onChange={(e) => onChange(+e.target.value)}
      className={styles.inputField}
    />
  </div>
);

export default GantryJogConfigInputField;
