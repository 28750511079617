import { Doughnut } from 'react-chartjs-2';
interface IDoughnutChart {
  chartData: any;
  screenWidth: number;
}
const DoughnutChart: React.FC<IDoughnutChart> = ({ chartData, screenWidth }) => {
  return (
    <Doughnut
      data={chartData}
      height={screenWidth < 800 ? 180 : 80}
      options={{
        maintainAspectRatio: true,
        responsive: true,
        legend: {
          position: 'bottom',
          align: 'center',
          fullSize: false,
        },
        tooltips: {
          callbacks: {
            // https://stackoverflow.com/questions/43604597/how-to-customize-the-tooltip-of-a-chart-js-2-0-doughnut-chart
            // https://jsfiddle.net/m7s43hrs/
            //   title: function(tooltipItem, data) {
            // 	return data['labels'][tooltipItem[0]['index']];
            //   },
            //   label: function(tooltipItem, data) {
            // 	return data['datasets'][0]['data'][tooltipItem['index']];
            //   },
            afterLabel: function (tooltipItem, data) {
              var dataset = data['datasets'][0];
              var dataTmp = dataset['data'];

              var total = dataTmp.reduce((a, b) => a + b, 0); // get sum of data array
              var percent = Math.round((dataset['data'][tooltipItem['index']] / total) * 100);
              return '(' + percent + '%)';
            },
          },
          backgroundColor: '#000',
          titleFontSize: 15,
          titleFontColor: '#fff',
          bodyFontColor: '#fff',
          bodyFontSize: 15,
          displayColors: true,
        },
      }}
    />
  );
};

export default DoughnutChart;
