import CommandUI from '../FirmwareCommunication/CommandUI';
import CalibrationAction from '../FirmwareCommunication/Messaging/Calibration/CalibrationAction';
import CommandCode from '../FirmwareCommunication/Messaging/CommandCode';
import PeripheralIdentifier from '../PeripheralIdentifier';
import AdditivesAction from './AdditivesAction';
import AdditivesCalibrationFactor from './AdditivesCalibrationFactor';
import AdditivesDispenseTemperature from './AdditivesDispenseTemperature';
import { AdditivesDevice, AdditivesPosition } from './AdditivesMotion';
import AdditivesSequenceType from './AdditivesSequenceType';

const AdditivesCommands: { [key in CommandCode]?: CommandUI } = {
  [CommandCode.Identify]: {
    name: 'Identify',
    identifier: PeripheralIdentifier.Additives,
    code: CommandCode.Identify,
    args: [],
  },
  [CommandCode.ResetHardware]: {
    name: 'Reset Hardware',
    identifier: PeripheralIdentifier.Additives,
    code: CommandCode.ResetHardware,
    args: [],
  },
  [CommandCode.ResetProcessor]: {
    name: 'Reset Processor',
    identifier: PeripheralIdentifier.Additives,
    code: CommandCode.ResetProcessor,
    args: [],
  },
  [CommandCode.Provision]: {
    name: 'Provision',
    identifier: PeripheralIdentifier.Additives,
    code: CommandCode.Provision,
    args: [
      {
        name: 'PCB Version',
        type: 'decimal',
        options: [],
      },
      {
        name: 'BOM Version',
        type: 'decimal',
        options: [],
      },
      {
        name: 'Serial Number',
        type: 'decimal',
        options: [],
      },
    ],
  },
  [CommandCode.Query]: {
    name: 'Query',
    identifier: PeripheralIdentifier.Additives,
    code: CommandCode.Query,
    args: [],
  },
  [CommandCode.Action]: {
    name: 'Action',
    identifier: PeripheralIdentifier.Additives,
    code: CommandCode.Action,
    args: [
      {
        name: 'Action',
        type: 'select',
        options: [
          {
            name: AdditivesAction[AdditivesAction.StopAll],
            value: AdditivesAction.StopAll,
          },
          {
            name: AdditivesAction[AdditivesAction.StartDispenseRefill],
            value: AdditivesAction.StartDispenseRefill,
          },
          {
            name: AdditivesAction[AdditivesAction.StopDispenseRefillOrPurge],
            value: AdditivesAction.StopDispenseRefillOrPurge,
          },
          {
            name: AdditivesAction[AdditivesAction.ResetFlowMeterTicks],
            value: AdditivesAction.ResetFlowMeterTicks,
          },
          {
            name: AdditivesAction[AdditivesAction.StartPurgeRefill],
            value: AdditivesAction.StartPurgeRefill,
          },
          {
            name: AdditivesAction[AdditivesAction.StartPurgeFill],
            value: AdditivesAction.StartPurgeFill,
          },
        ],
      },
      {
        name: 'Water Select Source',
        type: 'select',
        options: [
          {
            name: AdditivesDispenseTemperature[
              AdditivesDispenseTemperature.Cold
            ],
            value: AdditivesDispenseTemperature.Cold,
          },
          {
            name: AdditivesDispenseTemperature[
              AdditivesDispenseTemperature.Ambient
            ],
            value: AdditivesDispenseTemperature.Ambient,
          },
        ],
      },
      {
        name: 'Timeout (Start Dispense Refill or Purge only)',
        type: 'decimal',
        options: [],
      },
      {
        name: 'Overall Timeout (Start Dispense Refill or Purge only)',
        type: 'decimal',
        options: [],
      },
      {
        name: 'Additive Pump 1 Magnitude',
        type: 'decimal',
        options: [],
      },
      {
        name: 'Additive Pump 2 Magnitude',
        type: 'decimal',
        options: [],
      },
      {
        name: 'Additive Pump 3 Magnitude',
        type: 'decimal',
        options: [],
      },
      {
        name: 'Additive Pump 4 Magnitude',
        type: 'decimal',
        options: [],
      },
      {
        name: 'Additive Pump 5 Magnitude',
        type: 'decimal',
        options: [],
      },
      {
        name: 'Additive Pump 6 Magnitude',
        type: 'decimal',
        options: [],
      },
    ],
  },
  [CommandCode.Motion]: {
    name: 'Motion',
    identifier: PeripheralIdentifier.Additives,
    code: CommandCode.Motion,
    args: [
      {
        name: 'Device',
        type: 'select',
        options: [
          {
            name: AdditivesDevice[AdditivesDevice.AmbientDispenser],
            value: AdditivesDevice.AmbientDispenser,
          },
          {
            name: AdditivesDevice[AdditivesDevice.ColdDispenser],
            value: AdditivesDevice.ColdDispenser,
          },
          {
            name: AdditivesDevice[AdditivesDevice.Servo],
            value: AdditivesDevice.Servo,
          },
        ],
      },
      {
        name: 'Position',
        type: 'select',
        options: [
          {
            name: AdditivesPosition[AdditivesPosition.ServoCenterNoPinch],
            value: AdditivesPosition.ServoCenterNoPinch,
          },
          {
            name: AdditivesPosition[AdditivesPosition.ServoFill],
            value: AdditivesPosition.ServoFill,
          },
          {
            name: AdditivesPosition[AdditivesPosition.ServoRefill],
            value: AdditivesPosition.ServoRefill,
          },
          {
            name: AdditivesPosition[AdditivesPosition.DispensersOn],
            value: AdditivesPosition.DispensersOn,
          },
          {
            name: AdditivesPosition[AdditivesPosition.DispensersOff],
            value: AdditivesPosition.DispensersOff,
          },
        ],
      },
    ],
  },
  [CommandCode.EnterBootLoader]: {
    name: 'EnterBootLoader',
    identifier: PeripheralIdentifier.Additives,
    code: CommandCode.EnterBootLoader,
    args: [],
  },
  [CommandCode.Calibrate]: {
    name: 'Calibrate',
    identifier: PeripheralIdentifier.Additives,
    code: CommandCode.Calibrate,
    args: [
      {
        name: 'Calibration Factor',
        type: 'select',
        options: [
          {
            name: AdditivesCalibrationFactor[
              AdditivesCalibrationFactor.ServoCenterPosition
            ],
            value: AdditivesCalibrationFactor.ServoCenterPosition,
          },
          {
            name: AdditivesCalibrationFactor[
              AdditivesCalibrationFactor.BottleFillFlowRateCorrection
            ],
            value: AdditivesCalibrationFactor.BottleFillFlowRateCorrection,
          },
          {
            name: AdditivesCalibrationFactor[
              AdditivesCalibrationFactor.RefillFlowRateCorrection
            ],
            value: AdditivesCalibrationFactor.RefillFlowRateCorrection,
          },
          {
            name: AdditivesCalibrationFactor[
              AdditivesCalibrationFactor.PumpMotorsDirection
            ],
            value: AdditivesCalibrationFactor.PumpMotorsDirection,
          },
          {
            name: AdditivesCalibrationFactor[
              AdditivesCalibrationFactor.ServoRefillPosition
            ],
            value: AdditivesCalibrationFactor.ServoRefillPosition,
          },
          {
            name: AdditivesCalibrationFactor[
              AdditivesCalibrationFactor.ServoFillPosition
            ],
            value: AdditivesCalibrationFactor.ServoFillPosition,
          },
          {
            name: AdditivesCalibrationFactor[
              AdditivesCalibrationFactor.StepperVelocityXY
            ],
            value: AdditivesCalibrationFactor.StepperVelocityXY,
          },
          {
            name: AdditivesCalibrationFactor[
              AdditivesCalibrationFactor.StepperVelocityZ
            ],
            value: AdditivesCalibrationFactor.StepperVelocityZ,
          },
          {
            name: AdditivesCalibrationFactor[
              AdditivesCalibrationFactor.StepperVelocityA
            ],
            value: AdditivesCalibrationFactor.StepperVelocityA,
          },
        ],
      },
      {
        name: 'Action',
        type: 'select',
        options: [
          {
            name: CalibrationAction[CalibrationAction.CalculateAndStore],
            value: CalibrationAction.CalculateAndStore,
          },
          {
            name: CalibrationAction[CalibrationAction.RetrieveStored],
            value: CalibrationAction.RetrieveStored,
          },
          {
            name: CalibrationAction[CalibrationAction.OverwriteFactor],
            value: CalibrationAction.OverwriteFactor,
          },
        ],
      },
      {
        name: 'Value',
        type: 'decimal',
        options: [],
      },
    ],
  },
  [CommandCode.PerformSequence]: {
    name: 'Perform Sequence',
    identifier: PeripheralIdentifier.Additives,
    code: CommandCode.PerformSequence,
    args: [
      {
        name: 'Type',
        type: 'select',
        options: [
          {
            name: AdditivesSequenceType[
              AdditivesSequenceType.DispenseBottleFill
            ],
            value: AdditivesSequenceType.DispenseBottleFill,
          },
          {
            name: AdditivesSequenceType[AdditivesSequenceType.BreakInTubing],
            value: AdditivesSequenceType.BreakInTubing,
          },
        ],
      },
      {
        name: 'Additive Pump 1 Magnitude',
        type: 'decimal',
        options: [],
      },
      {
        name: 'Additive Pump 2 Magnitude',
        type: 'decimal',
        options: [],
      },
      {
        name: 'Additive Pump 3 Magnitude',
        type: 'decimal',
        options: [],
      },
      {
        name: 'Additive Pump 4 Magnitude',
        type: 'decimal',
        options: [],
      },
      {
        name: 'Additive Pump 5 Magnitude',
        type: 'decimal',
        options: [],
      },
      {
        name: 'Additive Pump 6 Magnitude',
        type: 'decimal',
        options: [],
      },
      {
        name: 'Bottle volume ml',
        type: 'decimal',
        options: [],
      },
    ],
  },
};

export default AdditivesCommands;
