import { useCallback, useEffect, useState, useRef } from 'react';
import KioskService from '../../services/Kiosk/KioskService';
import KioskMonitorReport from './KioskMonitorReport';
import KioskStatusReport from '../../services/Kiosk/KioskStatusReport';

const useDeviceMonitor = (
  deviceStateChanged: boolean,
  deviceId?: string,
  intervalMs: number = 10000
): KioskMonitorReport => {
  const [report, setReport] = useState<KioskStatusReport>();
  const [deviceMonitorActive, setDeviceMonitorActive] = useState<boolean>(false);
  const intervalId = useRef<NodeJS.Timeout | undefined>();
  const timeoutId = useRef<NodeJS.Timeout | undefined>();
  const online = report?.status === 'connected';

  const checkDeviceStatus = useCallback(async () => {
    if (!deviceId) {
      console.info(`not starting device monitor until deviceId defined`);
      return;
    }
    try {
      const statusReport = await new KioskService(deviceId).getDeviceStatus();
      setReport(statusReport);
      setDeviceMonitorActive(true);
    } catch (error) {
      console.error(error);
    }
  }, [deviceId]);

  useEffect(() => {
    if (deviceStateChanged && deviceMonitorActive && deviceId) {
      timeoutId.current = setTimeout(() => {
        console.log('device state changed, need to check device now');
        checkDeviceStatus();
      }, 500);
    }
    if (deviceId || !deviceMonitorActive) {
      checkDeviceStatus();
      intervalId.current = setInterval(() => {
        checkDeviceStatus();
      }, intervalMs);
    }

    return () => {
      if (intervalId.current) clearInterval(intervalId.current);
      if (timeoutId.current) clearTimeout(timeoutId.current);
    };
  }, [checkDeviceStatus, deviceId, deviceMonitorActive, deviceStateChanged, intervalMs]);

  return { online, loaded: deviceMonitorActive, ready: deviceMonitorActive && online, report };
};

export default useDeviceMonitor;
