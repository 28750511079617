import { selectFilter, textFilter, dateFilter, Comparator, numberFilter } from 'react-bootstrap-table2-filter';
import kebabToTitleCase from '../../../utils/kebabToTitleCase';
import DataColumn from '../../../components/table/DataColumn';
import IMedia from '../../../services/Media/IMedia';
import MediaType from '../../../services/Media/MediaType';
import DownloadIcon from '@material-ui/icons/Save';
import moment from 'moment';
import sortCaret from '../../../components/table/ColumnSortIcon';

const mediaTypeOptions = Object.entries(MediaType).reduce((options, [key, value]) => {
  options[value] = key;
  return options;
}, {});

type MediaKeys = keyof IMedia;

const MediaTableConfig = (allKioskIds: string[]): DataColumn<IMedia, MediaKeys>[] => {
  const kioskOptions = allKioskIds
    .filter((kioskId) => kioskId)
    .reduce((options, id) => {
      options[id] = id;
      return options;
    }, {});

  return [
    {
      dataField: 'fileName',
      text: 'File Name',
      filter: textFilter(),
      sort: true,
      sortCaret: (order: 'asc' | 'desc') => sortCaret(order),
    },
    {
      dataField: 'type',
      text: 'Type',
      filter: selectFilter({
        options: mediaTypeOptions,
      }),
      formatter: (cell: string) => (cell ? <>{kebabToTitleCase(cell)}</> : undefined),
    },
    {
      dataField: 'kiosk_ID',
      text: 'Kiosk',
      filter: selectFilter({
        options: kioskOptions,
        comparator: Comparator.LIKE,
      }),
    },
    {
      dataField: 'size',
      text: 'Size',
      formatter: (cell: number) => (cell ? <>{(cell / 1048576).toFixed(2)}MB</> : 'unknown'),
      filter: numberFilter({
        placeholder: 'Size (KB)',
        comparator: Comparator.GT,
      }),
      sort: true,
      sortCaret: (order: 'asc' | 'desc') => sortCaret(order),
    },
    {
      dataField: 'description',
      text: 'Description',
      filter: textFilter(),
    },
    {
      dataField: 'createdAt',
      text: 'Date Created',
      sort: true,
      sortCaret: (order: 'asc' | 'desc') => sortCaret(order),
      formatter: (cell: string) => moment(cell).format('YYYY-MM-DD HH:mm:ss'),
      filter: dateFilter({}),
    },
    {
      dataField: 'signedUrl',
      text: 'Actions',
      formatter: (cell: string) => (
        <a
          style={{ background: 'none', border: 'none', cursor: 'pointer' }}
          href={cell}
          target='_blank'
          rel='noreferrer'
        >
          <DownloadIcon />
        </a>
      ),
    },
  ];
};

export default MediaTableConfig;
