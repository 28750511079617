import { EmailOutlined, SmsOutlined } from '@material-ui/icons';
import NotificationMedium from '../../../services/Notifications/NotificationMedium';

interface INotificationMediumIcon {
  medium: NotificationMedium;
}

const NotificationMediumIcon: React.FC<INotificationMediumIcon> = ({ medium }) => {
  const iconFromMedium: Record<NotificationMedium, JSX.Element> = {
    [NotificationMedium.Email]: <EmailOutlined />,
    [NotificationMedium.SMS]: <SmsOutlined />,
    [NotificationMedium.InstantMessage]: <></>,
    [NotificationMedium.FileUpload]: <></>
  };

  if (!medium) return <></>;

  return iconFromMedium[medium];
};

export default NotificationMediumIcon;
