import IIconAdditive from './IIconAdditive';
import SvgIcon from './SvgIcon';

const IconAdditiveWater: React.FC<IIconAdditive> = ({ className }) => (
  <SvgIcon
    className={className}
    width="112"
    height="112"
    fill="none"
    viewBox="0 0 112 112"
  >
    <path
      fill="#5FD4E0"
      d="M56 14l3.812 6.93 20.806 37.716C82.858 62.678 84 67.004 84 71.519c0 2.835-.452 5.67-1.335 8.421-1.637 4.977-4.653 9.324-8.745 12.579-4.954 3.927-13.742 5.292-17.726 5.481H56V14z"
    />
    <path
      fill="#BEE7FA"
      d="M56 53.667l6.15 12.37c.529 1.082.85 2.28.85 3.592 0 .76-.115 1.567-.344 2.373-.39 1.405-1.17 2.626-2.18 3.501-1.033.921-3.19 1.428-4.476 1.497V53.667z"
    />
    <path
      fill="#6CE3FF"
      d="M56 14l-3.812 6.93L31.38 58.625C29.142 62.657 28 67.004 28 71.498c0 2.835.452 5.67 1.335 8.421 1.637 4.977 4.653 9.324 8.745 12.579 4.932 3.948 13.742 5.313 17.705 5.481L56 98V14z"
    />
    <path
      fill="#D8EFF7"
      d="M56 53.667l-6.154 12.37c-.526 1.082-.846 2.28-.846 3.592 0 .76.114 1.567.343 2.373.412 1.405 1.19 2.626 2.173 3.501 1.03.921 3.18 1.428 4.461 1.497L56 53.667z"
    />
  </SvgIcon>
);

IconAdditiveWater.defaultProps = {
  className: '',
};

export default IconAdditiveWater;
