import UserRole from '../../common/UserRole';

type RoleToRoleList = {
  [K in UserRole]?: UserRole[];
};

const roleToRoleList: RoleToRoleList = {
  [UserRole.DropSuperAdmin]: [
    UserRole.DropSuperAdmin,
    UserRole.MachineSuperAdmin,
    UserRole.MachineAdmin,
    UserRole.User,
  ],
  [UserRole.MachineSuperAdmin]: [UserRole.MachineSuperAdmin, UserRole.MachineAdmin, UserRole.User],
};

const selectableUserRoles = (currentRole?: UserRole): UserRole[] | undefined => {
  if (!currentRole) return;
  return roleToRoleList[currentRole];
};

export default selectableUserRoles;
