import IconProps from './IconProps';
import SvgIcon from './SvgIcon';

const IconRotateRight: React.FC<IconProps> = ({ className, width, height }) => (
  <SvgIcon
    className={className}
    width={width}
    height={height}
    viewBox='0 0 24 24'
    fill='none'
    strokeWidth='2'
    strokeLinecap='round'
    strokeLinejoin='round'
  >
    <path d='M21.5 2v6h-6M21.34 15.57a10 10 0 1 1-.57-8.38' />
  </SvgIcon>
);
export default IconRotateRight;
