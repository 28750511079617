import React from 'react';
import is from 'check-types';
import PositionCoordinates from '../PositionCoordinates';
import styles from './GantryPositionDisplay.module.css';

interface IGantryPositionDisplay {
  coordinates: PositionCoordinates;
  lastUpdate?: string;
}

const GantryPositionDisplay: React.FC<IGantryPositionDisplay> = ({ coordinates, lastUpdate }) => {
  const [rawX, rawZ, encoderX, encoderZ] = coordinates;

  const createRow = (label: string, x: number, z: number) => (
    <tr>
      <td className={styles.cell}>
        <strong>{label}</strong>
      </td>
      <td className={styles.cell}>{is.emptyArray(coordinates) ? 'unknown' : x}</td>
      <td className={styles.cell}>{is.emptyArray(coordinates) ? 'unknown' : z}</td>
    </tr>
  );

  return (
    <table className={styles.table}>
      <thead>
        <tr>
          <th className={styles.header}>Axis</th>
          <th className={styles.header}>X</th>
          <th className={styles.header}>Z</th>
        </tr>
      </thead>
      <tbody>
        {createRow('Raw Position', rawX, rawZ)}
        {createRow('Encoder Position', encoderX, encoderZ)}
      </tbody>
      {lastUpdate && (
        <tfoot>
          <tr>
            <td className={styles.cell} colSpan={3}>
              Updated at: {lastUpdate}
            </td>
          </tr>
        </tfoot>
      )}
    </table>
  );
};

export default GantryPositionDisplay;
