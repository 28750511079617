import CommandUI from '../FirmwareCommunication/CommandUI';
import CommandCode from '../FirmwareCommunication/Messaging/CommandCode';
import PeripheralIdentifier from '../PeripheralIdentifier';
import MonitorAction from './MonitorAction';
import { MonitorMotionDevice, MonitorMotionPosition } from './MonitorMotion';

const MonitorCommands: { [key in CommandCode]?: CommandUI } = {
  [CommandCode.Identify]: {
    name: 'Identify',
    identifier: PeripheralIdentifier.Monitor,
    code: CommandCode.Identify,
    args: []
  },
  [CommandCode.ResetHardware]: {
    name: 'Reset Hardware',
    identifier: PeripheralIdentifier.Monitor,
    code: CommandCode.ResetHardware,
    args: []
  },
  [CommandCode.ResetProcessor]: {
    name: 'Reset Processor',
    identifier: PeripheralIdentifier.Monitor,
    code: CommandCode.ResetProcessor,
    args: []
  },
  [CommandCode.Provision]: {
    name: 'Provision',
    identifier: PeripheralIdentifier.Monitor,
    code: CommandCode.Provision,
    args: [
      {
        name: 'PCB Version',
        type: 'decimal',
        options: []
      },
      {
        name: 'BOM Version',
        type: 'decimal',
        options: []
      },
      {
        name: 'Serial Number',
        type: 'decimal',
        options: []
      }
    ]
  },
  [CommandCode.Query]: {
    name: 'Query',
    identifier: PeripheralIdentifier.Monitor,
    code: CommandCode.Query,
    args: []
  },
  [CommandCode.Action]: {
    name: 'Action',
    identifier: PeripheralIdentifier.Monitor,
    code: CommandCode.Action,
    args: [
      {
        name: 'Action',
        type: 'select',
        options: [
          {
            name: MonitorAction[MonitorAction.Stop],
            value: MonitorAction.Stop
          }
        ]
      }
    ]
  },
  [CommandCode.Motion]: {
    name: 'Motion',
    identifier: PeripheralIdentifier.Monitor,
    code: CommandCode.Motion,
    args: [
      {
        name: 'Device',
        type: 'select',
        options: [
          {
            name: MonitorMotionDevice[MonitorMotionDevice.DoorLatch],
            value: MonitorMotionDevice.DoorLatch
          },
          {
            name: MonitorMotionDevice[MonitorMotionDevice.UVFilter],
            value: MonitorMotionDevice.UVFilter
          }
        ]
      },
      {
        name: 'Position',
        type: 'select',
        options: [
          {
            name: MonitorMotionPosition[MonitorMotionPosition.Unlock],
            value: MonitorMotionPosition.Unlock
          },
          {
            name: MonitorMotionPosition[MonitorMotionPosition.UVFilterOn],
            value: MonitorMotionPosition.UVFilterOn
          },
          {
            name: MonitorMotionPosition[MonitorMotionPosition.UVFilterOff],
            value: MonitorMotionPosition.UVFilterOff
          }
        ]
      }
    ]
  },

  [CommandCode.EnterBootLoader]: {
    name: 'Enter BootLoader',
    identifier: PeripheralIdentifier.Monitor,
    code: CommandCode.EnterBootLoader,
    args: []
  }
};

export default MonitorCommands;
