import { DASHBOARD_METRICS } from "../actions/types";
const initialState = {
  loading: false,
  dashboard_metrics: {}
};
export default function overviewReducers (state = initialState, action) {
  switch (action.type) {
    case DASHBOARD_METRICS:
      return {
        ...state,
        dashboard_metrics: action.payload,
      };
    default:
      return state;
  }
}
