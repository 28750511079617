import { ORDER_CREATE, ORDER_CREATE_LOADING } from "../actions/types";

const initialState = {
  kioskDetails: {},
  loading: false,
  errors: {},
};

export default function orderCreateReducers (state = initialState, action) {
  switch (action.type) {
    case ORDER_CREATE:
      return {
        ...state,
        kioskDetails: action.payload,
      };
    case ORDER_CREATE_LOADING:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
}
