import ConfigService from '../ConfigService';
import ApiService from '../HttpClient/ApiService';
import IMedia from './IMedia';

export default class MediaService {
  apiService: ApiService;

  constructor() {
    this.apiService = new ApiService(ConfigService.apiHost);
  }

  getMedia = async (): Promise<IMedia[]> => {
    const mediaItems = await this.apiService.request(`media`, 'GET');
    return mediaItems as unknown as IMedia[];
  };
}
