import { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import KioskControlButton from './KioskControlButton';
import KioskStrings from './KioskStrings';

interface IKioskSoftwareUpdateControl {
  display: boolean;
  disabled: boolean;
  onConfirm: (ignoreMaintenance: boolean) => void;
}

const KioskSoftwareUpdateControl: React.FC<IKioskSoftwareUpdateControl> = ({ display, disabled, onConfirm }) => {
  const [maintenanceWarningDisplay, setMaintenanceWarningDisplay] = useState<boolean>(false);
  return (
    <>
      {maintenanceWarningDisplay && (
        <Dialog
          open={maintenanceWarningDisplay}
          onClose={() => setMaintenanceWarningDisplay(false)}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <DialogTitle id='alert-dialog-title'>{KioskStrings.SoftwareUpdateMaintenanceConfirmTitle}</DialogTitle>
          <DialogContent>
            <DialogContentText id='alert-dialog-description'>
              {KioskStrings.SoftwareUpdateMaintenanceWarning}
            </DialogContentText>
          </DialogContent>
          <div>
            <KioskControlButton
              display={true}
              disabled={false}
              onClick={() => {
                onConfirm(true);
                setMaintenanceWarningDisplay(false);
              }}
              label={KioskStrings.UserConfirmUpdateOutsideMaintenance}
              color='grey'
            />
            <KioskControlButton
              display={true}
              disabled={false}
              onClick={() => {
                onConfirm(false);
                setMaintenanceWarningDisplay(false);
              }}
              label={KioskStrings.UserConfirmUpdateDuringMaintenance}
              color='blue'
            />
          </div>
        </Dialog>
      )}
      <KioskControlButton
        display={display}
        disabled={disabled}
        onClick={() => setMaintenanceWarningDisplay(!maintenanceWarningDisplay)}
        label={KioskStrings.KioskControlButtonUpdateSoftware}
        color='orange'
      />
    </>
  );
};

export default KioskSoftwareUpdateControl;
