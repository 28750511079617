import is from 'check-types';
import { useState, useCallback } from 'react';
import UserRole from '../../common/UserRole';
import { useAppSelector } from '../../hooks/storeHooks';
import KioskService from './KioskService';
import KioskSettings, { Pump, TowerSettings } from '../../common/Kiosk/KioskSettings';

const useKioskSettingDataAccess = (kioskId?: string) => {
  const [settings, setSettings] = useState<KioskSettings>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const user = useAppSelector((store) => store.auth?.user);

  const getKioskSettings = useCallback(async () => {
    if (user?.role !== UserRole.DropSuperAdmin) {
      console.log(`user role ${user?.role} does not meet requirement`);
      return;
    }
    if (!kioskId) {
      console.warn(`No kioskId provided, not requesting Kiosk Settings`);
      return;
    }
    setLoading(true);
    setError(null);
    const kioskService = new KioskService(kioskId);
    try {
      const fetchedSettings = await kioskService.getKioskSettings();
      setSettings(fetchedSettings);
    } catch (err) {
      setError('Failed to fetch settings');
      console.error(err);
    } finally {
      setLoading(false);
    }
  }, [kioskId, user?.role]);

  function mergeArrayUpdates<T extends { id: number }>(existingArray: T[], updateArray: Partial<T>[]): T[] {
    if (is.undefined(existingArray) || is.undefined(updateArray)) return existingArray || [];
    return existingArray.map((item) => {
      const update = updateArray.find((u) => u.id === item.id);
      return update ? { ...item, ...update } : item;
    });
  }

  const setLocalSettings = (update: Partial<KioskSettings>) => {
    setSettings((prevSettings) => {
      let newSettings = { ...prevSettings };

      if (update.pumps && prevSettings?.pumps) {
        newSettings.pumps = mergeArrayUpdates(prevSettings.pumps, update.pumps as Partial<Pump>[]);
      }

      if (update.towers && prevSettings?.towers) {
        newSettings.towers = mergeArrayUpdates(prevSettings.towers, update.towers as Partial<TowerSettings>[]);
      }

      const { pumps, towers, ...otherUpdates } = update;
      newSettings = { ...newSettings, ...otherUpdates };

      return newSettings;
    });
  };

  const updateKioskSettings = async (update: Partial<KioskSettings>) => {
    if (!kioskId) {
      console.warn(`No kioskId provided, cannot update Kiosk Settings`);
      return;
    }
    setLoading(true);
    setError(null);
    const kioskService = new KioskService(kioskId);
    try {
      const response = await kioskService.updateKioskSettings(update);
      if (response.error) throw new Error(`Failed to update settings: ${response.error} status: ${response.status}`);
      setLocalSettings(update);
      return response;
    } catch (err) {
      const errorString = err instanceof Error ? err.message : JSON.stringify(err);
      setError(errorString);
      console.error(err);
      throw new Error(errorString);
    } finally {
      setLoading(false);
    }
  };

  return { settings, loading, error, updateKioskSettings, getKioskSettings };
};

export default useKioskSettingDataAccess;
