import { Card } from 'react-bootstrap';
import { useContext } from 'react';
import SubmitButton from '../../../../components/Button/SubmitButton';
import { KioskSettingsContext } from './KioskSettingsContext';
import KioskEditTabReference from '../KioskEditTabReference';
import KioskStatusSelect from './KioskStatusSelect';
import DisabledHardwareSelect from './DisabledHardwareSelect';
import KioskDrinkPricingEdit from './KioskDrinkPricingEdit';
import KioskSettingsStatusMessage from './KioskSettingsStatusMessage';
import KioskPumpsEdit from './KioskPumpsEdit/KioskPumpsEdit';
import KioskTowersEdit from './KioskTowersEdit/KioskTowersEdit';
import KioskCustomerExperienceEdit from './KioskCustomerExperienceEdit/KioskCustomerExperienceEdit';
import KioskBackgroundServicesEdit from './BackgroundServices/KioskBackgroundServicesEdit';
import KioskInventoryControl from './KioskInventoryControl/KioskInventoryControl';

const KioskSettingsEdit: React.FC = () => {
  const kioskSettings = useContext(KioskSettingsContext);
  return (
    <Card style={{ padding: '2%' }}>
      <Card.Body>
        <Card.Title>Settings</Card.Title>
        <KioskSettingsStatusMessage deviceStatus={kioskSettings?.deviceStatus} />
        <Card.Body className='row' style={{}}>
          <div className='col s12 m6' style={{ margin: '10px 0px' }}>
            <KioskStatusSelect />
          </div>
          <div className='col s12 m12'>
            <DisabledHardwareSelect />
          </div>
          <div className='col s12 m12'>
            <KioskDrinkPricingEdit />
          </div>
          <div className='col s12 m12'>
            <KioskPumpsEdit />
          </div>
          <div className='col s12 m12'>
            <KioskTowersEdit />
          </div>
          <div className='col s12 m12'>
            <KioskInventoryControl />
          </div>
          <div className='col s12 m12'>
            <KioskCustomerExperienceEdit />
          </div>
          <div className='col s12 m12'>
            <KioskBackgroundServicesEdit />
          </div>
        </Card.Body>
        <SubmitButton
          label='Save'
          disabled={kioskSettings?.state.loading || !kioskSettings?.state.unsavedChanges}
          onClick={() => kioskSettings?.saveSettings()}
        />
      </Card.Body>
      <Card.Text className='row' style={{}}>
        {kioskSettings?.state?.error?.[KioskEditTabReference.Settings]?.message}
        {kioskSettings?.state?.message?.[KioskEditTabReference.Settings]}
      </Card.Text>
    </Card>
  );
};
export default KioskSettingsEdit;
