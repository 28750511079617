import IconLoading from '../../../../components/ui/Icons/IconLoading';
import KioskMonitorReport from '../../../../hooks/useDeviceMonitor/KioskMonitorReport';
import KioskEditStrings from '../KioskEditStrings';

interface IKioskSettingsStatusMessage {
  deviceStatus?: KioskMonitorReport;
}

const KioskSettingsStatusMessage: React.FC<IKioskSettingsStatusMessage> = ({ deviceStatus }) => {
  return (
    <>
      {!deviceStatus?.loaded && (
        <span style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
          <IconLoading width={30} height={20} /> {KioskEditStrings.FetchingSettings}
        </span>
      )}
      {deviceStatus?.loaded && !deviceStatus?.online && (
        <span style={{ display: 'flex', alignItems: 'center', gap: '5px', color: '#ff4136', fontWeight: 600 }}>
          {KioskEditStrings.SettingsDisabledDeviceNotOnline}
        </span>
      )}
    </>
  );
};
export default KioskSettingsStatusMessage;
