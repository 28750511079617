enum KioskStrings {
  SoftwareUpdateMaintenanceWarning = 'By default, the kiosk software will update within maintenance hours (1am - 4am local time)',
  SoftwareUpdateMaintenanceConfirmTitle = 'Update Software During Maintenance Hours?',
  UserConfirmUpdateDuringMaintenance = 'Update During Maintenance Hours',
  UserConfirmUpdateOutsideMaintenance = 'Update ASAP',
  KioskControlButtonUpdateSoftware = 'Update Software',
  KioskControlButtonRequestScreenshot = 'Take Screenshot',
  ScreenshotTitle = 'Kiosk Screenshot',
  KioskControlButtonReboot = 'Reboot',
  KioskRebootConfirmTitle = 'Reboot Kiosk',
  KioskRebootWarning = 'Are you sure you want to reboot the Kiosk computer? This can take a few minutes and is only advised if customers are not accessing the kiosk. If a feature is not operating as expected, toggling a feature will reset the feature and can fix some issues if they occur. If you still want to continue, please confirm below.',
  UserConfirmReboot = 'I understand, reboot now',
  UserDeclineReboot = 'Cancel',
  CameraSnapshotTitle = 'Kiosk Camera Snapshot',
  KioskControlButtonRequestSnapshot = 'Take Picture',
  KioskControlScreenButton = 'Control',
  KioskConfigureScreenButton = 'Configure'
}

export default KioskStrings;
