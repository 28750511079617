import { MISC, MISC_LOADING } from '../actions/types';

const initialState = {
	loading: false,
	counter: 0
};

export default function miscTestsReducers (state = initialState, action) {
	switch (action.type) {
		case MISC:
			return {
				...state,
				counter: action.payload
			};
		case MISC_LOADING:
			return {
				...state,
				loading: true
			};
		default:
			return state;
	}
}

