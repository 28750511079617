import React, { useCallback, useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-dropdown/style.css';
import '../../css/date_picker.css';
import ReactLoading from 'react-loading';
import Typography from '@material-ui/core/Typography';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import InventoryService from '../../services/Inventory/InventoryService';
import InventoryTable from './InventoryTable/InventoryTable';
import InventoryExcelExport from './InventoryExcelExport';
import InventoryFilters from './InventoryFilters';
import IInventoryListState from './InventoryListState';

const InventoryList: React.FC = () => {
  const initialState = {
    companies: [],
    showFilters: false,
    filterAnchor: false,
    inventory: [],
  };

  const [state, setState] = useState<IInventoryListState>(initialState);

  const getData = useCallback(async () => {
    const inventory = await new InventoryService().getInventory();
    setState((prevState) => ({
      ...prevState,
      inventory,
    }));
  }, []);

  useEffect(() => {
    getData();
  }, [getData]);

  const loadInventoryWithFilters = () => {
    setState({
      ...state,
      showFilters: false,
    });
    setState({ ...state, filterAnchor: false });
  };


  const _onClearFilters = () => {
    setState({
      ...state,
      companies: [],
    });
  };


  const toggleDrawer = () => (event) => {
    console.log('Toggling');
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, filterAnchor: !state.filterAnchor });
  };

  const _onFilterClick = (type, text) => {
    console.log('called: ' + type + ' ' + JSON.stringify(text));
    let tmp = state.companies;
    const index = tmp.indexOf(text);
    if (index === -1) {
      tmp.push(text);
    } else {
      tmp.splice(index, 1);
    }
    setState({
      ...state,
      companies: tmp,
    });
  };

  const _isCheckBoxChecked = (text, arr) => {
    console.log('is checkbox checked: ' + text + ' ' + JSON.stringify(arr));
    let a = arr.includes(text);
    console.log('Is checked: ' + a);
    return a;
  };

  if (!state.inventory) {
    return (
      <div>
        <ReactLoading
          type={'spinningBubbles'}
          color={'#58cbe1'}
          height='20%'
          width='20%'
          className='center-align dash-progress-spinner'
        />
      </div>
    );
  }
  return (
    <div className='valign-wrapper'>
      <div className='row'>
        <div style={{}} className='col s12 overview-header'>
          <div className='col s12' style={{ padding: '0px', marginTop: '15px' }}>
            <Typography
              style={{
                fontWeight: '400',
                fontSize: '24px',
                font: "'Open Sans', sans-serif'",
                paddingRight: '0px',
                paddingLeft: '0px',
              }}
              className='col s4 l10 m10 left'
            >
              Inventory
            </Typography>
            <div className='col s2 l2 m2 right-align' style={{ marginBottom: '10px' }}>
              <InventoryExcelExport inventory={state.inventory} loadInventoryWithFilters={loadInventoryWithFilters} />
            </div>
          </div>
          <div className='col s12 l12 m12'>
            <div className='col s4 l10 m10 left'>
              {state.companies.map((item, i) => (
                <div className='chip chosen-tags'>{item}</div>
              ))}
            </div>
            <div className='col s2 l2 m2 right-align filter-label'>
              <React.Fragment key={'right'}>
                <Button onClick={toggleDrawer()} className='filter-button right-align'>
                  Filters
                </Button>
                <Drawer className='filter-drawer' anchor={'right'} open={state.filterAnchor} onClose={toggleDrawer()}>
                  <InventoryFilters
                    companies={state.companies}
                    toggleDrawer={() => toggleDrawer()}
                    _onFilterClick={(filterType: string, value: string) => _onFilterClick(filterType, value)}
                    _isCheckBoxChecked={_isCheckBoxChecked}
                    _onClearFilters={_onClearFilters}
                    loadInventoryWithFilters={loadInventoryWithFilters}
                    filterAnchor={state.filterAnchor}
                  />
                </Drawer>
              </React.Fragment>
            </div>
          </div>
          <div
            className='col l2 m6 s12 overview-header-orders'
            style={{ padding: '0px !important', marginTop: '15px', marginBottom: '10px' }}
          ></div>
        </div>
        {state.inventory.length > 0 && <InventoryTable inventoryData={state.inventory} />}
      </div>
    </div>
  );
};

export default InventoryList;
