export default class PeripheralUtility {
  static stringAsHex = (code: string): string => {
    const asciiCode = code.charCodeAt(0);
    return `0x${asciiCode.toString(16)}`;
  };

  static numberAsHex = (num: number): string => {
    return `0x${num.toString(16)}`;
  };

}
