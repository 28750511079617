import React from 'react';
import { Checkbox, Divider, IconButton, ListItem, ListItemText, Typography, Button, List } from '@material-ui/core';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { Refresh } from '@material-ui/icons';

interface InventoryFiltersProps {
  companies: string[];
  toggleDrawer: () => void;
  _onFilterClick: (filterType: string, value: string) => void;
  _isCheckBoxChecked: (text: string, list: string[]) => boolean;
  _onClearFilters: () => void;
  loadInventoryWithFilters: () => void;
  filterAnchor: boolean;
}

const InventoryFilters: React.FC<InventoryFiltersProps> = ({
  companies,
  toggleDrawer,
  _onFilterClick,
  _isCheckBoxChecked,
  _onClearFilters,
  loadInventoryWithFilters,
  filterAnchor,
}) => {
  return (
    <div role='presentation' style={{ backgroundColor: '#FFFFFF !important' }}>
      <div role='presentation' style={{ backgroundColor: '#FFFFFF !important' }}>
        <div className='filter-icon-header' style={{}} onClick={() => toggleDrawer()}>
          <IconButton>{filterAnchor ? <ChevronRightIcon /> : <ChevronLeftIcon />}</IconButton>
          <Divider />
        </div>

        <Typography
          style={{ fontWeight: '800', fontSize: '14px', font: "'Open Sans', sans-serif'", marginTop: '15%' }}
          className='filter-items-header'
        >
          Clients
        </Typography>
        <List className='filter-items-list' style={{ backgroundColor: '#F7F7F7 !important' }}>
          {companies.map((text, index) => (
            <ListItem button key={text} onClick={() => _onFilterClick('companyFilter', text)}>
              <Checkbox
                className=''
                disableRipple
                color='default'
                checkedIcon={<CheckBoxIcon fontSize='medium' />}
                icon={<CheckBoxOutlineBlankIcon fontSize='medium' />}
                inputProps={{ 'aria-label': 'decorative checkbox' }}
                checked={_isCheckBoxChecked(text, companies)}
                // onChange={_onCheckBoxChecked}
              />
              <ListItemText primary={text} />
            </ListItem>
          ))}
        </List>

        <Divider style={{ marginBottom: '50px' }} />
        <div className='filter-footer' style={{}}>
          <IconButton
            color='primary'
            aria-label=''
            component='span'
            className='reset-filters'
            onClick={() => _onClearFilters()}
          >
            <Refresh />
          </IconButton>
          <Button onClick={() => loadInventoryWithFilters()} className='filter-reset-button'>
            Done
          </Button>
        </div>
      </div>
    </div>
  );
};

export default InventoryFilters;
