import { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import KioskControlButton from './KioskControlButton';
import KioskStrings from './KioskStrings';

interface IKioskRebootControl {
  display: boolean;
  disabled: boolean;
  onConfirm: () => void;
}

const KioskRebootControl: React.FC<IKioskRebootControl> = ({ display, disabled, onConfirm }) => {
  const [warningDisplay, setWarningDisplay] = useState<boolean>(false);
  return (
    <>
      {warningDisplay && (
        <Dialog
          open={warningDisplay}
          onClose={() => setWarningDisplay(false)}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <DialogTitle id='alert-dialog-title'>{KioskStrings.KioskRebootConfirmTitle}</DialogTitle>
          <DialogContent>
            <DialogContentText id='alert-dialog-description'>
              {KioskStrings.KioskRebootWarning}
            </DialogContentText>
          </DialogContent>
          <div>
            <KioskControlButton
              display={true}
              disabled={false}
              onClick={() => {
                setWarningDisplay(false);
              }}
              label={KioskStrings.UserDeclineReboot}
              color='grey'
            />
            <KioskControlButton
              display={true}
              disabled={false}
              onClick={() => {
                onConfirm();
                setWarningDisplay(false);
              }}
              label={KioskStrings.UserConfirmReboot}
              color='blue'
            />
          </div>
        </Dialog>
      )}
      <KioskControlButton
        display={display}
        disabled={disabled}
        onClick={() => setWarningDisplay(true)}
        label={KioskStrings.KioskControlButtonReboot}
        color='red'
      />
    </>
  );
};

export default KioskRebootControl;
