interface IKioskControlButton {
  display: boolean;
  disabled: boolean;
  label: string;
  color: 'orange' | 'red' | 'blue' | 'grey' | 'lightBlue' | 'cyan';
  onClick: () => void;
}

const KioskControlButton: React.FC<IKioskControlButton> = ({ display, disabled, label, color, onClick }) => {
  if (!display) return null;
  return (
    <button
      style={{
        width: 'auto',
        borderRadius: '3px',
        letterSpacing: '1px',
        margin: '1rem',
        fontSize: '13px',
      }}
      disabled={disabled}
      className={`btn btn-small hoverable ${color}`}
      onClick={onClick}
    >
      {label}
    </button>
  );
};

export default KioskControlButton;
