import GantryControlsStrings from '../GantryControlsStrings';
import Up from '../../../../../../components/ui/Icons/IconArrowUpTwoTone';
import Down from '../../../../../../components/ui/Icons/IconArrowDownTwoTone';
import Left from '../../../../../../components/ui/Icons/IconArrowLeftTwoTone';
import Right from '../../../../../../components/ui/Icons/IconArrowRightTwoTone';
import IconLoading from '../../../../../../components/ui/Icons/IconLoading';
import styles from '../GantryControls.module.css';
import { GantryDevice } from '../../../../../../common/Peripherals/Gantry/GantryMotion';
import { IconButton } from '@material-ui/core';
import GantryJogConfig from '../../../../../../common/Peripherals/Gantry/GantryJogConfig';

interface IGantryJogDirectionButtons {
  loading: boolean;
  config: GantryJogConfig;
  onClick: (config: GantryJogConfig) => void;
}

const GantryJogDirectionButtons: React.FC<IGantryJogDirectionButtons> = ({ loading, config, onClick }) => {
  const renderButton = (
    icon: React.ReactNode,
    axis: GantryDevice.GantryX | GantryDevice.GantryZ,
    distanceMultiplier: number
  ) => (
    <IconButton
      onClick={() => {
        onClick({ ...config, axis, stepDistance: config.stepDistance * distanceMultiplier });
      }}
      disabled={loading}
    >
      {icon}
    </IconButton>
  );
  return (
    <div className={styles.GantryJogInput}>
      <p className='center'>{GantryControlsStrings.JogPrompt}</p>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', opacity: loading ? 0.5 : 1.0 }}>
        {renderButton(<Up width='50' height='50' />, GantryDevice.GantryZ, -1)}
        <div className={styles.grid3}>
          {renderButton(<Left width='50' height='50' />, GantryDevice.GantryX, -1)}
          <div>{loading && <IconLoading width={30} height={30} />}</div>
          {renderButton(<Right width='50' height='50' />, GantryDevice.GantryX, 1)}
        </div>
        {renderButton(<Down width='50' height='50' />, GantryDevice.GantryZ, 1)}
      </div>
      <p className='center'>
        <strong>Warning:</strong> {GantryControlsStrings.JogInstantStartWarning}
      </p>
    </div>
  );
};

export default GantryJogDirectionButtons;
