import { USER_TABLE_LOADING, USER_PROFILE } from "../actions/types";
const initialState = {
  user_profile: {},
  loading: false,
};

export default function userProfileReducers (state = initialState, action) {
  console.log("Reducer called");
  switch (action.type) {
    case USER_PROFILE:
      return {
        ...state,
        user_profile: action.payload,
      };
    case USER_TABLE_LOADING:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
}
