import { MouseEvent, FC } from 'react';

interface ITablePaginationLinks {
  page: number | string;
  active: boolean;
  disable: boolean;
  title: string;
  onPageChange: (page: number | string) => void;
}

const TablePaginationLinks: FC<ITablePaginationLinks> = ({ page, active, disable, title, onPageChange }) => {
  const handleClick = (e: MouseEvent) => {
    e.preventDefault();
    onPageChange(page);
  };

  let activeStyle = {
    backgroundColor: active ? '#2471a3' : 'white',
    color: active ? 'white' : 'black',
    borderRadius: active ? '5px' : '0px',
    display: 'inline-block',
    fontSize: '1.2rem',
    padding: '0 10px',
    lineHeight: '30px',
    border: '0px',
    cursor: 'pointer',
  };

  if (typeof page === 'string') {
    activeStyle = {
      ...activeStyle,
      backgroundColor: 'white',
      color: 'black',
    };
  }

  return (
    <li className='page-item' key={title}>
      <button disabled={disable} title={title} onClick={handleClick} style={activeStyle}>
        {page}
      </button>
    </li>
  );
};

export default TablePaginationLinks;
