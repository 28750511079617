import KioskCommandSequence from '../../../../../common/Kiosk/KioskCommandSequence';
import KioskType from '../../../../../common/Kiosk/KioskType';

const KioskCommandSequenceConfig: Record<KioskCommandSequence, KioskType[]> = {
  [KioskCommandSequence.UncapBottleSequence]: [KioskType.Duo],
  [KioskCommandSequence.CapBottleSequence]: [KioskType.Duo],
  [KioskCommandSequence.FlapSeal]: [KioskType.Duo]
};

export default KioskCommandSequenceConfig;
