const formatFieldName = (string: string) => {
  let formatted = string
    .replace(/([A-Z])/g, ' $1')
    .replace(/_/g, ' ')
    .trim();

  formatted = formatted.charAt(0).toUpperCase() + formatted.slice(1);

  return formatted;
};

export default formatFieldName;
