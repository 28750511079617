export const tableRowStyle = (_row: unknown, rowIndex: number) => {
  const style = {
    backgroundColor: '',
    cursor: 'pointer',
  };
  if (rowIndex === 0 || rowIndex % 2 === 0) {
    style.backgroundColor = '#f2f2f2';
  }
  return style;
};
