import React, { useState, useEffect, useCallback, FC } from 'react';
import { QRCodeSVG } from 'qrcode.react';
import KioskService from '../../../../../services/Kiosk/KioskService';
import UserRole from '../../../../../common/UserRole';
import AuthGuard from '../../../../../components/auth/AuthGuard';
import KioskSelect from './KioskSelect';
import { useAppSelector } from '../../../../../hooks/storeHooks';
import KioskAccessGeneratorStrings from './KioskAccessGeneratorStrings';
import IconLoading from '../../../../../components/ui/Icons/IconLoading';

const KioskAccessGenerator: FC = () => {
  const user = useAppSelector((store) => store.auth?.user);
  const [token, setToken] = useState<string>();
  const [error, setError] = useState<string>();
  const [kioskId, setKioskId] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const [fullscreen, setFullscreen] = useState<boolean>(false);
  const tokenPrefix = 'kiosk-access:';
  const qrValue = `${tokenPrefix}${token}`;

  const getToken = useCallback(async () => {
    if (!kioskId) return;
    try {
      setLoading(true);
      setToken(undefined);
      const response = await new KioskService(kioskId).generateAccessToken();
      setToken(response.token);
      setError(undefined);
    } catch (err) {
      setError(KioskAccessGeneratorStrings.TokenGenerateFailure);
      console.error(err);
    } finally {
      setLoading(false);
    }
  }, [kioskId]);

  useEffect(() => {
    getToken();
  }, [getToken]);

  const fullscreenStyles: React.CSSProperties = {
    position: 'fixed',
    top: 0,
    right: 0,
    zIndex: 9999,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyItems: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    background: '#fff',
    padding: '20px'
  };

  return (
    <>
      <AuthGuard
        authorizedRoles={[UserRole.DropSuperAdmin, UserRole.MachineSuperAdmin, UserRole.MachineAdmin]}
        userRole={user.role}
      >
        {error && <p style={{ color: 'red' }}>{error}</p>}
        <KioskSelect value={kioskId || ''} name='Select Kiosk' label='Select Kiosk' onChange={setKioskId} />
        {token && (
          <div>
            <div onClick={() => setFullscreen(!fullscreen)} style={fullscreen ? fullscreenStyles : {}}>
              <p>{KioskAccessGeneratorStrings.KioskTokenDescription}</p>
              <QRCodeSVG value={qrValue} width='100%' height='auto' />
            </div>
          </div>
        )}
        {!token && loading && <IconLoading width={50} height={50} />}
      </AuthGuard>
      <AuthGuard authorizedRoles={[UserRole.User]} userRole={user.role}>
        {KioskAccessGeneratorStrings.Unauthorized}
      </AuthGuard>
    </>
  );
};

export default KioskAccessGenerator;
