import React from 'react';
import SvgIcon from './SvgIcon';
import IIconAdditive from './IIconAdditive';

const IconAdditiveRaspberryLychee: React.FC<IIconAdditive> = ({
  className,
}) => (
  <SvgIcon
    className={className}
    width="112"
    height="112"
    fill="none"
    viewBox="0 0 112 112"
  >
    <path
      fill="#5c8a1e"
      d="M50.11,37.91v-15.99c4.64-7.64,13.52-9.58,13.52-9.58,0,0,3,5.74,2.2,12.63,6.52-.81,11.97,1.72,11.97,1.72,0,0-1.07,6.25-5.75,11.22h-21.96.01Z"
    />
    <path
      fill="#9ec953"
      d="M50.11,37.91v-15.99c-4.64-7.64-13.52-9.58-13.52-9.58,0,0-3,5.74-2.2,12.63-6.52-.81-11.97,1.72-11.97,1.72,0,0,1.07,6.25,5.75,11.22h21.96-.01Z"
    />
    <path
      fill="#f92f3b"
      d="M43.46,30.81c-1.65-1.8-4.02-2.93-6.66-2.93-5,0-9.04,4.05-9.04,9.04,0,.2.02.39.03.58-.99-.37-2.05-.58-3.17-.58-5,0-9.04,4.05-9.04,9.04,0,2.78,1.25,5.26,3.23,6.92-3.91,1-6.8,4.53-6.8,8.75s2.89,7.75,6.8,8.75c-1.97,1.66-3.23,4.14-3.23,6.92,0,5,4.05,9.04,9.04,9.04.61,0,1.22-.07,1.79-.18-.44,1.07-.69,2.24-.69,3.48,0,5,4.05,9.04,9.04,9.04,2.57,0,4.89-1.08,6.54-2.81.91,4.03,4.51,7.04,8.81,7.04V27.88c-2.64,0-5,1.14-6.66,2.93Z"
    />
    <path
      fill="#d11934"
      d="M88.22,61.64c0-4.22-2.89-7.75-6.8-8.75,1.97-1.66,3.23-4.14,3.23-6.92,0-5-4.05-9.04-9.04-9.04-1.12,0-2.18.21-3.17.58.01-.2.03-.39.03-.58,0-5-4.05-9.04-9.04-9.04-2.64,0-5,1.14-6.66,2.93-1.65-1.8-4.02-2.93-6.66-2.93v75.05c4.31,0,7.9-3.01,8.81-7.04,1.65,1.72,3.97,2.81,6.54,2.81,5,0,9.04-4.05,9.04-9.04,0-1.23-.25-2.4-.69-3.48.58.12,1.18.18,1.79.18,5,0,9.04-4.05,9.04-9.04,0-2.78-1.25-5.26-3.23-6.92,3.91-1,6.8-4.53,6.8-8.75h0Z"
    />
    <path
      fill="#f92f3b"
      d="M59.01,71.54l3.17-5.83c2.66-4.88,4.12-11.07,4.12-17.46v-5.02c0-6.73-2.79-12.44-6.67-14.5-1.08.51-2.06,1.22-2.86,2.09-1.59-1.73-3.85-2.84-6.37-2.92h-.29v50.05c3.29,0,6.57-2.14,8.89-6.41h0Z"
    />
    <g>
      <path
        fill="#e57373"
        d="M78.14,48.74c-.9,0-1.79.39-2.4,1.18l-.12.15c-.92,1.18-2.56,1.52-3.87.79h0c-1.69-.94-3.82-.08-4.38,1.77l-.24.8c-.41,1.35-1.7,2.24-3.11,2.15l-.36-.02c-1.81-.12-3.32,1.37-3.23,3.18l.08,1.51c.07,1.3-.7,2.49-1.9,2.97l-.95.38c-1.58.63-2.33,2.43-1.67,4l.71,1.68c.47,1.13.23,2.43-.63,3.3l-1.13,1.15c-1.15,1.18-1.15,3.07,0,4.24l1.13,1.15c.85.87,1.1,2.17.63,3.3l-.71,1.68c-.66,1.56.09,3.36,1.67,4l.95.38c1.2.48,1.97,1.67,1.9,2.97l-.08,1.51c-.09,1.81,1.42,3.3,3.23,3.18l.36-.02c1.41-.09,2.69.8,3.11,2.15l.24.8c.56,1.85,2.69,2.71,4.38,1.77h0c1.31-.73,2.95-.39,3.87.79l.12.15c.61.78,1.5,1.18,2.4,1.18v-54.19Z"
      />
      <path
        fill="#edeff0"
        d="M78.14,53.37c-10.86,0-19.65,10.06-19.65,22.46s8.8,22.46,19.65,22.46v-44.92Z"
      />
      <path
        fill="#c14d4d"
        d="M78.14,102.93c.9,0,1.79-.39,2.4-1.18l.12-.15c.92-1.18,2.56-1.52,3.87-.79h0c1.69.94,3.82.08,4.38-1.77l.24-.8c.41-1.35,1.7-2.24,3.11-2.15l.36.02c1.81.12,3.32-1.37,3.23-3.18l-.08-1.51c-.07-1.3.7-2.49,1.9-2.97l.95-.38c1.58-.63,2.33-2.43,1.67-4l-.71-1.68c-.47-1.13-.23-2.43.63-3.3l1.13-1.15c1.15-1.18,1.15-3.07,0-4.24l-1.13-1.15c-.85-.87-1.1-2.17-.63-3.3l.71-1.68c.66-1.56-.09-3.36-1.67-4l-.95-.38c-1.2-.48-1.97-1.67-1.9-2.97l.08-1.51c.09-1.81-1.42-3.3-3.23-3.18l-.36.02c-1.41.09-2.69-.8-3.11-2.15l-.24-.8c-.56-1.85-2.69-2.71-4.38-1.77h0c-1.31.73-2.95.39-3.87-.79l-.12-.15c-.61-.78-1.5-1.18-2.4-1.18v54.19h0Z"
      />
      <path
        fill="#cfd8dc"
        d="M78.14,98.3c10.86,0,19.65-10.06,19.65-22.46s-8.8-22.46-19.65-22.46v44.92Z"
      />
      <path
        fill="#edeff0"
        d="M78.14,89.1c4.82,0,8.74-5.94,8.74-13.26s-3.91-13.26-8.74-13.26v26.52h0Z"
      />
      <path
        fill="#cfd8dc"
        d="M78.14,62.57c-4.82,0-8.74,5.94-8.74,13.26s3.91,13.26,8.74,13.26v-26.52h0Z"
      />
    </g>
  </SvgIcon>
);

export default IconAdditiveRaspberryLychee;
