enum ErrorCode {
  SUCCESS = 0,
  BUSY = 1,
  NULL_POINTER = 2,
  BAD_PARAM = 3,
  NO_DATA = 4,
  INCOMPLETE = 5,
  TIMEOUT = 6,
  OUT_OF_MEMORY = 7,
  CORRUPTED = 8,
  NOT_INITIALIZED = 9,
  PERIPHERAL_FAILURE = 10,
  UNIMPLEMENTED = 11,
  BAD_PKT_ID = 12,
  NO_FREE_TX_BUFFER = 13,
  BAD_TX_ID = 14,
  INVALID_CMD = 15,
  CHIP_FAILURE = 16,
  UNKNOWN = 17,
  INTERRUPTED = 18,
  HARDWARE_ERROR = 19,
  INVALID_STATE = 20,
  MOTOR_STALLED = 21,
  MALFORMED_CMD = 22,
  DUPLICATE_CMD = 23,
  POWER_CHECK_FAIL = 24,
  HIGH_POWER_CHECK_FAIL = 25,
  MOTOR_INIT_FAIL = 26,
  ALREADY_IN_POSITION = 27,
  NO_FREE_RX_BUFFER = 28,
  CMD_VALIDATION_FAILED = 29,
  NOT_IN_POSITION = 30,
  CALIBRATION_FAILED = 31,
  INTEGER_OVERFLOW = 32,
  INTEGER_UNDERFLOW = 33,
  UNSET_CAL_FACTOR = 34,
  INVALID_CAL_FACTOR = 35,
  CALCULATION_ERROR = 36,
  UV_FILTER_UNPOWERED = 37,
  ACTION_DISALLOWED = 38,
  NO_FLOW_DETECTED = 39,
  PERIPHERAL_ID_MISMATCH = 40,
  REFILL_TIMEOUT = 41,
  STUCK_ACTUATOR = 42,
  ACTUATOR_AT_UPPER_LIMIT = 43,
  ACTUATOR_AT_LOWER_LIMIT = 44,
  GANTRY_X_ACTUATOR_STUCK = 45,
  GANTRY_Z_ACTUATOR_STUCK = 46,
  GANTRY_X_NOT_AT_FINAL_POSITION = 47,
  GANTRY_Z_NOT_AT_FINAL_POSITION = 48,
  GANTRY_FAILED_TO_GRAB = 49,
  CAPPER_X_POSITION_OUT_OF_RANGE = 50,
  CAPPER_Z_POSITION_OUT_OF_RANGE = 51,
  GANTRY_X_FINAL_POSITION_ADJUSTED = 52,
  GANTRY_Z_FINAL_POSITION_ADJUSTED = 53,
  ZERO_TIME_DELTA = 54,
}

export default ErrorCode;
