import CommandUI from '../FirmwareCommunication/CommandUI';
import CommandCode from '../FirmwareCommunication/Messaging/CommandCode';
import PeripheralIdentifier from '../PeripheralIdentifier';
import TowerAction from './TowerAction';
import TowerNumber from './TowerNumber';
import TowerPosition from './TowerPosition';

const TowerCommands: { [key in CommandCode]?: CommandUI } = {
  [CommandCode.Identify]: {
    name: 'Identify',
    identifier: PeripheralIdentifier.Tower,
    code: CommandCode.Identify,
    args: [],
  },
  [CommandCode.ResetHardware]: {
    name: 'Reset Hardware',
    identifier: PeripheralIdentifier.Tower,
    code: CommandCode.ResetHardware,
    args: [],
  },
  [CommandCode.ResetProcessor]: {
    name: 'Reset Processor',
    identifier: PeripheralIdentifier.Tower,
    code: CommandCode.ResetProcessor,
    args: [],
  },
  [CommandCode.Provision]: {
    name: 'Provision',
    identifier: PeripheralIdentifier.Tower,
    code: CommandCode.Provision,
    args: [
      {
        name: 'PCB Version',
        type: 'decimal',
        options: [],
      },
      {
        name: 'BOM Version',
        type: 'decimal',
        options: [],
      },
      {
        name: 'Serial Number',
        type: 'decimal',
        options: [],
      },
    ],
  },
  [CommandCode.Query]: {
    name: 'Query',
    identifier: PeripheralIdentifier.Tower,
    code: CommandCode.Query,
    args: [],
  },
  [CommandCode.Action]: {
    name: 'Action',
    identifier: PeripheralIdentifier.Tower,
    code: CommandCode.Action,
    args: [
      {
        name: 'Action',
        type: 'select',
        options: [
          {
            name: TowerAction[TowerAction.StopAll],
            value: TowerAction.StopAll,
          },
        ],
      },
    ],
  },
  [CommandCode.Motion]: {
    name: 'Motion',
    identifier: PeripheralIdentifier.Tower,
    code: CommandCode.Motion,
    args: [
      {
        name: 'Tower Select Flags',
        type: 'bitmask',
        options: [
          { name: TowerNumber[TowerNumber.One], value: TowerNumber.One },
          { name: TowerNumber[TowerNumber.Two], value: TowerNumber.Two },
          { name: TowerNumber[TowerNumber.Three], value: TowerNumber.Three },
        ],
      },
      {
        name: 'Tower Position',
        type: 'select',
        options: [
          {
            name: TowerPosition[TowerPosition.DownOneBottle],
            value: TowerPosition.DownOneBottle,
          },
          {
            name: TowerPosition[TowerPosition.LowerUntilSwitch],
            value: TowerPosition.LowerUntilSwitch,
          },
          {
            name: TowerPosition[TowerPosition.RaiseUntilSwitch],
            value: TowerPosition.RaiseUntilSwitch,
          },
          {
            name: TowerPosition[TowerPosition.UpOneBottle],
            value: TowerPosition.UpOneBottle,
          },
        ],
      },
    ],
  },
  [CommandCode.EnterBootLoader]: {
    name: 'EnterBootLoader',
    identifier: PeripheralIdentifier.Tower,
    code: CommandCode.EnterBootLoader,
    args: [],
  },
};

export default TowerCommands;
