import CommandUI from '../FirmwareCommunication/CommandUI';
import CalibrationAction from '../FirmwareCommunication/Messaging/Calibration/CalibrationAction';
import CommandCode from '../FirmwareCommunication/Messaging/CommandCode';
import PeripheralIdentifier from '../PeripheralIdentifier';
import DeckAction from './DeckAction';
import DeckCalibrationFactor from './DeckCalibrationFactor';
import DeckDispenseTemperature from './DeckDispenseTemperature';
import { DeckMotionDevice, DeckMotionPosition } from './DeckMotion';

const DeckCommands: { [key in CommandCode]?: CommandUI } = {
  [CommandCode.Identify]: {
    name: 'Identify',
    identifier: PeripheralIdentifier.Deck,
    code: CommandCode.Identify,
    args: [],
  },
  [CommandCode.ResetHardware]: {
    name: 'Reset Hardware',
    identifier: PeripheralIdentifier.Deck,
    code: CommandCode.ResetHardware,
    args: [],
  },
  [CommandCode.ResetProcessor]: {
    name: 'Reset Processor',
    identifier: PeripheralIdentifier.Deck,
    code: CommandCode.ResetProcessor,
    args: [],
  },
  [CommandCode.Provision]: {
    name: 'Provision',
    identifier: PeripheralIdentifier.Deck,
    code: CommandCode.Provision,
    args: [
      {
        name: 'PCB Version',
        type: 'decimal',
        options: [],
      },
      {
        name: 'BOM Version',
        type: 'decimal',
        options: [],
      },
      {
        name: 'Serial Number',
        type: 'decimal',
        options: [],
      },
    ],
  },
  [CommandCode.Query]: {
    name: 'Query',
    identifier: PeripheralIdentifier.Deck,
    code: CommandCode.Query,
    args: [],
  },
  [CommandCode.Action]: {
    name: 'Action',
    identifier: PeripheralIdentifier.Deck,
    code: CommandCode.Action,
    args: [
      {
        name: 'Action',
        type: 'select',
        options: [
          {
            name: DeckAction[DeckAction.Stop],
            value: DeckAction.Stop,
          },
          {
            name: DeckAction[DeckAction.ResetFlowMeterTicks],
            value: DeckAction.ResetFlowMeterTicks,
          },
        ],
      },
    ],
  },
  [CommandCode.Motion]: {
    name: 'Motion',
    identifier: PeripheralIdentifier.Deck,
    code: CommandCode.Motion,
    args: [
      {
        name: 'Device',
        type: 'select',
        options: [
          {
            name: DeckMotionDevice[DeckMotionDevice.Capper],
            value: DeckMotionDevice.Capper,
          },
          {
            name: DeckMotionDevice[DeckMotionDevice.UVProber],
            value: DeckMotionDevice.UVProber,
          },
          {
            name: DeckMotionDevice[DeckMotionDevice.AmbientDispenser],
            value: DeckMotionDevice.AmbientDispenser,
          },
          {
            name: DeckMotionDevice[DeckMotionDevice.ColdDispenser],
            value: DeckMotionDevice.ColdDispenser,
          },
          {
            name: DeckMotionDevice[DeckMotionDevice.UVFilter],
            value: DeckMotionDevice.UVFilter,
          },
          {
            name: DeckMotionDevice[DeckMotionDevice.BottleDispenser],
            value: DeckMotionDevice.BottleDispenser,
          },
        ],
      },
      {
        name: 'Position',
        type: 'select',
        options: [
          {
            name: DeckMotionPosition[DeckMotionPosition.CapperCap],
            value: DeckMotionPosition.CapperCap,
          },
          {
            name: DeckMotionPosition[DeckMotionPosition.CapperUncap],
            value: DeckMotionPosition.CapperUncap,
          },
          {
            name: DeckMotionPosition[DeckMotionPosition.UVProbeFilterDispensersOn],
            value: DeckMotionPosition.UVProbeFilterDispensersOn,
          },
          {
            name: DeckMotionPosition[DeckMotionPosition.UVProbeFilterDispensersOff],
            value: DeckMotionPosition.UVProbeFilterDispensersOff,
          },
          {
            name: DeckMotionPosition[DeckMotionPosition.ClearCapper],
            value: DeckMotionPosition.ClearCapper,
          },
        ],
      },
      {
        name: 'Magnitude (Temperature - BottleDispenser Only)',
        type: 'select',
        options: [
          {
            name: DeckDispenseTemperature[DeckDispenseTemperature.Cold],
            value: DeckDispenseTemperature.Cold,
          },
          {
            name: DeckDispenseTemperature[DeckDispenseTemperature.One],
            value: DeckDispenseTemperature.One,
          },
          {
            name: DeckDispenseTemperature[DeckDispenseTemperature.Two],
            value: DeckDispenseTemperature.Two,
          },
          {
            name: DeckDispenseTemperature[DeckDispenseTemperature.Three],
            value: DeckDispenseTemperature.Three,
          },
          {
            name: DeckDispenseTemperature[DeckDispenseTemperature.One],
            value: DeckDispenseTemperature.One,
          },
          {
            name: DeckDispenseTemperature[DeckDispenseTemperature.Four],
            value: DeckDispenseTemperature.Four,
          },
          {
            name: DeckDispenseTemperature[DeckDispenseTemperature.Five],
            value: DeckDispenseTemperature.Five,
          },
          {
            name: DeckDispenseTemperature[DeckDispenseTemperature.Six],
            value: DeckDispenseTemperature.Six,
          },
          {
            name: DeckDispenseTemperature[DeckDispenseTemperature.Seven],
            value: DeckDispenseTemperature.Seven,
          },
          {
            name: DeckDispenseTemperature[DeckDispenseTemperature.Ambient],
            value: DeckDispenseTemperature.Ambient,
          },
        ],
      },
      {
        name: 'Capper Number Steps',
        type: 'decimal',
        options: [],
      },
      {
        name: 'Bottle Volume - (Bottle Dispense only, mL)',
        type: 'decimal',
        options: [],
      },
    ],
  },

  [CommandCode.EnterBootLoader]: {
    name: 'Enter BootLoader',
    identifier: PeripheralIdentifier.Deck,
    code: CommandCode.EnterBootLoader,
    args: [],
  },
  [CommandCode.Calibrate]: {
    name: 'Calibrate',
    identifier: PeripheralIdentifier.Deck,
    code: CommandCode.Calibrate,
    args: [
      {
        name: 'Calibration Factor',
        type: 'select',
        options: [
          {
            name: DeckCalibrationFactor[DeckCalibrationFactor.FlowRateCorrection],
            value: DeckCalibrationFactor.FlowRateCorrection,
          },
          {
            name: DeckCalibrationFactor[DeckCalibrationFactor.CapperVerifyMin1],
            value: DeckCalibrationFactor.CapperVerifyMin1,
          },
          {
            name: DeckCalibrationFactor[DeckCalibrationFactor.CapperVerifyMax1],
            value: DeckCalibrationFactor.CapperVerifyMax1,
          },
          {
            name: DeckCalibrationFactor[DeckCalibrationFactor.CapperVerifyMin2],
            value: DeckCalibrationFactor.CapperVerifyMin2,
          },
          {
            name: DeckCalibrationFactor[DeckCalibrationFactor.CapperVerifyMax2],
            value: DeckCalibrationFactor.CapperVerifyMax2,
          },
        ],
      },
      {
        name: 'Action',
        type: 'select',
        options: [
          {
            name: CalibrationAction[CalibrationAction.CalculateAndStore],
            value: CalibrationAction.CalculateAndStore,
          },
          {
            name: CalibrationAction[CalibrationAction.RetrieveStored],
            value: CalibrationAction.RetrieveStored,
          },
          {
            name: CalibrationAction[CalibrationAction.OverwriteFactor],
            value: CalibrationAction.OverwriteFactor,
          },
        ],
      },
      {
        name: 'Value',
        type: 'decimal',
        options: [],
      },
    ],
  },
};

export default DeckCommands;
