import IKioskHealthReport from '../../../services/Reports/IKiosksHealthReport';
import PageHeading from '../../../components/Heading/PageHeading';
import KioskHealthReportKeys from './KioskHealthReportKeys';
import KioskHealthState from './KioskHealthState';
import { useCallback, useEffect, useState } from 'react';
import AuthGuard from '../../../components/auth/AuthGuard';
import UserRole from '../../../common/UserRole';
import DataTable from '../../../components/table/DataTable';
import KiosksHealthReportsTableConfig from './KiosksHealthReportsTableConfig';
import { useAppSelector } from '../../../hooks/storeHooks';
import useReports from '../../../services/Reports/useReports';
import ReportsStrings from '../ReportsStrings';

interface IKioskHealth {}

const KioskHealth: React.FC<IKioskHealth> = () => {
  const initialState: KioskHealthState = {
    data: [],
    loading: true
  };
  const [state, setState] = useState<KioskHealthState>(initialState);
  const user = useAppSelector((store) => store.auth?.user);
  const { getKiosksHealthReport } = useReports();

  const fetchHealthReport = useCallback(async () => {
    try {
      const kiosksHealthReport = await getKiosksHealthReport();
      kiosksHealthReport?.sort((a, b) => a.kioskId.localeCompare(b.kioskId));
      setState((prevState) => ({
        ...prevState,
        data: kiosksHealthReport || [],
        error: !kiosksHealthReport || kiosksHealthReport.length === 0 ? 'No Kiosk Health Reports' : undefined,
        loading: false
      }));
    } catch (error) {
      setState((prevState) => ({
        ...prevState,
        error: 'Failed to fetch kiosk health reports',
        loading: false
      }));
    }
  }, [getKiosksHealthReport]);

  useEffect(() => {
    fetchHealthReport();
  }, [fetchHealthReport, getKiosksHealthReport]);

  if (!user?.role) return null;

  return (
    <AuthGuard
      authorizedRoles={[UserRole.MachineSuperAdmin, UserRole.MachineAdmin, UserRole.DropSuperAdmin]}
      userRole={user?.role}
    >
      <div className='valign-wrapper'>
        <div className='row' style={{ width: '100%' }}>
          <div className='col s12' style={{ padding: '0px', marginTop: '15px' }}>
            <div className='col s12'>
              <PageHeading heading='Kiosks Health' />
            </div>
            <div className='col s4' style={{ justifyContent: 'end', display: 'flex' }}></div>
          </div>
          <>{state.error}</>
          <DataTable<IKioskHealthReport, KioskHealthReportKeys>
            data={state.data}
            loading={state.loading}
            totalSize={state.data.length}
            contextName='Kiosks'
            dataColumnConfig={KiosksHealthReportsTableConfig()}
            keyField='kioskId'
          />
          <p>{ReportsStrings.DatesConvertedToLocalWarning}</p>
        </div>
      </div>
    </AuthGuard>
  );
};

export default KioskHealth;
