import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { Card } from 'react-bootstrap';
import filterFactory from 'react-bootstrap-table2-filter';
import Dropdown from 'react-dropdown';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider,
  PaginationTotalStandalone,
  PaginationListStandalone,
} from 'react-bootstrap-table2-paginator';
import TableTotals from './TableTotals';
import DataColumn from './DataColumn';
import IconLoading from '../ui/Icons/IconLoading';
import { tableRowStyle } from './tableRowStyle';
import { useState } from 'react';
import TablePaginationLinks from './TablePagination';

interface IExpandingRow<T> {
  renderer: (row: T) => JSX.Element | undefined;
  onlyOneExpanding: boolean;
}

interface IDataTable<T, K extends keyof T | string> {
  totalSize: number;
  contextName: string;
  dataColumnConfig: DataColumn<T, K>[];
  data: unknown;
  loading: boolean;
  expandingRow?: IExpandingRow<T>;
  rowEvents?: { onClick: (_e: React.MouseEvent, row: T) => void };
  keyField?: string;
}

function DataTable<T, K extends keyof T | string>(props: IDataTable<T, K>) {
  const { totalSize, contextName, dataColumnConfig, data, loading, expandingRow, rowEvents, keyField = '_id' } = props;

  const [state, setState] = useState({
    pageCountSelected: 50,
  });
  const options = {
    sizePerPage: state.pageCountSelected,
    custom: true,
    totalSize: totalSize,
    pageButtonRenderer: TablePaginationLinks,
    paginationTotalRenderer: (from: number, to: number, size: number) => (
      <TableTotals from={from} to={to} size={size} contextLabel={contextName} />
    ),
  };
  const pageCount = ['10', '20', '50', '100'];
  const handleSizePerPage = ({ page, onSizePerPageChange }, newSizePerPage) => {
    onSizePerPageChange(newSizePerPage, page);
  };
  const onSelectPageCount = (value: number, paginationProps, length: number) => {
    paginationProps.page = 1;
    setState({
      ...state,
      pageCountSelected: value,
    });
    handleSizePerPage(paginationProps, value);
  };

  return (
    <Card className='col s12 l12 m12 ' style={{ borderRadius: '7px' }}>
      <div className='col s12 l12 m12' style={{ width: '100% !important' }}>
        <PaginationProvider pagination={paginationFactory(options)}>
          {({ paginationProps, paginationTableProps }) => (
            <div className='col s12 l12 m12 h-100 table-wrapper'>
              <BootstrapTable
                keyField={keyField}
                style={{
                  maxWidth: '100%',
                }}
                data={data}
                columns={dataColumnConfig}
                wrapperClasses='table-responsive'
                noDataIndication={
                  loading ? (
                    <IconLoading width={50} height={50} />
                  ) : (
                    `No ${contextName.toLocaleLowerCase()} for selected filters`
                  )
                }
                rowStyle={tableRowStyle}
                expandRow={expandingRow}
                filter={filterFactory()}
                rowEvents={rowEvents}
                {...paginationTableProps}
              />

              <PaginationTotalStandalone className='col l4' {...paginationProps} />
              <div className='per-page-select'>
                <div className='col l3 right-align orders-per-page'>
                  <strong>{contextName} Per Page</strong>
                </div>
                <div className='col s2 l1 m2 select-page-count center-align'>
                  <Dropdown
                    options={pageCount}
                    onChange={(option) => onSelectPageCount(+option.value, paginationProps, totalSize)}
                    value={state.pageCountSelected.toString()}
                  />
                </div>
              </div>
              <PaginationListStandalone className='col l4' key={Math.random()} {...paginationProps} />
            </div>
          )}
        </PaginationProvider>
      </div>
    </Card>
  );
}

export default DataTable;
