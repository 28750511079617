import IIconAdditive from './IIconAdditive';
import SvgIcon from './SvgIcon';

const IconAdditiveMintMojito: React.FC<IIconAdditive> = ({ className }) => (
  <SvgIcon
    className={className}
    width="112"
    height="112"
    fill="none"
    viewBox="0 0 112 112"
  >
    <path fill="#8ee0fd" d="M56.18,96.03h23.87l7.02-61.8h-30.9v61.8Z" />
    <path fill="#d6d6d6" d="M56.18,15.89v18.35h30.72l2.11-18.35h-32.83Z" />
    <path fill="#81d0df" d="M33.05,96.03h23.13v-61.8h-29.93l6.8,61.8Z" />
    <path fill="#adadad" d="M23.35,15.89l2.11,18.35h30.72V15.89H23.35Z" />
    <path
      fill="#d6d6d6"
      d="M33.03,96.15L24.16,15.89h-5.64l9.49,85.93h28.16v-5.67h-23.15Z"
    />
    <path
      fill="#adadad"
      d="M79.92,96.15l9.1-80.26h5.78l-9.74,85.93h-28.88v-5.67h23.74Z"
    />
    <path
      fill="#93bc00"
      d="M82.86,70.16c0-14.49-11.75-26.23-26.23-26.23v52.47c14.49,0,26.23-11.75,26.23-26.23Z"
    />
    <path
      fill="#d9ed85"
      d="M79.07,70.16c0-12.4-10.05-22.44-22.44-22.44v44.9c12.4,0,22.44-10.05,22.44-22.44h0Z"
    />
    <path
      fill="#fefdda"
      d="M71.33,70.16c0-8.12-6.59-14.71-14.71-14.71v29.42c8.12,0,14.71-6.59,14.71-14.71Z"
    />
    <path
      fill="#93bc00"
      d="M61.77,73.98l10.3,4.27c.67.27.84,1.14.33,1.65l-6.03,6.03c-.51.51-1.37.34-1.65-.33l-4.27-10.3c-.34-.83.49-1.67,1.33-1.33h0Z"
    />
    <path
      fill="#93bc00"
      d="M62.97,69.23l10.3-4.27c.67-.27,1.4.21,1.4.94v8.53c0,.72-.73,1.21-1.4.94l-10.3-4.27c-.83-.35-.83-1.53,0-1.87Z"
    />
    <path
      fill="#93bc00"
      d="M66.36,54.39l6.03,6.03c.51.51.34,1.37-.33,1.65l-10.3,4.27c-.83.35-1.67-.49-1.33-1.33l4.27-10.3c.27-.67,1.14-.84,1.65-.33h0Z"
    />
    <path
      fill="#93bc00"
      d="M56.63,88.2h4.27c.72,0,1.21-.73.94-1.4l-4.27-10.3c-.18-.42-.55-.62-.94-.62v12.33Z"
    />
    <path
      fill="#93bc00"
      d="M56.63,64.45c.38,0,.76-.21.94-.62l4.27-10.3c.27-.67-.21-1.4-.94-1.4h-4.27v12.33Z"
    />
    <path fill="#f0f3ff" d="M43.67,50.63h-12.95v18.7h12.95v-18.7Z" />
    <path fill="#dedeff" d="M56.62,50.63h-12.95v18.7h12.95v-18.7Z" />
    <path fill="#f0f3ff" d="M69.58,25.22h-12.95v18.7h12.95v-18.7Z" />
    <path fill="#dedeff" d="M82.53,25.22h-12.95v18.7h12.95v-18.7Z" />
    <path
      fill="#9ec953"
      d="M62.36,11.71l-31.5,31.5c7.04,7.04,18.96,6.54,26.63-1.12,10.8-10.8,4.87-30.38,4.87-30.38Z"
    />
    <path
      fill="#5c8a1e"
      d="M62.36,11.71l-31.5,31.5c-7.04-7.04-6.54-18.96,1.12-26.63,10.8-10.8,30.38-4.87,30.38-4.87Z"
    />
    <path
      fill="#386200"
      d="M36.27,37.8l2-16.83s2.75,5.25,1.66,13.17l-3.67,3.67h0Z"
    />
    <path
      fill="#386200"
      style={{ opacity: 0.5 }}
      d="M36.27,37.8l16.83-2s-5.25-2.75-13.17-1.66l-3.67,3.67h0Z"
    />
    <path
      fill="#386200"
      d="M46.61,27.47l1.44-12.11s1.98,3.78,1.2,9.47l-2.64,2.64Z"
    />
    <path
      fill="#386200"
      style={{ opacity: 0.5 }}
      d="M46.61,27.47l12.11-1.44s-3.78-1.98-9.47-1.2l-2.64,2.64Z"
    />
  </SvgIcon>
);

export default IconAdditiveMintMojito;
