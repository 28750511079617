import React from 'react';
import { Divider, IconButton, Typography, Button } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Refresh from '@material-ui/icons/Refresh';
import OrderType from '../../../common/OrderType';
import TransactionType from '../../../common/TransactionType';
import IOrdersQuery from '../../../services/Orders/IOrdersQuery';
import OrdersListState from '../../../screens/Orders/OrdersListState';
import OrdersFiltersState from './OrdersFiltersState';
import FilterCheckboxList from '../FilterCheckboxList';
import AuthGuard from '../../auth/AuthGuard';
import UserRole from '../../../common/UserRole';
import OverviewState from '../../../screens/Overview/OverviewState';
import CompanyFilterCheckboxList from '../CompanyFilterCheckboxList';

interface FilterListProps {
  state: OrdersListState | OverviewState;
  filters: OrdersFiltersState;
  _onFilterClick: (filterKey: keyof IOrdersQuery, text: string) => void;
  _onClearFilters: () => void;
  toggleDrawer: () => void;
  userRole: UserRole;
}

const OrdersFilters: React.FC<FilterListProps> = ({
  state,
  filters,
  _onFilterClick,
  _onClearFilters,
  toggleDrawer,
  userRole,
}) => {
  const orderTypeValues = Object.values(OrderType);
  const transactionTypeValues = Object.values(TransactionType);
  const authorizedRoles: UserRole[] = [UserRole.DropSuperAdmin];
  return (
    <div role='presentation' style={{ backgroundColor: '#FFFFFF !important' }}>
      <div className='filter-icon-header' onClick={toggleDrawer}>
        <IconButton>{state.filterAnchor ? <ChevronRightIcon /> : <ChevronLeftIcon />}</IconButton>
        <Divider />
      </div>
      <Divider style={{ marginBottom: '50px' }} />
      <AuthGuard userRole={userRole} authorizedRoles={authorizedRoles}>
        <Typography
          style={{ fontWeight: '800', fontSize: '14px', font: "'Open Sans', sans-serif'", marginTop: '100px' }}
          className='filter-items-header'
        >
          Companies
        </Typography>
        <CompanyFilterCheckboxList
          items={state.companies || []}
          filterKey='companies'
          _onFilterClick={_onFilterClick}
          checkedItems={filters.companies || []}
        />
      </AuthGuard>
      <Typography
        style={{
          fontWeight: '800',
          fontSize: '14px',
          font: "'Open Sans', sans-serif'",
          marginLeft: '5%',
          marginTop: '5%',
        }}
        className='filter-items-header'
      >
        Kiosks
      </Typography>
      <FilterCheckboxList
        items={state.kiosks || []}
        filterKey='kiosks'
        _onFilterClick={_onFilterClick}
        checkedItems={filters.kiosks || []}
      />

      <Typography
        style={{
          fontWeight: '800',
          fontSize: '14px',
          font: "'Open Sans', sans-serif'",
          marginLeft: '5%',
          marginTop: '5%',
        }}
        className='filter-items-header'
      >
        Order Type
      </Typography>
      <FilterCheckboxList
        items={orderTypeValues}
        filterKey='orderTypes'
        _onFilterClick={_onFilterClick}
        checkedItems={filters.orderTypes || []}
      />

      <Typography
        style={{
          fontWeight: '800',
          fontSize: '14px',
          font: "'Open Sans', sans-serif'",
          marginLeft: '5%',
          marginTop: '5%',
        }}
        className='filter-items-header'
      >
        Price
      </Typography>
      <FilterCheckboxList
        items={transactionTypeValues}
        filterKey='transactionTypes'
        _onFilterClick={_onFilterClick}
        checkedItems={filters.transactionTypes || []}
      />

      <Divider style={{ marginBottom: '50px' }} />
      <div className='filter-footer' style={{}}>
        <IconButton
          color='primary'
          aria-label=''
          component='span'
          className='reset-filters'
          onClick={() => _onClearFilters()}
        >
          <Refresh />
        </IconButton>
        <Button className='filter-reset-button' onClick={toggleDrawer}>
          Done
        </Button>
      </div>
    </div>
  );
};

export default OrdersFilters;
