export const GET_ERRORS = "GET_ERRORS";
export const USER_LOADING = "USER_LOADING";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const ALL_USERS = "ALL_USERS";
export const USER_TABLE_LOADING = "USER_TABLE_LOADING";
export const EMAIL_QRCODE = "EMAIL_QRCODE";
export const ALL_ORDERS = "ALL_ORDERS";
export const ORDER_TABLE_LOADING = "ORDER_TABLE_LOADING";
export const DASHBOARD_METRICS = "DASHBOARD_METRICS";
export const INVENTORY_TABLE_LOADING = "INVENTORY_TABLE_LOADING";
export const INVENTORY = "INVENTORY";
export const USER_INTERACTIONS = "USER_INTERACTIONS";
export const USER_INTERACTIONS_TABLE_LOADING = "USER_INTERACTIONS_TABLE_LOADING";
export const USER_INTERACTION_VIDEO = "USER_INTERACTION_VIDEO";
export const ORDER_CREATE = "ORDER_CREATE";
export const ORDER_CREATE_LOADING = "ORDER_CREATE_LOADING";
export const KIOSK = "KIOSK";
export const KIOSK_LOADING = "KIOSK_LOADING";
export const VALIDATE_CURRENT_USER = "VALIDATE_CURRENT_USER";
export const KIOSK_DETAILS = "KIOSK_DETAILS";
export const KIOSK_DETAILS_LOADING = "KIOSK_DETAILS_LOADING";
export const KIOSK_ID = "KIOSK_ID";
export const MISC = "MISC";
export const MISC_LOADING = "MISC_LOADING";
export const USER_PROFILE = "USER_PROFILE";
export const REPORT_LOADING = "REPORT_LOADING";
export const REPORTS = "REPORTS";
export const REPORT_DETAILS = "REPORT_DETAILS";
// export const ORDER_CREATE = "ORDER_CREATE";
// export const ORDER_CREATE = "ORDER_CREATE";
