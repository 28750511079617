import IconLoading from '../../../components/ui/Icons/IconLoading';

interface IKioskEditLoading {
  message?: string;
  display?: boolean;
}

const KioskEditLoading: React.FC<IKioskEditLoading> = ({ message = '', display }) => {
  if (!display) return null;
  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
      <IconLoading width={50} height={20} /> {message}
    </div>
  );
};

export default KioskEditLoading;
