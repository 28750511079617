enum KioskStatus {
  PeripheralProvisioning = 'provision',
  MachineAssembly = 'assembly',
  AssemblyQA = 'assembly-qa',
  Calibration = 'calibration',
  DeploymentQA = 'deployment-qa',
  Deployed = 'deployed',
}

export default KioskStatus;
