import React from 'react';
import ICompany from '../../services/Company/ICompany';

interface ICompanyTags {
  companyIds: string[];
  companies: ICompany[];
  label?: string;
}

const CompanyFilterTags: React.FC<ICompanyTags> = ({ companyIds, companies, label = 'Companies' }) => {
  if (companyIds?.length === 0) return null;
  return (
    <div className='chip chosen-tags'>
      <strong>{label}: </strong>
      {companyIds.map((companyId, i) => companies.find((company) => company._id === companyId)?.name).join(', ')}
    </div>
  );
};

export default CompanyFilterTags;
