import IconProps from './IconProps';
import SvgIcon from './SvgIcon';

const IconArrowUpTwoTone: React.FC<IconProps> = ({
  className,
  width,
  height,
}) => (
  <SvgIcon className={className} width={width} height={height} fill="#555">
    <path
      fill="#000000"
      d="M12 20c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8m-1-8v4h2v-4h3l-4-4-4 4z"
      opacity=".3"
    />
    <path
      fill="#000000"
      d="M12 20c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8m0 2c5.52 0 10-4.48 10-10S17.52 2 12 2 2 6.48 2 12s4.48 10 10 10m-1-10v4h2v-4h3l-4-4-4 4z"
    />
  </SvgIcon>
);
export default IconArrowUpTwoTone;
