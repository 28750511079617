import LightColorRGB from './LightColorRGB';

const LightColor: Record<string, LightColorRGB> = {
  water: [0, 255, 255],
  white: [255, 255, 255],
  black: [0, 0, 0],
  unassigned: [255, 255, 255],
  tropicalEnergy: [209, 71, 121],
  mint: [158, 201, 83],
  orange: [255, 194, 72],
  passionOrangeGuava: [237, 135, 106],
  peach: [226, 141, 60],
  pomegranate: [209, 71, 121],
  raspberry: [249, 47, 59],
  strawberry: [249, 60, 18],
  teaPlusLemonade: [226, 141, 60],
  ginger: [255, 248, 77],
  blackberry: [36, 54, 81],
};

export default LightColor;
