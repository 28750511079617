export enum ReceptionMotionDevice {
  Door = 0,
  Slider = 1,
  DoorLock = 2,
}

export enum ReceptionMotionPosition {
  DoorAndLockClose = 0,
  DoorAndLockOpen = 1,
  SliderGarbage = 2,
  SliderDropOff = 3,
  SliderAndDoorReset = 4,
}
