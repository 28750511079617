import axios from "axios";
import setAuthToken from "../utils/setAuthToken";
import jwt_decode from "jwt-decode";
import {
  GET_ERRORS,
  SET_CURRENT_USER,
  USER_LOADING,
  VALIDATE_CURRENT_USER,
} from "./types";
import ConfigService from "../services/ConfigService";
// Register User
export const registerUser = (userData, userId, history) => (dispatch) => {
  axios
    .post(`${ConfigService.apiHost}/users/register`, { ...userData, userId: userId })
    .then((res) => history.push("/login")) // re-direct to login on successful register
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};
// Login - get user token
export const loginUser = (userData) => async (dispatch) => {
  try {
    const response = (await axios.post(`${ConfigService.apiHost}/auth/login`, userData)).data;
    if (!response?.token){
      throw new Error('Unsuccessful login attempt - no token retrieved')
    }
    localStorage.setItem("jwtToken", response.token);
    setAuthToken(response.token);
    const { user } = jwt_decode(response.token);
    if (!user) {
      localStorage.removeItem("jwtToken");
      throw new Error('No user provided with auth response')
    }
    localStorage.setItem("uuid", user.uuid);
    localStorage.setItem("role", user.role);
    dispatch(setCurrentUser(user));
  } catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload: error.response.data,
    });
  }
};
// Set logged in user
export const setCurrentUser = (decoded) => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded,
  };
};
// User loading
export const setUserLoading = () => {
  return {
    type: USER_LOADING,
  };
};
// Log user out
export const logoutUser = () => (dispatch) => {
  process.env.DEBUG === "true" && console.log("log out user clicked");
  // Remove token from local storage
  localStorage.removeItem("jwtToken");
  localStorage.removeItem("uuid");
  localStorage.removeItem("role");
  // Remove auth header for future requests
  setAuthToken(false);
  // Set current user to empty object {} which will set isAuthenticated to false
  dispatch(setCurrentUser({}));
};

export const validateUser = (userData) => (dispatch) => {
  axios
    .post(`${ConfigService.apiHost}/users/validate`, userData)
    .then((res) => {
      dispatch(setCurrentUser(res.data));
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

export const validateCurrentUser = (isValid) => {
  return {
    type: VALIDATE_CURRENT_USER,
    payload: isValid,
  };
};
