import { Checkbox, ListItem, ListItemText, List } from '@material-ui/core';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import IOrdersQuery from '../../services/Orders/IOrdersQuery';

interface FilterListProps {
  items: string[];
  filterKey: keyof IOrdersQuery;
  _onFilterClick: (filterKey: keyof IOrdersQuery, text: string) => void;
  checkedItems: string[];
}

const FilterCheckboxList: React.FC<FilterListProps> = ({ items, filterKey, _onFilterClick, checkedItems }) => {
  const _isCheckBoxChecked = (text: string, arr: string[]) => arr.includes(text);

  return (
    <List>
      {items.map((item) => (
        <ListItem button key={item} onClick={() => _onFilterClick(filterKey, item)}>
          <Checkbox
            className='filter-items-list'
            disableRipple
            color='default'
            checkedIcon={<CheckBoxIcon fontSize='medium' />}
            icon={<CheckBoxOutlineBlankIcon fontSize='medium' />}
            inputProps={{ 'aria-label': 'decorative checkbox' }}
            checked={_isCheckBoxChecked(item, checkedItems)}
          />
          <ListItemText primary={item} />
        </ListItem>
      ))}
    </List>
  );
};

export default FilterCheckboxList;
