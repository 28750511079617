import { Features } from '../../../../common/Kiosk/KioskSettings';
import KioskType from '../../../../common/Kiosk/KioskType';

export default class FeaturesSettingsUtility {
  private static featureConfig: Record<keyof Features, { order: number; kioskTypes: KioskType[] }> = {
    bottleOrders: { order: 1, kioskTypes: [KioskType.Duo] },
    refillOrders: { order: 2, kioskTypes: [] },
    payment: { order: 3, kioskTypes: [] },
    lighting: { order: 4, kioskTypes: [] },
    promotions: { order: 5, kioskTypes: [] }
  };

  static getOrderedSettings = (settings: Features, kioskType: KioskType): Partial<Features> => {
    return Object.keys(settings)
      .filter((key) => {
        const config = this.featureConfig[key as keyof Features];
        return config.kioskTypes.length === 0 || config.kioskTypes.includes(kioskType);
      })
      .sort((a, b) => this.featureConfig[a as keyof Features].order - this.featureConfig[b as keyof Features].order)
      .reduce((acc, key) => {
        acc[key as keyof Features] = settings[key as keyof Features];
        return acc;
      }, {} as Partial<Features>);
  };
}
