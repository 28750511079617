import React from 'react';
import SvgIcon from './SvgIcon';
import IIconAdditive from './IIconAdditive';

const IconAdditivePomegranateAcai: React.FC<IIconAdditive> = ({
  className,
}) => (
  <SvgIcon
    className={className}
    width="112"
    height="112"
    fill="none"
    viewBox="0 0 112 112"
  >
    <path
      fill="#d14779"
      d="M9.59,55.59c0,25.07,20.32,45.39,45.39,45.39V10.21c-25.07,0-45.39,20.32-45.39,45.39h0Z"
    />
    <path
      fill="#a3164f"
      d="M100.36,55.59c0-25.07-20.32-45.39-45.39-45.39v90.77c25.07,0,45.39-20.32,45.39-45.39h0Z"
    />
    <path
      fill="#fffdff"
      d="M14.4,55.59c0,22.38,18.14,40.53,40.53,40.53V15.06c-22.38,0-40.53,18.14-40.53,40.53Z"
    />
    <path
      fill="#efddeb"
      d="M95.45,55.59c0-22.38-18.14-40.53-40.53-40.53v81.06c22.38,0,40.53-18.14,40.53-40.53Z"
    />
    <g fill="#a3164f">
      <path d="M62.87,31.63c2.74,0,4.96-2.22,4.96-4.96s-2.22-4.96-4.96-4.96-4.96,2.22-4.96,4.96,2.22,4.96,4.96,4.96Z" />
      <path d="M62.87,43.2c2.74,0,4.96-2.22,4.96-4.96s-2.22-4.96-4.96-4.96-4.96,2.22-4.96,4.96,2.22,4.96,4.96,4.96Z" />
      <path d="M62.87,54.77c2.74,0,4.96-2.22,4.96-4.96s-2.22-4.96-4.96-4.96-4.96,2.22-4.96,4.96,2.22,4.96,4.96,4.96Z" />
      <path d="M62.87,66.34c2.74,0,4.96-2.22,4.96-4.96s-2.22-4.96-4.96-4.96-4.96,2.22-4.96,4.96,2.22,4.96,4.96,4.96Z" />
      <path d="M62.87,77.91c2.74,0,4.96-2.22,4.96-4.96s-2.22-4.96-4.96-4.96-4.96,2.22-4.96,4.96,2.22,4.96,4.96,4.96Z" />
      <path d="M62.87,89.49c2.74,0,4.96-2.22,4.96-4.96s-2.22-4.96-4.96-4.96-4.96,2.22-4.96,4.96,2.22,4.96,4.96,4.96Z" />
      <path d="M72.8,37.41c2.74,0,4.96-2.22,4.96-4.96s-2.22-4.96-4.96-4.96-4.96,2.22-4.96,4.96,2.22,4.96,4.96,4.96Z" />
      <path d="M72.8,48.99c2.74,0,4.96-2.22,4.96-4.96s-2.22-4.96-4.96-4.96-4.96,2.22-4.96,4.96,2.22,4.96,4.96,4.96Z" />
      <path d="M72.8,60.56c2.74,0,4.96-2.22,4.96-4.96s-2.22-4.96-4.96-4.96-4.96,2.22-4.96,4.96,2.22,4.96,4.96,4.96Z" />
      <path d="M72.8,72.13c2.74,0,4.96-2.22,4.96-4.96s-2.22-4.96-4.96-4.96-4.96,2.22-4.96,4.96,2.22,4.96,4.96,4.96Z" />
      <path d="M72.8,83.7c2.74,0,4.96-2.22,4.96-4.96s-2.22-4.96-4.96-4.96-4.96,2.22-4.96,4.96,2.22,4.96,4.96,4.96Z" />
      <path d="M82.73,43.2c2.74,0,4.96-2.22,4.96-4.96s-2.22-4.96-4.96-4.96-4.96,2.22-4.96,4.96,2.22,4.96,4.96,4.96Z" />
      <path d="M82.73,54.77c2.74,0,4.96-2.22,4.96-4.96s-2.22-4.96-4.96-4.96-4.96,2.22-4.96,4.96,2.22,4.96,4.96,4.96Z" />
      <path d="M82.73,66.34c2.74,0,4.96-2.22,4.96-4.96s-2.22-4.96-4.96-4.96-4.96,2.22-4.96,4.96,2.22,4.96,4.96,4.96Z" />
      <path d="M82.73,77.91c2.74,0,4.96-2.22,4.96-4.96s-2.22-4.96-4.96-4.96-4.96,2.22-4.96,4.96,2.22,4.96,4.96,4.96Z" />
    </g>
    <path
      fill="#754f99"
      d="M70.35,87.66c4.13,0,7.48-3.35,7.48-7.48s-3.35-7.48-7.48-7.48-7.48,3.35-7.48,7.48,3.35,7.48,7.48,7.48Z"
    />
    <path
      fill="#243651"
      d="M96.97,87.66c4.13,0,7.48-3.35,7.48-7.48s-3.35-7.48-7.48-7.48-7.48,3.35-7.48,7.48,3.35,7.48,7.48,7.48Z"
    />
    <path
      fill="#754f99"
      d="M76.17,94.07c0,4.13,3.35,7.48,7.49,7.48v-14.97c-4.13,0-7.49,3.35-7.49,7.48h0Z"
    />
    <path
      fill="#243651"
      d="M83.66,86.58v14.97c4.13,0,7.48-3.35,7.48-7.48s-3.35-7.48-7.48-7.48h0Z"
    />
    <path
      fill="#9ec953"
      d="M83.66,55.92c-2.91,0-4.65,2.26-4.65,2.26,0,0,.54,5.74.54,8.48s-10.83,15.89-10.83,15.89l1.19,1.43,10.19-10.73,1.41,18.57,2.14,1.02v-36.92h0Z"
    />
    <path
      fill="#5c8a1e"
      d="M83.66,55.92c2.91,0,4.65,2.26,4.65,2.26,0,0-.54,5.74-.54,8.48s10.83,15.89,10.83,15.89l-1.19,1.43-10.19-10.73-1.41,18.57-2.14,1.02v-36.92h0Z"
    />
  </SvgIcon>
);

export default IconAdditivePomegranateAcai;
