import IPromoDetails from '../../services/Orders/IPromoDetails';

interface IOrderPromotionsList {
  promoDetails: IPromoDetails;
}
const OrderPromotionsList: React.FC<IOrderPromotionsList> = ({ promoDetails }) => {
  return (
    <>
      <h6>Promotions</h6>
      <table>
        <thead>
          <th>Value</th>
          <th>Type</th>
          <th>Code</th>
        </thead>
        <tbody>
          <tr>
            <td>{promoDetails?.value?.toFixed(2)}</td>
            <td>{promoDetails?.type}</td>
            <td>{promoDetails?.code}</td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default OrderPromotionsList;
