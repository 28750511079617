enum AdditivesCalibrationFactor {
  ServoCenterPosition = 0,
  RefillFlowRateCorrection = 1,
  PumpMotorsDirection = 2,
  ServoRefillPosition = 3,
  ServoFillPosition = 4,
  StepperVelocityXY = 5, // pumps 1, 2
  StepperVelocityZ = 6, // pumps 3, 4
  StepperVelocityA = 7, // pumps 5, 6
  BottleFillFlowRateCorrection = 8,
}

export default AdditivesCalibrationFactor;
