import React from 'react';
import ToggleButton from 'react-toggle-button';
import propTypes from 'prop-types';
import IconLoading from '../../components/ui/Icons/IconLoading';
import UserRole from '../../common/UserRole';
import AuthGuard from '../../components/auth/AuthGuard';

interface IFeatureToggle {
  icon: string;
  status: boolean;
  onFeatureToggle: (currentState: boolean) => void;
  details: [string, string | number][];
  loading: boolean;
  disabled: boolean;
  authorizedRoles: UserRole[];
  userRole: UserRole;
}

const FeatureToggle: React.FC<IFeatureToggle> = ({
  icon,
  status,
  onFeatureToggle,
  details,
  loading,
  disabled,
  authorizedRoles,
  userRole,
}) => {
  return (
    <div
      className='col s6 l4 m4'
      style={{ opacity: disabled && !loading ? 0.5 : 1.0, pointerEvents: disabled ? 'none' : 'all' }}
    >
      <div
        style={{
          background: `url(${icon}) no-repeat`,
          backgroundSize: 'cover',
          position: 'relative',
          aspectRatio: '1/1',
        }}
      >
        {loading && (
          <div className='center-align col s12 absolute' style={{ paddingTop: '40%' }}>
            <IconLoading width={50} height={30} />
          </div>
        )}
      </div>
      <div
        className='center-align menu-item'
        style={{ marginLeft: '34%', marginBottom: '20px', opacity: loading ? 0.8 : 1.0 }}
      >
        <AuthGuard authorizedRoles={authorizedRoles} userRole={userRole}>
          <ToggleButton className='center-align' value={status} onToggle={onFeatureToggle} />
        </AuthGuard>
      </div>
      <div className=''>
        {details.map(([label, value], detailIndex) => (
          <p className='center-align menu-item' key={+detailIndex}>
            <strong>{label}:</strong>&nbsp;&nbsp;{value}
          </p>
        ))}
      </div>
    </div>
  );
};

FeatureToggle.propTypes = {
  icon: propTypes.string.isRequired,
  status: propTypes.bool.isRequired,
  onFeatureToggle: propTypes.func.isRequired,
  details: propTypes.array.isRequired,
  loading: propTypes.bool.isRequired,
};

export default FeatureToggle;
