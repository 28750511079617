import { Card } from 'react-bootstrap';
import { Pump } from '../../../../../common/Kiosk/KioskSettings';
import { useContext } from 'react';
import { KioskSettingsContext } from '../KioskSettingsContext';
import KioskEditStrings from '../../KioskEditStrings';
import KioskPumpEditCard from './KioskPumpEditCard';
import KioskMonitorReport from '../../../../../hooks/useDeviceMonitor/KioskMonitorReport';

const KioskPumpsEdit: React.FC = () => {
  const kioskSettings = useContext(KioskSettingsContext);

  const updatePump = <T extends keyof Pump>(field: T, pumpId: number, value: Pump[T]) => {
    kioskSettings?.handlePumpChange(field, pumpId, value);
  };

  const message = (deviceStatus?: KioskMonitorReport, pumps?: Pump[]) => {
    if (deviceStatus?.loaded && !deviceStatus.online) {
      return `${KioskEditStrings.KioskOffline}, cannot edit ${KioskEditStrings.PumpsLabel}`;
    }
    if (deviceStatus?.ready && pumps?.length === 0) {
      return `No ${KioskEditStrings.PumpsLabel} retrieved from kiosk`;
    }
  };

  return (
    <>
      <Card.Subtitle style={{ fontWeight: '600', fontSize: '16px', marginTop: '10px' }}>
        {KioskEditStrings.PumpsLabel}
      </Card.Subtitle>
      <div>
        {message(kioskSettings?.deviceStatus, kioskSettings?.state.settings?.pumps)}
        {kioskSettings?.state.settings?.pumps &&
          kioskSettings?.state.settings?.pumps.map((pump) => (
            <div key={pump.id} className='col s12 m6 l4'>
              <KioskPumpEditCard
                pump={pump}
                onAdditiveChange={(pump) => updatePump('additive', pump.id, pump.additive)}
                onInventoryChange={(pump) => updatePump('inventory', pump.id, pump.inventory)}
                disabled={!kioskSettings?.deviceStatus.ready}
              />
            </div>
          ))}
      </div>
    </>
  );
};

export default KioskPumpsEdit;
