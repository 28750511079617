import NotificationMedium from '../../../services/Notifications/NotificationMedium';
import { ChatParticipant } from '../ChatWidget/ChatWidget';
import NotificationMediumIcon from '../NotificationMediumIcon/NotificationMediumIcon';
import { IMessageConfig } from '../ChatMessageInput/ChatMessageInput';
import styles from './ChatMessageConfiguration.module.css';
import { parseParticipantsByMedium } from './parseChatParticipantsByMedium';
import { WarningRounded } from '@material-ui/icons';

interface IChatMessageConfigurationReport {
  config: IMessageConfig;
  warningMessage?: {
    sms?: string;
    email?: string;
  };
  infoMessage?: {
    sms?: string;
    email?: string;
  };
}

const ChatMessageConfigurationReport: React.FC<IChatMessageConfigurationReport> = ({
  config,
  warningMessage,
  infoMessage
}) => {
  const recipientListByKey = (key: keyof ChatParticipant): (string | undefined)[] =>
    parseParticipantsByMedium(config.recipients, key);
  const smsList = recipientListByKey('phone');
  const emailList = recipientListByKey('email');

  return (
    <div className={styles.ChatMessageConfigurationReport}>
      <div className={warningMessage?.sms ? styles.warningMessage : ''}>
        <NotificationMediumIcon medium={NotificationMedium.SMS} />
        <div style={{ fontStyle: 'normal' }}>{infoMessage?.sms}</div>
        <div>{smsList?.length > 0 ? smsList.join(', ') : 'No SMS recipients'}</div>
      </div>
      {warningMessage?.sms && (
        <div className={styles.warningMessage}>
          <WarningRounded />
          <strong>{warningMessage?.sms}</strong>
        </div>
      )}
      <div className={warningMessage?.email ? styles.warningMessage : ''}>
        <NotificationMediumIcon medium={NotificationMedium.Email} />
        <div style={{ fontStyle: 'normal' }}>{infoMessage?.email}</div>
        <div>{emailList?.length > 0 ? emailList.join(', ') : 'No Email recipients'}</div>
      </div>
      {warningMessage?.email && (
        <div className={styles.warningMessage}>
          <WarningRounded />
          <strong>{warningMessage?.email}</strong>
        </div>
      )}
    </div>
  );
};

export default ChatMessageConfigurationReport;
