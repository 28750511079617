import IPaymentDetails from '../../services/Orders/IPaymentDetails';

interface IOrderPaymentsList {
  paymentDetails: IPaymentDetails[];
}
const OrderPaymentsList: React.FC<IOrderPaymentsList> = ({ paymentDetails }) => {
  const paymentsSortedByDate = paymentDetails.sort((a, b) => Date.parse(b.dateCreated) - Date.parse(a.dateCreated));
  return (
    <>
      <h6>Payments</h6>
      <table>
        <thead>
          <th>Created</th>
          <th>Amount</th>
          <th>Vendor ID</th>
          <th>Status</th>
          <th>Transaction Type</th>
          <th>Description</th>
        </thead>
        <tbody>
          {paymentsSortedByDate.map(
            ({ dateCreated, vendorId, status, amount, transactionType, description }, index) => (
              <tr>
                <td>{new Date(dateCreated).toLocaleString()}</td>
                <td>{amount.toFixed(2)}</td>
                <td>{vendorId}</td>
                <td>{status.toUpperCase()}</td>
                <td>{transactionType.toUpperCase()}</td>
                <td>{description}</td>
              </tr>
            )
          )}
        </tbody>
      </table>
    </>
  );
};

export default OrderPaymentsList;
