import CommandUI from '../FirmwareCommunication/CommandUI';
import CalibrationAction from '../FirmwareCommunication/Messaging/Calibration/CalibrationAction';
import CommandCode from '../FirmwareCommunication/Messaging/CommandCode';
import PeripheralIdentifier from '../PeripheralIdentifier';
import GantryAction from './GantryAction';
import GantryCalibrationFactor from './GantryCalibrationFactor';
import { GantryDevice, GantryPosition } from './GantryMotion';
import GantrySequence from './GantrySequence';

const GantryCommands: { [key in CommandCode]?: CommandUI } = {
  [CommandCode.Identify]: {
    name: 'Identify',
    identifier: PeripheralIdentifier.Gantry,
    code: CommandCode.Identify,
    args: [],
  },
  [CommandCode.ResetHardware]: {
    name: 'Reset Hardware',
    identifier: PeripheralIdentifier.Gantry,
    code: CommandCode.ResetHardware,
    args: [],
  },
  [CommandCode.ResetProcessor]: {
    name: 'Reset Processor',
    identifier: PeripheralIdentifier.Gantry,
    code: CommandCode.ResetProcessor,
    args: [],
  },
  [CommandCode.Provision]: {
    name: 'Provision',
    identifier: PeripheralIdentifier.Gantry,
    code: CommandCode.Provision,
    args: [
      {
        name: 'PCB Version',
        type: 'decimal',
        options: [],
      },
      {
        name: 'BOM Version',
        type: 'decimal',
        options: [],
      },
      {
        name: 'Serial Number',
        type: 'decimal',
        options: [],
      },
    ],
  },
  [CommandCode.Query]: {
    name: 'Query',
    identifier: PeripheralIdentifier.Gantry,
    code: CommandCode.Query,
    args: [],
  },
  [CommandCode.EnterBootLoader]: {
    name: 'EnterBootLoader',
    identifier: PeripheralIdentifier.Gantry,
    code: CommandCode.EnterBootLoader,
    args: [],
  },
  [CommandCode.Action]: {
    name: 'Action',
    identifier: PeripheralIdentifier.Gantry,
    code: CommandCode.Action,
    args: [
      {
        name: 'Action',
        type: 'select',
        options: [
          {
            name: GantryAction[GantryAction.Stop],
            value: GantryAction.Stop,
          },
          {
            name: GantryAction[GantryAction.DeactivateMotors],
            value: GantryAction.DeactivateMotors,
          },
          {
            name: GantryAction[GantryAction.ActivateMotors],
            value: GantryAction.ActivateMotors,
          },
        ],
      },
    ],
  },
  [CommandCode.Motion]: {
    name: 'Motion',
    identifier: PeripheralIdentifier.Gantry,
    code: CommandCode.Motion,
    args: [
      {
        name: 'Device',
        type: 'select',
        options: [
          {
            name: GantryDevice[GantryDevice.Gripper],
            value: GantryDevice.Gripper,
          },
          {
            name: GantryDevice[GantryDevice.GantryX],
            value: GantryDevice.GantryX,
          },
          {
            name: GantryDevice[GantryDevice.GantryZ],
            value: GantryDevice.GantryZ,
          },
        ],
      },
      {
        name: 'Position',
        type: 'select',
        options: [
          {
            name: GantryPosition[GantryPosition.GripperOpen],
            value: GantryPosition.GripperOpen,
          },
          {
            name: GantryPosition[GantryPosition.GripperClosed],
            value: GantryPosition.GripperClosed,
          },
          {
            name: GantryPosition[GantryPosition.GripperClosedOnBottle],
            value: GantryPosition.GripperClosedOnBottle,
          },
          {
            name: GantryPosition[GantryPosition.GripperBottleSense],
            value: GantryPosition.GripperBottleSense,
          },
          {
            name: GantryPosition[GantryPosition.JogToPosition],
            value: GantryPosition.JogToPosition,
          },
          {
            name: GantryPosition[GantryPosition.JogInDirection],
            value: GantryPosition.JogInDirection,
          },
          {
            name: GantryPosition[GantryPosition.GripperOpenPastLimit],
            value: GantryPosition.GripperOpenPastLimit,
          },
          {
            name: GantryPosition[GantryPosition.GripperCloseToLimit],
            value: GantryPosition.GripperCloseToLimit,
          },
        ],
      },
      {
        name: 'Timeout',
        type: 'decimal',
        options: [],
      },
      {
        name: 'Step Distance',
        type: 'decimal',
        options: [],
      },
      {
        name: 'Jog Direction',
        type: 'decimal',
        options: [],
      },
      {
        name: 'Jog RPM',
        type: 'decimal',
        options: [],
      },
      {
        name: 'Jog Speed Steps',
        type: 'decimal',
        options: [],
      },
      {
        name: 'Jog Acceleration Steps',
        type: 'decimal',
        options: [],
      },
      {
        name: 'Jog Deceleration Steps',
        type: 'decimal',
        options: [],
      },
    ],
  },
  [CommandCode.PerformSequence]: {
    name: 'Perform Sequence',
    identifier: PeripheralIdentifier.Gantry,
    code: CommandCode.PerformSequence,
    args: [
      {
        name: 'Type',
        type: 'select',
        options: [
          {
            name: GantrySequence[GantrySequence.Home],
            value: GantrySequence.Home,
          },
          {
            name: GantrySequence[GantrySequence.HomeX],
            value: GantrySequence.HomeX,
          },
          {
            name: GantrySequence[GantrySequence.HomeZ],
            value: GantrySequence.HomeZ,
          },
          {
            name: GantrySequence[GantrySequence.MoveToCapperToCap],
            value: GantrySequence.MoveToCapperToCap,
          },
          {
            name: GantrySequence[GantrySequence.MoveToCapperToUncap],
            value: GantrySequence.MoveToCapperToUncap,
          },
          {
            name: GantrySequence[GantrySequence.DeliverBottle],
            value: GantrySequence.DeliverBottle,
          },
          {
            name: GantrySequence[GantrySequence.MoveToTower1GrabPosition],
            value: GantrySequence.MoveToTower1GrabPosition,
          },
          {
            name: GantrySequence[GantrySequence.MoveToTower2GrabPosition],
            value: GantrySequence.MoveToTower2GrabPosition,
          },
          {
            name: GantrySequence[GantrySequence.MoveToTower3GrabPosition],
            value: GantrySequence.MoveToTower3GrabPosition,
          },
          {
            name: GantrySequence[GantrySequence.GrabBottleFromTower],
            value: GantrySequence.GrabBottleFromTower,
          },
          {
            name: GantrySequence[GantrySequence.CapMotion],
            value: GantrySequence.CapMotion,
          },
          {
            name: GantrySequence[GantrySequence.UncapMotion],
            value: GantrySequence.UncapMotion,
          },
          {
            name: GantrySequence[GantrySequence.FlapClose],
            value: GantrySequence.FlapClose,
          },
          {
            name: GantrySequence[GantrySequence.ProbeBottle],
            value: GantrySequence.ProbeBottle,
          },
          {
            name: GantrySequence[GantrySequence.GarbageBottle],
            value: GantrySequence.GarbageBottle,
          },
          {
            name: GantrySequence[GantrySequence.DoubleBottleCheck],
            value: GantrySequence.DoubleBottleCheck,
          },
          {
            name: GantrySequence[GantrySequence.MoveZToProbeClear],
            value: GantrySequence.MoveZToProbeClear,
          },
          {
            name: GantrySequence[GantrySequence.MoveToFiller],
            value: GantrySequence.MoveToFiller,
          },
          {
            name: GantrySequence[GantrySequence.MoveToCapperBelowUncap],
            value: GantrySequence.MoveToCapperBelowUncap,
          },
          {
            name: GantrySequence[GantrySequence.ClearCapper],
            value: GantrySequence.ClearCapper,
          },
          {
            name: GantrySequence[GantrySequence.ClearSlider],
            value: GantrySequence.ClearSlider,
          },
        ],
      },
    ],
  },
  [CommandCode.Calibrate]: {
    name: 'Calibrate',
    identifier: PeripheralIdentifier.Gantry,
    code: CommandCode.Calibrate,
    args: [
      {
        name: 'Calibration Factor',
        type: 'select',
        options: [
          {
            name: GantryCalibrationFactor[
              GantryCalibrationFactor.Tower1Position
            ],
            value: GantryCalibrationFactor.Tower1Position,
          },
          {
            name: GantryCalibrationFactor[
              GantryCalibrationFactor.Tower3Position
            ],
            value: GantryCalibrationFactor.Tower3Position,
          },
          {
            name: GantryCalibrationFactor[
              GantryCalibrationFactor.TowerBottleZPosition
            ],
            value: GantryCalibrationFactor.TowerBottleZPosition,
          },
          {
            name: GantryCalibrationFactor[
              GantryCalibrationFactor.FillerXPosition
            ],
            value: GantryCalibrationFactor.FillerXPosition,
          },
          {
            name: GantryCalibrationFactor[
              GantryCalibrationFactor.CapperXPosition
            ],
            value: GantryCalibrationFactor.CapperXPosition,
          },
          {
            name: GantryCalibrationFactor[
              GantryCalibrationFactor.CapperZPosition
            ],
            value: GantryCalibrationFactor.CapperZPosition,
          },
          {
            name: GantryCalibrationFactor[
              GantryCalibrationFactor.DeliveryZPosition
            ],
            value: GantryCalibrationFactor.DeliveryZPosition,
          },
          {
            name: GantryCalibrationFactor[
              GantryCalibrationFactor.FlapSealXPosition
            ],
            value: GantryCalibrationFactor.FlapSealXPosition,
          },
          {
            name: GantryCalibrationFactor[
              GantryCalibrationFactor.FlapSealZPosition
            ],
            value: GantryCalibrationFactor.FlapSealZPosition,
          },
          {
            name: GantryCalibrationFactor[
              GantryCalibrationFactor.GarbageXPosition
            ],
            value: GantryCalibrationFactor.GarbageXPosition,
          },
          {
            name: GantryCalibrationFactor[
              GantryCalibrationFactor.DoubleBottleCheckZPosition
            ],
            value: GantryCalibrationFactor.DoubleBottleCheckZPosition,
          },
          {
            name: GantryCalibrationFactor[GantryCalibrationFactor.BottleGrip],
            value: GantryCalibrationFactor.BottleGrip,
          },
        ],
      },
      {
        name: 'Action',
        type: 'select',
        options: [
          {
            name: CalibrationAction[CalibrationAction.CalculateAndStore],
            value: CalibrationAction.CalculateAndStore,
          },
          {
            name: CalibrationAction[CalibrationAction.RetrieveStored],
            value: CalibrationAction.RetrieveStored,
          },
          {
            name: CalibrationAction[CalibrationAction.OverwriteFactor],
            value: CalibrationAction.OverwriteFactor,
          },
        ],
      },
      {
        name: 'Value',
        type: 'decimal',
        options: [],
      },
    ],
  },
};

export default GantryCommands;
