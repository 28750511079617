export enum InventoryCalculation {
  PerDevice = 'per-device',
  PerMachine = 'per-machine',
}

export type AlarmConfig = {
  threshold: number;
};

export type InventoryAlarmsConfig = {
  low: AlarmConfig;
  empty: AlarmConfig;
};

export type BottleInventorySettings = {
  calculation: InventoryCalculation;
  alarms: InventoryAlarmsConfig;
};

export type CO2InventorySettings = {
  enabled: boolean;
  alarms: InventoryAlarmsConfig;
};

export type InventoryControlSettings = {
  bottles?: BottleInventorySettings;
  CO2?: CO2InventorySettings;
};
