import React, { useContext } from 'react';
import { Card } from 'react-bootstrap';
import { TextField } from '@material-ui/core';
import { UserAccountContext } from '../../UserAccountContext';
import UserAccountTabReference from '../UserAccountTabReference';
import SubmitButton from '../../../../components/Button/SubmitButton';
import AccountEditStrings from '../AccountEditStrings';
import KioskAccessGenerator from './KioskAccess/KioskAccessGenerator';
import { useAppSelector } from '../../../../hooks/storeHooks';

const UserAccountSecurity: React.FC = () => {
  const userAccount = useContext(UserAccountContext);
  const user = useAppSelector((store) => store.auth?.user);
  const accountIsLoggedInUser = user.uuid === userAccount?.state.user?.uuid;

  return (
    <Card style={{ padding: '2%' }}>
      <Card.Body>
        <Card.Title>Security</Card.Title>
        <Card.Body className='row' style={{}}>
          <div className='col s12 m6'>
            {accountIsLoggedInUser && (
              <>
                <Card.Subtitle style={{ fontWeight: '600', fontSize: '14px', marginTop: '3%' }}>
                  {AccountEditStrings.KioskAccessTitle}
                </Card.Subtitle>
                <KioskAccessGenerator />
              </>
            )}
            <Card.Subtitle style={{ fontWeight: '600', fontSize: '14px', marginTop: '3%' }}>
              {AccountEditStrings.DashboardAccessTitle}
            </Card.Subtitle>
          </div>
        </Card.Body>
        <Card.Body className='row' style={{}}>
          <div className='col s12 m4'>
            <TextField
              type='password'
              size='small'
              variant='outlined'
              fullWidth={true}
              required={true}
              id='password'
              label='New Password'
              value={userAccount?.state?.user?.password || ''}
              onChange={(e) => userAccount?.handleUserDataChange('password', e.target.value)}
              style={{ margin: '10px 0' }}
              disabled={userAccount?.state.loading}
            />
          </div>
          <div className='col s12 m4'>
            <TextField
              type='password'
              size='small'
              variant='outlined'
              fullWidth={true}
              required={true}
              id='password-confirm'
              label='Confirm New Password'
              value={userAccount?.state.passwordConfirm || ''}
              onChange={(e) => userAccount?.handlePasswordConfirmationChange(e.target.value)}
              style={{ margin: '10px 0' }}
              disabled={userAccount?.state.loading}
            />
          </div>
        </Card.Body>
        <SubmitButton
          label='Save'
          disabled={userAccount?.state.loading || !userAccount?.state.unsavedChanges}
          onClick={() => userAccount?.handleSaveSecurityData(UserAccountTabReference.Security)}
        />
      </Card.Body>
      <Card.Text className='row' style={{}}>
        {userAccount?.state?.error?.[UserAccountTabReference.Security]?.message}
        {userAccount?.state?.message?.[UserAccountTabReference.Security]}
      </Card.Text>
    </Card>
  );
};
export default UserAccountSecurity;
