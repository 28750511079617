export enum AdditivesDevice {
  AmbientDispenser = 0,
  ColdDispenser = 1,
  Servo = 2,
}

export enum AdditivesPosition {
  ServoCenterNoPinch = 0,
  ServoFill = 1,
  ServoRefill = 2,
  DispensersOn = 3,
  DispensersOff = 4,
}
