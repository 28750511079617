import ConfigService from '../ConfigService';
import ApiService from '../HttpClient/ApiService';
import IUser from './IUser';

export default class UserService {
  apiService: ApiService;

  constructor() {
    this.apiService = new ApiService(ConfigService.apiHost);
  }

  getUser = async (uuid: string): Promise<IUser> => {
    const user = await this.apiService.request(`users/${uuid}`, 'GET');
    return user as unknown as IUser;
  };

  getUsers = async (companyId?: string): Promise<IUser[]> => {
    const user = await this.apiService.request(`users`, 'GET');
    return user as unknown as IUser[];
  };

  updateUser = async (user: IUser): Promise<IUser> => {
    const updatedUser = await this.apiService.request(`users/${user.uuid}`, 'PUT', undefined, user);
    return updatedUser as unknown as IUser;
  };

  createUser = async (user: IUser): Promise<IUser> => {
    const updatedUser = await this.apiService.request(`users`, 'POST', undefined, user);
    return updatedUser as unknown as IUser;
  };
}
