import React, { useCallback, useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Grid from '@material-ui/core/Grid';
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import GantryJogInputs from './GantryJog/GantryJogInputs';
import KioskFeedbackImage from '../KioskFeedback/KioskFeedbackImage';
import GantryPositionDisplay from './GantryPositionDisplay/GantryPositionDisplay';
import { GantryPosition } from '../../../../../common/Peripherals/Gantry/GantryMotion';
import useKioskDevice from '../../../../../services/Kiosk/useKioskDevice';
import CommandCode from '../../../../../common/Peripherals/FirmwareCommunication/Messaging/CommandCode';
import PeripheralIdentifier from '../../../../../common/Peripherals/PeripheralIdentifier';
import IGantryQueryData from '../../../../../common/Peripherals/Gantry/IGantryQueryData';
import PeripheralCommandResponse from '../../../../../services/Kiosk/PeripheralCommandResponse';
import PositionCoordinates from './PositionCoordinates';
import GantryJogConfig from '../../../../../common/Peripherals/Gantry/GantryJogConfig';
import GantryControlsStrings from './GantryControlsStrings';

interface IGantryControls {
  onCloseClick: () => void;
  disable?: boolean;
  kioskId: string;
}

interface IGantryQueryDataWithTimestamp extends IGantryQueryData {
  updatedAt?: string;
}

const GantryControls: React.FC<IGantryControls> = ({ onCloseClick, disable = false, kioskId }) => {
  const [openErrorDialog, setOpenErrorDialog] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [coordinates, setCoordinates] = useState<PositionCoordinates>([0, 0, 0, 0]);
  const kioskDevice = useKioskDevice(kioskId);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [cameraCapture, setCameraCapture] = useState<{
    url?: string;
    loading: boolean;
    updatedAt?: string;
    errorString?: string;
  }>();
  const [refreshImage, setRefreshImage] = useState<boolean>(true);
  const [queryData, setQueryData] = useState<IGantryQueryDataWithTimestamp>();

  const checkForError = (response: PeripheralCommandResponse) => {
    if (response.error) throw new Error(response.error);
  };

  const refreshCameraCapture = useCallback(async () => {
    if (!refreshImage) return;
    setCameraCapture((prev) => ({ ...prev, loading: true, errorString: undefined }));
    const cameraCaptureResponse = await kioskDevice.sendCameraSnapshotRequest();
    setCameraCapture({
      url: cameraCaptureResponse.downloadLink,
      loading: false,
      updatedAt: new Date().toLocaleTimeString(),
      errorString: cameraCaptureResponse.error
    });
  }, [kioskDevice, refreshImage]);

  const queryGantry = useCallback(async () => {
    try {
      setIsLoading(true);
      const queryResponse = await kioskDevice.sendPeripheralCommand(CommandCode.Query, PeripheralIdentifier.Gantry, [
        []
      ]);
      checkForError(queryResponse);
      const queryDataObj: IGantryQueryData = queryResponse.message as IGantryQueryData;
      setQueryData({ ...queryDataObj, updatedAt: new Date().toLocaleTimeString() });
      setCoordinates([
        queryDataObj.rawPositionXStepperDriver,
        queryDataObj.rawPositionZStepperDriver,
        queryDataObj.encoderXStepperPosition,
        queryDataObj.encoderZStepperPosition
      ]);
      refreshCameraCapture();
    } catch (err) {
      const msg = err instanceof Error ? err.message : JSON.stringify(err);
      console.error(msg);
      setError(true);
      setErrorMessage(msg);
      setOpenErrorDialog(true);
    } finally {
      setIsLoading(false);
    }
  }, [kioskDevice, refreshCameraCapture]);

  const execStop = async () => {
    try {
      setIsLoading(true);
      const response = await kioskDevice.sendPeripheralCommand(CommandCode.Action, PeripheralIdentifier.Gantry, [[0]]);
      checkForError(response);
      await queryGantry();
    } catch (err) {
      const msg = err instanceof Error ? err.message : JSON.stringify(err);
      console.error(msg);
      setError(true);
      setErrorMessage(msg);
      setOpenErrorDialog(true);
    } finally {
      setIsLoading(false);
    }
  };

  const execJog = async (jogConfig: GantryJogConfig) => {
    const { axis, timeout = 0, stepDistance, speedSteps, accelerationSteps, decelerationSteps } = jogConfig;
    try {
      setIsLoading(true);
      const response = await kioskDevice.sendPeripheralCommand(CommandCode.Motion, PeripheralIdentifier.Gantry, [
        [axis],
        [GantryPosition.JogToPosition], // JogInDirection not supported yet
        [timeout],
        [stepDistance],
        [], // Only for jog in direction
        [], // RPM is not used for JogToPosition
        [speedSteps],
        [accelerationSteps],
        [decelerationSteps]
      ]);
      checkForError(response);
      await queryGantry();
    } catch (err) {
      const msg = err instanceof Error ? err.message : JSON.stringify(err);
      console.error(msg);
      setError(true);
      setErrorMessage(msg);
      setOpenErrorDialog(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    setOpenErrorDialog(false);
  };

  useEffect(() => {
    queryGantry();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={openErrorDialog}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <MuiAlert elevation={6} variant='filled' onClose={handleClose} severity={error ? 'error' : 'info'}>
          {error
            ? `${GantryControlsStrings.FailureCommandMessage} ${errorMessage}`
            : GantryControlsStrings.SuccessCommandMessage}
        </MuiAlert>
      </Snackbar>

      <Dialog open={!disable} maxWidth={'md'} fullWidth>
        <DialogTitle>
          <Grid container direction='row' alignItems='center' justifyContent='center'>
            {GantryControlsStrings.GantryControlsHeading}
          </Grid>
        </DialogTitle>
        <DialogContent dividers>
          <div className='row'>
            <div className='col s12 m6'>
              <KioskFeedbackImage
                imageUrl={cameraCapture?.url}
                refreshImageEnabled={refreshImage}
                onChangeRefreshImageEnabled={setRefreshImage}
                loading={cameraCapture?.loading}
                lastUpdate={cameraCapture?.updatedAt}
                errorString={cameraCapture?.errorString}
              />
              <GantryPositionDisplay coordinates={coordinates} lastUpdate={queryData?.updatedAt} />
            </div>
            <div className='col s12 m6'>
              <GantryJogInputs onClick={execJog} loading={isLoading} />
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button variant='contained' onClick={() => execStop()} type='button' color='secondary' disabled={isLoading}>
            {GantryControlsStrings.StopExecLabel}
          </Button>
          <Button variant='contained' onClick={onCloseClick} color='primary'>
            {GantryControlsStrings.CloseLabel}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

GantryControls.defaultProps = {
  disable: false
};

export default GantryControls;
