import React, { useState } from 'react';
import clsx from 'clsx';
import { useScrollTrigger, useTheme, useMediaQuery } from '@material-ui/core';
import Slide from '@material-ui/core/Slide';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import DashboardIcon from '@material-ui/icons/Dashboard';
import logoImage from '../../images/img.png';
import { Link } from 'react-router-dom';
import AccountCircle from '@material-ui/icons/PermIdentity';
import OrdersIcon from '@material-ui/icons/FormatListNumberedRtl';
import InventoryIcon from '@material-ui/icons/Store';
import AccountIcon from '@material-ui/icons/AccountCircle';
import Logout from '@material-ui/icons/ExitToApp';
import AboutUsIcon from '@material-ui/icons/PeopleAlt';
import PaidOrdersIcon from '@material-ui/icons/AttachMoney';
import FreeOrdersIcon from '@material-ui/icons/MoneyOff';
import AllOrdersIcon from '@material-ui/icons/Ballot';
import { logoutUser } from '../../actions/authActions';
import NavMenuItems from './NavMenuItems';
import UserRole from '../../common/UserRole';
import MediaIcon from '@material-ui/icons/VideocamOutlined';
import { useAppDispatch, useAppSelector } from '../../hooks/storeHooks';
import MenuItem from './MenuItem';
import { ReportProblem, Build, Healing } from '@material-ui/icons';

interface HideOnScrollProps {
  children: React.ReactElement;
  window?: () => Window;
}

function HideOnScroll(props: HideOnScrollProps) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    target: window ? window() : undefined
  });

  return (
    <Slide appear={false} direction='down' in={!trigger}>
      {children}
    </Slide>
  );
}

type NavDrawer2Props = {
  classes?: any;
};

const NavDrawer: React.FC<NavDrawer2Props> = ({ classes = '' }) => {
  const [open, setOpen] = useState(false);
  const store = useAppSelector((store) => store);
  const { auth } = store;
  const dispatch = useAppDispatch();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const logOut = (e: React.MouseEvent<Element>) => {
    e.preventDefault();
    dispatch(logoutUser() as any);
  };

  const menuItems: MenuItem[] = [
    {
      label: 'Overview',
      icon: <DashboardIcon />,
      path: '/overview',
      authorizedRoles: []
    },
    {
      label: 'Orders',
      icon: <OrdersIcon />,
      subItems: [
        {
          label: 'Paid',
          icon: <PaidOrdersIcon />,
          path: '/orders/paid',
          authorizedRoles: []
        },
        {
          label: 'Free',
          icon: <FreeOrdersIcon />,
          path: '/orders/free',
          authorizedRoles: []
        },
        {
          label: 'All',
          icon: <AllOrdersIcon />,
          path: '/orders/all',
          authorizedRoles: []
        }
      ],
      path: '',
      authorizedRoles: []
    },
    {
      label: 'Fleet Management',
      icon: <Build />,
      subItems: [
        {
          label: 'Inventory',
          icon: <InventoryIcon />,
          path: '/inventory',
          authorizedRoles: []
        },
        {
          label: 'Kiosk Health',
          icon: <Healing />,
          path: '/reports/kiosk-health',
          authorizedRoles: []
        },
        {
          label: 'Incidents',
          icon: <ReportProblem />,
          path: '/reports/incidents',
          authorizedRoles: [UserRole.DropSuperAdmin]
        },
      ],
      path: '#',
      authorizedRoles: []
    },
    {
      label: 'Media',
      path: '/media',
      icon: <MediaIcon />,
      authorizedRoles: [UserRole.DropSuperAdmin]
    },
    {
      label: 'Users',
      path: '/users',
      icon: <AboutUsIcon />,
      authorizedRoles: [UserRole.DropSuperAdmin]
    },
    {
      label: 'Account',
      path: `/account/edit/${auth.user.uuid}`,
      icon: <AccountIcon />,
      authorizedRoles: []
    },
    {
      label: 'Logout',
      icon: <Logout />,
      path: '#',
      onClick: (e) => logOut(e),
      authorizedRoles: []
    }
  ];

  return (
    <div>
      <CssBaseline />
      <HideOnScroll>
        <AppBar
          position='fixed'
          className={clsx(classes.appBar, {
            [classes.appBarShift]: open
          })}
          style={{ backgroundColor: 'white', boxShadow: '5px 10px' }}
        >
          <Toolbar className={classes.toolbar}>
            {auth.isAuthenticated && (
              <IconButton
                color='primary'
                aria-label='open drawer'
                onClick={handleDrawerOpen}
                edge='start'
                className={clsx(classes.menuButton, { [classes.hide]: open })}
              >
                <MenuIcon />
              </IconButton>
            )}
            <div className={classes.logoContainer}>
              <Link to='/overview'>
                <img src={logoImage} alt='Logo' className={classes.logo} />
              </Link>
            </div>
            {auth.user.uuid && (
              <Link to={`/account/edit/${auth.user.uuid}`} className={classes.accountIcon}>
                <AccountCircle style={{ color: '#363844' }} />
              </Link>
            )}
          </Toolbar>
        </AppBar>
      </HideOnScroll>
      {auth.isAuthenticated && (
        <Drawer
          variant={isMobile ? 'temporary' : 'permanent'}
          open={open}
          onClose={handleDrawerClose}
          className={clsx(classes.drawer, { [classes.drawerOpen]: open, [classes.drawerClose]: !open })}
          classes={{
            paper: clsx({ [classes.drawerOpen]: open, [classes.drawerClose]: !open })
          }}
          onMouseEnter={!isMobile ? handleDrawerOpen : undefined}
          anchor='left'
          id='drawer'
        >
          <div className={classes.toolbar}>
            <IconButton onClick={handleDrawerClose}>
              <ChevronLeftIcon />
            </IconButton>
          </div>
          <Divider />
          <NavMenuItems items={menuItems} />
        </Drawer>
      )}
    </div>
  );
};

export default NavDrawer;
