import SvgIcon from './SvgIcon';
import IIconAdditive from './IIconAdditive';

const IconAdditivePassionOrangeGuava: React.FC<IIconAdditive> = ({
  className,
}) => (
  <SvgIcon
    className={className}
    width="112"
    height="112"
    fill="none"
    viewBox="0 0 112 112"
  >
    <path
      fill="#d14779"
      d="M30.61,35.53c0,14.02,11.37,25.39,25.39,25.39V10.14c-14.02,0-25.39,11.37-25.39,25.39Z"
    />
    <path
      fill="#a3164f"
      d="M81.39,35.53c0-14.02-11.37-25.39-25.39-25.39v50.78c14.02,0,25.39-11.37,25.39-25.39Z"
    />
    <path
      fill="#fffdff"
      d="M32.7,35.53c0,12.86,10.43,23.3,23.3,23.3V12.23c-12.86,0-23.3,10.43-23.3,23.3Z"
    />
    <path
      fill="#efddeb"
      d="M79.3,35.53c0-12.86-10.43-23.3-23.3-23.3v46.59c12.86,0,23.3-10.43,23.3-23.3h0Z"
    />
    <path
      fill="#ffc22f"
      d="M36.58,35.53c0,10.73,8.7,19.42,19.42,19.42V16.1c-10.73,0-19.42,8.7-19.42,19.42Z"
    />
    <path
      fill="#eda400"
      d="M75.42,35.53c0-10.73-8.7-19.42-19.42-19.42v38.84c10.73,0,19.42-8.7,19.42-19.42h0Z"
    />
    <path
      fill="#aa9103"
      d="M52.77,22.59c0,1.79,1.45,6.16,3.23,6.16v-9.39c-1.79,0-3.23,1.45-3.23,3.23Z"
    />
    <path
      fill="#aa9103"
      d="M52.77,48.11c0,1.79,1.45,3.23,3.23,3.23v-9.39c-1.79,0-3.23,4.37-3.23,6.16Z"
    />
    <path
      fill="#aa9103"
      d="M49.26,24.04c1.26,1.26,3.33,5.38,2.06,6.64-1.26,1.26-5.38-.8-6.64-2.06-1.26-1.26-1.26-3.31,0-4.58,1.26-1.26,3.31-1.26,4.58,0Z"
    />
    <path
      fill="#aa9103"
      d="M43.24,32.11c1.79,0,6.16,1.45,6.16,3.23s-4.37,3.23-6.16,3.23-3.23-1.45-3.23-3.23,1.45-3.23,3.23-3.23Z"
    />
    <path
      fill="#aa9103"
      d="M44.69,42.08c1.26-1.26,5.38-3.33,6.64-2.06,1.26,1.26-.8,5.38-2.06,6.64-1.26,1.26-3.31,1.26-4.58,0-1.26-1.26-1.26-3.31,0-4.58Z"
    />
    <path
      fill="#846e00"
      d="M59.23,48.11c0-1.79-1.45-6.16-3.23-6.16v9.39c1.79,0,3.23-1.45,3.23-3.23Z"
    />
    <path
      fill="#846e00"
      d="M59.23,22.59c0-1.79-1.45-3.23-3.23-3.23v9.39c1.79,0,3.23-4.37,3.23-6.16Z"
    />
    <path
      fill="#846e00"
      d="M62.74,46.66c-1.26-1.26-3.33-5.38-2.06-6.64,1.27-1.26,5.38.8,6.64,2.06,1.26,1.26,1.26,3.31,0,4.58s-3.31,1.26-4.58,0Z"
    />
    <path
      fill="#846e00"
      d="M68.76,38.58c-1.79,0-6.16-1.45-6.16-3.23s4.37-3.23,6.16-3.23,3.23,1.45,3.23,3.23-1.45,3.23-3.23,3.23Z"
    />
    <path
      fill="#846e00"
      d="M67.31,28.61c-1.26,1.26-5.38,3.33-6.64,2.06-1.26-1.26.8-5.38,2.06-6.64,1.26-1.26,3.31-1.26,4.58,0,1.26,1.26,1.26,3.31,0,4.58Z"
    />
    <path
      fill="#dfeb59"
      d="M52.77,76.47c0,14.02,11.37,25.39,25.39,25.39v-57.34s-25.39,10.21-25.39,31.95Z"
    />
    <path
      fill="#d2d634"
      d="M103.55,76.47c0,14.02-11.37,25.39-25.39,25.39v-57.34s25.39,10.21,25.39,31.95Z"
    />
    <path
      fill="#f09f88"
      d="M56.41,76.47c0,11.99,9.75,21.74,21.74,21.74v-49.69c-4.96,2.39-21.74,11.69-21.74,27.95h0Z"
    />
    <path
      fill="#ed876a"
      d="M99.9,76.47c0,11.99-9.75,21.74-21.74,21.74v-49.69c4.96,2.39,21.74,11.69,21.74,27.95h0Z"
    />
    <path
      fill="#f2beb5"
      d="M67.28,75.99c0,6.01,4.87,10.87,10.87,10.87v-21.74c-6.01,0-10.87,4.87-10.87,10.87Z"
    />
    <path
      fill="#f1aea1"
      d="M89.03,75.99c0-6.01-4.87-10.87-10.87-10.87v21.74c6.01,0,10.87-4.87,10.87-10.87Z"
    />
    <path
      fill="#fdf6f4"
      d="M75.98,63.55c0,1.2.98,6.01,2.17,6.01v-8.19c-1.2,0-2.17.98-2.17,2.17h0Z"
    />
    <path
      fill="#fdf6f4"
      d="M75.98,88.43c0,1.2.98,2.17,2.17,2.17v-8.19c-1.2,0-2.17,4.81-2.17,6.01h0Z"
    />
    <path
      fill="#f8d9d6"
      d="M80.33,88.43c0-1.2-.98-6.01-2.17-6.01v8.19c1.2,0,2.17-.98,2.17-2.17h0Z"
    />
    <path
      fill="#f8d9d6"
      d="M80.33,63.55c0-1.2-.98-2.17-2.17-2.17v8.19c1.2,0,2.17-4.81,2.17-6.01h0Z"
    />
    <path
      fill="#f8d9d6"
      d="M90.6,78.16c-1.2,0-6.01-.98-6.01-2.17s4.81-2.17,6.01-2.17,2.17.98,2.17,2.17-.98,2.17-2.17,2.17Z"
    />
    <path
      fill="#fdf6f4"
      d="M65.71,73.82c1.2,0,6.01.98,6.01,2.17s-4.81,2.17-6.01,2.17-2.17-.98-2.17-2.17.98-2.17,2.17-2.17Z"
    />
    <path
      fill="#f8d9d6"
      d="M86.92,69.83c-.72.72-4.19,3.02-4.91,2.3-.72-.72,1.58-4.19,2.3-4.91.72-.72,1.89-.72,2.61,0,.72.72.72,1.89,0,2.61Z"
    />
    <path
      fill="#fdf6f4"
      d="M69.38,82.15c.72-.72,4.19-3.02,4.91-2.3.72.72-1.58,4.19-2.3,4.91-.72.72-1.89.72-2.61,0-.72-.72-.72-1.89,0-2.61Z"
    />
    <path
      fill="#f8d9d6"
      d="M84.31,84.76c-.72-.72-3.02-4.19-2.3-4.91.72-.72,4.19,1.58,4.91,2.3.72.72.72,1.89,0,2.61-.72.72-1.89.72-2.61,0Z"
    />
    <path
      fill="#fdf6f4"
      d="M71.99,67.22c.72.72,3.02,4.19,2.3,4.91s-4.19-1.58-4.91-2.3-.72-1.89,0-2.61c.72-.72,1.89-.72,2.61,0Z"
    />
    <path
      fill="#ffc248"
      d="M8.45,76.47c0,14.02,11.37,25.39,25.39,25.39v-50.78c-14.02,0-25.39,11.37-25.39,25.39Z"
    />
    <path
      fill="#eaa721"
      d="M59.23,76.47c0-14.02-11.37-25.39-25.39-25.39v50.78c14.02,0,25.39-11.37,25.39-25.39Z"
    />
    <path
      fill="#ffffd9"
      d="M12.12,76.47c0,12,9.73,21.72,21.72,21.72v-43.45c-12,0-21.72,9.73-21.72,21.72h0Z"
    />
    <path
      fill="#ffeb99"
      d="M55.57,76.47c0-12-9.73-21.72-21.72-21.72v43.45c12,0,21.72-9.73,21.72-21.72h0Z"
    />
    <path
      fill="#fffff3"
      d="M19.61,76.47c0,7.86,6.37,14.23,14.23,14.23v-28.47c-7.86,0-14.23,6.37-14.23,14.23Z"
    />
    <path
      fill="#fefdda"
      d="M48.08,76.47c0-7.86-6.37-14.23-14.23-14.23v28.47c7.86,0,14.23-6.37,14.23-14.23Z"
    />
    <path
      fill="#ffc248"
      d="M18.58,67.05l5.84-5.84c.5-.5,1.33-.33,1.6.32l4.13,9.97c.34.8-.47,1.61-1.28,1.28l-9.97-4.13c-.64-.27-.81-1.1-.32-1.6h0Z"
    />
    <path
      fill="#ffc248"
      d="M17.74,71.44l9.97,4.13c.8.34.8,1.48,0,1.81l-9.97,4.13c-.64.27-1.35-.21-1.35-.91v-8.25c0-.7.71-1.17,1.35-.91h0Z"
    />
    <path
      fill="#ffc248"
      d="M18.9,84.3l9.97-4.13c.8-.34,1.61.47,1.28,1.28l-4.13,9.97c-.27.64-1.1.81-1.6.32l-5.84-5.84c-.5-.5-.33-1.33.32-1.6h0Z"
    />
    <path
      fill="#ffc248"
      d="M33.85,59.02h-4.13c-.7,0-1.17.71-.91,1.35l4.13,9.97c.17.4.54.6.91.6v-11.93h0Z"
    />
    <path
      fill="#ffc248"
      d="M33.85,82.01c-.37,0-.74.2-.91.6l-4.13,9.97c-.27.64.21,1.35.91,1.35h4.13v-11.93h0Z"
    />
    <path
      fill="#eaa721"
      d="M38.82,80.17l9.97,4.13c.64.27.81,1.1.32,1.6l-5.84,5.84c-.5.5-1.33.33-1.6-.32l-4.13-9.97c-.34-.8.47-1.61,1.28-1.28h0Z"
    />
    <path
      fill="#eaa721"
      d="M39.98,75.57l9.97-4.13c.64-.27,1.35.21,1.35.91v8.25c0,.7-.71,1.17-1.35.91l-9.97-4.13c-.8-.34-.8-1.48,0-1.81h0Z"
    />
    <path
      fill="#eaa721"
      d="M43.27,61.21l5.84,5.84c.5.5.33,1.33-.32,1.6l-9.97,4.13c-.8.34-1.61-.47-1.28-1.28l4.13-9.97c.27-.64,1.1-.81,1.6-.32h0Z"
    />
    <path
      fill="#eaa721"
      d="M33.85,93.93h4.13c.7,0,1.17-.71.91-1.35l-4.13-9.97c-.17-.4-.54-.6-.91-.6v11.93h0Z"
    />
    <path
      fill="#eaa721"
      d="M33.85,70.94c.37,0,.74-.2.91-.6l4.13-9.97c.27-.64-.21-1.35-.91-1.35h-4.13v11.93h0Z"
    />
  </SvgIcon>
);

export default IconAdditivePassionOrangeGuava;
