import { useState, useEffect } from 'react';
import IMedia from './IMedia';
import MediaService from './MediaService';

const useMediaDataAccess = (): { mediaItems: IMedia[] | undefined; error: Error | undefined } => {
  const [mediaItems, setMediaItems] = useState<IMedia[]>();
  const [error, setError] = useState<Error>();

  useEffect(() => {
    const getMedia = async () => {
      if (mediaItems) return;
      setError(undefined);
      const mediaService = new MediaService();
      try {
        const records = await mediaService.getMedia();
        setMediaItems(records);
      } catch (error) {
        setError(error as Error);
      }
    };
    if (!mediaItems) {
      getMedia();
    }
  }, [mediaItems]);

  return { mediaItems, error };
};

export default useMediaDataAccess;
