import { Card } from 'react-bootstrap';
import AuthGuard from '../../../../components/auth/AuthGuard';
import UserRole from '../../../../common/UserRole';
import { useAppSelector } from '../../../../hooks/storeHooks';
import { UserAccountContext } from '../../UserAccountContext';
import { useContext } from 'react';
import UserAccountTabReference from '../UserAccountTabReference';
import UserRoleSelect from '../../UserRoleSelect';
import UserRoleDisplayOnly from './UserRoleDisplayOnly';
import KiosksSelect from '../../KiosksSelect';
import AccountCreateStrings from '../../AccountCreate/AccountCreateStrings';
import SubmitButton from '../../../../components/Button/SubmitButton';

const UserAccountPermissions: React.FC = () => {
  const user = useAppSelector((store) => store.auth?.user);
  const userAccount = useContext(UserAccountContext);
  const accountIsLoggedInUser = user.uuid === userAccount?.state.user?.uuid;
  return (
    <Card style={{ padding: '2%' }}>
      <Card.Body>
        <Card.Title>Permissions</Card.Title>
        <Card.Body className='row' style={{}}>
          <div className='col s12 m4'>
            <AuthGuard authorizedRoles={[UserRole.DropSuperAdmin, UserRole.MachineSuperAdmin]} userRole={user?.role}>
              {accountIsLoggedInUser ? <UserRoleDisplayOnly /> : <UserRoleSelect />}
              <KiosksSelect description={AccountCreateStrings.KioskSelectDescription} companyId={userAccount?.state.user?.company as string} />
            </AuthGuard>
            <AuthGuard authorizedRoles={[UserRole.User, UserRole.MachineAdmin]} userRole={user?.role}>
              <UserRoleDisplayOnly />
            </AuthGuard>
          </div>
        </Card.Body>
        <SubmitButton
          label='Save'
          disabled={userAccount?.state.loading || !userAccount?.state.unsavedChanges}
          onClick={() => userAccount?.handleSaveUserData(UserAccountTabReference.Permissions)}
        />
      </Card.Body>
      <Card.Text className='row' style={{}}>
        {userAccount?.state?.error?.[UserAccountTabReference.Permissions]?.message}
        {userAccount?.state?.message?.[UserAccountTabReference.Permissions]}
      </Card.Text>
    </Card>
  );
};
export default UserAccountPermissions;
