export enum MonitorMotionDevice {
    DoorLatch = 0,
    UVFilter = 1,
  }
  
  export enum MonitorMotionPosition {
    Unlock = 0,
    UVFilterOn = 1,
    UVFilterOff = 2,
  }
  