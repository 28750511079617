import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import KioskControlButton from './KioskControlButton';
import KioskStrings from './KioskStrings';
import IconLoading from '../../components/ui/Icons/IconLoading';

interface IKioskCameraSnapshotControl {
  display: boolean;
  disabled: boolean;
  onRequest: () => void;
  imageUrl?: string;
  onClose: () => void;
}

const KioskCameraSnapshotControl: React.FC<IKioskCameraSnapshotControl> = ({
  display,
  disabled,
  onRequest,
  imageUrl,
  onClose,
}) => {
  const [modalDisplay, setModalDisplay] = useState<boolean>(false);

  const dialogStyle = {
    width: '90vw',
    maxWidth: '90vw',
    height: '55vw',
    maxHeight: '55vw'
  };

  const imageStyle = {
    width: '100%',
    height: 'auto'
  };

  return (
    <>
      {modalDisplay && (
        <Dialog
          open={modalDisplay}
          onClose={() => {
            setModalDisplay(false);
            onClose();
          }}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
          PaperProps={{
            style: dialogStyle
          }}
        >
          <DialogTitle id='alert-dialog-title'>{KioskStrings.CameraSnapshotTitle}</DialogTitle>
          <DialogContent>
            {imageUrl ? (
              <img src={imageUrl} alt='camera-snapshot' className='screenshot-modal' style={imageStyle} />
            ) : (
              <div className='center-align col s12' style={{ paddingTop: '40%' }}>
                <IconLoading width={50} height={30} />
              </div>
            )}
          </DialogContent>
        </Dialog>
      )}
      <KioskControlButton
        display={display}
        disabled={disabled}
        onClick={() => {
          onRequest();
          setModalDisplay(!modalDisplay);
        }}
        label={KioskStrings.KioskControlButtonRequestSnapshot}
        color='blue'
      />
    </>
  );
};

export default KioskCameraSnapshotControl;
