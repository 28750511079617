import IIconAdditive from './IIconAdditive';
import SvgIcon from './SvgIcon';

const IconAdditiveOrange: React.FC<IIconAdditive> = ({ className }) => (
  <SvgIcon
    className={className}
    width="112"
    height="112"
    fill="none"
    viewBox="0 0 112 112"
  >
    <path
      fill="#ffc248"
      d="M10.39,57.65c0,25.01,20.28,45.29,45.29,45.29V12.36c-25.01,0-45.29,20.28-45.29,45.29Z"
    />
    <path
      fill="#eaa721"
      d="M100.97,57.65c0-25.01-20.28-45.29-45.29-45.29v90.57c25.01,0,45.29-20.28,45.29-45.29Z"
    />
    <path
      fill="#ffffd9"
      d="M16.93,57.65c0,21.4,17.35,38.75,38.75,38.75V18.89c-21.4,0-38.75,17.35-38.75,38.75h0Z"
    />
    <path
      fill="#ffeb99"
      d="M94.43,57.65c0-21.4-17.35-38.75-38.75-38.75v77.5c21.4,0,38.75-17.35,38.75-38.75h0Z"
    />
    <path
      fill="#fffff3"
      d="M30.29,57.65c0,14.02,11.37,25.39,25.39,25.39v-50.78c-14.02,0-25.39,11.37-25.39,25.39Z"
    />
    <path
      fill="#fefdda"
      d="M81.07,57.65c0-14.02-11.37-25.39-25.39-25.39v50.78c14.02,0,25.39-11.37,25.39-25.39Z"
    />
    <path
      fill="#ffc248"
      d="M28.46,40.84l10.42-10.42c.88-.88,2.38-.58,2.85.57l7.37,17.78c.6,1.44-.85,2.88-2.28,2.28l-17.78-7.37c-1.15-.48-1.44-1.97-.57-2.85h0Z"
    />
    <path
      fill="#ffc248"
      d="M26.96,48.67l17.78,7.37c1.44.6,1.44,2.63,0,3.23l-17.78,7.37c-1.15.48-2.41-.37-2.41-1.62v-14.72c0-1.25,1.26-2.09,2.41-1.62h0Z"
    />
    <path
      fill="#ffc248"
      d="M29.02,71.61l17.78-7.37c1.44-.6,2.88.85,2.28,2.28l-7.37,17.78c-.48,1.15-1.97,1.44-2.85.57l-10.42-10.42c-.88-.88-.58-2.38.57-2.85h0Z"
    />
    <path
      fill="#ffc248"
      d="M55.68,26.51h-7.37c-1.25,0-2.09,1.26-1.62,2.41l7.37,17.78c.29.72.96,1.07,1.62,1.07v-21.27h0Z"
    />
    <path
      fill="#ffc248"
      d="M55.68,67.51c-.66,0-1.31.36-1.62,1.07l-7.37,17.78c-.48,1.15.37,2.41,1.62,2.41h7.37v-21.27h0Z"
    />
    <path
      fill="#eaa721"
      d="M64.56,64.24l17.78,7.37c1.15.48,1.44,1.97.57,2.85l-10.42,10.42c-.88.88-2.38.58-2.85-.57l-7.37-17.78c-.6-1.44.85-2.88,2.28-2.28h0Z"
    />
    <path
      fill="#eaa721"
      d="M66.62,56.03l17.78-7.37c1.15-.48,2.41.37,2.41,1.62v14.72c0,1.25-1.26,2.09-2.41,1.62l-17.78-7.37c-1.44-.6-1.44-2.63,0-3.23h0Z"
    />
    <path
      fill="#eaa721"
      d="M72.49,30.42l10.42,10.42c.88.88.58,2.38-.57,2.85l-17.78,7.37c-1.44.6-2.88-.85-2.28-2.28l7.37-17.78c.48-1.15,1.97-1.45,2.85-.57h0Z"
    />
    <path
      fill="#eaa721"
      d="M55.68,88.79h7.37c1.25,0,2.09-1.26,1.62-2.41l-7.37-17.78c-.29-.72-.96-1.07-1.62-1.07v21.27h0Z"
    />
    <path
      fill="#eaa721"
      d="M55.68,47.78c.66,0,1.31-.36,1.62-1.07l7.37-17.78c.48-1.15-.37-2.41-1.62-2.41h-7.37v21.27h0Z"
    />
  </SvgIcon>
);

export default IconAdditiveOrange;
