import React from 'react';
import SvgIcon from './SvgIcon';
import IIconAdditive from './IIconAdditive';

const IconAdditiveStrawberryKiwi: React.FC<IIconAdditive> = ({ className }) => (
  <SvgIcon
    className={className}
    width="112"
    height="112"
    fill="none"
    viewBox="0 0 112 112"
  >
    <path
      fill="#5c8a1e"
      d="M52.26,37.92v-15.98c4.64-7.63,13.51-9.57,13.51-9.57s3,5.74,2.2,12.61c6.51-.81,11.97,1.73,11.97,1.73s-1.07,6.25-5.74,11.21h-21.94Z"
    />
    <path
      fill="#9ec953"
      d="M52.26,37.92v-15.98c-4.64-7.63-13.51-9.57-13.51-9.57s-3,5.74-2.2,12.61c-6.51-.81-11.97,1.73-11.97,1.73s1.07,6.25,5.74,11.21h21.94Z"
    />
    <path
      fill="#f93c12"
      d="M52.26,29.71h-14.37c-12.38,0-22.41,10.04-22.41,22.41v7.34c0,9.34,3.31,18.39,9.35,25.52l7.22,8.53c5.29,6.25,12.75,9.37,20.21,9.37V29.71Z"
    />
    <path
      fill="#db2b0e"
      d="M52.26,29.71h14.37c12.38,0,22.41,10.04,22.41,22.41v7.34c0,9.34-3.31,18.39-9.35,25.52l-7.22,8.53c-5.29,6.25-12.75,9.37-20.21,9.37V29.71Z"
    />
    <path
      fill="#fd9d47"
      d="M52.26,29.43h-6.32c-5.44,0-9.85,6.86-9.85,15.32v5.01c0,6.39,1.45,12.57,4.11,17.44l3.18,5.83c2.33,4.27,5.61,6.4,8.89,6.4V29.43Z"
    />
    <path
      fill="#ef852e"
      d="M52.26,29.43h6.32c5.44,0,9.85,6.86,9.85,15.32v5.01c0,6.39-1.45,12.57-4.11,17.44l-3.18,5.83c-2.33,4.27-5.61,6.4-8.89,6.4V29.43Z"
    />
    <path
      fill="#b18956"
      d="M53.53,78.12c0,13.7,11.11,24.81,24.81,24.81v-49.62c-13.7,0-24.81,11.11-24.81,24.81Z"
    />
    <path
      fill="#936737"
      d="M103.15,78.12c0-13.7-11.11-24.81-24.81-24.81v49.62c13.7,0,24.81-11.11,24.81-24.81Z"
    />
    <path
      fill="#9ec953"
      d="M55.57,78.12c0,12.57,10.19,22.76,22.76,22.76v-45.53c-12.57,0-22.76,10.19-22.76,22.76Z"
    />
    <path
      fill="#5c8a1e"
      d="M101.11,78.12c0-12.57-10.19-22.76-22.76-22.76v45.53c12.57,0,22.76-10.19,22.76-22.76Z"
    />
    <g>
      <path
        fill="#e9f7d3"
        d="M78.34,67.7c-.31,0-.63.16-.81.47l-1.12,1.99c-.24.42-.76.59-1.2.39l-2.07-.95c-.66-.3-1.39.23-1.31.95l.26,2.26c.05.48-.26.92-.74,1.01l-2.24.45c-.71.14-.99,1.01-.5,1.54l1.54,1.68c.33.36.33.9,0,1.25l-1.54,1.68c-.49.53-.21,1.4.5,1.54l2.24.45c.47.1.79.54.74,1.02l-.26,2.26c-.08.72.65,1.25,1.31.95l2.07-.95c.44-.2.96-.03,1.2.39l1.12,1.99c.18.31.49.47.81.47v-20.84h0Z"
      />
      <path
        fill="#c2d39f"
        d="M78.34,88.54c.31,0,.63-.16.81-.47l1.12-1.99c.24-.42.76-.59,1.2-.39l2.07.95c.66.3,1.39-.23,1.31-.95l-.26-2.26c-.05-.48.26-.92.74-1.01l2.24-.45c.71-.14.99-1.01.5-1.54l-1.54-1.68c-.33-.36-.33-.9,0-1.25l1.54-1.68c.49-.53.21-1.4-.5-1.54l-2.24-.45c-.47-.1-.79-.54-.74-1.02l.26-2.26c.08-.72-.65-1.25-1.31-.95l-2.07.95c-.44.2-.96.03-1.2-.39l-1.12-1.99c-.18-.31-.49-.47-.81-.47v20.84h0Z"
      />
    </g>
  </SvgIcon>
);

export default IconAdditiveStrawberryKiwi;
