import DayOfWeek from '../../../common/DayOfWeek';
import UserRole from '../../../common/UserRole';
import IUser from '../../../services/User/IUser';

const InitialUser: IUser = {
  name: '',
  email: '',
  phone_number: '',
  country: '',
  state: '',
  city: '',
  role: UserRole.User,
  kioskIds: [],
  updatedAt: '',
  company: '',
  notificationPreferences: {
    kioskOrderSummary: {
      enabled: false,
      hourOfDay: 6,
      dayOfWeek: DayOfWeek.Monday,
    },
  },
};

export default InitialUser;
