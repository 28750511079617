import { createContext } from 'react';
import UserAccountState from './UserAccountState';
import IUser from '../../services/User/IUser';
import UserAccountTabReference from './AccountEdit/UserAccountTabReference';
import { UserArrayKeys } from './useUserAccount';
import KioskOrderSummary from '../../services/User/KioskOrderSummary';

export interface IUserAccountContext {
  state: UserAccountState;
  handleUserDataChange: (field: keyof IUser, value: string) => void;
  handleSaveUserData: (scope: UserAccountTabReference) => Promise<void>;
  handleSaveSecurityData: (scope: UserAccountTabReference) => Promise<void>;
  handlePasswordConfirmationChange: (value: string) => void;
  switchTab: (tab: UserAccountTabReference) => void;
  handleUserDataArrayChange: (field: keyof UserArrayKeys, value: string) => void;
  clearKioskIds: () => void;
  handleCreateUser: (scope: UserAccountTabReference) => void;
  handleKioskOrderReportChange: (field: keyof KioskOrderSummary, value: any) => void;
}

export const UserAccountContext = createContext<IUserAccountContext | undefined>(undefined);
