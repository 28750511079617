enum ChatStrings {
  NoMessagesToDisplay = 'No messages to display yet',
  KioskSoftwareOriginLabel = '(Kiosk Software)',
  SendReportIssueUnknown = 'There was an unknown issue',
  SendReportIssueRecipientsPrefix = 'Attempted to send to: ',
  SendReportSuccessRecipientsPrefix = 'Sent to:',
  SendMessageButtonReady = 'Send',
  SendMessageButtonSending = 'Sending',
  SendMessageButtonError = 'Send',
  SendMessageButtonInit = 'Loading',
  MessageInputPlaceholder = 'Type your message...',
  MessageInputCharLengthPrefix = 'Length:',
  MessageInputCharLengthSuffix = 'characters',
  MessageInputConfigIconAria = 'Configure Options',
  MessageFailureRetryButtonLabel = 'Resend',
  MessageFailureCancelButtonLabel = 'Cancel',
  GetNotificationsError = 'Error fetching notifications',
  SendNotificationError = 'Error sending notification',
  MessageConfigureHeading = 'Message Settings'
}

export default ChatStrings;
