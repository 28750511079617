import IKioskState from './IKioskState';

const initialState: IKioskState = {
  uuid: '',
  kiosk: undefined,
  showModal: false,
  online: true,
  isValid: true,
  command: '',
  refillStatus: 'Off',
  dropBottleStatus: 'Off',
  paymentStatus: 'Off',
  toggleActive: true,
  loaded: {
    Payment: true,
    Compostable: true,
    Refill: true,
  },
  resetCommandActive: false,
  errorMessage: undefined,
  softwareUpdateActive: false,
  screenshotRequestActive: false,
  screenshotURL: undefined,
  cameraSnapshotRequestActive: false,
  cameraSnapshotURL: undefined,
};

export default initialState;
