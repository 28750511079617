import ConfigService from '../ConfigService';
import ApiService from '../HttpClient/ApiService';
import ApiUtility from '../HttpClient/ApiUtility';
import IOrdersQuery from './IOrdersQuery';

export default class OrdersService {
  apiService: ApiService;

  constructor() {
    this.apiService = new ApiService(ConfigService.apiHost);
  }

  getOrders = async (query: IOrdersQuery): Promise<any> => {
    const params = new URLSearchParams();
    params.append('startDate', query.startDate);
    params.append('endDate', query.endDate);
    ApiUtility.appendArrayToParams(params, 'kiosks', query.kiosks);
    ApiUtility.appendArrayToParams(params, 'companies', query.companies);
    ApiUtility.appendArrayToParams(params, 'orderTypes', query.orderTypes);
    ApiUtility.appendArrayToParams(params, 'transactionTypes', query.transactionTypes);
    const orders = await this.apiService.request('orders', 'GET', params);
    return orders;
  };

  getKiosksSummary = async (query: IOrdersQuery): Promise<any> => {
    const params = new URLSearchParams();
    params.append('startDate', query.startDate);
    params.append('endDate', query.endDate);
    ApiUtility.appendArrayToParams(params, 'kiosks', query.kiosks);
    ApiUtility.appendArrayToParams(params, 'companies', query.companies);
    ApiUtility.appendArrayToParams(params, 'orderTypes', query.orderTypes);
    ApiUtility.appendArrayToParams(params, 'transactionTypes', query.transactionTypes);
    const orders = await this.apiService.request('orders/summary', 'GET', params);
    return orders;
  };
}
