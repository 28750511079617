import { BottlePricing, RefillPricing } from '../../../../common/Kiosk/KioskSettings';
import KioskType from '../../../../common/Kiosk/KioskType';
import {
  BottleInventorySettings,
  CO2InventorySettings,
  InventoryAlarmsConfig,
  InventoryCalculation
} from '../Settings/KioskInventoryControl/InventoryControlSettings';

export type FieldConfig<T> = {
  key: keyof T;
  required: boolean;
  type: 'string' | 'number' | 'enum' | 'boolean';
  kioskTypes: KioskType[];
  enumValues?: Record<string, string | number>;
};

export const RefillPricingFieldConfig: FieldConfig<RefillPricing>[] = [
  {
    key: 'caffeine',
    required: true,
    type: 'number',
    kioskTypes: []
  },
  {
    key: 'flavor',
    required: true,
    type: 'number',
    kioskTypes: []
  },
  { key: 'sparkling', required: true, type: 'number', kioskTypes: [KioskType.Refill] },
  {
    key: 'minimumCharge',
    required: true,
    type: 'number',
    kioskTypes: []
  },
  {
    key: 'preAuthCharge',
    required: true,
    type: 'number',
    kioskTypes: []
  }
];

export const BottlePricingFieldConfig: FieldConfig<BottlePricing>[] = [
  {
    key: 'base',
    required: true,
    type: 'number',
    kioskTypes: []
  },
  {
    key: 'caffeine',
    required: true,
    type: 'number',
    kioskTypes: []
  },
  {
    key: 'flavor',
    required: false,
    type: 'number',
    kioskTypes: []
  }
];

export const BottleInventoryControlFieldConfig: FieldConfig<BottleInventorySettings>[] = [
  {
    key: 'calculation',
    required: true,
    type: 'enum',
    kioskTypes: [KioskType.Duo],
    enumValues: InventoryCalculation
  }
];

export const InventoryAlarmFieldConfig: FieldConfig<InventoryAlarmsConfig>[] = [
  {
    key: 'low',
    required: true,
    type: 'number',
    kioskTypes: []
  },
  {
    key: 'empty',
    required: true,
    type: 'number',
    kioskTypes: []
  }
];

export const CO2InventoryFieldConfig: FieldConfig<CO2InventorySettings>[] = [
  {
    key: 'enabled',
    required: true,
    type: 'boolean',
    kioskTypes: [KioskType.Refill],
  }
];
