import { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import KioskControlButton from './KioskControlButton';
import KioskStrings from './KioskStrings';
import IconLoading from '../../components/ui/Icons/IconLoading';

interface IKioskScreenshotControl {
  display: boolean;
  disabled: boolean;
  onRequest: () => void;
  imageUrl?: string;
  onClose: () => void;
}

const KioskScreenshotControl: React.FC<IKioskScreenshotControl> = ({ display, disabled, onRequest, imageUrl, onClose }) => {
  const [modalDisplay, setModalDisplay] = useState<boolean>(false);
  return (
    <>
      {modalDisplay && (
        <Dialog
          open={modalDisplay}
          onClose={() => { setModalDisplay(false); onClose(); }}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
          style={{ width: '90%', height: '90%' }}
        >
          <DialogTitle id='alert-dialog-title'>{KioskStrings.ScreenshotTitle}</DialogTitle>
          <DialogContent>
            {imageUrl ? (
              <img src={imageUrl} alt='screenshot' className='screenshot-modal' />
            ) : (
              <div className='center-align col s12' style={{ paddingTop: '40%' }}>
                <IconLoading width={50} height={30} />
              </div>
            )}
          </DialogContent>
        </Dialog>
      )}
      <KioskControlButton
        display={display}
        disabled={disabled}
        onClick={() => {
          onRequest();
          setModalDisplay(!modalDisplay);
        }}
        label={KioskStrings.KioskControlButtonRequestScreenshot}
        color='grey'
      />
    </>
  );
};

export default KioskScreenshotControl;
