import IncidentResolutionStatus from './IncidentResolutionStatus';

interface IResolutionBadge {
  resolution: IncidentResolutionStatus;
}

const ResolutionBadge: React.FC<IResolutionBadge> = ({ resolution }) => {
  const resolutionBackground: Record<IncidentResolutionStatus, string> = {
    [IncidentResolutionStatus.Unresolved]: '#ff4136',
    [IncidentResolutionStatus.Resolved]: '#999'
  };

  const style: React.CSSProperties = {
    background: resolutionBackground[resolution],
    padding: '5px 10px',
    width: 'auto',
    borderRadius: '20px',
    color: 'white',
    textTransform: 'uppercase',
    textAlign: 'center',
    fontSize: '12px'
  };

  return <div style={style}>{resolution}</div>;
};

export default ResolutionBadge;
