import React from 'react';
import HealthStatus from './HealthStatus';

interface IHealthStatusIndicator {
  status: HealthStatus;
}

const statusColors: Record<HealthStatus, string> = {
  [HealthStatus.Healthy]: '#28b62c',
  [HealthStatus.Unhealthy]: '#ff4136',
  [HealthStatus.Neutral]: '#999'
};

const HealthStatusIndicator: React.FC<IHealthStatusIndicator> = ({ status }) => {
  return <span style={{ background: statusColors[status] }} className='indicator' />;
};

export default HealthStatusIndicator;
