import { CheckOutlined, ErrorOutline } from '@material-ui/icons';
import SendReport from '../../../services/Notifications/SendReport';
import { ReactNode } from 'react';
import styles from './SendReportDisplay.module.css';
import ChatStrings from '../ChatStrings';

interface ISendReportDisplay {
  sendReport?: SendReport;
  recipients?: string[];
}

type SendStatus = 'success' | 'warning' | 'failure';

const SendReportDisplay: React.FC<ISendReportDisplay> = ({ sendReport, recipients }) => {
  const getSendStatus = (): SendStatus => {
    if (!sendReport?.ok && !sendReport?.error) return 'warning';
    if (!sendReport?.ok && sendReport?.error) return 'failure';
    return 'success';
  };

  const statusToIcon: Record<SendStatus, ReactNode> = {
    success: <CheckOutlined color='action' />,
    warning: <ErrorOutline htmlColor='orange' />,
    failure: <ErrorOutline color='error' />
  };

  const sendStatus = getSendStatus();

  const messageMap: Record<SendStatus, () => string> = {
    success: () =>
      recipients && recipients.length > 0
        ? `${ChatStrings.SendReportSuccessRecipientsPrefix} ${recipients.join(', ')}`
        : 'Sent',

    warning: () => {
      const baseMessage = sendReport?.message ?? ChatStrings.SendReportIssueUnknown;
      return recipients && recipients.length > 0
        ? `${baseMessage}. ${ChatStrings.SendReportIssueRecipientsPrefix} ${recipients.join(', ')}`
        : baseMessage;
    },

    failure: () => {
      const baseMessage = sendReport?.error ?? ChatStrings.SendReportIssueUnknown;
      return recipients && recipients.length > 0
        ? `${baseMessage}. ${ChatStrings.SendReportIssueRecipientsPrefix} ${recipients.join(', ')}`
        : baseMessage;
    }
  };

  if (!sendReport) return null;

  return (
    <div className={styles.SendReportDisplay}>
      {statusToIcon[sendStatus]} {messageMap[sendStatus]()}
    </div>
  );
};

export default SendReportDisplay;
