import { useContext } from 'react';
import { UserAccountContext } from '../../UserAccountContext';
import { MenuItem, TextField } from '@material-ui/core';
import { Card } from 'react-bootstrap';
import AccountEditStrings from '../AccountEditStrings';

const moment = require('moment-timezone');

const TimezoneSelect: React.FC = () => {
  const userAccount = useContext(UserAccountContext);
  const timezones = moment.tz.names();
  const defaultTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  return (
    <>
      <Card.Subtitle style={{ fontWeight: '600', fontSize: '14px', marginTop: '3%' }}>
        {AccountEditStrings.TimezoneLabel}
      </Card.Subtitle>
      <TextField
        select
        type='select'
        size='small'
        variant='outlined'
        fullWidth={true}
        required={true}
        id='timezone'
        label='Timezone'
        value={userAccount?.state.user?.timezone || defaultTimezone}
        onChange={(e) => userAccount?.handleUserDataChange('timezone', e.target.value)}
        style={{ margin: '20px 0' }}
        disabled={userAccount?.state.loading}
      >
        {timezones.map((option) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </TextField>
    </>
  );
};

export default TimezoneSelect;
