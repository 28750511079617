import React, { useState } from 'react';
import styles from '../GantryControls.module.css';
import GantryJogConfig from '../../../../../../common/Peripherals/Gantry/GantryJogConfig';
import GantryJogConstants from './GantryJogConstants';

import GantryJogConfigToggleNumberInput from './GantryJogConfigToggleNumberInput';
import GantryJogConfigInputField from './GantryJogConfigField';
import GantryJogDirectionButtons from './GantryJogDirectionButtons';

interface IGantryJogInput {
  onClick: (config: GantryJogConfig) => void;
  loading: boolean;
}

const { inputsConfig } = GantryJogConstants;

const GantryJogInputs: React.FC<IGantryJogInput> = ({ onClick, loading }) => {
  const [config, setConfig] = useState<GantryJogConfig>(GantryJogConstants.defaultConfig);
  const [useNumberInput, setUseNumberInput] = useState(false);

  const withinRangeInclusive = (min: number, max: number, input: number) => {
    return (input - min) * (input - max) <= 0;
  };

  const handleInputChange = (field: keyof GantryJogConfig, min: number, max: number) => (value: number) => {
    if (withinRangeInclusive(min, max, value)) {
      setConfig((prevConfig) => ({ ...prevConfig, [field]: value }));
    }
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '20px', width: '100%' }}>
      <GantryJogDirectionButtons onClick={onClick} config={config} loading={loading} />
      <div className={styles.GantryJogInput}>
        <GantryJogConfigToggleNumberInput
          onChangeUseNumberInput={(enable) => setUseNumberInput(enable)}
          useNumberInput={useNumberInput}
        />
        {inputsConfig.map(([label, type, field, min, max, uom]) => (
          <GantryJogConfigInputField
            key={field}
            label={label}
            type={useNumberInput ? 'number' : type}
            value={config[field]}
            min={min}
            max={max}
            onChange={handleInputChange(field, min, max)}
            uom={uom}
          />
        ))}
      </div>
    </div>
  );
};

export default GantryJogInputs;
