import ConfigService from '../ConfigService';
import ApiService from '../HttpClient/ApiService';

export default class InventoryService {
  apiService: ApiService;

  constructor() {
    this.apiService = new ApiService(ConfigService.apiHost);
  }

  getInventory = async (): Promise<any> => {
    const inventory = await this.apiService.request('inventory', 'GET');
    return inventory as unknown as any;
  };
}
