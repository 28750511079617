import React from 'react';
import IconButtonComponent from '@material-ui/core/IconButton';

interface IIconButton {
  onClick: () => void;
  label: string;
  icon: JSX.Element;
}

const IconButton: React.FC<IIconButton> = ({ onClick, label, icon }) => {
  return (
    <IconButtonComponent
      onClick={onClick}
      aria-label={label}
    >
      {icon}
    </IconButtonComponent>
  );
};

export default IconButton;
