import { MenuItem, TextField } from '@material-ui/core';
import selectableUserRoles from './selectableUserRoles';
import { useAppSelector } from '../../hooks/storeHooks';
import { useContext } from 'react';
import { UserAccountContext } from './UserAccountContext';
import kebabToTitleCase from '../../utils/kebabToTitleCase';
import UserRole from '../../common/UserRole';

const UserRoleSelect: React.FC = () => {
  const user = useAppSelector((store) => store.auth?.user);
  const userAccount = useContext(UserAccountContext);

  return (
    <>
      {user?.role && (
        <TextField
          select
          type='select'
          size='small'
          variant='outlined'
          fullWidth={true}
          required={true}
          id='role'
          label='Role'
          value={userAccount?.state.user?.role || ''}
          onChange={(e) => userAccount?.handleUserDataChange('role', e.target.value)}
          style={{ margin: '20px 0' }}
          disabled={userAccount?.state.loading}
        >
          {selectableUserRoles(user?.role as UserRole)?.map((option) => (
            <MenuItem key={option} value={option}>
              {kebabToTitleCase(option)}
            </MenuItem>
          ))}
        </TextField>
      )}
    </>
  );
};

export default UserRoleSelect;
