interface ITableTotals {
  from: number;
  to: number;
  size: number;
  contextLabel: string;
}

const TableTotals: React.FC<ITableTotals> = ({ from, to, size, contextLabel }) => (
  <span className='react-bootstrap-table-pagination-total'>
    <strong>
      Showing {from} to {to} of {size} {contextLabel}
    </strong>
  </span>
);

export default TableTotals;
