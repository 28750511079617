import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import TransactionType from '../../common/TransactionType';

const useTransactionType = (): TransactionType | null => {
  const location = useLocation();
  const [transactionType, setTransactionType] = useState<TransactionType | null>(null);

  useEffect(() => {
    const URIArray = location.pathname.split('/');
    const newTransactionType = URIArray[URIArray.length - 1];
    const newTransactionTypeUpperCase = `${newTransactionType.charAt(0).toUpperCase()}${newTransactionType.slice(
      1
    )}` as TransactionType;
    if (
      Object.values(TransactionType).includes(newTransactionTypeUpperCase) &&
      newTransactionTypeUpperCase !== transactionType
    ) {
      setTransactionType(newTransactionTypeUpperCase);
    }
  }, [location, transactionType]);

  return transactionType;
};

export default useTransactionType;
