import { useState, useEffect, useCallback, useRef } from 'react';
import UserAccountState from './UserAccountState';
import IUser from '../../services/User/IUser';
import useUserDataAccess from '../../services/User/useUserDataAccess';
import UserService from '../../services/User/UserService';
import UserRole from '../../common/UserRole';
import { useAppSelector } from '../../hooks/storeHooks';
import UserAccountTabReference from './AccountEdit/UserAccountTabReference';
import validatePassword from '../../utils/validatePassword/validatePassword';
import KioskOrderSummary from '../../services/User/KioskOrderSummary';
import InitialUser from './AccountCreate/InitialUser';

export type UserArrayKeys = Pick<IUser, 'kioskIds'>;

export const useUserAccount = (initialUser?: IUser) => {
  const [state, setState] = useState<UserAccountState>({
    passwordConfirm: '',
    currentTab: UserAccountTabReference.General,
    user: initialUser || ({} as IUser),
    unsavedChanges: false,
    loading: true,
    error: {},
    message: {},
  });
  const userData = useUserDataAccess();
  const user = useAppSelector((store) => store.auth?.user);

  useEffect(() => {
    if (initialUser) {
      setState((prevState) => ({
        ...prevState,
        loading: false,
      }));
      return;
    }
    if (!userData.user) return;
    setState((prevState) => ({
      ...prevState,
      user: userData?.user,
      loading: false,
    }));
  }, [initialUser, userData.user]);

  useEffect(() => {
    if (initialUser) {
      setState((prevState) => ({
        ...prevState,
        loading: false,
      }));
      return;
    }
    if (!userData.error) return;
    setState((prevState) => ({
      ...prevState,
      error: {
        [UserAccountTabReference.General]: userData?.error,
      },
    }));
  }, [initialUser, userData.error]);

  const handleUserDataChange = (field: keyof IUser, value: string) => {
    if (!state.user) return;
    const updatedUser = {
      ...state.user,
      [field]: value,
    };
    setState({
      ...state,
      user: updatedUser,
      unsavedChanges: true,
      message: {},
    });
  };

  const handleUserDataArrayChange = (field: keyof UserArrayKeys, value: string) => {
    if (!state.user) return;
    let updatedArray = [...state.user[field]];
    const updatedUser = {
      ...state.user,
      [field]: updatedArray.includes(value)
        ? updatedArray.filter((item) => item !== value)
        : (updatedArray = [...updatedArray, value]),
    };
    setState({
      ...state,
      user: updatedUser,
      unsavedChanges: true,
      message: {},
    });
  };

  const clearKioskIds = useCallback(() => {
    setState((prevState) => {
      if (!prevState.user) return prevState;
      const updatedUser = {
        ...prevState.user,
        kioskIds: [],
      };
      return { ...prevState, user: updatedUser, unsavedChanges: true, message: {} };
    });
  }, []);

  const previousCompany = useRef(state.user?.company);

  useEffect(() => {
    if (previousCompany.current && state.user?.company && previousCompany.current !== state.user?.company) {
      clearKioskIds();
    }
    previousCompany.current = state.user?.company;
  }, [clearKioskIds, state.user?.company]);

  const prepareUserUpdate = () => {
    const userUpdate = { ...state.user };
    if (user.role === UserRole.MachineAdmin || user.role === UserRole.User) {
      // these users cannot update a user's role
      delete userUpdate.role;
    }
    if (user.uuid === userUpdate.uuid) {
      // prevent users updating their own role
      delete userUpdate.role;
    }
    return userUpdate as IUser;
  };

  const handleSaveUserData = async (scope: UserAccountTabReference) => {
    setState({
      ...state,
      loading: true,
      message: {},
      error: {},
    });
    const userService = new UserService();
    try {
      if (!state.user) return;
      const userUpdate = prepareUserUpdate();
      const userServiceResponse = await userService.updateUser(userUpdate);
      setState({
        ...state,
        loading: false,
        user: userServiceResponse,
        message: {
          ...state.message,
          [scope]: 'Update successful',
        },
        error: {},
        unsavedChanges: false,
        passwordConfirm: undefined,
      });
    } catch (error) {
      console.error(error);
      setState({
        ...state,
        error: {
          ...state.error,
          [scope]: new Error(
            error instanceof Error ? error.message : 'Update not saved, an unknown error has occurred'
          ),
        },
      });
    }
  };

  const handleCreateUser = async (scope: UserAccountTabReference) => {
    setState({
      ...state,
      loading: true,
      message: {},
      error: {},
    });
    const userService = new UserService();
    try {
      if (!state.user) return;
      if (state?.user?.password && state?.user?.password !== state.passwordConfirm) {
        throw new Error('Password and confirm password do not match, please update');
      }
      validatePassword(state.user?.password as string);
      const userServiceResponse = await userService.createUser(state.user);
      setState({
        ...state,
        loading: false,
        user: InitialUser,
        message: {
          ...state.message,
          [scope]: `User created successfully with id: ${userServiceResponse._id}`,
        },
        error: {},
        unsavedChanges: false,
        passwordConfirm: undefined,
      });
    } catch (error) {
      console.error(error);
      setState({
        ...state,
        error: {
          ...state.error,
          [scope]: new Error(error instanceof Error ? error.message : 'User not saved, an unknown error has occurred'),
        },
      });
    }
  };

  const handleSaveSecurityData = async (scope: UserAccountTabReference) => {
    setState({
      ...state,
      loading: true,
      message: {},
      error: {},
    });
    try {
      if (state?.user?.password && state?.user?.password !== state.passwordConfirm) {
        throw new Error('Password and password confirmation do not match, please update');
      }
      validatePassword(state.user?.password as string);
      await handleSaveUserData(scope);
    } catch (error) {
      setState({
        ...state,
        error: {
          ...state.error,
          [scope]: new Error(
            error instanceof Error ? error.message : 'Update not saved, an unknown error has occurred'
          ),
        },
        loading: false,
      });
    }
  };

  const handleKioskOrderReportChange = (
    field: keyof KioskOrderSummary,
    value: any
  ) => {
    if (!state.user) return;
    const notificationPreferences = state.user.notificationPreferences || { kioskOrderSummary: {} as KioskOrderSummary };
    const kioskOrderSummary = notificationPreferences.kioskOrderSummary || {} as KioskOrderSummary;
    const updatedKioskOrderSummary = {
      ...kioskOrderSummary,
      [field]: value,
    };
    const updatedPreferences = {
      ...notificationPreferences,
      kioskOrderSummary: updatedKioskOrderSummary,
    };
    const updatedUser = {
      ...state.user,
      notificationPreferences: updatedPreferences,
    };
    setState({
      ...state,
      user: updatedUser,
      unsavedChanges: true,
      message: {},
    });
  };

  const switchTab = (userAccountTabReference: UserAccountTabReference) => {
    setState({ ...state, currentTab: userAccountTabReference });
  };

  const handlePasswordConfirmationChange = (value: string) => {
    setState({ ...state, passwordConfirm: value });
  };

  return {
    state,
    handleUserDataChange,
    handleSaveUserData,
    handleSaveSecurityData,
    handlePasswordConfirmationChange,
    switchTab,
    handleUserDataArrayChange,
    clearKioskIds,
    handleCreateUser,
    handleKioskOrderReportChange
  };
};
