interface IKioskLink {
  alias?: string;
  id: string;
}

export const KioskLink: React.FC<IKioskLink> = ({ alias, id }) => {
  return (
    <a href={`/kiosk/${id}`} style={{ color: '#343434', textTransform: 'uppercase', fontSize: '15px' }}>
      <strong>{id}</strong> {alias && alias !== id && <>({alias})</>}
    </a>
  );
};
