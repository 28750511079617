import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import KioskSettings, { Pump, TowerId, TowerSettings } from '../../common/Kiosk/KioskSettings';
import KioskEditTabReference from '../../screens/Kiosk/KioskEdit/KioskEditTabReference';
import KioskSettingsState from '../../screens/Kiosk/KioskEdit/KioskEditState';
import useKioskSettingDataAccess from './useKioskSettingDataAccess';
import useDeviceMonitor from '../../hooks/useDeviceMonitor/useDeviceMonitor';
import { InventoryControlSettings } from '../../screens/Kiosk/KioskEdit/Settings/KioskInventoryControl/InventoryControlSettings';

export const useKioskSettings = (initialSettings?: KioskSettings) => {
  const params = useParams();
  const kioskId = params?.uuid;

  const [state, setState] = useState<KioskSettingsState>({
    currentTab: KioskEditTabReference.Settings,
    unsavedChanges: false,
    loading: true,
    error: {},
    message: {},
  });

  const { settings: settingsData, updateKioskSettings, getKioskSettings } = useKioskSettingDataAccess(kioskId);

  useEffect(() => {
    if (initialSettings) {
      setState((prevState) => ({
        ...prevState,
        settings: initialSettings,
        loading: false,
      }));
    }
    if (!settingsData) return;
    setState((prevState) => ({
      ...prevState,
      settings: settingsData,
      loading: false,
    }));
  }, [initialSettings, settingsData]);

  const deviceStatus = useDeviceMonitor(false, kioskId);

  useEffect(() => {
    if (deviceStatus?.online) getKioskSettings();
  }, [deviceStatus.online, getKioskSettings]);

  useEffect(() => {
    if (kioskId)
      setState((prevState) => ({
        ...prevState,
        kioskId: kioskId,
      }));
  }, [kioskId]);

  const handleSettingsChange = <T extends keyof KioskSettings>(field: T, value: KioskSettings[T]) => {
    if (!state) return;
    if (state.settings === undefined) return;

    setState((prevState) => ({
      ...prevState,
      settings: {
        ...prevState.settings,
        [field]: value,
      },
      changes: {
        ...prevState.changes,
        [field]: value,
      },
      unsavedChanges: true,
      message: {},
    }));
  };

  const handleArrayItemChange = <T extends { id: number }>(
    arrayField: keyof KioskSettings,
    itemId: number,
    field: keyof T,
    value: T[keyof T]
  ) => {
    if (!state || !state.settings) return;

    const arrayToUpdate = [...(state.settings[arrayField] as T[])];
    const itemToUpdateIndex = arrayToUpdate.findIndex((item) => item.id === itemId);
    arrayToUpdate[itemToUpdateIndex] = { ...arrayToUpdate[itemToUpdateIndex], [field]: value };

    const changes = state.changes || {};
    const arrayChanges = (changes[arrayField] || []) as Partial<T>[];

    const existingItemChangeIndex = arrayChanges.findIndex((item) => item.id === itemId);
    if (existingItemChangeIndex >= 0) {
      arrayChanges[existingItemChangeIndex] = {
        ...arrayChanges[existingItemChangeIndex],
        [field]: value
      } as Partial<T>;
    } else {
      const newItemChange = { id: itemId, [field]: value } as Partial<T>;
      arrayChanges.push(newItemChange);
    }

    setState((prevState) => ({
      ...prevState,
      settings: {
        ...prevState.settings,
        [arrayField]: arrayToUpdate
      },
      changes: {
        ...changes,
        [arrayField]: arrayChanges
      },
      unsavedChanges: true,
    }));
  };

  const handlePumpChange = <T extends keyof Pump>(field: T, pumpId: number, value: Pump[T]) => {
    handleArrayItemChange<Pump>('pumps', pumpId, field, value);
  };

  const handleTowerChange = <T extends keyof TowerSettings>(
    field: T,
    towerId: TowerId,
    value: TowerSettings[T]
  ) => {
    handleArrayItemChange<TowerSettings>('towers', towerId, field, value);
  };

  const handleActivateTower = (towerId: TowerId, activate: boolean) => {
    setState((prevState) => {
      const updatedTowersInSettings =
        prevState.settings?.towers?.map((tower) => ({
          ...tower,
          active: tower.id === towerId ? activate : false,
        })) ?? [];

      const updatedTowersInChanges =
        prevState.settings?.towers?.map((tower) => ({
          ...tower,
          active: tower.id === towerId ? activate : false,
        })) ?? [];

      return {
        ...prevState,
        settings: {
          ...prevState.settings,
          towers: updatedTowersInSettings,
        },
        changes: {
          ...prevState.changes,
          towers: updatedTowersInChanges,
        },
        unsavedChanges: true,
      };
    });
  };

  const saveSettings = async () => {
    setState((prevState) => ({
      ...prevState,
      loading: true,
      error: {
        [KioskEditTabReference.Settings]: undefined,
      },
      message: {},
    }));

    try {
      await updateKioskSettings(state.changes as Partial<KioskSettings>);
      setState((prevState) => ({
        ...prevState,
        loading: false,
        unsavedChanges: false,
        message: { [KioskEditTabReference.Settings]: 'Settings updated successfully' },
        changes: undefined,
      }));
    } catch (error) {
      console.error(error);
      const errorString = `${
        error instanceof Error ? error.message : JSON.stringify(error)
      }. Unsaved changes: ${JSON.stringify(state.changes)} `;
      setState((prevState) => ({
        ...prevState,
        error: { [KioskEditTabReference.Settings]: new Error(errorString) },
        loading: false,
      }));
    }
  };

  const switchTab = (kioskEditTabReference: KioskEditTabReference) => {
    setState({ ...state, currentTab: kioskEditTabReference });
  };

  const handleInventoryControlChange = <T extends keyof InventoryControlSettings>(
    field: T,
    value: InventoryControlSettings[T]
  ) => {
    if (!state.settings?.inventoryControl) {
      return state;
    }

    setState((prevState) => {
      if (!prevState.settings?.inventoryControl) {
        return prevState;
      }

      return {
        ...prevState,
        settings: {
          ...prevState.settings,
          inventoryControl: {
            ...prevState.settings.inventoryControl,
            [field]: value
          }
        },
        changes: {
          ...prevState.changes,
          inventoryControl: {
            ...(prevState.changes?.inventoryControl || {}),
            [field]: value
          }
        },
        unsavedChanges: true
      };
    });
  };

  return {
    state,
    handleSettingsChange,
    saveSettings,
    switchTab,
    deviceStatus,
    handlePumpChange,
    handleTowerChange,
    handleActivateTower,
    handleInventoryControlChange
  };
};
