import { Card } from 'react-bootstrap';
import { useContext, useState } from 'react';
import { KioskSettingsContext } from '../Settings/KioskSettingsContext';
import KioskSettingsStatusMessage from '../Settings/KioskSettingsStatusMessage';
import SoftwareVersionEdit from './SoftwareVersionEdit';

const KioskSystem: React.FC = () => {
  const kioskSettings = useContext(KioskSettingsContext);
  const [changeInProgress, setChangeInProgress] = useState<boolean>(false);

  return (
    <Card style={{ padding: '2%' }}>
      <Card.Body>
        <Card.Title>System</Card.Title>
        <KioskSettingsStatusMessage deviceStatus={kioskSettings?.deviceStatus} />
      </Card.Body>
      <Card.Body className='row' style={{ display: 'flex', flexDirection: 'column', gap: '14px' }}>
        <SoftwareVersionEdit
          currentVersion={kioskSettings?.deviceStatus.report?.appVersion}
          onChangeRequestStarted={() => setChangeInProgress(true)}
          onChangeRequestComplete={() => setChangeInProgress(false)}
          disabled={changeInProgress || !kioskSettings?.deviceStatus.ready}
          kioskId={kioskSettings?.state?.kioskId}
        />
      </Card.Body>
    </Card>
  );
};
export default KioskSystem;
