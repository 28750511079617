import { MenuItem, TextField } from '@material-ui/core';
import { useContext } from 'react';
import useCompanies from '../../../services/Company/useCompany';
import { UserAccountContext } from '../UserAccountContext';

const CompanySelect: React.FC = () => {
  const userAccount = useContext(UserAccountContext);
  const companies = useCompanies();

  return (
    <>
      {companies && (
        <TextField
          select
          size='small'
          variant='outlined'
          fullWidth={true}
          required={true}
          id='company'
          label='Company'
          value={userAccount?.state?.user?.company || ''}
          onChange={(e) => userAccount?.handleUserDataChange('company', e.target.value)}
          style={{ margin: '20px 0' }}
          disabled={userAccount?.state.loading}
        >
          {companies.map((company) => (
            <MenuItem key={company._id} value={company._id}>
              {company.name}
            </MenuItem>
          ))}
        </TextField>
      )}
    </>
  );
};

export default CompanySelect;
