import React, { useEffect, useRef } from 'react';
import MessageCard from '../MessageCard/MessageCard';
import IMessage from '../../../common/Notifications/IMessage';
import styles from './MessageList.module.css';
import IconLoading from '../../ui/Icons/IconLoading';
import { ChatStatus } from '../ChatWidget/ChatWidget';
import ChatStrings from '../ChatStrings';

interface IMessageList {
  messages?: IMessage[];
  status: ChatStatus;
}

const MessageList: React.FC<IMessageList> = ({ messages, status }) => {
  const messagesEndRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (messagesEndRef.current) {
      const scrollContainer = messagesEndRef.current.parentNode as HTMLElement;
      if (scrollContainer) {
        scrollContainer.scrollTop = scrollContainer.scrollHeight;
      }
    }
  }, [messages]);

  const messagesSorted = messages?.sort((a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime());

  return (
    <div className={styles.MessageList}>
      {status === ChatStatus.Initializing && <IconLoading width={40} height={20} />}
      {messages && messages.length === 0 && <>{ChatStrings.NoMessagesToDisplay}</>}
      {messagesSorted && messagesSorted.map((message) => <MessageCard key={message.id} message={message} />)}
      <div ref={messagesEndRef} />
    </div>
  );
};

export default MessageList;
