import OrderPaymentsList from './OrderPaymentsList';
import OrderPromotionsList from './OrderPromotionsList';

const ExpandingTableRow = {
  renderer: (row) => {
    if (row?.payments?.length === 0 && !row?.promoDetails) return;
    return (
      <div key={row.id}>
        {row?.payments?.length > 0 && <OrderPaymentsList paymentDetails={row.payments} />}
        {row?.promoDetails && <OrderPromotionsList promoDetails={row.promoDetails} />}
      </div>
    );
  },
  onlyOneExpanding: true,
};

export default ExpandingTableRow;
