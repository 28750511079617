enum GantryControlsStrings {
  GantryControlsHeading = 'Gantry Controls',
  StopExecLabel = 'Stop',
  CloseLabel = 'Close',
  FailureCommandMessage = 'Failed to execute command.',
  SuccessCommandMessage = 'Command executed successfully',
  RefreshImagePrompt = 'Refresh picture after each change',
  JogInstantStartWarning = 'The Gantry will move after each tap / click',
  ToggleJogNumberInputs = 'Number Inputs',
  JogPrompt = 'Select a direction to move the Gantry',
  KioskFeedbackImageAlt = 'Kiosk Camera Capture'
}

export default GantryControlsStrings;
