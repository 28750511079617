import IIconAdditive from './IIconAdditive';
import SvgIcon from './SvgIcon';

const IconAdditiveBlackberry: React.FC<IIconAdditive> = ({ className }) => (
  <SvgIcon
    className={className}
    width="112"
    height="112"
    fill="none"
    viewBox="0 0 112 112"
  >
    <path
      fill="#5c8a1e"
      d="M55.04,35.79v-16.3c4.69-7.78,13.66-9.77,13.66-9.77,0,0,3.03,5.85,2.23,12.87,6.59-.83,12.1,1.76,12.1,1.76,0,0-1.08,6.38-5.81,11.44h-22.19,0Z"
    />
    <path
      fill="#9ec953"
      d="M55.03,35.79v-16.3c-4.69-7.78-13.66-9.77-13.66-9.77,0,0-3.03,5.85-2.23,12.87-6.59-.83-12.1,1.76-12.1,1.76,0,0,1.08,6.38,5.81,11.44h22.19,0Z"
    />
    <path
      fill="#40516b"
      d="M48.46,28.15c-1.64-1.83-3.97-2.98-6.57-2.98-4.93,0-8.93,4.12-8.93,9.19,0,.2.02.39.03.59-.98-.38-2.03-.59-3.13-.59-4.93,0-8.93,4.12-8.93,9.19,0,2.82,1.24,5.35,3.19,7.04-3.86,1.02-6.72,4.61-6.72,8.9s2.86,7.88,6.72,8.9c-1.95,1.69-3.19,4.21-3.19,7.04,0,5.08,4,9.19,8.93,9.19.61,0,1.2-.07,1.77-.18-.44,1.09-.69,2.28-.69,3.53,0,5.08,4,9.19,8.93,9.19,2.54,0,4.83-1.1,6.46-2.86.91,4.09,4.45,7.15,8.71,7.15V25.17c-2.61,0-4.94,1.15-6.57,2.98Z"
    />
    <path
      fill="#243651"
      d="M94.62,59.48c0-4.29-3-7.88-7.06-8.89,2.05-1.69,3.35-4.21,3.35-7.04,0-5.07-4.21-9.19-9.39-9.19-1.16,0-2.27.21-3.29.59.01-.2.03-.39.03-.59,0-5.07-4.21-9.19-9.39-9.19-2.74,0-5.2,1.15-6.91,2.98-1.72-1.83-4.17-2.98-6.91-2.98v76.28c4.47,0,8.21-3.06,9.16-7.15,1.71,1.75,4.12,2.86,6.79,2.86,5.19,0,9.39-4.12,9.39-9.19,0-1.25-.26-2.44-.72-3.53.6.12,1.23.18,1.86.18,5.19,0,9.39-4.12,9.39-9.19,0-2.82-1.3-5.35-3.35-7.04,4.05-1.02,7.06-4.61,7.06-8.89h0Z"
    />
    <path
      fill="#142335"
      d="M64.59,69.79l3.41-5.96c2.85-4.99,4.42-11.31,4.42-17.85v-5.13c0-6.88-2.99-12.72-7.16-14.83-1.17.52-2.21,1.25-3.07,2.14-1.71-1.77-4.13-2.9-6.84-2.98h-.31v51.17c3.53,0,7.05-2.18,9.55-6.55h0Z"
    />
    <path
      fill="#243651"
      d="M48.69,28.15c-.77-.89-1.69-1.62-2.73-2.14-3.7,2.11-6.37,7.95-6.37,14.83v5.13c0,6.54,1.39,12.86,3.93,17.85l3.03,5.96c2.22,4.37,5.35,6.55,8.49,6.55V25.17h-.27c-2.4.08-4.56,1.21-6.08,2.98h0Z"
    />
  </SvgIcon>
);

IconAdditiveBlackberry.defaultProps = {
  className: '',
};

export default IconAdditiveBlackberry;
