enum KioskEditStrings {
  KioskStatusLabel = 'Kiosk Status',
  DisabledHardwareLabel = 'Disabled Hardware',
  DisabledHardwareWarning = 'Kiosk must be rebooted for any changes to disabled devices to take effect',
  BottlePricingLabel = 'Bottle Pricing',
  RefillPricingLabel = 'Refill Pricing',
  FetchingSettings = 'Fetching kiosk settings',
  SettingsDisabledDeviceNotOnline = 'Cannot edit kiosk settings, kiosk is not online',
  FeatureToggleInProgress = 'Toggling feature, please wait',
  PumpsLabel = 'Pumps',
  TowersLabel = 'Towers',
  KioskOffline = 'Kiosk offline',
  SoftwareUpdateInProgress = 'Software Update Request In Progress',
  SoftwareUpdateIgnoreMaintenanceLabel = 'Update ASAP (outside of maintenance hours)',
  CustomerExperienceSettingsLabel = 'Customer Experience Settings',
  BackgroundServicesSettingsLabel = 'Background Services Settings',
  CommandSequenceInProgress = 'Command Sequence In Progress',
  PeripheralControlsHeading = 'Peripheral Controls',
  PeripheralControlLabel = 'controls',
  PeripheralCommandsHeading = 'Peripheral Commands',
  InventoryControlLabel = 'Inventory Control Settings',
  BottleInventoryControlLabel = 'Bottle Inventory Control',
  AlarmThresholdLabel = 'Alarm Threshold',
  CO2InventoryControlLabel = 'CO2 Inventory Control',
  CO2InventoryContextLabel = 'PSI',
  EnableCO2Label = 'Enable CO2 (Sparkling) Sales'
}

export default KioskEditStrings;
