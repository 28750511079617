import React, { useContext } from 'react';
import { Card } from 'react-bootstrap';
import { TextField } from '@material-ui/core';
import { UserAccountContext } from '../../UserAccountContext';
import { UserProfileFieldConfig } from '../../UserAccountFieldConfig';
import formatFieldName from '../../../../utils/formatFieldName';
import UserAccountTabReference from '../UserAccountTabReference';
import SubmitButton from '../../../../components/Button/SubmitButton';

const UserAccountGeneral: React.FC = () => {
  const userAccount = useContext(UserAccountContext);

  return (
    <Card style={{ padding: '2%' }} className='animate__animated animate__zoomIn'>
      <Card.Body>
        <Card.Title>General</Card.Title>
        <Card.Body className='row' style={{}}>
          {UserProfileFieldConfig.map((field, i) => (
            <div key={i} className='col s12 m4'>
              <TextField
                size='small'
                variant='outlined'
                fullWidth={true}
                required={field.required}
                id={field.key}
                label={formatFieldName(field.key)}
                value={userAccount?.state?.user?.[field.key] || ''}
                onChange={(e) => userAccount?.handleUserDataChange(field.key, e.target.value)}
                style={{ margin: '10px 0' }}
                disabled={userAccount?.state.loading}
              />
            </div>
          ))}
        </Card.Body>
        <SubmitButton
          label='Save'
          disabled={userAccount?.state.loading || !userAccount?.state.unsavedChanges}
          onClick={() => userAccount?.handleSaveUserData(UserAccountTabReference.General)}
        />
      </Card.Body>
      <Card.Text className='row' style={{}}>
        {userAccount?.state?.error?.[UserAccountTabReference.General]?.message}
        {userAccount?.state?.message?.[UserAccountTabReference.General]}
      </Card.Text>
    </Card>
  );
};

export default UserAccountGeneral;
