import AppBar from '@material-ui/core/AppBar';
import Tab from '@material-ui/core/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import PageHeading from '../../../components/Heading/PageHeading';
import UserAccountTabReference from './UserAccountTabReference';
import { UserAccountContext } from '../UserAccountContext';
import { useUserAccount } from '../useUserAccount';
import UserAccountGeneral from './General/UserAccountGeneral';
import UserAccountSecurity from './Security/UserAccountSecurity';
import UserAccountPermissions from './Permissions/UserAccountPermissions';
import UserAccountNotifications from './Notifications/UserAccountNotifications'

const UserAccountEdit: React.FC = () => {
  const userAccount = useUserAccount();
  return (
    <div id='user-account-create' style={{ marginTop: '1%', width: '100%' }} className='valign-wrapper'>
      <div className='row' style={{ marginTop: '1%', width: '100%'}}>
        <PageHeading heading='User Account' />
        <div>
          <div style={{ marginTop: '50px' }}>
            <UserAccountContext.Provider value={userAccount}>
              <TabContext value={userAccount.state.currentTab}>
                <AppBar position='static' style={{ backgroundColor: 'transparent', color: 'black' }}>
                  <TabList onChange={(_e, index) => userAccount.switchTab(index)} aria-label=''>
                    <Tab label='General' value={UserAccountTabReference.General} />
                    <Tab label='Security' value={UserAccountTabReference.Security} />
                    <Tab label='Permissions' value={UserAccountTabReference.Permissions} />
                    <Tab label='Notifications' value={UserAccountTabReference.Notifications} />
                  </TabList>
                </AppBar>
                <TabPanel value={UserAccountTabReference.General} style={{ padding: 0 }}>
                  <UserAccountGeneral />
                </TabPanel>
                <TabPanel value={UserAccountTabReference.Security} style={{ padding: 0 }}>
                  <UserAccountSecurity />
                </TabPanel>
                <TabPanel value={UserAccountTabReference.Permissions} style={{ padding: 0 }}>
                  <UserAccountPermissions />
                </TabPanel>
                <TabPanel value={UserAccountTabReference.Notifications} style={{ padding: 0 }}>
                  <UserAccountNotifications />
                </TabPanel>
              </TabContext>
            </UserAccountContext.Provider>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserAccountEdit;
