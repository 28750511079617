import IconProps from './IconProps';
import SvgIcon from './SvgIcon';

const IconArrowDownTwoTone: React.FC<IconProps> = ({
  className,
  width,
  height,
}) => (
  <SvgIcon className={className} width={width} height={height} fill="#555">
    <path
      fill="#000000"
      d="M12 4c-4.41 0-8 3.59-8 8s3.59 8 8 8 8-3.59 8-8-3.59-8-8-8m0 12-4-4h3V8h2v4h3z"
      opacity=".3"
    />
    <path
      fill="#000000"
      d="M12 4c4.41 0 8 3.59 8 8s-3.59 8-8 8-8-3.59-8-8 3.59-8 8-8m0-2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2m1 10V8h-2v4H8l4 4 4-4z"
    />
  </SvgIcon>
);
export default IconArrowDownTwoTone;
