import React, { useEffect, useState } from 'react';

import PageHeading from '../../components/Heading/PageHeading';
import UserRole from '../../common/UserRole';
import DataTable from '../../components/table/DataTable';
import MediaListState from './MediaListState';
import useMediaDataAccess from '../../services/Media/useMediaDataAccess';
import AuthGuard from '../../components/auth/AuthGuard';
import { useAppSelector } from '../../hooks/storeHooks';
import MediaColumnKeys from './MediaTable/MediaColumnKeys';
import MediaTableConfig from './MediaTable/MediaTableConfig';
import IMedia from '../../services/Media/IMedia';

const UsersList: React.FC = () => {
  const initialState: MediaListState = {
    mediaItems: [],
    loading: true,
  };
  const [state, setState] = useState<MediaListState>(initialState);
  const mediaData = useMediaDataAccess();
  const user = useAppSelector((store) => store.auth?.user);

  useEffect(() => {
    if (!mediaData.mediaItems) return;
    setState((prevState) => ({
      ...prevState,
      mediaItems: mediaData.mediaItems || [],
      error: mediaData.mediaItems?.length === 0 ? 'No media' : undefined,
      loading: false,
    }));
  }, [mediaData.mediaItems]);

  useEffect(() => {
    if (!mediaData.error) return;
    setState((prevState) => ({
      ...prevState,
      error: mediaData.error,
      loading: false,
    }));
  }, [mediaData.error]);

  const allKioskIds = [...new Set(state.mediaItems.map((item) => item?.kiosk_ID))];

  return (
    <AuthGuard authorizedRoles={[UserRole.DropSuperAdmin]} userRole={user.role}>
      <div className='valign-wrapper'>
        <div className='row' style={{ width: '100%' }}>
          <div className='col s12' style={{ padding: '0px', marginTop: '15px' }}>
            <div className='col s8'>
              <PageHeading heading='Media' />
            </div>
            <div className='col s4' style={{ justifyContent: 'end', display: 'flex' }}></div>
          </div>
          <DataTable<IMedia, MediaColumnKeys>
            data={state.mediaItems}
            loading={state.loading}
            totalSize={state.mediaItems.length}
            contextName='Media'
            dataColumnConfig={MediaTableConfig(allKioskIds)}
          />
        </div>
      </div>
    </AuthGuard>
  );
};

export default UsersList;
