enum CommandCode {
  Null = 0x00,
  Identify = 0x3F,
  ResetHardware = 0x49,
  ResetProcessor = 0x52,
  Provision = 0x56,
  Query = 0x51,
  Set = 0x53,
  Action = 0x41,
  Motion = 0x4d,
  PerformSequence = 0x50,
  EnterBootLoader = 0x42,
  Calibrate = 0x43,
}

export default CommandCode;
