import React from 'react';
import moment from 'moment-timezone';
import ReactExport from 'react-export-excel';
import KioskOrderSummary from '../../services/Orders/KioskOrderSummary';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

interface IOrdersXlsExport {
  orders: any[];
  summaries: KioskOrderSummary[];
  startDate: string;
  endDate: string;
}

const OrdersXlsExport: React.FC<IOrdersXlsExport> = ({ orders, summaries, startDate, endDate }) => {
  if (!summaries) return null;

  const ordersWithDates = orders.map((order) => ({
    ...order,
    date: new Date(order.date_created).toLocaleDateString('en-US'),
    time: new Date(order.date_created).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' }),
  }));

  const summaryDateRange = () =>
    `SummaryReport ${moment(startDate).format('MM/DD/YY')} - ${moment(endDate).format('MM/DD/YY')}`;

  return (
    orders && (
      <ExcelFile
        element={
          <button style={{}} className='download-csv-button'>
            Export CSV
          </button>
        }
        filename={`DropWater Orders ${new Date().toISOString().slice(0, 10)}`}
      >
        <ExcelSheet data={ordersWithDates} name='Orders'>
          <ExcelColumn label='ID' value='id' />
          <ExcelColumn label='Status' value='status' />
          <ExcelColumn label='Promotion' value='promo' />
          <ExcelColumn label='Date' value='date' />
          <ExcelColumn label='Time' value='time' />
          <ExcelColumn label='Kiosk' value='kiosk_ID' />
          <ExcelColumn label='Price($)' value='drink_price' />
          <ExcelColumn label='Sale Price($)' value='salePrice' />
          <ExcelColumn label='Flavor' value='flavor' />
          <ExcelColumn label='Carbonation' value='carbonation' />
          <ExcelColumn label='Temperature' value='temperature' />
          <ExcelColumn label='Type' value='order_type' />
          <ExcelColumn label='Volume(ml)' value='volume' />
        </ExcelSheet>
        <ExcelSheet data={summaries} name={summaryDateRange()}>
          <ExcelColumn label='Location' value='location' />
          <ExcelColumn label='Free Refills' value='num_free_refills' />
          <ExcelColumn label='Paid Refills' value='num_paid_refills' />
          <ExcelColumn label='Refill Revenue($)' value='revenue_refill' />
          <ExcelColumn label='Refill Average($)' value='average_refill' />
          <ExcelColumn label='Compostable Bottles' value='num_compostable' />
          <ExcelColumn label='Compostable Bottle Revenue($)' value='revenue_compostable' />
          <ExcelColumn label='Compostable Average($)' value='average_compostable' />
          <ExcelColumn label='Total Discounts($)' value='total_discounts' />
          <ExcelColumn label='Total Revenue($)' value='total_revenue' />
        </ExcelSheet>
      </ExcelFile>
    )
  );
};

export default OrdersXlsExport;
