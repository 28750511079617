import IconProps from './IconProps';
import SvgIcon from './SvgIcon';

const IconArrowLeftTwoTone: React.FC<IconProps> = ({
  className,
  width,
  height,
}) => (
  <SvgIcon className={className} width={width} height={height} fill="#555">
    <path
      fill="#000000"
      d="M20 12c0 4.41-3.59 8-8 8s-8-3.59-8-8 3.59-8 8-8 8 3.59 8 8m-8 1h4v-2h-4V8l-4 4 4 4z"
      opacity=".3"
    />
    <path
      fill="#000000"
      d="M20 12c0 4.41-3.59 8-8 8s-8-3.59-8-8 3.59-8 8-8 8 3.59 8 8m2 0c0-5.52-4.48-10-10-10S2 6.48 2 12s4.48 10 10 10 10-4.48 10-10m-10 1h4v-2h-4V8l-4 4 4 4z"
    />
  </SvgIcon>
);
export default IconArrowLeftTwoTone;
