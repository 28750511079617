interface ISubmitButtonProps {
  disabled: boolean;
  label: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

const SubmitButton: React.FC<ISubmitButtonProps> = ({ disabled, label, onClick }) => {
  return (
    <button
      style={{
        width: '110px',
        borderRadius: '3px',
        letterSpacing: '1.5px',
      }}
      disabled={disabled}
      type='submit'
      className='btn btn-medium waves-effect white-text'
      onClick={onClick}
    >
      {label}
    </button>
  );
};

export default SubmitButton;
