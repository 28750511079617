import { Pump } from '../../../../../common/Kiosk/KioskSettings';
import styles from './KioskPumpEditCard.module.css';
import { MenuItem, TextField } from '@material-ui/core';
import stringWithSpacesUppercase from '../../../../../utils/stringWithSpacesUppercase';
import AdditiveReference from '../../../../../common/Kiosk/AdditiveReference';
import additiveIconMap from '../../../../../components/ui/Icons/additiveIconMap';

interface IKioskPumpEditCard {
  pump: Pump;
  onAdditiveChange: (pump: Pump) => unknown;
  onInventoryChange: (pump: Pump) => unknown;
  disabled: boolean;
}

const additiveReferences: AdditiveReference[] = [
  'water',
  'caffeine',
  'lemonadeV3',
  'tropicalEnergy',
  'blackberry',
  'mintMojito',
  'orange',
  'passionOrangeGuava',
  'peachTea',
  'pomegranateAcai',
  'raspberryLychee',
  'strawberryKiwi',
  'teaPlusLemonade',
  'yuzuGinger',
  'sanitizer',
];

const AdditiveIcon = (additive: AdditiveReference, className: string) => {
  let additiveReference = additive;
  if (additive === 'caffeine') additiveReference = 'caffeine70';
  return additiveIconMap(additiveReference, className);
};

const KioskPumpEditCard: React.FC<IKioskPumpEditCard> = ({ pump, onAdditiveChange, onInventoryChange, disabled }) => {
  const handleAdditiveChange = (additive: AdditiveReference) => {
    const update = { ...pump };
    update.additive = additive;
    onAdditiveChange(update);
  };

  const handleInventoryChange = (value: number) => {
    const update = { ...pump };
    update.inventory = value;
    onInventoryChange(update);
  };

  return (
    <div className={styles.KioskPumpEditCard}>
      <div className={styles.alignCenter}>
        <strong>Pump {pump.id}</strong>
      </div>
      <div className={styles.icon}>{AdditiveIcon(pump.additive, styles.tableIcon)}</div>
      <TextField
        select
        size='small'
        variant='outlined'
        fullWidth
        required
        id={pump.additive}
        label='Additive'
        value={pump.additive}
        onChange={(e) => handleAdditiveChange(e.target.value as AdditiveReference)}
        disabled={disabled}
      >
        {additiveReferences.map((additive, additiveIndex) => (
          <MenuItem value={additive} className={styles.alignCenter} key={+additiveIndex}>
            {additive === 'water' ? 'None' : stringWithSpacesUppercase(additive)}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        inputProps={{
          min: 0,
          style: {
            textAlign: 'center',
            fontSize: '20px',
          },
        }}
        InputLabelProps={{
          shrink: true,
        }}
        variant='outlined'
        label='ml'
        value={pump.inventory?.toFixed(0)}
        className={styles.pumpAmountInput}
        onChange={(e) => handleInventoryChange(+e.target.value)}
        id={`${pump.id}`}
        type='number'
        disabled={disabled}
        fullWidth
      />
    </div>
  );
};

export default KioskPumpEditCard;
