import IIconAdditive from './IIconAdditive';
import SvgIcon from './SvgIcon';

const IconAdditiveCaffeine20: React.FC<IIconAdditive> = ({ className }) => (
  <SvgIcon
    className={className}
    width="112"
    height="112"
    fill="none"
    viewBox="0 0 112 112"
  >
    <path
      fill="#F3F3F3"
      d="M56 95.666c-21.916 0-39.667-17.75-39.667-39.666 0-21.916 17.751-39.667 39.667-39.667v79.333z"
    />
    <path
      fill="#98EEC0"
      d="M21 56c0 19.33 15.67 35 35 35V21c-19.33 0-35 15.67-35 35z"
    />
    <path
      fill="#F3F3F3"
      d="M53.667 60.667h-14L56 35v42l-4.667 7 2.334-23.333z"
    />
    <path
      fill="#E6EBF5"
      d="M56 16.333c21.916 0 39.667 17.75 39.667 39.667 0 21.915-17.751 39.666-39.667 39.666V16.333z"
    />
    <path fill="#90DEB5" d="M56 91c19.33 0 35-15.67 35-35S75.33 21 56 21v70z" />
    <path fill="#E6EBF5" d="M58.333 51.333h14L56 77V35l4.667-7-2.334 23.333z" />
  </SvgIcon>
);

IconAdditiveCaffeine20.defaultProps = {
  className: '',
};

export default IconAdditiveCaffeine20;
