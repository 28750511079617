import IUser from '../../services/User/IUser';

type FieldConfig = {
  key: keyof IUser;
  required: boolean;
  type: 'string';
};

export const UserProfileFieldConfig: FieldConfig[] = [
  { key: 'name', required: true, type: 'string' },
  { key: 'email', required: true, type: 'string' },
  { key: 'phone_number', required: false, type: 'string' },
  { key: 'country', required: false, type: 'string' },
  { key: 'state', required: false, type: 'string' },
  { key: 'city', required: false, type: 'string' },
];
