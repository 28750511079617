import IIconAdditive from './IIconAdditive';
import SvgIcon from './SvgIcon';

const IconAdditiveSanitizer: React.FC<IIconAdditive> = ({ className }) => (
  <SvgIcon className={className} height="112" fill="none" viewBox="0 0 48 48">
    <rect
      fill="#F1373D"
      x="31.71"
      y="3.88"
      width="4.9"
      height=".4"
      rx=".2"
      ry=".2"
      transform="translate(5.4 22.96) rotate(-40.09)"
    />
    <g>
      <path
        fill="#F1373D"
        d="m25.14,8.57c-.74,1.21.51,3.29,1.1,4.27.31.51,2.72,4.46,4.64,4.03.25-.06.71-.24.73-.49.03-.33-.72-.59-1.22-.85-1.89-1-2.58-3.29-2.69-3.66-.61-2.16.52-3.81-.24-4.27-.45-.27-1.22.07-1.34.12,0,0-.62.27-.98.85Z"
      />
      <path
        fill="#E59B8D"
        d="m20.13,13.58c-1-.59-1.43-1.43-2.2-2.93-1.05-2.05-1.72-3.36-1.22-4.27.5-.9,1.88-.96,6.59-.98,1.77,0,3.96-.01,6.47,0,.04.85.08,1.71.12,2.56-3.77.98-4.91,2.23-5.13,3.3-.19.92.33,1.54-.12,2.2-.7,1.02-2.98,1.04-4.52.12Z"
      />
      <path
        fill="#FDAD9D"
        d="m24.28,12.17c0,.07,0,.13,0,.2-.06-.01-.12-.02-.18-.02h-3.66c-.06,0-.12,0-.18.02,0-.07,0-.13,0-.2,0-1.38.9-2.5,2.01-2.5s2.01,1.12,2.01,2.5Z"
      />
      <rect fill="#F1373D" x="29.29" y="5.4" width="1.83" height="2.69" />
      <path
        fill="#29ABE2"
        d="m32.65,35.01c-.55-2.78-1.56-6.53-3.28-10.03-.88-1.76-1.94-3.45-3.19-4.67v-2.85c0-1.4-1.13-2.53-2.53-2.53h-3.11c-1.4,0-2.53,1.13-2.53,2.53v2.85c-1.25,1.22-2.31,2.91-3.19,4.67-1.72,3.51-2.73,7.25-3.28,10.03-.5,2.84.24,5.05,2.2,7.04,1.92,1.9,5.05,3.58,8.36,3.53,3.31.05,6.45-1.62,8.36-3.53,1.96-1.98,2.7-4.2,2.2-7.04Z"
      />
      <path
        fill="#F1373D"
        d="m25.02,13.27v.85c0,.51-.41.92-.92.92h-3.66c-.51,0-.92-.41-.92-.92v-.85c0-.45.32-.82.74-.9.06-.01.12-.02.18-.02h3.66c.06,0,.12,0,.18.02.42.08.74.45.74.9Z"
      />
      <path
        fill="#373B49"
        d="m26.55,8.15c.1.11.09.27-.02.37-.11.1-.27.09-.37-.02s-.09-.27.02-.37c.11-.1.27-.09.37.02Z"
      />
      <ellipse fill="#FBB03B" cx="22.21" cy="32.75" rx="6.72" ry="8.85" />
    </g>
    <rect
      fill="#F1373D"
      x="31.37"
      y="9.14"
      width="4.9"
      height=".4"
      rx=".2"
      ry=".2"
      transform="translate(19.18 -22.55) rotate(49.91)"
    />
    <rect
      fill="#F1373D"
      x="32.74"
      y="6.37"
      width="3.88"
      height=".4"
      rx=".2"
      ry=".2"
    />
    <g>
      <path
        fill="#1A1A1A"
        d="m24.27,33.12c.25-.23.62-.4.62-1.51,0-2.7-2.82-2.64-2.82-2.64,0,0-2.82-.06-2.82,2.64,0,1.1.34,1.28.59,1.51,0,0,.08-.35.09-1.04,0,0,.1,1.02-.06,1.27-.16.25-.24.64.13.71.37.07.64,0,.64.46,0,0,.26.35,1.42.35s1.42-.35,1.42-.35c0-.48.27-.39.64-.46.37-.07.29-.46.13-.71-.16-.25-.08-1.3-.08-1.3,0,.7.08,1.06.08,1.06Zm-2.68.03c-.19.36-1.27.12-1.27.12-.44-1.35,1.41-1.08,1.41-1.08,0,0,.06.57-.14.96Zm.48.73c-.15.05-.31.05-.31.05.17-.19.31-.72.31-.72,0,0,.14.53.31.72,0,0-.16,0-.31-.05Zm1.74-.61s-1.08.24-1.27-.12c-.21-.39-.14-.96-.14-.96,0,0,1.84-.27,1.41,1.08Z"
      />
      <path
        fill="#1A1A1A"
        d="m23.88,34.12s.28.97-.26,1.33c-.49.33-.92.77-1.55.77s-1.07-.44-1.55-.77c-.53-.36-.26-1.33-.26-1.33,0,0,.08.01.11.02.03,0,.09.04.09.04,0,0-.23,1.11,1.61,1.11,1.9.03,1.63-1.12,1.63-1.12l.18-.05Z"
      />
      <path
        fill="#1A1A1A"
        d="m22.07,34.89v.38s-.17,0-.26-.01c0,0,0-.32.08-.37h.18Z"
      />
      <path
        fill="#1A1A1A"
        d="m21.76,34.88s.04.09.02.38c0,0-.05,0-.14,0s-.12-.02-.12-.02c0,0,.02-.3.09-.37h.16Z"
      />
      <path
        fill="#1A1A1A"
        d="m21.51,34.86s.02.06-.01.37c0,0-.15-.02-.22-.05,0,0,.02-.29.11-.34,0,0,.1.02.13.02Z"
      />
      <path
        fill="#1A1A1A"
        d="m21.3,34.83s-.01.1-.06.34c0,0-.08,0-.19-.06,0,0,.07-.25.16-.29l.08.02Z"
      />
      <path
        fill="#1A1A1A"
        d="m21.1,34.77s.04.04-.03.23c-.03.1-.03.1-.03.1,0,0-.13-.04-.15-.09,0,0,.06-.17.11-.21.06-.05.1-.03.1-.03Z"
      />
      <path
        fill="#1A1A1A"
        d="m20.93,34.72s.05.05-.06.28c0,0-.07-.01-.1-.07,0,0,.05-.21.09-.23.03,0,.04.02.07.02Z"
      />
      <path
        fill="#1A1A1A"
        d="m20.8,34.66s.01.05,0,.13c-.02.07-.03.13-.03.13s-.07-.04-.09-.1c-.01-.06.05-.19.09-.18.03,0,.04.02.04.02Z"
      />
      <path
        fill="#1A1A1A"
        d="m20.67,34.56s.04.03.01.13c-.02.1-.02.13-.02.13,0,0-.06-.04-.06-.12,0-.08.02-.18.06-.14Z"
      />
      <path
        fill="#1A1A1A"
        d="m22.09,34.89v.38s.17,0,.26-.01c0,0,0-.32-.08-.37h-.18Z"
      />
      <path
        fill="#1A1A1A"
        d="m22.4,34.88s-.04.09-.02.38c0,0,.05,0,.14,0s.12-.02.12-.02c0,0-.02-.3-.09-.37h-.16Z"
      />
      <path
        fill="#1A1A1A"
        d="m22.66,34.86s-.02.06.01.37c0,0,.15-.02.22-.05,0,0-.02-.29-.11-.34,0,0-.1.02-.13.02Z"
      />
      <path
        fill="#1A1A1A"
        d="m22.86,34.83s.01.1.06.34c0,0,.08,0,.19-.06,0,0-.07-.25-.16-.29l-.08.02Z"
      />
      <path
        fill="#1A1A1A"
        d="m23.06,34.77s-.04.04.03.23c.03.1.03.1.03.1,0,0,.13-.04.15-.09,0,0-.06-.17-.11-.21-.06-.05-.1-.03-.1-.03Z"
      />
      <path
        fill="#1A1A1A"
        d="m23.24,34.72s-.05.05.06.28c0,0,.07-.01.1-.07,0,0-.05-.21-.09-.23-.03,0-.04.02-.07.02Z"
      />
      <path
        fill="#1A1A1A"
        d="m23.36,34.66s-.01.05,0,.13c.02.07.03.13.03.13s.07-.04.09-.1c.01-.06-.05-.19-.09-.18-.03,0-.04.02-.04.02Z"
      />
      <path
        fill="#1A1A1A"
        d="m23.5,34.56s-.04.03-.01.13c.02.1.02.13.02.13,0,0,.06-.04.06-.12,0-.08-.02-.18-.06-.14Z"
      />
      <g>
        <path
          fill="#1A1A1A"
          d="m25.5,36.06c-.4-.15-.71.11-.71.11-.12,0-.64-.23-1.33-.54,0,0-.02.05-.26.23-.19.15-.36.2-.36.2.86.42,1.52.76,1.56.8.08.1-.28.63.47.75.57.09.57-.31.53-.51-.07-.09-.13-.26-.34-.28,0,0,.18-.06.35.18.45,0,.48-.8.09-.94Z"
        />
        <path
          fill="#1A1A1A"
          d="m19.95,34.09c-.19-.06-.21-.21-.21-.21-.65-.32-1.02-.51-1.22-.79-.23-.34-.56-.44-.91-.33-.35.11-.17.64-.17.64,0,0,.12-.19.36-.14-.35.01-.42.4-.42.4,0,0-.13.59.32.76.45.17.53-.51,1.42-.24,0,0,.17.05,1.04.53,0,0-.02-.28.07-.56-.07,0-.12-.01-.27-.06Z"
        />
      </g>
      <g>
        <path
          fill="#1A1A1A"
          d="m18.67,36.06c.4-.15.71.11.71.11.12,0,.64-.23,1.33-.54,0,0,.02.05.26.23.19.15.36.2.36.2-.86.42-1.52.76-1.56.8-.08.1.28.63-.47.75-.57.09-.57-.31-.53-.51.07-.09.13-.26.34-.28,0,0-.18-.06-.35.18-.45,0-.48-.8-.09-.94Z"
        />
        <path
          fill="#1A1A1A"
          d="m24.22,34.09c.19-.06.21-.21.21-.21.65-.32,1.02-.51,1.22-.79.23-.34.56-.44.91-.33.35.11.17.64.17.64,0,0-.12-.19-.36-.14.35.01.42.4.42.4,0,0,.13.59-.32.76-.45.17-.53-.51-1.42-.24,0,0-.17.05-1.04.53,0,0,.02-.28-.07-.56.07,0,.12-.01.27-.06Z"
        />
      </g>
    </g>
  </SvgIcon>
);

IconAdditiveSanitizer.defaultProps = {
  className: '',
};

export default IconAdditiveSanitizer;
