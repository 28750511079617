import { Card } from 'react-bootstrap';
import { UserAccountContext } from '../../UserAccountContext';
import { useContext } from 'react';
import UserAccountTabReference from '../UserAccountTabReference';
import KioskOrderReportsPreferences from './KioskOrderReportsPreferences';
import TimezoneSelect from './TimezoneSelect';
import SubmitButton from '../../../../components/Button/SubmitButton';

const UserAccountNotifications: React.FC = () => {
  const userAccount = useContext(UserAccountContext);
  return (
    <Card style={{ padding: '2%' }}>
      <Card.Body>
        <Card.Title>Notifications</Card.Title>
        <Card.Body className='row' style={{}}>
          <div className='col s12 m12'>
            <KioskOrderReportsPreferences />
          </div>
          <div className='col s12 m6'>
            <TimezoneSelect />
          </div>
        </Card.Body>
        <SubmitButton
          label='Save'
          disabled={userAccount?.state.loading || !userAccount?.state.unsavedChanges}
          onClick={() => userAccount?.handleSaveUserData(UserAccountTabReference.Notifications)}
        />
      </Card.Body>
      <Card.Text className='row' style={{}}>
        {userAccount?.state?.error?.[UserAccountTabReference.Notifications]?.message}
        {userAccount?.state?.message?.[UserAccountTabReference.Notifications]}
      </Card.Text>
    </Card>
  );
};
export default UserAccountNotifications;
