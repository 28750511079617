import { Checkbox } from '@material-ui/core';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { Card } from 'react-bootstrap';

interface IToggleButton {
  enabled: boolean;
  onToggle: (value: boolean) => void;
  label: string;
  description: JSX.Element | string;
}

const ToggleButton: React.FC<IToggleButton> = ({ enabled, onToggle, label, description }) => {
  return (
    <button
      style={{
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'start',
        gap: 10,
        cursor: 'pointer',
        border: 0,
        background: 'transparent',
      }}
      onClick={() => onToggle(!enabled)}
    >
      <Checkbox
        color='default'
        checkedIcon={<CheckBoxIcon fontSize='medium' />}
        icon={<CheckBoxOutlineBlankIcon fontSize='medium' />}
        inputProps={{ 'aria-label': 'decorative checkbox' }}
        checked={enabled}
      />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'start',
        }}
      >
        <Card.Subtitle style={{ fontWeight: '600', fontSize: '16px' }}>{label}</Card.Subtitle>
        <p style={{ margin: '5px 0', textAlign: 'left' }}>{description}</p>
      </div>
    </button>
  );
};

export default ToggleButton;
