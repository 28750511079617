import { TextField } from '@material-ui/core';
import { useContext } from 'react';
import { UserAccountContext } from '../../UserAccountContext';
import kebabToTitleCase from '../../../../utils/kebabToTitleCase';

const UserRoleDisplayOnly: React.FC = () => {
  const userAccount = useContext(UserAccountContext);
  // only display the role - do not extend to select role
  return (
    <TextField
      type='role'
      size='small'
      variant='outlined'
      fullWidth={true}
      required={true}
      id='role'
      label='role'
      value={kebabToTitleCase(userAccount?.state.user?.role || '')}
      style={{ margin: '10px 0' }}
      disabled={true}
      inputProps={{
        style: {
          padding: 10,
        },
      }}
    />
  );
};

export default UserRoleDisplayOnly;
