import React from 'react';
import ResolutionBadge from '../ResolutionBadge';
import IncidentReportStrings from '../IncidentReportStrings';
import IncidentResolutionStatus from '../IncidentResolutionStatus';
import BackButton from '../../../../components/Button/BackButton';
import IconButton from '../../../../components/Button/IconButton';
import styles from './IncidentReportHeader.module.css';
import { MessageOutlined } from '@material-ui/icons';

interface IIncidentReportHeader {
  resolution?: IncidentResolutionStatus;
  onClickMessageButton: () => void;
}

const IncidentReportHeader: React.FC<IIncidentReportHeader> = ({ resolution, onClickMessageButton }) => {
  return (
    <div className={styles.IncidentReportHeader}>
      <div className={styles.headerContent}>
        <h1 className={styles.heading}>{IncidentReportStrings.Heading}</h1>
        {resolution && <ResolutionBadge resolution={resolution} />}
        <BackButton />
        <IconButton
          onClick={onClickMessageButton}
          label={IncidentReportStrings.ChatWidgetHeading}
          icon={<MessageOutlined />}
        />
      </div>
    </div>
  );
};

export default IncidentReportHeader;
