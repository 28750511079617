interface IKioskEditErrorMessage {
  error?: string;
}

const KioskEditErrorMessage: React.FC<IKioskEditErrorMessage> = ({ error }) => {
  if (!error) return null;
  return <span style={{ color: 'red' }}>{error}</span>;
};

export default KioskEditErrorMessage;
