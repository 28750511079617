import styles from './IconButtonWithLabel.module.css';

interface IIconButtonWithLabel {
  disabled: boolean;
  label: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  icon: JSX.Element;
}

const IconButtonWithLabel: React.FC<IIconButtonWithLabel> = ({ disabled, label, onClick, icon }) => {
  return (
    <button disabled={disabled} type='button' onClick={onClick} className={styles.IconButtonWithLabel}>
      {icon && icon} <span>{label}</span>
    </button>
  );
};

export default IconButtonWithLabel;
