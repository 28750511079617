import { useState, useEffect, useCallback } from 'react';
import CompanyService from './CompanyService';
import UserRole from '../../common/UserRole';
import ICompany from './ICompany';
import { useAppSelector } from '../../hooks/storeHooks';

const useCompanies = (): ICompany[] | undefined => {
  const [companies, setCompanies] = useState<ICompany[]>();
  const user = useAppSelector((store) => store.auth?.user);

  const getCompanies = useCallback(async () => {
    if (companies) return;
    const companyService = new CompanyService();
    setCompanies(await companyService.getCompanies());
  }, [companies]);

  useEffect(() => {
    if (user?.role === UserRole.DropSuperAdmin) {
      getCompanies();
    }
  }, [getCompanies, user]);
  

  return companies;
};

export default useCompanies;
