import React from 'react';
import SvgIcon from './SvgIcon';
import IIconAdditive from './IIconAdditive';

const IconAdditivePeachTea: React.FC<IIconAdditive> = ({ className }) => (
  <SvgIcon
    className={className}
    width="112"
    height="112"
    fill="none"
    viewBox="0 0 112 112"
  >
    <path
      fill="#9ec953"
      d="M55.13,10.15v91.73c20.51,0,37.13-18.09,37.13-40.4,0-31.45-37.13-51.32-37.13-51.32Z"
    />
    <path
      fill="#5c8a1e"
      d="M55.13,10.15v91.73c-20.51,0-37.13-18.09-37.13-40.4,0-31.45,37.13-51.32,37.13-51.32Z"
    />
    <path
      fill="#386200"
      d="M55.13,86.1l-21.6-27.42s11.65,3.64,21.6,16.75v10.68h0Z"
    />
    <path
      fill="#386200"
      style={{ opacity: 0.5 }}
      d="M55.13,86.1l21.6-27.42s-11.65,3.64-21.6,16.75v10.68h0Z"
    />
    <path
      fill="#386200"
      d="M55.13,56.01l-15.53-19.72s8.38,2.62,15.53,12.04v7.68Z"
    />
    <path
      fill="#386200"
      style={{ opacity: 0.5 }}
      d="M55.13,56.01l15.53-19.72s-8.38,2.62-15.53,12.04v7.68Z"
    />
    <path
      fill="#bf4a39"
      d="M81.91,59.09c-2.29-1.98-4.89-3.02-7.6-3.02-4.22,0-8.11,2.48-10.98,6.99-2.7,4.24-4.18,9.84-4.18,15.77,0,12.56,10.22,22.77,22.77,22.77v-42.52h0Z"
    />
    <path
      fill="#a5291c"
      d="M81.91,59.09c2.29-1.98,4.89-3.02,7.6-3.02,4.22,0,8.11,2.48,10.98,6.99,2.7,4.24,4.18,9.84,4.18,15.77,0,12.56-10.22,22.77-22.77,22.77v-42.52h0Z"
    />
    <path
      fill="#ffc22f"
      d="M74.31,57.97c-7.33,0-13.27,9.34-13.27,20.87s9.34,20.87,20.87,20.87v-37.97c-2.15-2.37-4.77-3.77-7.6-3.77Z"
    />
    <path
      fill="#eda400"
      d="M89.51,57.97c7.33,0,13.27,9.34,13.27,20.87s-9.34,20.87-20.87,20.87v-37.97c2.15-2.37,4.77-3.77,7.6-3.77Z"
    />
    <path
      fill="#ff7a34"
      d="M81.91,64.95c-5.2,1.36-9.11,7.06-9.11,13.88s3.91,12.53,9.11,13.89v-27.77Z"
    />
    <path
      fill="#dd5b22"
      d="M81.91,92.72c5.2-1.36,9.11-7.06,9.11-13.88s-3.91-12.53-9.11-13.89v27.77Z"
    />
  </SvgIcon>
);

export default IconAdditivePeachTea;
