enum PeripheralIdentifier {
  Central = 0x43,
  Additives = 0x41,
  Deck = 0x44,
  Gantry = 0x47,
  Lighting = 0x4c,
  Reception = 0x52,
  Tower = 0x54,
  TowerExpansion = 0x58,
  USBOnly = 0x55,
  UnprovisionedDevice = 0xFF,
  Monitor = 0x4d,
}

export default PeripheralIdentifier;
