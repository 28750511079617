import React from 'react';
import { useContext } from 'react';
import { KioskSettingsContext } from '../KioskSettingsContext';
import { CO2InventorySettings, InventoryAlarmsConfig } from './InventoryControlSettings';
import { CO2InventoryFieldConfig, InventoryAlarmFieldConfig } from '../../Fields/KioskSettingsFieldConfig';
import FieldRenderer from '../../Fields/FieldRenderer';
import KioskEditStrings from '../../KioskEditStrings';
import KioskType from '../../../../../common/Kiosk/KioskType';
import formatFieldName from '../../../../../utils/formatFieldName';

const CO2InventoryControl: React.FC = () => {
  const kioskSettings = useContext(KioskSettingsContext);
  const inventoryControl = kioskSettings?.state.settings?.inventoryControl;
  const CO2Settings = inventoryControl?.CO2;
  const alarmThresholdChangeStep: number = 10;

  const updateCO2InventoryControl = (key: keyof CO2InventorySettings, value: any) => {
    if (!CO2Settings) return;

    kioskSettings?.handleInventoryControlChange('CO2', {
      ...CO2Settings,
      [key]: value
    });
  };


  const updateAlarmThreshold = (alarmType: keyof InventoryAlarmsConfig, value: number) => {
    if (!CO2Settings?.alarms || value < 0) return;

    const updatedAlarms = {
      ...CO2Settings.alarms,
      [alarmType]: { threshold: value }
    };

    kioskSettings?.handleInventoryControlChange('CO2', {
      ...CO2Settings,
      alarms: updatedAlarms
    });
  };

  const kioskType = kioskSettings?.state?.settings?.installInfo?.kioskType;

  const fields = CO2InventoryFieldConfig.filter(
    (field) => field.kioskTypes.length === 0 || (kioskType && field.kioskTypes.includes(kioskType))
  );

  const alarmFields = InventoryAlarmFieldConfig.filter(
    (field) => field.kioskTypes.length === 0 || (kioskType && field.kioskTypes.includes(kioskType))
  );

  if (alarmFields.length + fields.length === 0 || kioskType !== KioskType.Refill) return null;

  return (
    <>
      <h5 style={{ fontWeight: '600', fontSize: '14px', marginTop: '10px' }}>
        {KioskEditStrings.CO2InventoryControlLabel}
      </h5>
      <div className='row' style={{ width: '100%', marginBottom: 0 }}>
        {fields.map((field) => (
          <div key={field.key} className='col s12 m8 l8'>
            <FieldRenderer
              field={field}
              value={CO2Settings?.[field.key] || ''}
              onChange={updateCO2InventoryControl}
              disabled={!kioskSettings?.deviceStatus.ready}
              labelOverride={KioskEditStrings.EnableCO2Label}
            />
          </div>
        ))}
      </div>
      <div className='row' style={{ width: '100%', marginBottom: 0 }}>
        {alarmFields.map((field) => (
          <div key={String(field.key)} className='col s12 m4'>
            <FieldRenderer
              field={field}
              value={
                CO2Settings?.alarms?.[field.key]?.threshold === undefined
                  ? ''
                  : CO2Settings?.alarms?.[field.key]?.threshold
              }
              onChange={(key, val) => updateAlarmThreshold(key, val)}
              disabled={!kioskSettings?.deviceStatus.ready}
              labelOverride={`${formatFieldName(field.key)} ${
                KioskEditStrings.CO2InventoryContextLabel
              } ${formatFieldName(KioskEditStrings.AlarmThresholdLabel)}`}
              inputProps={{ step: String(alarmThresholdChangeStep) }}
            />
          </div>
        ))}
      </div>
    </>
  );
};

export default CO2InventoryControl;
