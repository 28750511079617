import is from 'check-types';
import { useState } from 'react';
import KioskService from '../../../../../services/Kiosk/KioskService';
import KioskEditStrings from '../../KioskEditStrings';
import SubmitButton from '../../../../../components/Button/SubmitButton';
import KioskEditLoading from '../../KioskEditLoading';
import KioskEditErrorMessage from '../../KioskEditErrorMessage';
import KioskCommandSequenceSelect from './KioskCommandSequenceSelect';
import KioskCommandSequence from '../../../../../common/Kiosk/KioskCommandSequence';
import KioskType from '../../../../../common/Kiosk/KioskType';

interface IRunKioskCommandSequence {
  onChangeRequestStarted: () => unknown;
  onChangeRequestComplete: () => unknown;
  disabled: boolean;
  kioskId?: string;
  kioskType?: KioskType;
}

const RunKioskCommandSequence: React.FC<IRunKioskCommandSequence> = ({
  onChangeRequestStarted,
  onChangeRequestComplete,
  disabled,
  kioskId,
  kioskType
}) => {
  const [sequenceToRun, setSequence] = useState<KioskCommandSequence>();
  const [message, setMessage] = useState<string>();
  const [error, setError] = useState<Error>();
  const [changeInProgress, setChangeInProgress] = useState<boolean>(false);

  const handleCommandSequenceRequest = async () => {
    try {
      if (is.undefined(sequenceToRun)) throw new Error('Missing sequence to run');
      onChangeRequestStarted();
      setMessage(undefined);
      setError(undefined);
      setChangeInProgress(true);
      const response = await new KioskService(kioskId).runCommandSequence(sequenceToRun);
      if (response.error) throw new Error(`${response.error}. Error ID: ${response.correlationId}`);
      setMessage(response.message);
    } catch (err) {
      setError(err instanceof Error ? err : new Error(JSON.stringify(err)));
    } finally {
      onChangeRequestComplete();
      setChangeInProgress(false);
    }
  };

  if (!kioskType) return null;

  return (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyItems: 'center',
          marginTop: '10px',
          alignContent: 'center',
          width: 'auto',
          gap: 14
        }}
      >
        <KioskCommandSequenceSelect
          onChange={(sequence) => setSequence(sequence)}
          disabled={disabled}
          kioskType={kioskType}
        />
        {is.assigned(sequenceToRun) && (
          <SubmitButton label='Run' disabled={disabled} onClick={() => handleCommandSequenceRequest()} />
        )}
      </div>
      <strong>{message}</strong>
      <KioskEditLoading
        display={changeInProgress}
        message={`${KioskEditStrings.CommandSequenceInProgress}: ${sequenceToRun}`}
      />
      <KioskEditErrorMessage error={error?.message} />
    </>
  );
};

export default RunKioskCommandSequence;
