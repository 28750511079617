import { useEffect, useState } from 'react';
import is from 'check-types';
import CommandCode from '../../../../common/Peripherals/FirmwareCommunication/Messaging/CommandCode';
import IUpdate from '../../../../common/Peripherals/FirmwareCommunication/Messaging/Update/IUpdate';
import IResponse from '../../../../common/Peripherals/FirmwareCommunication/Messaging/Response/IResponse';
import CommandUI from '../../../../common/Peripherals/FirmwareCommunication/CommandUI';
import PeripheralIdentifier from '../../../../common/Peripherals/PeripheralIdentifier';
import PeripheralUtility from '../../../../common/Peripherals/PeripheralUtility';
import IconLoading from '../../../../components/ui/Icons/IconLoading';
import ErrorCode from '../../../../common/Peripherals/FirmwareCommunication/Messaging/ErrorCode';
import KioskService from '../../../../services/Kiosk/KioskService';
import styles from './PeripheralCommandInputs.module.css';
import KioskMonitorReport from '../../../../hooks/useDeviceMonitor/KioskMonitorReport';
import RGBInput from './RGBInput';

interface IPeripheralCommandTests {
  peripheralIdentifier: PeripheralIdentifier;
  commands: { [key in CommandCode]?: CommandUI };
  onError: (errorMessage: string) => void;
  commandButtonLabel?: string;
  disconnectedLabel?: string;
  status: KioskMonitorReport;
  kioskId: string;
  inputDialog?: Map<
    CommandCode,
    | (() => React.FC<{
        onCloseClick: () => void;
        disable?: boolean;
        kioskId: string;
      }>)
    | undefined
  >;
}

const PeripheralCommandInputs: React.FC<IPeripheralCommandTests> = ({
  peripheralIdentifier,
  commands,
  commandButtonLabel = 'Send',
  disconnectedLabel = 'DISCONNECTED',
  onError,
  status,
  kioskId,
  inputDialog
}) => {
  const [response, setResponse] = useState<IResponse | IUpdate>();
  const [commandCode, setCommand] = useState<CommandCode>(CommandCode.Identify);
  const [commandArgs, setCommandArgs] = useState<number[][]>([]);
  const [responseTime, setResponseTime] = useState<number>();
  const [portActive, setPortActive] = useState<boolean>(false);
  const [InputDialog, setInputDialog] = useState<
    React.FC<{
      onCloseClick: () => void;
      disable?: boolean;
      kioskId: string;
    }>
  >();
  const [openDialog, setDialogOpen] = useState<boolean>(false);

  const kioskService = new KioskService(kioskId);

  const sendMessageToPeripheral = async (
    commandCodeToWrite: CommandCode,
    peripheralIdentifier: PeripheralIdentifier,
    args: number[][]
  ) => {
    const cmdResponse = await kioskService.sendPeripheralCommand(commandCodeToWrite, peripheralIdentifier, args);
    if (cmdResponse.error) {
      onError(cmdResponse.error);
      return JSON.stringify(cmdResponse);
    }
    return JSON.stringify(cmdResponse.message);
  };

  const writeToPort = async (commandCodeToWrite: CommandCode, args: number[][]) => {
    onError('');
    const startTime = performance.now();
    setPortActive(true);
    try {
      const serialPortResponse = await sendMessageToPeripheral(commandCodeToWrite, peripheralIdentifier, args);
      setResponse(JSON.parse(serialPortResponse));
      setPortActive(false);
      return response;
    } catch (error: any) {
      onError(error.message);
      return response;
    } finally {
      setPortActive(false);
      setResponseTime(performance.now() - startTime);
    }
  };

  useEffect(() => {
    const command = commands[commandCode as CommandCode] as CommandUI;
    if (is.emptyArray(command.args)) return undefined;
    const args = command.args.map((arg) => {
      if (arg.type === 'bitmask' || arg.type === 'decimal' || is.emptyArray(arg.options)) {
        return [];
      }
      return [arg.options[0].value];
    });
    setCommandArgs(args);
    return () => {
      setCommandArgs([]);
    };
  }, [commandCode, commands]);

  useEffect(() => {
    return () => {
      setCommand(CommandCode.Identify);
    };
  }, [commands]);

  useEffect(() => {
    setInputDialog(inputDialog?.get(commandCode as CommandCode));
  }, [commandCode, inputDialog]);

  const responseDisplay = (responseObj: IUpdate) => {
    if (!is.undefined(responseObj.parsedValues) && !is.emptyArray(responseObj.parsedValues)) {
      return responseObj.parsedValues.join(', ');
    }
    if (!is.undefined(responseObj.updateValues) && !is.emptyArray(responseObj.updateValues)) {
      return responseObj.updateValues.join(', ');
    }
    if (!is.undefined(responseObj.errorCode)) {
      return ErrorCode[responseObj.errorCode];
    }
    return JSON.stringify(responseObj, null, 2);
  };

  const handleBitMaskChange = (value: number, argumentIndex: number) => {
    const existingIndex = commandArgs[argumentIndex].findIndex((currValue) => currValue === value);
    if (existingIndex > -1) {
      commandArgs[argumentIndex].splice(existingIndex, 1);
    } else {
      commandArgs[argumentIndex].push(value);
    }
    setCommandArgs(commandArgs);
  };

  const handleRgbChange = (rgbArray: number[], argumentIndex: number) => {
    const newCommandArgs = [...commandArgs];
    newCommandArgs[argumentIndex] = rgbArray;
    setCommandArgs(newCommandArgs);
  };

  return (
    <tr className={styles.PeripheralCommandInputs}>
      <td>{`${PeripheralIdentifier[peripheralIdentifier]} (${PeripheralUtility.numberAsHex(
        peripheralIdentifier
      )})`}</td>
      <td>
        <select onChange={(e) => setCommand(+e.target.value)}>
          <option disabled>-- Select --</option>
          {Object.keys(commands).map((command, index) => (
            <option
              key={`${peripheralIdentifier}-cmds-${index + 1}`}
              value={commands[command as unknown as CommandCode]?.code}
            >
              {commands[command as unknown as CommandCode]?.name} (
              {PeripheralUtility.numberAsHex(commands[command as unknown as CommandCode]?.code as CommandCode)})
            </option>
          ))}
        </select>
      </td>
      <td>
        {commands[commandCode]?.args?.map((argument, argumentIndex) => (
          <div style={{ width: '100%' }} key={`args-${+argumentIndex}`}>
            <strong>{argument.name}</strong>
            {argument.type === 'select' && (
              <select
                onChange={(e) => {
                  const args = commandArgs;
                  args[+argumentIndex] = [+e.target.value];
                  setCommandArgs(args);
                }}
                style={{ width: '100%' }}
              >
                <option disabled>-- Select --</option>
                {argument?.options?.map((option, selectOptionIndex) => (
                  <option
                    key={`${option.value}-select-${+selectOptionIndex}`}
                    value={option.value}
                    selected={
                      commandArgs.length > 0 &&
                      commandArgs[+argumentIndex] &&
                      commandArgs[+argumentIndex].includes(option.value)
                    }
                  >
                    {option.name}
                  </option>
                ))}
              </select>
            )}
            {argument.type === 'bitmask' && (
              <>
                {argument?.options?.map((option, bitmaskOptionIndex) => (
                  <div style={{ width: '100%' }}>
                    <input
                      type='checkbox'
                      key={`${option.value}-bitmask-${+bitmaskOptionIndex}`}
                      value={option.value}
                      id={`${option.value}`}
                      onChange={(e) => handleBitMaskChange(+e.target.value, argumentIndex)}
                    />
                    <label htmlFor={`${option.value}`}>{option.name}</label>
                  </div>
                ))}
              </>
            )}
            {argument.type === 'decimal' && (
              <div style={{ width: '100%' }}>
                <input
                  type='text'
                  placeholder='Decimal'
                  id={`${argument.name}-number`}
                  onChange={(e) => {
                    const args = commandArgs;
                    args[+argumentIndex] = [+e.target.value];
                    setCommandArgs(args);
                  }}
                />
              </div>
            )}
            {argument?.type === 'rgb' && (
              <RGBInput
                argumentName={argument.name}
                onChange={(rgbArray) => handleRgbChange(rgbArray, argumentIndex)}
              />
            )}
          </div>
        ))}
      </td>
      <td style={{ verticalAlign: 'middle' }}>
        <button disabled={!status.online} type='button' onClick={() => writeToPort(commandCode, commandArgs)}>
          {status.online && commandButtonLabel}
          {!status.online && !status.loaded && <IconLoading width={30} height={10} />}
          &nbsp; {portActive && <IconLoading width={30} height={10} />}
          {!status.online && status.loaded && disconnectedLabel}
        </button>
        {InputDialog && (
          <button type='button' onClick={() => setDialogOpen(true)} style={{ marginTop: '10px' }}>
            Show Input Dialog
          </button>
        )}
        {InputDialog && openDialog && (
          <InputDialog onCloseClick={() => setDialogOpen(false)} disable={!openDialog} kioskId={kioskId} />
        )}
      </td>
      <td>{responseTime && responseTime > 0 && `${responseTime?.toFixed(2)}ms`}</td>
      <td style={{ maxWidth: '200px', overflow: 'auto' }}>{response && responseDisplay(response as IUpdate)}</td>
    </tr>
  );
};
export default PeripheralCommandInputs;
