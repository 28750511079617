import { Checkbox, ListItem, ListItemText, List } from '@material-ui/core';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';

interface ICheckboxList {
  items: string[];
  onFilterClick: (text: string) => void;
  checkedItems: string[];
}

const CheckboxList: React.FC<ICheckboxList> = ({ items, onFilterClick, checkedItems }) => {
  const isCheckBoxChecked = (text: string, arr: string[]) => arr.includes(text);

  return (
    <List>
      {items.map((item) => (
        <ListItem button key={item} onClick={() => onFilterClick(item)}>
          <Checkbox
            color='default'
            checkedIcon={<CheckBoxIcon fontSize='medium' />}
            icon={<CheckBoxOutlineBlankIcon fontSize='medium' />}
            inputProps={{ 'aria-label': 'decorative checkbox' }}
            checked={isCheckBoxChecked(item, checkedItems)}
          />
          <ListItemText primary={item} />
        </ListItem>
      ))}
    </List>
  );
};

export default CheckboxList;
