import React from 'react';
import styles from '../GantryControls.module.css';
import GantryControlsStrings from '../GantryControlsStrings';

interface IGantryJogConfigToggleNumberInput {
  onChangeUseNumberInput: (checked: boolean) => void;
  useNumberInput: boolean;
}

const GantryJogConfigToggleNumberInput: React.FC<IGantryJogConfigToggleNumberInput> = ({
  onChangeUseNumberInput,
  useNumberInput
}) => {
  return (
    <div className={styles.toggleInputType}>
      <label>
        <input type='checkbox' checked={useNumberInput} onChange={(e) => onChangeUseNumberInput(e.target.checked)} />
        <span>{GantryControlsStrings.ToggleJogNumberInputs}</span>
      </label>
    </div>
  );
};

export default GantryJogConfigToggleNumberInput;
