import React from 'react';
import stringWithSpacesUppercase from '../../utils/stringWithSpacesUppercase';

interface IAnyDataTable {
  data: any;
}

const AnyDataTable: React.FC<IAnyDataTable> = ({ data }) => {
  if (!data) return null;

  const renderTable = (obj: any, parentKey: string = ''): JSX.Element[] => {
    return Object.entries(obj).map(([key, value]) => {
      const fullKey = parentKey ? `${parentKey}: ${stringWithSpacesUppercase(key)}` : stringWithSpacesUppercase(key);

      if (typeof value === 'object' && !Array.isArray(value) && value !== null) {
        return (
          <div key={fullKey} style={{ marginBottom: '20px' }}>
            <h6>
              <strong>{fullKey}</strong>
            </h6>
            {renderTable(value, `${fullKey}`)}
          </div>
        );
      }

      return (
        <table key={fullKey} style={{ tableLayout: 'fixed', width: '100%', background: '#fff', padding: '20px' }}>
          <tbody>
            <tr>
              <td style={{ width: '50%', wordWrap: 'break-word' }}>
                <strong>{stringWithSpacesUppercase(key)}</strong>
              </td>
              <td style={{ width: '50%', wordWrap: 'break-word' }}>{renderValue(value)}</td>
            </tr>
          </tbody>
        </table>
      );
    });
  };

  const renderValue = (value: any): JSX.Element | string => {
    if (typeof value === 'object' && !Array.isArray(value) && value !== null) {
      return <>{renderTable(value)}</>;
    }
    if (Array.isArray(value)) return value.map(String).join(', ');
    return String(value);
  };

  return <div>{renderTable(data)}</div>;
};

export default AnyDataTable;
