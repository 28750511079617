import { Checkbox, ListItem, ListItemText, List } from '@material-ui/core';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import IOrdersQuery from '../../services/Orders/IOrdersQuery';
import ICompany from '../../services/Company/ICompany';

interface ICompanyFilterCheckboxList {
  items: ICompany[];
  filterKey: keyof IOrdersQuery;
  _onFilterClick: (filterKey: keyof IOrdersQuery, companyId: string) => void;
  checkedItems: string[];
}

const CompanyFilterCheckboxList: React.FC<ICompanyFilterCheckboxList> = ({
  items,
  filterKey,
  _onFilterClick,
  checkedItems,
}) => {
  const _isCheckBoxChecked = (id: string, companyIds: string[]) => companyIds.includes(id);

  return (
    <List>
      {items.map((item) => (
        <ListItem button key={item._id} onClick={() => _onFilterClick(filterKey, item._id)}>
          <Checkbox
            className='filter-items-list'
            disableRipple
            color='default'
            checkedIcon={<CheckBoxIcon fontSize='medium' />}
            icon={<CheckBoxOutlineBlankIcon fontSize='medium' />}
            inputProps={{ 'aria-label': 'decorative checkbox' }}
            checked={_isCheckBoxChecked(item._id, checkedItems)}
          />
          <ListItemText primary={item.name} />
        </ListItem>
      ))}
    </List>
  );
};

export default CompanyFilterCheckboxList;
