enum GantryCalibrationFactor {
  Tower1Position = 0,
  Tower3Position = 1,
  TowerBottleZPosition = 2,
  FillerXPosition = 3,
  CapperXPosition = 4,
  CapperZPosition = 5,
  DeliveryZPosition = 6,
  FlapSealXPosition = 7,
  FlapSealZPosition = 8,
  GarbageXPosition = 9,
  DoubleBottleCheckZPosition = 10,
  BottleGrip = 11,
}

export default GantryCalibrationFactor;
