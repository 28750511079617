import { ReactElement } from 'react';

export interface ISvgIcon {
  className?: string;
  height?: string;
  width?: string;
  children?: ReactElement[] | ReactElement;
  fill?: string;
  stroke?: string;
  strokeWidth?: string;
  strokeLinecap?: 'round' | 'butt' | 'square' | 'inherit';
  strokeLinejoin?: 'round' | 'inherit' | 'miter' | 'bevel';
  viewBox?: string;
}
const Icon: React.FC<ISvgIcon> = ({
  className = '',
  height = '24',
  width = '24',
  children,
  fill = 'none',
  stroke = 'currentColor',
  strokeWidth = '0',
  strokeLinecap = 'round',
  strokeLinejoin = 'round',
  viewBox = '0 0 24 24',
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    width={width}
    height={height}
    viewBox={viewBox}
    fill={fill}
    stroke={stroke}
    strokeWidth={strokeWidth}
    strokeLinecap={strokeLinecap}
    strokeLinejoin={strokeLinejoin}
  >
    {children}
  </svg>
);

export default Icon;
