import React from 'react';

const NotFound: React.FC = () => {
  return (
    <div style={{ textAlign: 'center', padding: '50px' }}>
      <h1>404</h1>
      <h2>Sorry! Page not found</h2>
      <p>The page you're looking for cannot be found.</p>
      <a href='/'>Back to Home</a>
    </div>
  );
};

export default NotFound;
