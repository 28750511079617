import GantryJogConfig from '../../../../../../common/Peripherals/Gantry/GantryJogConfig';
import { GantryDevice, GantryPosition } from '../../../../../../common/Peripherals/Gantry/GantryMotion';
import GantryJogConfigInput from './GantryJogConfigInput';

const UINT_8_MAX = 255;

export default class GantryJogConstants {
  static defaultConfig: GantryJogConfig = {
    axis: GantryDevice.GantryX,
    operation: GantryPosition.JogToPosition,
    stepDistance: 250,
    speedSteps: 50000,
    accelerationSteps: 50000,
    decelerationSteps: 100000,
    timeout: 5
  };

  private static maxSpeedSteps = 500000;

  private static maxDistanceSteps = 1000;

  static inputsConfig: GantryJogConfigInput[] = [
    ['Timeout', 'number', 'timeout', 0, UINT_8_MAX, 'seconds'],
    ['Distance', 'range', 'stepDistance', -this.maxDistanceSteps, this.maxDistanceSteps, 'steps'],
    ['Speed', 'range', 'speedSteps', 0, this.maxSpeedSteps, 'steps'],
    ['Acceleration', 'range', 'accelerationSteps', 0, this.maxSpeedSteps, 'steps'],
    ['Deceleration', 'range', 'decelerationSteps', 0, this.maxSpeedSteps, 'steps']
  ];
}
