export enum DeckMotionDevice {
  Capper = 0,
  UVProber = 1,
  AmbientDispenser = 2,
  ColdDispenser = 3,
  UVFilter = 4,
  BottleDispenser = 5,
}

export enum DeckMotionPosition {
  CapperCap = 0,
  CapperUncap = 1,
  UVProbeFilterDispensersOn = 2,
  UVProbeFilterDispensersOff = 3,
  ClearCapper = 4,
}
