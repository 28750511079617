import { REPORTS, REPORT_LOADING } from '../actions/types';

const initialState = {
	reports: {},
	loading: false
};

export default function reportReducers (state = initialState, action) {
    console.log("Report Reducer called");
	switch (action.type) {
		case REPORTS:
			return {
				...state,
				reports: action.payload
			};
		case REPORT_LOADING:
			return {
				...state,
				loading: true
			};
		default:
			return state;
	}
}
