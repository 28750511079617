enum DeckDispenseTemperature {
  Cold = 0,
  One = 1,
  Two = 2,
  Three = 3,
  Four = 4,
  Five = 5,
  Six = 6,
  Seven = 7,
  Ambient = 8,
}

export default DeckDispenseTemperature;
