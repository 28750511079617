import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import UsersTableConfig from './UsersTable/UsersTableConfig';
import { useAppSelector } from '../../hooks/storeHooks';
import PageHeading from '../../components/Heading/PageHeading';
import UsersListState from './UsersListState';
import useMultipleUserDataAccess from '../../services/User/useMultipleUserDataAccess';
import UserRole from '../../common/UserRole';
import DataTable from '../../components/table/DataTable';
import IUser from '../../services/User/IUser';
import UserColumnKeys from './UsersTable/UserColumnKeys';
import AddNewUserLike from './AddNewLink';

const UsersList: React.FC = () => {
  const initialState: UsersListState = {
    users: [],
    loading: true,
  };
  const [state, setState] = useState<UsersListState>(initialState);
  const user = useAppSelector((store) => store.auth?.user);
  const userData = useMultipleUserDataAccess();
  const history = useHistory();

  useEffect(() => {
    if (!userData.users) return;
    setState((prevState) => ({
      ...prevState,
      users: userData.users || [],
      error: userData.users?.length === 0 ? 'No users' : undefined,
      loading: false,
    }));
  }, [userData.users]);

  useEffect(() => {
    if (!userData.error) return;
    setState((prevState) => ({
      ...prevState,
      error: userData.error,
      loading: false,
    }));
  }, [userData.error]);

  if (user.role !== UserRole.DropSuperAdmin) return null;

  const linkRowToAccount = {
    onClick: (_e, row: any) => {
      history.push(`/account/edit/${row.uuid}`);
    },
  };

  const allKioskIds = [...new Set(state.users.flatMap((item) => item?.kioskIds))];
  const allCompanies = [
    ...new Set(
      state.users
        .filter((user) => user.company !== undefined)
        .map((item) => (typeof item.company === 'object' ? item.company?.name : item.company))
    ),
  ].filter(Boolean) as string[];

  return (
    <div className='valign-wrapper'>
      <div className='row' style={{ width: '100%' }}>
        <div className='col s12' style={{ padding: '0px', marginTop: '15px' }}>
          <div className='col s8'>
            <PageHeading heading='All Users' />
          </div>
          <div className='col s4' style={{ justifyContent: 'end', display: 'flex' }}>
            <AddNewUserLike />
          </div>
        </div>
        <DataTable<IUser, UserColumnKeys>
          data={state.users}
          loading={state.loading}
          rowEvents={linkRowToAccount}
          totalSize={state.users.length}
          contextName='Users'
          dataColumnConfig={UsersTableConfig(allKioskIds, allCompanies)}
        />
      </div>
    </div>
  );
};

export default UsersList;
