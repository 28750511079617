import { Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

const AddNewUserLike: React.FC = () => {
  const history = useHistory();
  return (
    <Button onClick={() => history.push('/account/create')} className='filter-button '>
      <span>Add New</span>
    </Button>
  );
};
export default AddNewUserLike;
