import { Card } from 'react-bootstrap';
import { CustomerExperienceSettings } from '../../../../../common/Kiosk/KioskSettings';
import { useContext } from 'react';
import { KioskSettingsContext } from '../KioskSettingsContext';
import KioskEditStrings from '../../KioskEditStrings';
import KioskMonitorReport from '../../../../../hooks/useDeviceMonitor/KioskMonitorReport';
import { FormControlLabel, Checkbox } from '@material-ui/core';
import stringWithSpacesUppercase from '../../../../../utils/stringWithSpacesUppercase';

const KioskCustomerExperienceEdit: React.FC = () => {
  const kioskSettings = useContext(KioskSettingsContext);

  const experienceSettings = kioskSettings?.state.settings?.customerExperience;

  const updateExperienceSetting = (key: keyof CustomerExperienceSettings, value: boolean) => {
    kioskSettings?.handleSettingsChange('customerExperience', {
      ...experienceSettings,
      [key]: value,
    });
  };

  const message = (deviceStatus?: KioskMonitorReport, experienceSettings?: CustomerExperienceSettings) => {
    if (deviceStatus?.loaded && !deviceStatus.online) {
      return `${KioskEditStrings.KioskOffline}, cannot edit ${KioskEditStrings.CustomerExperienceSettingsLabel}`;
    }
    if (deviceStatus?.ready && !experienceSettings) {
      return `No ${KioskEditStrings.CustomerExperienceSettingsLabel} retrieved from kiosk`;
    }
  };

  return (
    <>
      <Card.Subtitle style={{ fontWeight: '600', fontSize: '16px', marginTop: '10px' }}>
        {KioskEditStrings.CustomerExperienceSettingsLabel}
      </Card.Subtitle>
      <p>{message(kioskSettings?.deviceStatus, experienceSettings)}</p>
      <div>
        {experienceSettings &&
          Object.keys(experienceSettings).map((setting, index) => (
            <FormControlLabel
              control={
                <Checkbox
                  checked={experienceSettings?.[setting as keyof CustomerExperienceSettings]}
                  onChange={(e) =>
                    updateExperienceSetting(setting as keyof CustomerExperienceSettings, e.target.checked)
                  }
                  name={setting}
                />
              }
              label={stringWithSpacesUppercase(setting)}
              key={`${setting}-${index}`}
              style={{ color: '#333' }}
              disabled={!kioskSettings?.deviceStatus.ready}
            />
          ))}
      </div>
    </>
  );
};

export default KioskCustomerExperienceEdit;
