import PropTypes from 'prop-types';

interface IKioskOnlineStatus {
  online: boolean;
  loaded: boolean;
}

const KioskOnlineStatus: React.FC<IKioskOnlineStatus> = ({ online, loaded }) => {
  const statusColor = (onlineStatus: boolean, loadedStatus: boolean) => {
    if (loadedStatus === false) return '#999';
    return onlineStatus ? '#28b62c' : '#ff4136';
  };
  const statusLabel = (onlineStatus: boolean, loadedStatus: boolean) => {
    if (loadedStatus === false) return 'Loading...';
    return onlineStatus ? 'Online' : 'Offline';
  };
  return (
    <div className='col s6 l6 m6 right-align online-status'>
      <span style={{ background: statusColor(online, loaded) }} className='indicator' />
      {statusLabel(online, loaded)}
    </div>
  );
};

KioskOnlineStatus.propTypes = {
  online: PropTypes.bool.isRequired,
  loaded: PropTypes.bool.isRequired,
};

export default KioskOnlineStatus;
