import IIconAdditive from './IIconAdditive';
import SvgIcon from './SvgIcon';

const IconAdditiveLemonadeV3: React.FC<IIconAdditive> = ({ className }) => (
  <SvgIcon
    className={className}
    width="112"
    height="112"
    fill="none"
    viewBox="0 0 112 112"
  >
    <path
      stroke="0"
      fill="#e9f45b"
      d="M9.54,55.8c0,25.22,20.45,45.67,45.67,45.67V10.15c-25.22,0-45.67,20.45-45.67,45.67h0Z"
    />
    <path
      stroke="0"
      fill="#e1e52e"
      d="M100.88,55.8c0-25.22-20.44-45.67-45.66-45.67v91.32c25.22,0,45.66-20.44,45.66-45.67h0Z"
    />
    <path
      stroke="0"
      fill="#fffea3"
      d="M16.14,55.8c0,21.58,17.5,39.07,39.07,39.07V16.74c-21.58,0-39.07,17.5-39.07,39.07h0Z"
    />
    <path
      stroke="0"
      fill="#f9fb86"
      d="M94.28,55.8c0-21.58-17.5-39.07-39.07-39.07v78.14c21.58,0,39.07-17.5,39.07-39.07h0Z"
    />
    <path
      stroke="0"
      fill="#fffff3"
      d="M29.61,55.8c0,14.14,11.46,25.6,25.6,25.6V30.2c-14.14,0-25.6,11.46-25.6,25.6Z"
    />
    <path
      stroke="0"
      fill="#fefdda"
      d="M80.81,55.8c0-14.14-11.46-25.6-25.6-25.6v51.2c14.14,0,25.6-11.46,25.6-25.6Z"
    />
    <path
      stroke="0"
      fill="#e9f45b"
      d="M27.76,38.86l10.5-10.5c.89-.89,2.4-.59,2.87.57l7.43,17.93c.6,1.45-.85,2.9-2.3,2.3l-17.93-7.43c-1.16-.48-1.46-1.98-.57-2.87Z"
    />
    <path
      stroke="0"
      fill="#e9f45b"
      d="M26.25,46.76l17.93,7.43c1.45.6,1.45,2.65,0,3.25l-17.93,7.43c-1.16.48-2.43-.37-2.43-1.63v-14.85c0-1.25,1.28-2.11,2.43-1.63h0Z"
    />
    <path
      stroke="0"
      fill="#e9f45b"
      d="M28.33,70.26l17.93-7.43c1.45-.6,2.9.85,2.3,2.3l-7.43,17.93c-.48,1.16-1.98,1.46-2.87.57l-10.5-10.5c-.89-.89-.59-2.39.57-2.87Z"
    />
    <path
      stroke="0"
      fill="#e9f45b"
      d="M55.21,24.41h-7.43c-1.25,0-2.11,1.28-1.63,2.43l7.43,17.93c.3.72.97,1.09,1.63,1.09v-21.45Z"
    />
    <path
      stroke="0"
      fill="#e9f45b"
      d="M55.21,66.12c-.66,0-1.33.36-1.63,1.09l-7.43,17.93c-.48,1.16.37,2.43,1.63,2.43h7.43v-21.45Z"
    />
    <path
      stroke="0"
      fill="#e1e52e"
      d="M64.16,62.83l17.93,7.43c1.16.48,1.46,1.98.57,2.87l-10.5,10.5c-.89.89-2.39.59-2.87-.57l-7.43-17.93c-.6-1.45.85-2.9,2.3-2.3Z"
    />
    <path
      stroke="0"
      fill="#e1e52e"
      d="M66.24,54.55l17.93-7.43c1.16-.48,2.43.37,2.43,1.63v14.85c0,1.25-1.28,2.11-2.43,1.63l-17.93-7.43c-1.45-.6-1.45-2.65,0-3.25h0Z"
    />
    <path
      stroke="0"
      fill="#e1e52e"
      d="M72.16,28.73l10.5,10.5c.89.89.59,2.39-.57,2.87l-17.93,7.43c-1.45.6-2.9-.85-2.3-2.3l7.43-17.93c.48-1.16,1.98-1.46,2.87-.57Z"
    />
    <path
      stroke="0"
      fill="#e1e52e"
      d="M55.21,87.58h7.43c1.25,0,2.11-1.28,1.63-2.43l-7.43-17.93c-.3-.72-.97-1.09-1.63-1.09v21.45Z"
    />
    <path
      stroke="0"
      fill="#e1e52e"
      d="M55.21,45.86c.66,0,1.33-.36,1.63-1.09l7.43-17.93c.48-1.16-.37-2.43-1.63-2.43h-7.43v21.45Z"
    />
  </SvgIcon>
);

IconAdditiveLemonadeV3.defaultProps = {
  className: '',
};

export default IconAdditiveLemonadeV3;
