import { KIOSK, KIOSK_LOADING } from '../actions/types';

const initialState = {
	kiosk: {},
	loading: false
};

export default function kioskReducers (state = initialState, action) {
	switch (action.type) {
		case KIOSK:
			return {
				...state,
				kiosk: action.payload
			};
		case KIOSK_LOADING:
			return {
				...state,
				loading: true
			};
		default:
			return state;
	}
}
