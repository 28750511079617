import { FC } from 'react';
import Card from 'react-bootstrap/Card';
import refill from '../../images/refill_square.png';
import paid from '../../images/paid.png';
import plastic_bottle from '../../images/plastic_bottle.png';
import gross from '../../images/gross.png';
import drop_bottle from '../../images/drop_bottle.png';

interface Props {
  num_refills: number;
  num_compostable: number;
  num_paid_orders: number;
  tot_earnings: number;
  num_orders: number;
}

const OverviewMetrics: FC<Props> = ({
  num_refills,
  num_compostable,
  num_paid_orders,
  tot_earnings,
  num_orders,
}) => {
  return (
    <>
      <Card
        style={{ width: '19%', marginRight: '1.25%', borderRadius: '10px', textAlign: 'left' }}
        className='animate__animated animate__zoomIn col l2 m2 s12 center-align summary-card
                                summary-card-three'
      >
        <Card.Body
          style={{
            backgroundColor: '#FFFFFF',
            float: 'left',
            margin: '0px',
            borderRadius: '50%',
            width: '25%',
            height: '22%',
            marginRight: '5%',
            paddingRight: '10%',
            marginTop: '7%',
            paddingTop: '1%',
            marginBottom: '5%',
            boxShadow: 'inset 0px 0px 11px rgba(154, 162, 184, 0.1)',
            marginLeft: '3%',
          }}
        >
          <Card.Img
            style={{ marginLeft: '35%', marginBottom: '10px', marginTop: '10px', width: '100%', marginRight: '0px' }}
            src={refill}
          />
        </Card.Body>
        <Card.Body style={{ marginTop: '7%' }}>
          <Card.Title style={{ color: '#636876', fontSize: 'large', fontWeight: '400', lineHeight: '24px' }}>
            refill servings
          </Card.Title>
          <Card.Text
            style={{
              fontWeight: '800',
              fontSize: 'x-large',
              color: '#2E2E35',
              marginTop: '10px',
              marginBottom: '10px',
              lineHeight: '24px',
            }}
          >
            {num_refills}
          </Card.Text>
        </Card.Body>
      </Card>

      <Card
        style={{ width: '19%', marginRight: '1.25%', borderRadius: '10px', textAlign: 'left' }}
        className='animate__animated animate__zoomIn col l2 m2 s12 center-align summary-card
                                summary-card-three'
      >
        <Card.Body
          style={{
            backgroundColor: '#FFFFFF',
            float: 'left',
            margin: '0px',
            borderRadius: '50%',
            width: '25%',
            height: '22%',
            marginRight: '5%',
            paddingRight: '10%',
            marginTop: '7%',
            paddingTop: '1%',
            marginBottom: '5%',
            boxShadow: 'inset 0px 0px 11px rgba(154, 162, 184, 0.1)',
            marginLeft: '3%',
          }}
        >
          <Card.Img
            style={{ marginLeft: '35%', marginBottom: '10px', marginTop: '10px', width: '100%', marginRight: '0px' }}
            src={drop_bottle}
          />
        </Card.Body>
        <Card.Body style={{ marginTop: '7%' }}>
          <Card.Title style={{ color: '#636876', fontSize: 'large', fontWeight: '400', lineHeight: '24px' }}>
            drop bottles
          </Card.Title>
          <Card.Text
            style={{
              fontWeight: '800',
              fontSize: 'x-large',
              color: '#2E2E35',
              marginTop: '10px',
              marginBottom: '10px',
              lineHeight: '24px',
            }}
          >
            {num_compostable}
          </Card.Text>
        </Card.Body>
      </Card>

      <Card
        style={{ width: '19%', marginRight: '1.25%', borderRadius: '10px ', textAlign: 'left' }}
        className='animate__animated animate__zoomIn col l2 m2 s12 center-align summary-card summary-card-four'
      >
        <Card.Body
          style={{
            backgroundColor: '#FFFFFF',
            float: 'left',
            margin: '0px',
            borderRadius: '50%',
            width: '25%',
            height: '22%',
            marginRight: '5%',
            paddingRight: '10%',
            marginTop: '7%',
            paddingTop: '1%',
            marginBottom: '5%',
            boxShadow: 'inset 0px 0px 11px rgba(154, 162, 184, 0.1)',
            marginLeft: '3%',
          }}
        >
          <Card.Img
            style={{ marginLeft: '35%', marginBottom: '10px', marginTop: '10px', width: '100%', marginRight: '30px' }}
            src={paid}
          />
        </Card.Body>
        <Card.Body style={{ marginTop: '7%' }}>
          <Card.Title style={{ color: '#636876', fontSize: 'large', fontWeight: '400', lineHeight: '24px' }}>
            paid transactions
          </Card.Title>
          <Card.Text style={{ fontWeight: '800', fontSize: 'x-large', color: '#2E2E35', margin: '10px' }}>
            {num_paid_orders}
          </Card.Text>
        </Card.Body>
      </Card>
      <Card
        style={{ width: '19%', marginRight: '1.25%', borderRadius: '10px', textAlign: 'left' }}
        className='animate__animated animate__zoomIn col l2 m2 s12 center-align summary-card summary-card-five'
      >
        <Card.Body
          style={{
            backgroundColor: '#FFFFFF',
            float: 'left',
            margin: '0px',
            borderRadius: '50%',
            width: '25%',
            height: '22%',
            marginRight: '5%',
            paddingRight: '10%',
            marginTop: '7%',
            paddingTop: '1%',
            marginBottom: '5%',
            boxShadow: 'inset 0px 0px 11px rgba(154, 162, 184, 0.1)',
            marginLeft: '3%',
          }}
        >
          <Card.Img
            style={{ marginLeft: '35%', marginBottom: '10px', marginTop: '10px', width: '100%', marginRight: '0px' }}
            src={gross}
          />
        </Card.Body>
        <Card.Body style={{ marginTop: '7%' }}>
          <Card.Title style={{ color: '#636876', fontSize: 'large', fontWeight: '400', lineHeight: '24px' }}>
            gross sales
          </Card.Title>
          <Card.Text
            style={{
              fontWeight: '800',
              fontSize: 'x-large',
              color: '#2E2E35',
              marginTop: '10px',
              marginBottom: '10px',
            }}
          >
            $ {tot_earnings.toFixed()}
          </Card.Text>
        </Card.Body>
      </Card>

      <Card
        style={{
          width: '19%',
          marginRight: '0px',
          borderRadius: '10px',
          backgroundColor: '#FFFFFF',
          textAlign: 'left',
        }}
        className='col l2 m2 s12 center-align summary-card summary-card-one animate__animated animate__zoomIn'
      >
        <Card.Body
          style={{
            backgroundColor: '#FFFFFF',
            float: 'left',
            margin: '0px',
            borderRadius: '50%',
            width: '25%',
            height: '22%',
            marginRight: '5%',
            paddingRight: '10%',
            marginTop: '7%',
            paddingTop: '1%',
            marginBottom: '5%',
            boxShadow: 'inset 0px 0px 11px rgba(154, 162, 184, 0.1)',
            marginLeft: '3%',
          }}
        >
          <Card.Img
            style={{ marginLeft: '35%', marginBottom: '10px', marginTop: '10px', width: '100%', marginRight: '0px' }}
            src={plastic_bottle}
          />
        </Card.Body>
        <Card.Body style={{ marginTop: '7%' }}>
          <Card.Title style={{ color: '#636876', fontSize: 'large', fontWeight: '400', lineHeight: '24px' }}>
            plastic bottles saved
          </Card.Title>
          <Card.Text style={{ color: '#2E2E35', fontWeight: '800', fontSize: 'x-large', margin: '10px' }}>
            {num_orders}
          </Card.Text>
        </Card.Body>
      </Card>
    </>
  );
};

export default OverviewMetrics;
