import React from 'react';
import styles from './MessageCard.module.css';
import IMessage from '../../../common/Notifications/IMessage';
import kebabToTitleCase from '../../../utils/kebabToTitleCase';
import stringWithSpacesUppercase from '../../../utils/stringWithSpacesUppercase';
import EventOrigin from '../../../services/Notifications/EventOrigin';
import SendReportDisplay from './SendReportDisplay';
import ChatStrings from '../ChatStrings';
import NotificationMediumIcon from '../NotificationMediumIcon/NotificationMediumIcon';

interface IMessageCard {
  message: IMessage;
}

const senderNameString = (name: string, origin: EventOrigin, company?: string) => {
  if (origin === EventOrigin.KioskSoftware) {
    return `${stringWithSpacesUppercase(name)} ${ChatStrings.KioskSoftwareOriginLabel}`;
  }
  return `${stringWithSpacesUppercase(name)} ${company ? `(${company})` : ''}`;
};

const MessageCard: React.FC<IMessageCard> = ({ message }) => {
  const { senderName, content, timestamp, medium, recipients, senderCompany } = message;
  const date = new Date(timestamp);
  const formattedTime = `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
  return (
    <div className={[styles.messageCard, styles[message.origin ?? '']].join(' ')}>
      <div className={styles.senderDetails}>
        <NotificationMediumIcon medium={medium} />
        <div className={styles.senderWithTimestamp}>
          <div className={styles.senderName}>
            {senderName
              ? senderNameString(senderName, message.origin, senderCompany)
              : kebabToTitleCase(message.origin)}
          </div>
          <div className={styles.timestamp}>{formattedTime}</div>
        </div>
      </div>
      <div className={styles.messageContent}>{content}</div>
      <SendReportDisplay sendReport={message.sendReport} recipients={recipients} />
    </div>
  );
};

export default MessageCard;
