import { KioskLink } from '../../../components/Link/KioskLink';
import IInventory from '../../../services/Inventory/IInventory';
import generateRandomHexColor from '../../../utils/generateRandomColor';

interface IInventoryTableRow {
  inventory: IInventory;
}

const InventoryTableRow: React.FC<IInventoryTableRow> = ({ inventory }) => {
  const { kiosk_ID, kiosk, towerSelected, ...inventoryNumbers } = inventory;

  const inventoryNumberKeys = [
    'towerOneRemaining',
    'towerTwoRemaining',
    'towerThreeRemaining',
    'CO2PSI',
    'lemonadeV3',
    'tropicalEnergy',
    'blackberry',
    'mintMojito',
    'orange',
    'passionOrangeGuava',
    'peachTea',
    'pomegranateAcai',
    'raspberryLychee',
    'strawberryKiwi',
    'teaPlusLemonade',
    'yuzuGinger',
    'sanitizer',
  ];

  const getCellStyle = (value?: string | number | Date) => {
    if (typeof value !== 'number') {
      return {};
    }
    return value <= 0 ? { color: '#ff7e65', fontWeight: 'bolder' } : {};
  };

  return (
    <tr key={`inv-row-${kiosk_ID}`}>
      <td className='center-align'>
        <span className='dot' style={{ backgroundColor: generateRandomHexColor() }}></span>
      </td>
      <td>
        <KioskLink alias={kiosk?.alias} id={kiosk_ID} />
        <div>{kiosk?.location}</div>
      </td>
      <td key='row-towerSelected' className='center-align'>
        {towerSelected ?? '-'}
      </td>
      {inventoryNumberKeys.map((key) => {
        const value = inventoryNumbers[key as keyof typeof inventoryNumbers];
        return (
          <td key={key} className='center-align' style={getCellStyle(value)}>
            <>{value?.toLocaleString() || '-'}</>
          </td>
        );
      })}
    </tr>
  );
};

export default InventoryTableRow;
