import React from 'react';
import { Drawer, Dialog, IconButton, useMediaQuery, useTheme } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import MessageList from '../MessageList/MessageList';
import ChatMessageInput from '../ChatMessageInput/ChatMessageInput';
import styles from './ChatWidget.module.css';
import IMessage from '../../../common/Notifications/IMessage';
import MessageFailureOptions from '../MessageFailureOptions/MessageFailureOptions';

export enum ChatStatus {
  Initializing = 'initializing',
  Ready = 'ready',
  Sending = 'sending',
  Error = 'error'
}

export type ChatParticipant = {
  phone?: string;
  email?: string;
};

interface MessagesWidgetProps {
  open: boolean;
  onClose: () => void;
  heading: string;
  description?: string;
  messages?: IMessage[];
  onSendMessage: (message: string, recipients: ChatParticipant[]) => void;
  status: ChatStatus;
  failedMessage?: { message: string; recipients: ChatParticipant[] };
  error?: string;
  onClearFailedMessage: () => void;
  participants: ChatParticipant[];
  messageControls?: React.ReactNode;
}

const ChatWidget: React.FC<MessagesWidgetProps> = ({
  open,
  onClose,
  heading,
  description,
  messages,
  onSendMessage,
  status,
  failedMessage,
  error,
  onClearFailedMessage,
  participants,
  messageControls
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      {isMobile ? (
        <Dialog
          open={open}
          onClose={onClose}
          fullScreen
          className={styles.messageDialog}
          PaperProps={{ style: { overflowY: 'hidden' } }}
        >
          <div className={styles.dialogTitle}>
            <div>
              {heading && <h3>{heading}</h3>}
              {description && <p className={styles.description}>{description}</p>}
            </div>
            <IconButton aria-label='close' onClick={onClose} className={styles.closeButton}>
              <CloseIcon />
            </IconButton>
          </div>
          <MessageList messages={messages} status={status} />
          <div className={styles.stickyInput}>
            <MessageFailureOptions
              failedMessage={failedMessage}
              error={error}
              onResend={onSendMessage}
              onCancel={onClearFailedMessage}
            />
            <ChatMessageInput
              onSendMessage={onSendMessage}
              status={status}
              participants={participants}
              messageControls={messageControls}
            />
          </div>
        </Dialog>
      ) : (
        <Drawer
          anchor='right'
          open={open}
          onClose={onClose}
          className={styles.messageDrawer}
          PaperProps={{ style: { width: '500px', overflowY: 'hidden' } }}
        >
          <div className={styles.drawerTitle}>
            {heading && <h3>{heading}</h3>}
            {description && <p className={styles.description}>{description}</p>}
          </div>
          <MessageList messages={messages} status={status} />
          <div className={styles.stickyInput}>
            <MessageFailureOptions
              failedMessage={failedMessage}
              error={error}
              onResend={onSendMessage}
              onCancel={onClearFailedMessage}
            />
            <ChatMessageInput
              onSendMessage={onSendMessage}
              status={status}
              participants={participants}
              messageControls={messageControls}
            />
          </div>
        </Drawer>
      )}
    </>
  );
};

export default ChatWidget;
