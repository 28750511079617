import { Line } from 'react-chartjs-2';
interface ILineGraph {
  chartData: any;
  screenWidth: number;
  label: string;
  labelPrefix?: string;
}
const LineGraph: React.FC<ILineGraph> = ({ chartData, screenWidth, label, labelPrefix = '' }) => {
  return (
    <Line
      data={chartData}
      height={screenWidth < 800 ? 170 : 80}
      options={{
        maintainAspectRatio: true,
        legend: {
          display: true,
        },
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: false,
                callback: function (value, _index, _values) {
                  return `${labelPrefix}${value}`;
                },
              },
              scaleLabel: {
                display: false,
                labelString: label,
                fontSize: 13,
              },
            },
          ],
        },
      }}
    />
  );
};

export default LineGraph;
