import is from 'check-types';
import { Checkbox, FormControlLabel, TextField } from '@material-ui/core';
import { useState } from 'react';
import KioskService from '../../../../services/Kiosk/KioskService';
import KioskEditStrings from '../KioskEditStrings';
import SubmitButton from '../../../../components/Button/SubmitButton';
import KioskStrings from '../../KioskStrings';
import KioskEditLoading from '../KioskEditLoading';
import KioskEditErrorMessage from '../KioskEditErrorMessage';

interface ISoftwareVersionEdit {
  onChangeRequestStarted: () => unknown;
  onChangeRequestComplete: () => unknown;
  disabled: boolean;
  currentVersion?: string;
  kioskId?: string;
}

const SoftwareVersionEdit: React.FC<ISoftwareVersionEdit> = ({
  onChangeRequestStarted,
  onChangeRequestComplete,
  disabled,
  kioskId,
  currentVersion,
}) => {
  const [versionChange, setVersionChange] = useState<string>();
  const [message, setMessage] = useState<string>();
  const [error, setError] = useState<Error>();
  const [changeInProgress, setChangeInProgress] = useState<boolean>(false);
  const [ignoreMaintenance, setIgnoreMaintenance] = useState<boolean>(false);

  const handleSoftwareVersionUpdate = async () => {
    try {
      if (is.undefined(versionChange)) throw new Error('Missing version');
      onChangeRequestStarted();
      setMessage(undefined);
      setError(undefined);
      setChangeInProgress(true);
      const response = await new KioskService(kioskId).sendSoftwareUpdateRequest(ignoreMaintenance, versionChange);
      if (response.error) throw new Error(`${response.error}. Error ID: ${response.correlationId}`);
      setMessage(response.message);
    } catch (err) {
      setError(err instanceof Error ? err : new Error(JSON.stringify(err)));
    } finally {
      onChangeRequestComplete();
      setChangeInProgress(false);
    }
  };

  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center', justifyItems: 'center', marginTop: '10px' }}>
        <TextField
          label='Software Version'
          value={versionChange ?? currentVersion ?? 'unknown'}
          onChange={(e) => setVersionChange(e.target.value)}
          id='software-version-edit'
          disabled={disabled}
        />
        {is.assigned(versionChange) && versionChange !== currentVersion && (
          <SubmitButton label='Update' disabled={disabled} onClick={() => handleSoftwareVersionUpdate()} />
        )}
      </div>
      {is.assigned(versionChange) && versionChange !== currentVersion && (
        <>
          <FormControlLabel
            control={
              <Checkbox
                checked={ignoreMaintenance}
                onChange={(e) => setIgnoreMaintenance(e.target.checked)}
                name='ignoreMaintenance'
              />
            }
            label={KioskEditStrings.SoftwareUpdateIgnoreMaintenanceLabel}
            style={{ color: '#333' }}
            disabled={is.undefined(versionChange) || versionChange === currentVersion}
          />
          {KioskStrings.SoftwareUpdateMaintenanceWarning}
        </>
      )}
      <strong>{message}</strong>
      <KioskEditLoading display={changeInProgress} message={KioskEditStrings.SoftwareUpdateInProgress} />
      <KioskEditErrorMessage error={error?.message} />
    </>
  );
};

export default SoftwareVersionEdit;
