import { MenuItem, TextField } from '@material-ui/core';
import HoursOfDay from '../../../../common/HourOfDay';

interface IHourOfDaySelect {
  value: number;
  name: string;
  label: string;
  disabled?: boolean;
  onChange: (value: string) => void;
}

const HourOfDaySelect: React.FC<IHourOfDaySelect> = ({ value, name, label, disabled, onChange }) => {
  return (
    <TextField
      select
      size='small'
      variant='outlined'
      fullWidth={true}
      required={true}
      id={name}
      label={label}
      value={value || ''}
      onChange={(e) => onChange(e.target.value)}
      style={{ margin: '20px 0' }}
      disabled={disabled}
    >
      {HoursOfDay.map((hour, index) => (
        <MenuItem key={index} value={index}>
          {hour}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default HourOfDaySelect;
