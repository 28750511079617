import Typography from '@material-ui/core/Typography';

interface IPageHeading {
  heading: string;
}

const PageHeading: React.FC<IPageHeading> = ({ heading }) => {
  return (
    <Typography
      style={{
        fontWeight: 400,
        fontSize: '24px',
        font: "'Open Sans', sans-serif",
        paddingRight: '0px',
        paddingLeft: '0px',
      }}
      className='col s6 l10 m10 left'
    >
      {heading}
    </Typography>
  );
};

export default PageHeading;
