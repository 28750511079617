import { Card } from 'react-bootstrap';
import IInventory from '../../../services/Inventory/IInventory';
import InventoryTableRow from './InventoryTableRow';
import TableHeader from '../../../components/table/TableHeader';

interface IInventoryTable {
  inventoryData: IInventory[];
}

const InventoryTable: React.FC<IInventoryTable> = ({ inventoryData }) => {
  const headers: [string, string][] = [
    ['', ''],
    ['Kiosk', ''],
    ['Active Tower', 'center-align'],
    ['Tower One', 'center-align'],
    ['Tower Two', 'center-align'],
    ['Tower Three', 'center-align'],
    ['CO2 PSI', 'center-align'],
    ['Lemonade V3', 'center-align'],
    ['Tropical Energy', 'center-align'],
    ['Blackberry', 'center-align'],
    ['Mint Mojito', 'center-align'],
    ['Orange', 'center-align'],
    ['Passion Orange Guava', 'center-align'],
    ['Peach Tea', 'center-align'],
    ['Pomegranate Acai', 'center-align'],
    ['Raspberry Lychee', 'center-align'],
    ['Strawberry Kiwi', 'center-align'],
    ['Tea + Lemonade', 'center-align'],
    ['Yuzu Ginger', 'center-align'],
    ['Sanitizer', 'center-align'],
  ];
  return (
    <Card
      style={{ width: '100%', borderRadius: '20px', paddingBottom: '25px' }}
      className='col s12 l12 m12 filter-card'
    >
      <div>
        <table className='responsive-table2'>
          <TableHeader headers={headers} />
          <tbody>
            {inventoryData.length > 0 &&
              inventoryData
                .sort((a, b) => a.kiosk_ID.localeCompare(b.kiosk_ID))
                .map((inventoryItem) => <InventoryTableRow inventory={inventoryItem} key={inventoryItem.kiosk_ID} />)}
          </tbody>
        </table>
      </div>
    </Card>
  );
};

export default InventoryTable;
