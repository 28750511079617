import { USER_INTERACTIONS, USER_INTERACTIONS_TABLE_LOADING } from '../actions/types';

const initialState = {
	inventory_list: {},
	loading: false
};

export default function userInteractionsReducers (state = initialState, action) {
	switch (action.type) {
		case USER_INTERACTIONS:
			return {
				...state,
				user_interactions_list: action.payload
			};
		case USER_INTERACTIONS_TABLE_LOADING:
			return {
				...state,
				loading: true
			};
		default:
			return state;
	}
}
