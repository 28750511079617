import { useState, useEffect } from 'react';
import IUser from './IUser';
import UserService from './UserService';

const useMultipleUserDataAccess = (): { users: IUser[] | undefined; error: Error | undefined } => {
  const [users, setUsers] = useState<IUser[]>();
  const [error, setError] = useState<Error>();

  useEffect(() => {
    const getUser = async () => {
      if (users) return;
      setError(undefined);
      const userService = new UserService();
      try {
        const userResults = await userService.getUsers();
        setUsers(userResults);
      } catch (error) {
        setError(error as Error);
      }
    };
    if (!users) {
      getUser();
    }
  }, [users]);

  return { users, error };
};

export default useMultipleUserDataAccess;
