import { INVENTORY, INVENTORY_TABLE_LOADING } from '../actions/types';

const initialState = {
	inventory_list: {},
	loading: false
};

export default function inventoryReducers (state = initialState, action) {
	switch (action.type) {
		case INVENTORY:
			return {
				...state,
				inventory_list: action.payload
			};
		case INVENTORY_TABLE_LOADING:
			return {
				...state,
				loading: true
			};
		default:
			return state;
	}
}