import { useMemo } from 'react';
import KioskService from './KioskService';
import KioskCommandSequence from '../../common/Kiosk/KioskCommandSequence';
import { Features } from '../../common/Kiosk/KioskSettings';
import CommandCode from '../../common/Peripherals/FirmwareCommunication/Messaging/CommandCode';
import PeripheralIdentifier from '../../common/Peripherals/PeripheralIdentifier';
import CameraSnapshotResponse from './CameraSnapshotResponse';
import CommandSequenceResponse from './CommandSequenceResponse';
import FeatureToggleResponse from './FeatureToggleResponse';
import KioskCommand from './KioskCommand';
import KioskStatusReport from './KioskStatusReport';
import PeripheralCommandResponse from './PeripheralCommandResponse';
import ResetResponse from './ResetResponse';
import ScreenshotResponse from './ScreenshotResponse';
import SoftwareUpdateResponse from './SoftwareUpdateResponse';

const useKioskDevice = (kioskId?: string) => {
  const kioskService = useMemo(() => new KioskService(kioskId), [kioskId]);

  const runCommandSequence = (sequence: KioskCommandSequence): Promise<CommandSequenceResponse> => {
    return kioskService.runCommandSequence(sequence);
  };

  const sendPeripheralCommand = (
    command: CommandCode,
    peripheralIdentifier: PeripheralIdentifier,
    args: number[][]
  ): Promise<PeripheralCommandResponse> => kioskService.sendPeripheralCommand(command, peripheralIdentifier, args);

  const sendCameraSnapshotRequest = (): Promise<CameraSnapshotResponse> => kioskService.sendCameraSnapshotRequest();

  const sendScreenshotRequest = (): Promise<ScreenshotResponse> => kioskService.sendScreenshotRequest();

  const sendSoftwareUpdateRequest = (ignoreMaintenance: boolean, version?: string): Promise<SoftwareUpdateResponse> =>
    kioskService.sendSoftwareUpdateRequest(ignoreMaintenance, version);

  const sendResetCommand = (): Promise<ResetResponse> => kioskService.sendResetCommand();

  const sendToggleFeatureCommand = (
    command: KioskCommand,
    enable: boolean,
    uuid: string,
    feature?: keyof Features
  ): Promise<FeatureToggleResponse> => kioskService.sendToggleFeatureCommand(command, enable, uuid, feature);

  const getDeviceStatus = (): Promise<KioskStatusReport> => kioskService.getDeviceStatus();

  return {
    runCommandSequence,
    sendPeripheralCommand,
    sendCameraSnapshotRequest,
    sendScreenshotRequest,
    sendSoftwareUpdateRequest,
    sendResetCommand,
    sendToggleFeatureCommand,
    getDeviceStatus
  };
};

export default useKioskDevice;
