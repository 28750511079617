import PageHeading from '../../../components/Heading/PageHeading';
import { useUserAccount } from '../useUserAccount';
import { Card } from 'react-bootstrap';
import { TextField } from '@material-ui/core';
import { UserAccountContext } from '../UserAccountContext';
import { UserProfileFieldConfig } from '../UserAccountFieldConfig';
import formatFieldName from '../../../utils/formatFieldName';
import UserAccountTabReference from '../AccountEdit/UserAccountTabReference';
import InitialUser from './InitialUser';
import AuthGuard from '../../../components/auth/AuthGuard';
import UserRole from '../../../common/UserRole';
import UserRoleSelect from '../UserRoleSelect';
import { useAppSelector } from '../../../hooks/storeHooks';
import AccountCreateStrings from './AccountCreateStrings';
import CompanySelect from './CompanySelect';
import KiosksSelect from '../KiosksSelect';
import SubmitButton from '../../../components/Button/SubmitButton';

const UserAccountCreate: React.FC = () => {
  const userAccount = useUserAccount(InitialUser);
  const user = useAppSelector((store) => store.auth?.user);

  const handleSubmit = (e) => {
    e.preventDefault();
    userAccount?.handleCreateUser(UserAccountTabReference.General);
  };

  return (
    <div id='user-account-create' style={{ marginTop: '1%', width: '100%' }} className='valign-wrapper'>
      <AuthGuard authorizedRoles={[UserRole.DropSuperAdmin]} userRole={user?.role}>
        <div className='row' style={{ marginTop: '1%', width: '100%', maxWidth: '1000px' }}>
          <PageHeading heading='Create Account' />
          <div style={{ marginTop: '50px' }}>
            <UserAccountContext.Provider value={userAccount}>
              <Card style={{ padding: '2%' }} className='animate__animated animate__zoomIn'>
                <Card.Body>
                  <Card.Body className='row' style={{ width: '100%' }}>
                    <form onSubmit={handleSubmit}>
                      {UserProfileFieldConfig.map((field, i) => (
                        <div key={i} className='col s12 m6'>
                          <TextField
                            size='small'
                            variant='outlined'
                            fullWidth={true}
                            required={field.required}
                            id={field.key}
                            label={formatFieldName(field.key)}
                            value={userAccount?.state?.user?.[field.key] || ''}
                            onChange={(e) => userAccount?.handleUserDataChange(field.key, e.target.value)}
                            style={{ margin: '10px 0' }}
                            disabled={userAccount?.state.loading}
                          />
                        </div>
                      ))}
                      <div className='col s12 m6'>
                        <UserRoleSelect />
                      </div>
                      <div className='col s12 m6'>
                        <CompanySelect />
                      </div>
                      <div className='col s12 m6'>
                        <TextField
                          type='password'
                          size='small'
                          variant='outlined'
                          fullWidth={true}
                          required={true}
                          id='password'
                          label='Password'
                          value={userAccount?.state?.user?.password || ''}
                          onChange={(e) => userAccount?.handleUserDataChange('password', e.target.value)}
                          style={{ margin: '10px 0' }}
                          disabled={userAccount?.state.loading}
                        />
                      </div>
                      <div className='col s12 m6'>
                        <TextField
                          type='password'
                          size='small'
                          variant='outlined'
                          fullWidth={true}
                          required={true}
                          id='password-confirm'
                          label='Confirm Password'
                          value={userAccount?.state.passwordConfirm || ''}
                          onChange={(e) => userAccount?.handlePasswordConfirmationChange(e.target.value)}
                          style={{ margin: '10px 0' }}
                          disabled={userAccount?.state.loading}
                        />
                      </div>
                      <div className='col s12 s12'>
                        <KiosksSelect
                          companyId={userAccount?.state?.user?.company as string | undefined}
                          description={AccountCreateStrings.KioskSelectDescription}
                        />
                      </div>
                      <div className='col s12'>
                        <SubmitButton label={AccountCreateStrings.SubmitButton} disabled={userAccount?.state.loading} />
                        <p>
                          <strong>NOTE:</strong> {AccountCreateStrings.SubmitWarning}
                        </p>
                        <p>
                          <span style={{ color: 'red' }}>
                            {userAccount?.state?.error?.[UserAccountTabReference.General]?.message}
                          </span>
                          {userAccount?.state?.message?.[UserAccountTabReference.General]}
                        </p>
                      </div>
                    </form>
                  </Card.Body>
                </Card.Body>
              </Card>
            </UserAccountContext.Provider>
          </div>
        </div>
      </AuthGuard>
    </div>
  );
};

export default UserAccountCreate;
