import { selectFilter } from 'react-bootstrap-table2-filter';
import kebabToTitleCase from '../../../utils/kebabToTitleCase';
import DataColumn from '../../../components/table/DataColumn';
import KioskHealthReportKeys from './KioskHealthReportKeys';
import KioskStatus from '../../../common/Kiosk/KioskStatus';
import stringWithSpacesUppercase from '../../../utils/stringWithSpacesUppercase';
import FeaturesHealth from './FeaturesHealth';
import HealthStatus from '../../../components/ui/Icons/HealthStatusIndicator/HealthStatus';
import { KioskLink } from '../../../components/Link/KioskLink';
import IKioskHealthReport, {
  KioskFeatureHealthReport,
  LatestOrder
} from '../../../services/Reports/IKiosksHealthReport';
import LatestOrdersList from './LatestOrdersList';

const kioskStatusOptions = Object.entries(KioskStatus).reduce((options, [key, value]) => {
  options[value] = stringWithSpacesUppercase(key);
  return options;
}, {});

const featuresHealthFilter = (filterVal: HealthStatus, data: IKioskHealthReport[]) => {
  if (!filterVal) return data;

  return data.filter((row: IKioskHealthReport) => {
    if (!row.features) return false;
    const hasHealthStatus = row.features.some((report) => report.healthStatus === filterVal);
    return hasHealthStatus;
  });
};

const KiosksHealthReportsTableConfig = (): DataColumn<IKioskHealthReport, KioskHealthReportKeys>[] => {
  return [
    {
      dataField: 'kioskId',
      text: 'Kiosk',
      formatter: (cell, row) => <KioskLink alias={row.alias} id={cell} />
    },
    {
      dataField: 'kioskStatus',
      text: 'Kiosk Status',
      formatter: (cell: string) => cell && kebabToTitleCase(cell),
      filter: selectFilter({
        options: kioskStatusOptions,
        defaultValue: KioskStatus.Deployed
      })
    },
    {
      dataField: 'features',
      text: 'Features Health',
      formatter: (reports: KioskFeatureHealthReport[], _row) => reports && <FeaturesHealth reports={reports} />,
      filter: selectFilter({
        options: { unhealthy: 'Unhealthy', healthy: 'Healthy' },
        onFilter: featuresHealthFilter
      })
    },
    {
      dataField: 'latestOrders',
      text: 'Latest Orders',
      formatter: (latest: LatestOrder[], _row) => <LatestOrdersList latestOrders={latest} />
    }
  ];
};

export default KiosksHealthReportsTableConfig;
