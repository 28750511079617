import { KIOSK_DETAILS, KIOSK_DETAILS_LOADING, KIOSK_ID } from "../actions/types";
const initialState = {
  kioskDetails: {},
  kioskInfo: {},
  loading: false,
};
export default function PropagateReducer (state = initialState, action) {
  switch (action.type) {
    case KIOSK_DETAILS:
      return {
        ...state,
        kioskDetails: action.payload,
      };
    case KIOSK_DETAILS_LOADING:
      return {
        ...state,
        loading: true,
      };
    case KIOSK_ID:
      return {
        ...state,
        kioskInfo: action.payload,
      };
    default:
      return state;
  }
}
