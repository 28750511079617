import Card from 'react-bootstrap/Card';
import LineGraph from '../../components/Graph/LineGraph';
import DoughnutChart from '../../components/Graph/DonutChart';
import IGraph from '../../components/Graph/IGraph';
import IconLoading from '../../components/ui/Icons/IconLoading';

interface IOverviewGraphsProps {
  flavor_graph?: IGraph;
  caffeine_flavor_graph?: IGraph;
  daily_sales_graph?: IGraph;
  daily_transactions_graph?: IGraph;
  loading: boolean;
}

const OverviewGraphs: React.FC<IOverviewGraphsProps> = ({
  flavor_graph,
  caffeine_flavor_graph,
  daily_sales_graph,
  daily_transactions_graph,
  loading,
}) => (
  <>
    <Card
      style={{ borderRadius: '15px', width: '49.5%', minHeight: '800' }}
      className='col s12 l6 m6 center-align mr-2 pie-chart'
    >
      <Card.Body>
        <Card.Title style={{ fontWeight: '800', fontSize: '16px', marginTop: '3%' }}>Servings by Flavor</Card.Title>
        <div>
          {loading && <IconLoading width={50} height={50} />}
          {flavor_graph && !loading && <DoughnutChart screenWidth={window.innerWidth} chartData={flavor_graph} />}
          {!flavor_graph && !loading && <>No Results</>}
        </div>
      </Card.Body>
    </Card>

    <Card
      style={{ borderRadius: '15px', width: '49.5%', marginLeft: '1%' }}
      className='col s12 l6 m6 center-align ml-5 pie-chart'
    >
      <Card.Body className='center-align'>
        <Card.Title style={{ fontWeight: '800', fontSize: '16px', marginTop: '3%' }}>Caffeine Servings</Card.Title>
        <div>
          {loading && <IconLoading width={50} height={50} />}
          {caffeine_flavor_graph && !loading && (
            <DoughnutChart screenWidth={window.innerWidth} chartData={caffeine_flavor_graph} />
          )}
          {!caffeine_flavor_graph && !loading && <>No Results</>}
        </div>
      </Card.Body>
    </Card>

    <Card style={{ width: '100%', borderRadius: '15px' }} className='col s12 l12 m12 center-align'>
      <Card.Body>
        <Card.Title style={{ fontWeight: '800', fontSize: 'large', marginTop: '3%' }}>Daily Sales (USD)</Card.Title>
        <div>
          {loading && <IconLoading width={50} height={50} />}
          {daily_sales_graph && !loading && (
            <LineGraph
              chartData={daily_sales_graph}
              label='Amount ($)'
              labelPrefix='$'
              screenWidth={window.innerWidth}
            />
          )}
          {!daily_sales_graph && !loading && <>No Results</>}
        </div>
      </Card.Body>
    </Card>

    {daily_transactions_graph && (
      <Card style={{ width: '100%', borderRadius: '15px' }} className='col s12 l12 m12 center-align'>
        <Card.Body>
          <Card.Title style={{ fontWeight: '800', fontSize: 'large', marginTop: '3%' }}>Daily Servings</Card.Title>
          <div>
            {loading ? (
              <IconLoading width={50} height={50} />
            ) : (
              <LineGraph
                chartData={daily_transactions_graph}
                label='Number of Servings'
                screenWidth={window.innerWidth}
              />
            )}
          </div>
        </Card.Body>
      </Card>
    )}
  </>
);

export default OverviewGraphs;
