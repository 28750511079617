import { USER_TABLE_LOADING, ALL_USERS, EMAIL_QRCODE } from "../actions/types";
const initialState = {
  all_users: {},
  loading: false,
  email_qrcode: ""
};
export default function userReducers (state = initialState, action) {
  switch (action.type) {
    case ALL_USERS:
      return {
        ...state,
        all_users: action.payload,
      };
    case USER_TABLE_LOADING:
      return {
        ...state,
        loading: true,
      };
    case EMAIL_QRCODE:
      return {
        ...state,
        email_qrcode: action.payload,
      };
    default:
      return state;
  }
}
