import { ReactElement, useState } from 'react';
import PeripheralIdentifier from '../../../../common/Peripherals/PeripheralIdentifier';
import GantryJog from './GantryControls/GantryControls';
import KioskEditStrings from '../../KioskEdit/KioskEditStrings';
import KioskType from '../../../../common/Kiosk/KioskType';

type ControlConfig = {
  component: ReactElement;
  kioskTypes: KioskType[];
};

interface IPeripheralControls {
  kioskId?: string;
  kioskType?: KioskType;
}

const PeripheralControls: React.FC<IPeripheralControls> = ({ kioskId, kioskType }) => {
  const [peripheralToDisplay, setPeripheralToDisplay] = useState<PeripheralIdentifier>();

  if (!kioskId) return null;

  const peripheralControlList: Partial<Record<PeripheralIdentifier, ControlConfig>> = {
    [PeripheralIdentifier.Gantry]: {
      component: (
        <GantryJog
          onCloseClick={() => setPeripheralToDisplay(undefined)}
          kioskId={kioskId}
          disable={peripheralToDisplay !== PeripheralIdentifier.Gantry}
        />
      ),
      kioskTypes: [KioskType.Duo]
    }
  };

  const handleButtonClick = (identifier: PeripheralIdentifier) => {
    setPeripheralToDisplay(identifier);
  };

  if (!kioskType) return null;

  const controlsForKioskType = Object.entries(peripheralControlList).filter(([, config]) =>
    config?.kioskTypes.includes(kioskType)
  );

  if (controlsForKioskType.length === 0) return null;

  return (
    <>
      <h4>{KioskEditStrings.PeripheralControlsHeading}</h4>
      {controlsForKioskType.map(([identifier, config]) => {
        return (
          <ul>
            <li key={identifier}>
              <button onClick={() => handleButtonClick(+identifier)} disabled={false}>
                {PeripheralIdentifier[identifier]} {KioskEditStrings.PeripheralControlLabel}
              </button>
              {peripheralToDisplay === +identifier && config?.component}
            </li>
          </ul>
        );
      })}
    </>
  );
};

export default PeripheralControls;
