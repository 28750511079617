import React from 'react';
import SvgIcon from './SvgIcon';
import IIconAdditive from './IIconAdditive';

const IconAdditiveTropicalEnergy: React.FC<IIconAdditive> = ({ className }) => (
  <SvgIcon
    className={className}
    width="112"
    height="112"
    fill="none"
    viewBox="0 0 112 112"
  >
    <path
      fill="#fcda4c"
      d="M55.25,10.57c-.63,0-1.27-.12-1.87-.37-1.83-.76-3.93-.34-5.32,1.06-.92.92-2.16,1.43-3.45,1.43-1.98,0-3.76,1.19-4.51,3.02-.5,1.2-1.45,2.15-2.64,2.64-1.83.76-3.02,2.54-3.02,4.51,0,1.29-.52,2.54-1.43,3.45-1.4,1.4-1.82,3.5-1.06,5.32.5,1.2.5,2.54,0,3.74-.76,1.83-.34,3.93,1.06,5.32.92.92,1.43,2.16,1.43,3.45,0,1.98,1.19,3.76,3.02,4.51,1.2.5,2.15,1.45,2.64,2.64.76,1.83,2.54,3.02,4.51,3.02,1.29,0,2.54.52,3.45,1.43,1.4,1.4,3.5,1.82,5.32,1.06.6-.25,1.24-.37,1.87-.37V10.57Z"
    />
    <path
      fill="#e8c531"
      d="M55.25,56.46c.63,0,1.27.12,1.87.37,1.83.76,3.93.34,5.32-1.06.92-.92,2.16-1.43,3.45-1.43,1.98,0,3.76-1.19,4.51-3.02.5-1.2,1.45-2.15,2.64-2.64,1.83-.76,3.02-2.54,3.02-4.51,0-1.29.52-2.54,1.43-3.45,1.4-1.4,1.82-3.5,1.06-5.32-.5-1.2-.5-2.54,0-3.74.76-1.83.34-3.93-1.06-5.32-.92-.92-1.43-2.16-1.43-3.45,0-1.98-1.19-3.76-3.02-4.51-1.2-.5-2.15-1.45-2.64-2.64-.76-1.83-2.54-3.02-4.51-3.02-1.29,0-2.54-.52-3.45-1.43-1.4-1.4-3.5-1.82-5.32-1.06-.6.25-1.24.37-1.87.37v45.89h0Z"
    />
    <path
      fill="#ffee72"
      d="M55.25,13.44c-.55,0-1.11-.11-1.64-.33-1.6-.66-3.44-.3-4.66.93-.8.8-1.89,1.25-3.02,1.25-1.73,0-3.29,1.04-3.95,2.64-.43,1.05-1.27,1.88-2.31,2.31-1.6.66-2.64,2.22-2.64,3.95,0,1.13-.45,2.22-1.25,3.02-1.22,1.22-1.59,3.06-.93,4.66.43,1.05.43,2.22,0,3.27-.66,1.6-.3,3.44.93,4.66.8.8,1.25,1.89,1.25,3.02,0,1.73,1.04,3.29,2.64,3.95,1.05.43,1.88,1.27,2.31,2.31.66,1.6,2.22,2.64,3.95,2.64,1.14,0,2.22.45,3.02,1.25,1.22,1.22,3.06,1.59,4.66.93.52-.22,1.08-.33,1.64-.33V13.44Z"
    />
    <path
      fill="#ffe448"
      d="M55.25,53.59c.55,0,1.11.11,1.64.33,1.6.66,3.44.3,4.66-.93.8-.8,1.89-1.25,3.02-1.25,1.73,0,3.29-1.04,3.95-2.64.43-1.05,1.27-1.88,2.31-2.31,1.6-.66,2.64-2.22,2.64-3.95,0-1.14.45-2.22,1.25-3.02,1.22-1.22,1.59-3.06.93-4.66-.43-1.05-.43-2.22,0-3.27.66-1.6.3-3.44-.93-4.66-.8-.8-1.25-1.89-1.25-3.02,0-1.73-1.04-3.29-2.64-3.95-1.05-.43-1.88-1.27-2.31-2.31-.66-1.6-2.22-2.64-3.95-2.64-1.14,0-2.22-.45-3.02-1.25-1.22-1.22-3.06-1.59-4.66-.93-.52.22-1.08.33-1.64.33v40.15h0Z"
    />
    <path
      fill="#fff5b3"
      d="M55.25,25.52c-4.41,0-7.99,3.58-7.99,7.99s3.58,7.99,7.99,7.99v-15.99Z"
    />
    <path
      fill="#ffed97"
      d="M55.25,41.51c4.41,0,7.99-3.58,7.99-7.99s-3.58-7.99-7.99-7.99v15.99Z"
    />
    <path
      fill="#fcda4c"
      d="M54.18,16.59l.34,6.83c.02.39.34.7.73.7v-8.64c-.61,0-1.1.51-1.07,1.12Z"
    />
    <path
      fill="#fcda4c"
      d="M54.52,43.62l-.34,6.83c-.03.61.46,1.12,1.07,1.12v-8.64c-.39,0-.71.31-.73.7Z"
    />
    <path
      fill="#fcda4c"
      d="M48.6,26.86c-.28.28-.72.29-1.01.03l-5.06-4.59c-.45-.41-.47-1.11-.04-1.55.43-.43,1.14-.42,1.55.04l4.59,5.06c.26.29.25.74-.03,1.01Z"
    />
    <path
      fill="#fcda4c"
      d="M45.84,33.51c0,.39-.31.71-.7.73l-6.83.34c-.61.03-1.12-.46-1.12-1.07s.51-1.1,1.12-1.07l6.83.34c.39.02.7.34.7.73Z"
    />
    <path
      fill="#fcda4c"
      d="M48.6,40.16c.28.28.29.72.03,1.01l-4.59,5.06c-.41.45-1.11.47-1.55.04-.43-.43-.42-1.14.04-1.55l5.06-4.59c.29-.26.74-.25,1.01.03Z"
    />
    <path
      fill="#e8c531"
      d="M56.31,50.44l-.34-6.83c-.02-.39-.34-.7-.73-.7v8.64c.61,0,1.1-.51,1.07-1.12Z"
    />
    <path
      fill="#e8c531"
      d="M55.98,23.42l.34-6.83c.03-.61-.46-1.12-1.07-1.12v8.64c.39,0,.71-.31.73-.7Z"
    />
    <path
      fill="#e8c531"
      d="M61.9,40.16c.28-.28.72-.29,1.01-.03l5.06,4.59c.45.41.47,1.11.04,1.55-.43.43-1.14.42-1.55-.04l-4.59-5.06c-.26-.29-.25-.74.03-1.01Z"
    />
    <path
      fill="#e8c531"
      d="M64.65,33.51c0-.39.31-.71.7-.73l6.83-.34c.61-.03,1.12.46,1.12,1.07s-.51,1.1-1.12,1.07l-6.83-.34c-.39-.02-.7-.34-.7-.73Z"
    />
    <path
      fill="#e8c531"
      d="M61.9,26.86c-.28-.28-.29-.72-.03-1.01l4.59-5.06c.41-.45,1.11-.47,1.55-.04.43.43.42,1.14-.04,1.55l-5.06,4.59c-.29.26-.74.25-1.01-.03Z"
    />
    <path
      fill="#bf4a39"
      d="M8.37,59.97c0,13.37,10.37,24.2,23.17,24.2V24.31c-12.8,0-23.17,22.3-23.17,35.67h0Z"
    />
    <path
      fill="#a5291c"
      d="M55.68,59.97c0-13.37-10.81-35.67-24.14-35.67v59.86c13.33,0,24.14-10.83,24.14-24.2h0Z"
    />
    <path
      fill="#ffc22f"
      d="M10.3,60.37c0,12.08,9.51,21.87,21.24,21.87V27.2c-11.73,0-21.24,21.08-21.24,33.16Z"
    />
    <path
      fill="#eda400"
      d="M53.75,60.37c0-12.08-9.94-33.16-22.21-33.16v55.03c12.27,0,22.21-9.79,22.21-21.87Z"
    />
    <path
      fill="#bf4a39"
      d="M79.02,34.71c-2.39-2.07-5.1-3.15-7.94-3.15-4.4,0-8.47,2.59-11.47,7.3-2.81,4.43-4.36,10.28-4.36,16.47,0,13.11,10.66,23.77,23.77,23.77v-44.39Z"
    />
    <path
      fill="#a5291c"
      d="M79.02,34.71c2.39-2.07,5.1-3.15,7.94-3.15,4.4,0,8.47,2.59,11.47,7.3,2.81,4.43,4.36,10.28,4.36,16.47,0,13.11-10.66,23.77-23.77,23.77v-44.39Z"
    />
    <path
      fill="#ffc22f"
      d="M71.08,33.53c-7.65,0-13.85,9.76-13.85,21.79s9.76,21.79,21.79,21.79v-39.64c-2.25-2.48-4.98-3.94-7.94-3.94h0Z"
    />
    <path
      fill="#eda400"
      d="M86.95,33.53c7.65,0,13.85,9.76,13.85,21.79s-9.76,21.79-21.79,21.79v-39.64c2.25-2.48,4.98-3.94,7.94-3.94h0Z"
    />
    <path
      fill="#ff7a34"
      d="M79.02,40.83c-5.44,1.42-9.51,7.37-9.51,14.49s4.08,13.08,9.51,14.49v-28.99Z"
    />
    <path
      fill="#dd5b22"
      d="M79.02,69.82c5.44-1.42,9.51-7.37,9.51-14.49s-4.08-13.08-9.51-14.49v28.99Z"
    />
    <path
      fill="#d14779"
      d="M31.48,77.78c0,13.13,10.64,23.77,23.77,23.77v-47.54c-13.13,0-23.77,10.64-23.77,23.77Z"
    />
    <path
      fill="#a3164f"
      d="M79.02,77.78c0-13.13-10.64-23.77-23.77-23.77v47.54c13.13,0,23.77-10.64,23.77-23.77Z"
    />
    <path
      fill="#fffdff"
      d="M33.44,77.78c0,12.05,9.77,21.81,21.81,21.81v-43.62c-12.05,0-21.81,9.77-21.81,21.81Z"
    />
    <path
      fill="#efddeb"
      d="M77.06,77.78c0-12.05-9.77-21.81-21.81-21.81v43.62c12.05,0,21.81-9.77,21.81-21.81Z"
    />
    <path
      fill="#ffc22f"
      d="M37.06,77.78c0,10.04,8.14,18.19,18.19,18.19v-36.37c-10.04,0-18.19,8.14-18.19,18.19h0Z"
    />
    <path
      fill="#eda400"
      d="M73.43,77.78c0-10.04-8.14-18.19-18.19-18.19v36.37c10.04,0,18.19-8.14,18.19-18.19h0Z"
    />
    <path
      fill="#aa9103"
      d="M52.22,65.66c0,1.67,1.36,5.76,3.03,5.76v-8.79c-1.67,0-3.03,1.36-3.03,3.03Z"
    />
    <path
      fill="#aa9103"
      d="M52.22,89.55c0,1.67,1.36,3.03,3.03,3.03v-8.79c-1.67,0-3.03,4.09-3.03,5.76Z"
    />
    <path
      fill="#aa9103"
      d="M48.94,67.02c1.18,1.18,3.12,5.04,1.93,6.22-1.18,1.18-5.04-.75-6.22-1.93s-1.18-3.1,0-4.28c1.18-1.18,3.1-1.18,4.28,0Z"
    />
    <path
      fill="#aa9103"
      d="M43.3,74.58c1.67,0,5.77,1.36,5.77,3.03s-4.09,3.03-5.77,3.03-3.03-1.36-3.03-3.03,1.36-3.03,3.03-3.03Z"
    />
    <path
      fill="#aa9103"
      d="M44.66,83.91c1.18-1.18,5.04-3.12,6.22-1.93,1.18,1.18-.75,5.04-1.93,6.22-1.18,1.18-3.1,1.18-4.28,0-1.18-1.18-1.18-3.1,0-4.28Z"
    />
    <path
      fill="#846e00"
      d="M58.28,89.55c0-1.67-1.36-5.76-3.03-5.76v8.79c1.67,0,3.03-1.36,3.03-3.03Z"
    />
    <path
      fill="#846e00"
      d="M58.28,65.66c0-1.67-1.36-3.03-3.03-3.03v8.79c1.67,0,3.03-4.09,3.03-5.76Z"
    />
    <path
      fill="#846e00"
      d="M61.55,88.2c-1.18-1.18-3.12-5.04-1.93-6.22s5.04.75,6.22,1.93c1.18,1.18,1.18,3.1,0,4.28-1.18,1.18-3.1,1.18-4.28,0Z"
    />
    <path
      fill="#846e00"
      d="M67.19,80.64c-1.67,0-5.77-1.36-5.77-3.03s4.09-3.03,5.77-3.03,3.03,1.36,3.03,3.03-1.36,3.03-3.03,3.03Z"
    />
    <path
      fill="#846e00"
      d="M65.84,71.3c-1.18,1.18-5.04,3.12-6.22,1.93-1.18-1.18.75-5.04,1.93-6.22,1.18-1.18,3.1-1.18,4.28,0,1.18,1.18,1.18,3.1,0,4.28Z"
    />
  </SvgIcon>
);

export default IconAdditiveTropicalEnergy;
