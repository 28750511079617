import IconProps from './IconProps';
import SvgIcon from './SvgIcon';

const IconEnlarge: React.FC<IconProps> = ({ className, width, height }) => (
  <SvgIcon
    className={className}
    width={width}
    height={height}
    viewBox='0 0 24 24'
    fill='none'
    strokeWidth='2'
    strokeLinecap='round'
    strokeLinejoin='round'
  >
    <path d='M3.8 3.8l16.4 16.4M20.2 3.8L3.8 20.2M15 3h6v6M9 3H3v6M15 21h6v-6M9 21H3v-6' />
  </SvgIcon>
);

export default IconEnlarge;
