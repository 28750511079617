import { configureStore, EnhancedStore, Action } from '@reduxjs/toolkit';
import thunk, { ThunkAction } from 'redux-thunk';
import authReducers from './reducers/authReducers';
import userReducers from './reducers/userReducers';
import kioskReducers from './reducers/kioskReducers';
import errorReducers from './reducers/errorReducers';
import orderReducers from './reducers/orderReducers';
import overviewReducers from './reducers/overviewReducers';
import inventoryReducers from './reducers/inventoryReducers';
import userInteractionsReducers from './reducers/userInteractionsReducers';
import orderCreateReducers from './reducers/orderCreateReducers';
import PropagateReducer from './reducers/PropagateReducer';
import miscTestsReducers from './reducers/miscTestsReducers';
import userProfileReducers from './reducers/userProfileReducers';
import reportReducers from './reducers/reportReducers';

const preloadedState = {};

const middleware = [thunk];

const reducer = {
  auth: authReducers,
  all_users: userReducers,
  kiosk: kioskReducers,
  errors: errorReducers,
  all_orders: orderReducers,
  dashboard_metrics: overviewReducers,
  inventory_list: inventoryReducers,
  user_interactions_list: userInteractionsReducers,
  create_order: orderCreateReducers,
  kioskDetails: PropagateReducer,
  misc: miscTestsReducers,
  user_profile: userProfileReducers,
  reports: reportReducers,
};

export const store: EnhancedStore = configureStore({
  reducer,
  middleware,
  devTools: process.env.NODE_ENV !== 'production',
  preloadedState,
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;


export default store;
