import { useContext } from 'react';
import { KioskSettingsContext } from '../KioskSettingsContext';
import KioskEditStrings from '../../KioskEditStrings';
import KioskMonitorReport from '../../../../../hooks/useDeviceMonitor/KioskMonitorReport';
import BottleInventoryControl from './BottleInventoryControl';
import { InventoryControlSettings } from './InventoryControlSettings';
import CO2InventoryControl from './CO2InventoryControl';

const KioskInventoryControl: React.FC = () => {
  const kioskSettings = useContext(KioskSettingsContext);

  const message = (deviceStatus?: KioskMonitorReport, inventoryControl?: InventoryControlSettings) => {
    if (deviceStatus?.loaded && !deviceStatus.online) {
      return `${KioskEditStrings.KioskOffline}, cannot edit ${KioskEditStrings.InventoryControlLabel}`;
    }
    if (deviceStatus?.ready && !inventoryControl) {
      return `No ${KioskEditStrings.InventoryControlLabel} retrieved from kiosk`;
    }
  };

  return (
    <div>
      <h5 style={{ fontWeight: '600', fontSize: '16px', marginBottom: '0px' }}>
        {KioskEditStrings.InventoryControlLabel}
      </h5>
      <p style={{ margin: '0px' }}>
        {message(kioskSettings?.deviceStatus, kioskSettings?.state.settings?.inventoryControl)}
      </p>
      <BottleInventoryControl />
      <CO2InventoryControl />
    </div>
  );
};

export default KioskInventoryControl;
