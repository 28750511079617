import { useContext } from 'react';
import { UserAccountContext } from '../../UserAccountContext';
import { Card } from 'react-bootstrap';
import AccountEditStrings from '../AccountEditStrings';
import HourOfDaySelect from './HourOfDaySelect';
import CheckboxList from '../../../../components/Form/Input/CheckboxList';
import DayOfWeek from '../../../../common/DayOfWeek';
import ToggleButton from '../../../../components/Button/ToggleButton';

const KioskOrderReportsPreferences: React.FC = () => {
  const userAccount = useContext(UserAccountContext);
  const notificationPreferences = userAccount?.state.user?.notificationPreferences;
  return (
    <div style={{ padding: '10px 0'}}>
      {notificationPreferences?.kioskOrderSummary && (
        <ToggleButton
          label={AccountEditStrings.ReportsOrdersTitle}
          enabled={notificationPreferences?.kioskOrderSummary.enabled}
          onToggle={(enabled) => userAccount?.handleKioskOrderReportChange('enabled', enabled)}
          description={
            <>
              {AccountEditStrings.ReportsOrdersDescription}: <strong>{userAccount?.state.user?.email}</strong>
            </>
          }
        />
      )}
      {notificationPreferences?.kioskOrderSummary.enabled && notificationPreferences?.kioskOrderSummary && (
        <>
          <Card.Subtitle style={{ fontWeight: '600', fontSize: '14px', marginTop: '3%' }}>
            {AccountEditStrings.HourOfDayLabel}
          </Card.Subtitle>
          <HourOfDaySelect
            value={notificationPreferences?.kioskOrderSummary.hourOfDay}
            onChange={(hour) => userAccount?.handleKioskOrderReportChange('hourOfDay', hour)}
            name='hourOfDay'
            label=''
          />
          <Card.Subtitle style={{ fontWeight: '600', fontSize: '14px', marginTop: '3%' }}>
            {AccountEditStrings.DayOfWeekLabel}
          </Card.Subtitle>
          <CheckboxList
            items={Object.values(DayOfWeek)}
            onFilterClick={(kioskId) => userAccount?.handleKioskOrderReportChange('dayOfWeek', kioskId)}
            checkedItems={[notificationPreferences?.kioskOrderSummary.dayOfWeek]}
          />
        </>
      )}
    </div>
  );
};
export default KioskOrderReportsPreferences;
