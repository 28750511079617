import ConfigService from '../ConfigService';
import ApiService from '../HttpClient/ApiService';
import ICompany from './ICompany';

export default class CompanyService {
  apiService: ApiService;

  constructor() {
    this.apiService = new ApiService(ConfigService.apiHost);
  }

  getCompanies = async (): Promise<ICompany[]> => {
    const companies = await this.apiService.request('companies', 'GET');
    return companies as unknown as ICompany[];
  };
}
