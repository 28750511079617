import React from 'react';
import { LatestOrder } from '../../../services/Reports/IKiosksHealthReport';
import OrderType from '../../../common/OrderType';

interface ILatestOrdersList {
  latestOrders: LatestOrder[];
}

const LatestOrdersList: React.FC<ILatestOrdersList> = ({ latestOrders }) => {
  if (!latestOrders || latestOrders.length === 0) {
    return <p>No orders found</p>;
  }

  const ordersByType = latestOrders.reduce((acc, order) => {
    acc[order.type] = acc[order.type] || [];
    acc[order.type].push(order.createdAt);
    return acc;
  }, {} as Record<OrderType, string[]>);

  return (
    <ul>
      {Object.entries(ordersByType).map(([type, dates]) =>
        dates.map((date, index) => (
          <li key={index}>
            <strong>{type}:</strong> {new Date(date).toLocaleString()}*
          </li>
        ))
      )}
    </ul>
  );
};

export default LatestOrdersList;
