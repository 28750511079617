import { useCallback, useEffect, useState } from 'react';
import ChatWidget, { ChatParticipant, ChatStatus } from '../../../../components/Chat/ChatWidget/ChatWidget';
import IncidentReportStrings from '../IncidentReportStrings';
import IMessage from '../../../../common/Notifications/IMessage';
import useReports from '../../../../services/Reports/useReports';
import INotification from '../../../../services/Notifications/INotification';
import NotificationMedium from '../../../../services/Notifications/NotificationMedium';
import ChatStrings from '../../../../components/Chat/ChatStrings';
import { EventNotification } from '../IncidentReportData';
import IncidentResolutionStatus from '../../../Reports/IncidentReport/IncidentResolutionStatus';
import IncidentMessagesControls from './IncidentMessagesControls';
import { parseParticipantsByMedium } from '../../../../components/Chat/ChatMessageConfiguration/parseChatParticipantsByMedium';

interface IIncidentMessages {
  displayChatWidget: boolean;
  toggleChatWidget: () => void;
  correlationId?: string;
  incidentId?: string;
  initialIncidentNotification?: EventNotification;
  onResolutionChange: (resolutionStatus: IncidentResolutionStatus) => void;
  incidentResolution?: IncidentResolutionStatus;
  kioskId?: string;
}

type IncidentMessagesState = {
  status: ChatStatus;
  failedMessage?: { message: string; recipients: ChatParticipant[] };
  error?: string;
  changeResolutionOnSend?: boolean;
  resolutionStatus?: IncidentResolutionStatus;
};

const formattedNotificationsAsMessages = (notifications: INotification[]): IMessage[] =>
  notifications.map((notification) => ({
    id: notification.id,
    senderName: notification.senderName,
    content: notification.message,
    timestamp: notification.createdAt,
    origin: notification.origin,
    medium: notification.medium,
    sendReport: notification.sendReport,
    recipients: notification.recipients,
    senderCompany: notification.senderCompany
  }));

const IncidentMessages: React.FC<IIncidentMessages> = ({
  displayChatWidget,
  toggleChatWidget,
  correlationId,
  incidentId,
  initialIncidentNotification,
  onResolutionChange,
  incidentResolution,
  kioskId
}) => {
  const [messages, setMessages] = useState<IMessage[]>();
  const [state, setState] = useState<IncidentMessagesState>({
    status: ChatStatus.Initializing,
    changeResolutionOnSend: false
  });
  const { getIncidentNotifications, sendIncidentNotification, updateIncidentResolution } = useReports();

  const chatParticipants: ChatParticipant[] = [
    ...(initialIncidentNotification?.notificationList ?? []).map((email) => ({ email })),
    ...(initialIncidentNotification?.phoneNumberList ?? []).map((phone) => ({ phone }))
  ];

  const getNotifications = useCallback(
    async (correlationId: string) => {
      try {
        const notifications = await getIncidentNotifications(correlationId, [
          NotificationMedium.Email,
          NotificationMedium.SMS
        ]);
        setMessages(formattedNotificationsAsMessages(notifications));
        setState((prev) => ({ ...prev, status: ChatStatus.Ready }));
      } catch (error) {
        console.error(ChatStrings.GetNotificationsError, error);
      }
    },
    [getIncidentNotifications]
  );

  const handleSendMessage = async (message: string, recipients: ChatParticipant[]) => {
    try {
      if (!correlationId || !incidentId || !kioskId) {
        throw new Error('Missing all data required to send message. Check logs for details.');
      }
      setState((prev) => ({ ...prev, status: ChatStatus.Sending, error: undefined, failedMessage: undefined }));
      const { changeResolutionOnSend, resolutionStatus } = state;
      if (changeResolutionOnSend && resolutionStatus) {
        await updateIncidentResolution(correlationId, resolutionStatus);
        onResolutionChange(resolutionStatus);
      }
      const newNotifications = await sendIncidentNotification(
        correlationId,
        message,
        incidentId,
        [NotificationMedium.Email, NotificationMedium.SMS],
        {
          emailList: parseParticipantsByMedium(recipients, 'email'),
          phoneNumberList: parseParticipantsByMedium(recipients, 'phone')
        },
        kioskId
      );

      const newMessages = formattedNotificationsAsMessages(newNotifications);
      setMessages((prevMessages) => (prevMessages ? [...prevMessages, ...newMessages] : newMessages));
    } catch (error) {
      const errorString = `${ChatStrings.SendNotificationError}: ${
        error instanceof Error ? error.message : `${error}`
      }`;
      setState((prev) => ({
        ...prev,
        error: errorString,
        failedMessage: { message, recipients }
      }));
      console.error(ChatStrings.SendNotificationError, error);
    } finally {
      setState((prev) => ({ ...prev, status: ChatStatus.Ready }));
    }
  };

  useEffect(() => {
    if (correlationId && displayChatWidget) getNotifications(correlationId);
  }, [correlationId, displayChatWidget, getNotifications]);

  const handleClearFailedMessage = () => {
    setState((prev) => ({ ...prev, failedMessage: undefined, error: undefined }));
  };

  useEffect(() => {
    if (incidentResolution) setState((prev) => ({ ...prev, resolutionStatus: incidentResolution }));
  }, [incidentResolution]);

  const handleToggleResolutionOnSend = (enable: boolean) => {
    setState((prev) => ({ ...prev, changeResolutionOnSend: enable }));
  };

  const handleResolutionStatusChange = (value: IncidentResolutionStatus) => {
    setState((prev) => ({ ...prev, resolutionStatus: value }));
  };

  return (
    <ChatWidget
      open={displayChatWidget}
      onClose={toggleChatWidget}
      heading={IncidentReportStrings.ChatWidgetHeading}
      description={IncidentReportStrings.ChatWidgetDescription}
      messages={messages}
      participants={chatParticipants}
      onSendMessage={handleSendMessage}
      status={state.status}
      failedMessage={state.failedMessage}
      error={state.error}
      onClearFailedMessage={handleClearFailedMessage}
      messageControls={
        <IncidentMessagesControls
          changeResolutionOnSend={Boolean(state.changeResolutionOnSend)}
          resolutionStatus={state.resolutionStatus}
          onChangeResolutionStatus={handleResolutionStatusChange}
          onToggleResolutionOnSend={handleToggleResolutionOnSend}
        />
      }
    />
  );
};

export default IncidentMessages;
