import ValidatePasswordStrings from './ValidatePasswordStrings';

const validatePassword = (password: string) => {
  const errors: ValidatePasswordStrings[] = [];
  const hasEightCharacters = /.{8,}/.test(password);
  const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);
  const hasNumber = /\d/.test(password);
  const hasUppercase = /[A-Z]/.test(password);
  const hasLowercase = /[a-z]/.test(password);

  if (!hasEightCharacters) {
    errors.push(ValidatePasswordStrings.EightCharacters);
  }

  if (!hasSpecialChar) {
    errors.push(ValidatePasswordStrings.SpecialChar);
  }

  if (!hasNumber) {
    errors.push(ValidatePasswordStrings.Number);
  }

  if (!hasUppercase) {
    errors.push(ValidatePasswordStrings.Uppercase);
  }

  if (!hasLowercase) {
    errors.push(ValidatePasswordStrings.Lowercase);
  }

  if (errors.length > 0) {
    throw new Error(`Password must contain at least: ${errors.join(', ')}`);
  }
};

export default validatePassword;
