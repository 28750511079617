import { TextField, Checkbox, FormControlLabel } from '@material-ui/core';
import EnumSelectInput from '../../../../components/Form/Input/EnumSelectInput';
import formatFieldName from '../../../../utils/formatFieldName';
import { FieldConfig } from './KioskSettingsFieldConfig';

interface IFieldRenderer<T> {
  field: FieldConfig<T>;
  value: any;
  onChange: (key: keyof T, value: any) => void;
  disabled?: boolean;
  labelOverride?: string;
  inputProps?: {
    step?: string;
  };
}

const FieldRenderer = <T,>({ field, value, onChange, disabled, labelOverride, inputProps }: IFieldRenderer<T>) => {
  if (field.type === 'enum' && field.enumValues) {
    return (
      <EnumSelectInput
        value={value}
        onChange={(val) => onChange(field.key, val)}
        enumValues={field.enumValues}
        label={labelOverride ? labelOverride : formatFieldName(String(field.key))}
        disabled={disabled}
      />
    );
  }

  if (field.type === 'boolean') {
    return (
      <FormControlLabel
        control={
          <Checkbox
            checked={!!value}
            onChange={(e) => onChange(field.key, e.target.checked)}
            name={String(field.key)}
          />
        }
        label={labelOverride ? labelOverride : formatFieldName(String(field.key))}
        style={{ color: '#333' }}
        disabled={disabled}
      />
    );
  }

  if (field.type === 'number') {
    return (
      <TextField
        size='small'
        variant='outlined'
        fullWidth
        required={field.required}
        label={labelOverride ? labelOverride : formatFieldName(String(field.key))}
        value={value === undefined ? '' : value}
        onChange={(e) => onChange(field.key, +e.target.value)}
        style={{ margin: '10px 0' }}
        inputProps={{ step: inputProps?.step ?? '0.01', style: { padding: '0 10px' } }}
        type='number'
        disabled={disabled}
      />
    );
  }

  return (
    <TextField
      size='small'
      variant='outlined'
      fullWidth
      required={field.required}
      label={labelOverride ? labelOverride : formatFieldName(String(field.key))}
      value={value || ''}
      onChange={(e) => onChange(field.key, e.target.value)}
      style={{ margin: '10px 0' }}
      type='text'
      disabled={disabled}
      inputProps={{ style: { padding: '0 10px' } }}
    />
  );
};

export default FieldRenderer;
