import React from 'react';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { ChatParticipant } from '../ChatWidget/ChatWidget';
import styles from './ChatMessageConfiguration.module.css';
import { parseParticipantsByMedium } from './parseChatParticipantsByMedium';
import IconButton from '../../Button/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import ChatStrings from '../ChatStrings';

interface IChatMessageConfigurationControl {
  participants: ChatParticipant[];
  selectedRecipients: ChatParticipant[];
  onRecipientChange: (updatedParticipants: ChatParticipant[]) => void;
  display: boolean;
  onClose: () => void;
}

const ChatMessageConfigurationControl: React.FC<IChatMessageConfigurationControl> = ({
  participants,
  selectedRecipients,
  onRecipientChange,
  display,
  onClose
}) => {
  const handleCheckboxChange = (participant: ChatParticipant) => {
    const isSelected = selectedRecipients.some((p) => p.email === participant.email && p.phone === participant.phone);
    const updatedSelected = isSelected
      ? selectedRecipients.filter((p) => p.email !== participant.email || p.phone !== participant.phone)
      : [...selectedRecipients, participant];

    onRecipientChange(updatedSelected);
  };

  const renderParticipantList = (list: string[], label: string, key: keyof ChatParticipant) =>
    list.length > 0 && (
      <div>
        <h6>{label}</h6>
        {participants
          .filter((p) => p[key])
          .map((participant) => (
            <FormControlLabel
              key={participant[key] as string}
              control={
                <Checkbox
                  checked={selectedRecipients.some((p) => p[key] === participant[key])}
                  onChange={() => handleCheckboxChange(participant)}
                  name={participant[key] as string}
                />
              }
              label={participant[key] as string}
            />
          ))}
      </div>
    );

  const smsList = parseParticipantsByMedium(participants, 'phone');
  const emailList = parseParticipantsByMedium(participants, 'email');

  return (
    <div className={`${styles.ChatMessageConfigurationControl} ${display ? styles.show : styles.hide}`}>
      <div className={styles.container}>
        <div className={styles.header}>
          <h5>{ChatStrings.MessageConfigureHeading}</h5>
          <div className={styles.closeButton}>
            <IconButton onClick={onClose} icon={<CloseIcon />} label='Close' />
          </div>
        </div>
        <div className={styles.content}>
          {renderParticipantList(smsList, 'SMS Recipients', 'phone')}
          {renderParticipantList(emailList, 'Email Recipients', 'email')}
        </div>
      </div>
    </div>
  );
};

export default ChatMessageConfigurationControl;
