import React, { useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { List, ListItem, ListItemIcon, ListItemText, Collapse } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import AuthGuard from '../auth/AuthGuard';
import { useAppSelector } from '../../hooks/storeHooks';
import MenuItem from './MenuItem';

interface INavMenuItems {
  items: MenuItem[];
}

const NavMenuItems: React.FC<INavMenuItems> = ({ items = [] }) => {
  const location = useLocation();
  const user = useAppSelector((store) => store.auth?.user);
  const [openSubMenu, setOpenSubMenu] = useState<string | null>(null);

  const handleSubMenuClick = (label: string) => {
    setOpenSubMenu(openSubMenu === label ? null : label);
  };

  if (items.length === 0) return null;
  return (
    <List>
      {items.map(({ icon, label, path, authorizedRoles, onClick, subItems }) => (
        <AuthGuard authorizedRoles={authorizedRoles} userRole={user?.role} key={`${path}-${label}`}>
          {subItems ? (
            <div key={label}>
              <ListItem button onClick={() => handleSubMenuClick(label)}>
                <ListItemIcon style={{ color: location.pathname === path ? '#ffffff' : '#00d2fa' }}>
                  {icon}
                </ListItemIcon>
                <ListItemText primary={label} />
                {openSubMenu === label ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={openSubMenu === label} timeout='auto' unmountOnExit>
                <List component='div' disablePadding>
                  {subItems.map((subItem) => (
                    <Link key={subItem.label} to={subItem.path!} style={{ textDecoration: 'none', color: 'inherit' }}>
                      <ListItem
                        button
                        style={{
                          color: '#000000',
                          backgroundColor: location.pathname === subItem.path ? '#00d2fa' : '#ffffff',
                          paddingLeft: '2em'
                        }}
                      >
                        <ListItemIcon style={{ color: location.pathname === subItem.path ? '#ffffff' : '#00d2fa' }}>
                          {subItem.icon}
                        </ListItemIcon>
                        <ListItemText
                          primary={subItem.label}
                          style={{ color: location.pathname === subItem.path ? '#ffffff' : '#000000' }}
                        />
                      </ListItem>
                    </Link>
                  ))}
                </List>
              </Collapse>
            </div>
          ) : (
            <Link key={label} to={path!} style={{ textDecoration: 'none', color: 'inherit' }}>
              <ListItem
                button
                onClick={onClick}
                style={{
                  color: '#000000',
                  backgroundColor: location.pathname === path ? '#00d2fa' : '#ffffff'
                }}
              >
                <ListItemIcon style={{ color: location.pathname === path ? '#ffffff' : '#00d2fa' }}>
                  {icon}
                </ListItemIcon>
                <ListItemText primary={label} style={{ color: location.pathname === path ? '#ffffff' : '#000000' }} />
              </ListItem>
            </Link>
          )}
        </AuthGuard>
      ))}
    </List>
  );
};

export default NavMenuItems;
