import { useContext } from 'react';
import { Card } from 'react-bootstrap';
import { UserAccountContext } from './UserAccountContext';
import useKiosksData from '../../services/Kiosk/useKiosks';
import CheckboxList from '../../components/Form/Input/CheckboxList';
import UserRole from '../../common/UserRole';
import IconLoading from '../../components/ui/Icons/IconLoading';

interface IKiosksSelect {
  companyId?: string;
  description: string;
}

const KiosksSelect: React.FC<IKiosksSelect> = ({ companyId, description }) => {
  const userAccount = useContext(UserAccountContext);
  const kiosks = useKiosksData({ companyOnlyKiosks: true, companyObjectId: companyId });
  const noKiosks = kiosks && kiosks.length === 0;
  const noCompany = companyId === '' || !companyId;
  const applicableRoles: UserRole[] = [UserRole.User, UserRole.MachineAdmin];
  if (!userAccount?.state?.user?.role || !applicableRoles.includes(userAccount?.state?.user?.role)) return null;

  return (
    <>
      <Card.Title>Kiosk Selection</Card.Title>
      <Card.Body style={{ padding: '10px 0' }}>{description}</Card.Body>
      {companyId && !kiosks && <IconLoading width={30} height={30} />}
      {noCompany && (
        <Card.Body style={{ padding: '10px 0' }}>
          <strong>Select a company to assign kiosks</strong>
        </Card.Body>
      )}
      {noKiosks && (
        <Card.Body style={{ padding: '10px 0' }}>
          <strong>No kiosks found for company {companyId}</strong>
        </Card.Body>
      )}
      {kiosks && userAccount?.state?.user?.kioskIds && (
        <CheckboxList
          items={kiosks?.map((kiosk) => kiosk.kiosk_ID)}
          onFilterClick={(kioskId) => userAccount?.handleUserDataArrayChange('kioskIds', kioskId)}
          checkedItems={userAccount?.state?.user?.kioskIds}
        />
      )}
    </>
  );
};

export default KiosksSelect;
