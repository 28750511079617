import { Checkbox, FormControlLabel } from '@material-ui/core';
import IncidentResolutionStatusSelect from '../IncidentResolutionStatusSelect';
import IncidentResolutionStatus from '../IncidentResolutionStatus';

interface IIncidentMessagesControls {
  changeResolutionOnSend: boolean;
  resolutionStatus?: IncidentResolutionStatus;
  onChangeResolutionStatus: (value: IncidentResolutionStatus) => void;
  onToggleResolutionOnSend: (checked: boolean) => void;
}

const IncidentMessagesControls: React.FC<IIncidentMessagesControls> = ({
  changeResolutionOnSend,
  resolutionStatus,
  onChangeResolutionStatus,
  onToggleResolutionOnSend
}) => {
  return (
    <div>
      <FormControlLabel
        control={
          <Checkbox
            checked={changeResolutionOnSend}
            onChange={(e) => {
              e.persist();
              onToggleResolutionOnSend(e.target.checked);
            }}
            name='change-resolution'
          />
        }
        label='Change Resolution'
        name='change-resolution'
        style={{ flexShrink: 0, fontSize: '0.875rem' }}
      />
      {changeResolutionOnSend && resolutionStatus && (
        <IncidentResolutionStatusSelect
          value={resolutionStatus}
          onChange={onChangeResolutionStatus}
          label=''
          fullWidth={false}
        />
      )}
    </div>
  );
};

export default IncidentMessagesControls;
