import { useState } from 'react';
import LightColor from '../../../../common/Peripherals/Lighting/LightColor';

type RGB = [red: number, green: number, blue: number];

interface IRGBInput {
  argumentName: string;
  onChange: (rgb: RGB) => void;
}

const RGBInput: React.FC<IRGBInput> = ({ argumentName, onChange }) => {
  const [rgbValues, setRgbValues] = useState<RGB>(LightColor.black);
  const rgbLabels: string[] = ['red', 'green', 'blue'];

  const updateRgbValue = (position: number, value: number) => {
    const updatedRgbValues = [...rgbValues];
    updatedRgbValues[position] = value;
    setRgbValues(updatedRgbValues as RGB);
    onChange(updatedRgbValues as RGB);
  };

  return (
    <div>
      {rgbLabels.map((color, i) => (
        <input
          key={`${argumentName}-${color}`}
          type='number'
          min={0}
          max={255}
          placeholder={color}
          value={rgbValues[i]}
          onChange={(e) => updateRgbValue(i, parseInt(e.target.value, 10) || 0)}
        />
      ))}
    </div>
  );
};

export default RGBInput;
