enum GantrySequence {
  Home = 0,
  HomeZ = 1,
  HomeX = 2,
  MoveToCapperToCap = 3,
  MoveToCapperToUncap = 4,
  DeliverBottle = 5,
  MoveToTower1GrabPosition = 6,
  MoveToTower2GrabPosition = 7,
  MoveToTower3GrabPosition = 8,
  GrabBottleFromTower = 9,
  CapMotion = 10,
  UncapMotion = 11,
  FlapClose = 12,
  ProbeBottle = 13,
  GarbageBottle = 14,
  DoubleBottleCheck = 15,
  MoveZToProbeClear = 16,
  MoveToFiller = 17,
  MoveToCapperBelowUncap = 18,
  ClearCapper = 19,
  ClearSlider = 20,
  Unassigned = 255,
}

export default GantrySequence;
