import ConfigService from '../ConfigService';
import ApiService from '../HttpClient/ApiService';
import ApiUtility from '../HttpClient/ApiUtility';
import IOverviewQuery from './IOverviewQuery';
import OverviewResponse from './OverviewResponse';

export default class OverviewService {
  apiService: ApiService;

  constructor() {
    this.apiService = new ApiService(ConfigService.apiHost);
  }

  getOverview = async (query: IOverviewQuery): Promise<OverviewResponse> => {
    const params = new URLSearchParams();
    params.append('startDate', query.startDate);
    params.append('endDate', query.endDate);
    ApiUtility.appendArrayToParams(params, 'kiosks', query.kiosks);
    ApiUtility.appendArrayToParams(params, 'companies', query.companies);
    ApiUtility.appendArrayToParams(params, 'orderTypes', query.orderTypes);
    ApiUtility.appendArrayToParams(params, 'transactionTypes', query.transactionTypes);
    const overviewResponse = await this.apiService.request('overview', 'GET', params);
    return overviewResponse as unknown as OverviewResponse;
  };
}
