import React from 'react';
import SvgIcon from './SvgIcon';
import IIconAdditive from './IIconAdditive';

const IconAdditiveYuzuGinger: React.FC<IIconAdditive> = ({ className }) => (
  <SvgIcon
    className={className}
    width="112"
    height="112"
    fill="none"
    viewBox="0 0 112 112"
  >
    <g>
      <g>
        <path
          fill="#e9f45b"
          strokeWidth="0px"
          d="M8.34,57.54c0,25.07,20.32,45.39,45.39,45.39V12.14c-25.07,0-45.39,20.32-45.39,45.39Z"
        />
        <path
          fill="#e1e52e"
          strokeWidth="0px"
          d="M99.12,57.54c0-25.07-20.32-45.39-45.39-45.39v90.78c25.07,0,45.39-20.32,45.39-45.39h0Z"
        />
        <path
          fill="#fffea3"
          strokeWidth="0px"
          d="M14.89,57.54c0,21.45,17.39,38.84,38.84,38.84V18.7c-21.45,0-38.84,17.39-38.84,38.84Z"
        />
        <path
          fill="#f9fb86"
          strokeWidth="0px"
          d="M92.57,57.54c0-21.45-17.39-38.84-38.84-38.84v77.68c21.45,0,38.84-17.39,38.84-38.84h0Z"
        />
        <path
          fill="#fffff3"
          strokeWidth="0px"
          d="M28.29,57.54c0,14.05,11.4,25.45,25.45,25.45v-50.9c-14.05,0-25.45,11.4-25.45,25.45Z"
        />
        <path
          fill="#fefdda"
          strokeWidth="0px"
          d="M79.18,57.54c0-14.05-11.4-25.45-25.45-25.45v50.9c14.05,0,25.45-11.4,25.45-25.45Z"
        />
      </g>
      <path
        fill="#e9f45b"
        strokeWidth="0px"
        d="M26.44,40.69l10.44-10.44c.88-.88,2.38-.58,2.86.57l7.38,17.82c.6,1.44-.84,2.88-2.29,2.29l-17.82-7.38c-1.15-.48-1.45-1.97-.57-2.86h0Z"
      />
      <path
        fill="#e9f45b"
        strokeWidth="0px"
        d="M24.94,48.54l17.82,7.38c1.44.6,1.44,2.64,0,3.24l-17.82,7.38c-1.15.48-2.42-.37-2.42-1.62v-14.76c0-1.25,1.27-2.1,2.42-1.62h0Z"
      />
      <path
        fill="#e9f45b"
        strokeWidth="0px"
        d="M27.01,71.53l17.82-7.38c1.44-.6,2.88.84,2.29,2.29l-7.38,17.82c-.48,1.15-1.97,1.45-2.86.57l-10.44-10.44c-.88-.88-.58-2.38.57-2.86h0Z"
      />
      <path
        fill="#e9f45b"
        strokeWidth="0px"
        d="M53.73,26.33h-7.38c-1.25,0-2.1,1.27-1.62,2.42l7.38,17.82c.3.72.96,1.08,1.62,1.08,0,0,0-21.32,0-21.32Z"
      />
      <path
        fill="#e9f45b"
        strokeWidth="0px"
        d="M53.73,67.43c-.66,0-1.32.36-1.62,1.08l-7.38,17.82c-.48,1.15.37,2.42,1.62,2.42h7.38v-21.32Z"
      />
      <g>
        <path
          fill="#e1e52e"
          strokeWidth="0px"
          d="M62.63,64.15l17.82,7.38c1.15.48,1.45,1.97.57,2.86l-10.44,10.44c-.88.88-2.38.58-2.86-.57l-7.38-17.82c-.6-1.44.84-2.88,2.29-2.29Z"
        />
        <path
          fill="#e1e52e"
          strokeWidth="0px"
          d="M64.7,55.92l17.82-7.38c1.15-.48,2.42.37,2.42,1.62v14.76c0,1.25-1.27,2.1-2.42,1.62l-17.82-7.38c-1.44-.6-1.44-2.64,0-3.24h0Z"
        />
        <path
          fill="#e1e52e"
          strokeWidth="0px"
          d="M70.58,30.25l10.44,10.44c.88.88.58,2.38-.57,2.86l-17.82,7.38c-1.44.6-2.88-.84-2.29-2.29l7.38-17.82c.48-1.15,1.97-1.45,2.86-.57h0Z"
        />
        <path
          fill="#e1e52e"
          strokeWidth="0px"
          d="M53.73,88.75h7.38c1.25,0,2.1-1.27,1.62-2.42l-7.38-17.82c-.3-.72-.96-1.08-1.62-1.08v21.32h0Z"
        />
        <path
          fill="#e1e52e"
          strokeWidth="0px"
          d="M53.73,47.65c.66,0,1.32-.36,1.62-1.08l7.38-17.82c.48-1.15-.37-2.42-1.62-2.42h-7.38v21.32Z"
        />
      </g>
    </g>
    <g>
      <path
        fill="#efca9e"
        strokeWidth="0px"
        d="M79.06,56.12c-8.74,0-10.86,5.68-10.86,7.58v9.09l-4.15-2.59c-2.19-1.37-5.08-.67-6.41,1.54l-3.14,5.23c-1.26,2.1-.66,4.82,1.37,6.2l12.44,8.45v6.57c0,2.61,2.12,4.73,4.73,4.73h6.01"
      />
      <path
        fill="#e4b26d"
        strokeWidth="0px"
        d="M79.06,56.12c8.74,0,10.86,5.68,10.86,7.58v9.09l4.15-2.59c2.19-1.37,5.08-.67,6.41,1.54l3.14,5.23c1.26,2.1.66,4.82-1.37,6.2l-12.44,8.45v6.57c0,2.61-2.12,4.73-4.73,4.73h-6.01"
      />
      <path
        fill="#fff4cc"
        strokeWidth="0px"
        d="M79.06,58.13c-7.28,0-8.85,4.47-8.85,5.57v12.71l-7.22-4.51c-.42-.26-.89-.4-1.38-.4-.93,0-1.77.48-2.25,1.27l-3.14,5.23c-.71,1.19-.37,2.73.77,3.51l13.32,9.05v7.63c0,1.5,1.22,2.73,2.73,2.73h6.01v-42.79h0Z"
      />
      <path
        fill="#ffed9b"
        strokeWidth="0px"
        d="M79.06,58.13c7.28,0,8.85,4.47,8.85,5.57v12.71l7.22-4.51c.42-.26.89-.4,1.38-.4.93,0,1.77.48,2.25,1.27l3.14,5.23c.71,1.19.37,2.73-.77,3.51l-13.32,9.05v7.63c0,1.5-1.22,2.73-2.73,2.73h-6.01v-42.79h0Z"
      />
    </g>
  </SvgIcon>
);

export default IconAdditiveYuzuGinger;
