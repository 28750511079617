import React, { useCallback, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-dropdown/style.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { getUTCDateTime } from '../../utils/dateUtility';
import ReactLoading from 'react-loading';
import Typography from '@material-ui/core/Typography';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import '../../css/date_picker.css';
import IOrdersQuery from '../../services/Orders/IOrdersQuery';
import TransactionType from '../../common/TransactionType';
import FilterList from '../../components/Filter/OrdersFilter/OrdersFilter';
import OrderType from '../../common/OrderType';
import OrdersFiltersState from '../../components/Filter/OrdersFilter/OrdersFiltersState';
import IconLoading from '../../components/ui/Icons/IconLoading';
import { useAppSelector } from '../../hooks/storeHooks';
import OverviewState from './OverviewState';
import OverviewService from '../../services/Overview/OverviewService';
import OverviewMetrics from '../../services/Overview/OverviewMetrics';
import OverviewGraphs from '../../services/Overview/OverviewGraphs';
import useCompanies from '../../services/Company/useCompany';
import CompanyFilterTags from '../../components/Tags/CompanyFilterTags';
import FilterTags from '../../components/Tags/FilterTags';
import moment from 'moment';

const Overview: React.FC = () => {
  const initialState: OverviewState = {
    overview: {
      num_orders: 0,
      num_paid_orders: 0,
      tot_earnings: 0,
      num_refills: 0,
      num_compostable: 0,
      companies: [],
      kiosks: [],
    },
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    companies: [],
    kiosks: [],
    showFilters: false,
    filterAnchor: false,
    loading: false,
  };
  const [state, setState] = useState<OverviewState>(initialState);
  const initialFilters = {
    kiosks: [],
    companies: [],
    orderTypes: [],
    transactionTypes: [],
  };
  const [filters, setFilters] = useState<OrdersFiltersState>(initialFilters);
  const [dates, setDates] = useState<{ startDate: string; endDate: string }>({
    startDate: getUTCDateTime(moment().subtract(3, 'days'), state.timeZone, true),
    endDate: getUTCDateTime(moment(), state.timeZone, false),
  });
  const user = useAppSelector((store) => store.auth?.user);
  const companies = useCompanies();

  const getData = useCallback(async () => {
    const overviewService = new OverviewService();
    try {
      setState((prevState) => ({
        ...prevState,
        error: undefined,
        loading: true,
      }));
      const overviewData = await overviewService.getOverview({
        ...dates,
        ...filters,
      });
      setState((prevState) => ({
        ...prevState,
        overview: overviewData,
        kiosks: overviewData.kiosks,
        loading: false,
      }));
    } catch (error) {
      setState((prevState) => ({
        ...prevState,
        error: error instanceof Error ? error.message : JSON.stringify(error),
        loading: false,
      }));
    }
  }, [dates, filters]);

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    if (!companies || companies?.length === 0) return;
    setState((prevState) => ({
      ...prevState,
      companies,
    }));
  }, [companies]);

  const setStartDate = (date) => {
    setDates({
      ...dates,
      startDate: getUTCDateTime(moment(date), state.timeZone, true),
    });
  };

  const setEndDate = (date) => {
    setDates({
      ...dates,
      endDate: getUTCDateTime(moment(date), state.timeZone, false),
    });
  };

  const _onClearFilters = () => {
    setFilters(initialFilters);
  };

  const _onFilterClick = (filterType: keyof IOrdersQuery, value: string | OrderType | TransactionType) => {
    const filterValues = filters[filterType] as (string | OrderType | TransactionType)[];
    if (!filterValues) return;
    if (!Array.isArray(filterValues)) return;
    setFilters({
      ...filters,
      [filterType]: filterValues.includes(value)
        ? filterValues.filter((item) => item !== value)
        : [...filterValues, value],
    });
  };

  const toggleDrawer = () => () => {
    setState({ ...state, filterAnchor: !state.filterAnchor });
  };

  return (
    <div className='valign-wrapper  animate__animated animate__slideInLeft'>
      {!state.overview && (
        <div>
          <ReactLoading
            type={'spinningBubbles'}
            color={'#58cbe1'}
            height='20%'
            width='20%'
            className='center-align dash-progress-spinner'
          />
        </div>
      )}
      <div className='row' style={{ width: '100%' }}>
        <div className='col s12'>
          <div className='col s12' style={{ padding: '0px', marginTop: '15px' }}>
            <Typography
              style={{
                fontWeight: '400',
                fontSize: '24px',
                font: "'Open Sans', sans-serif'",
                paddingRight: '0px',
                paddingLeft: '0px',
              }}
              className='col s4 l10 m10 left'
            >
              Overview
            </Typography>
            <div className='right-align'>
              <Button onClick={toggleDrawer()} className='filter-button '>
                Filters
              </Button>
            </div>
            <div className='col s2 l2 m2 right-align' style={{ marginBottom: '10px' }}></div>
          </div>
          <div className='col s12 l12 m12'>
            <div className='col s4 l10 left valign-wrapper'>
              {filters.companies && <CompanyFilterTags companies={state.companies} companyIds={filters.companies} />}
              {filters.kiosks && <FilterTags tags={filters.kiosks} label='Kiosks' />}
              {filters.orderTypes && <FilterTags tags={filters.orderTypes} label='Order Type' />}
              {filters.transactionTypes && <FilterTags tags={filters.transactionTypes} label='Transaction Type' />}
              {state.loading && <IconLoading width={30} height={30} />}
            </div>
            <div className='col s2 l2 m2 right-align filter-label'>
              <Drawer className='filter-drawer' anchor={'right'} open={state.filterAnchor} onClose={toggleDrawer()}>
                <FilterList
                  state={state}
                  filters={filters}
                  _onFilterClick={_onFilterClick}
                  _onClearFilters={_onClearFilters}
                  toggleDrawer={toggleDrawer()}
                  userRole={user?.role}
                />
              </Drawer>
            </div>
          </div>
          <div className='col l2 m6 s12' style={{ padding: '0px !important', marginTop: '15px', marginBottom: '10px' }}>
            <DatePicker
              selected={new Date(dates.startDate)}
              onChange={setStartDate}
              startDate={new Date(dates.startDate)}
              endDate={new Date(dates.endDate)}
              style={{ width: '100%' }}
              selectsStart
              showMonthDropdown
              showYearDropdown
              todayButton='Today'
              maxDate={new Date(new Date())}
              className='date-dropdown'
            />
          </div>
          <div className='col l2 m6 s12' style={{ padding: '0px !important', marginTop: '15px' }}>
            <DatePicker
              selected={new Date(dates.endDate)}
              onChange={setEndDate}
              selectsEnd
              showMonthDropdown
              showYearDropdown
              todayButton='Today'
              startDate={new Date(dates.startDate)}
              endDate={new Date(dates.endDate)}
              minDate={new Date(dates.startDate)}
              maxDate={new Date()}
              className='date-dropdown'
            />
          </div>
          <div className='col s12'>
            <OverviewMetrics
              num_refills={state.overview.num_refills}
              num_compostable={state.overview.num_compostable}
              num_paid_orders={state.overview.num_paid_orders}
              tot_earnings={state.overview.tot_earnings}
              num_orders={state.overview.num_orders}
            />
          </div>
          <OverviewGraphs
            flavor_graph={state.overview.flavor_graph}
            caffeine_flavor_graph={state.overview.caffeine_flavor_graph}
            daily_sales_graph={state.overview.daily_sales_graph}
            daily_transactions_graph={state.overview.daily_transactions_graph}
            loading={state.loading}
          />
        </div>
      </div>
    </div>
  );
};

export default Overview;
