import { useHistory } from 'react-router-dom';
import PageHeading from '../../../../components/Heading/PageHeading';
import { useCallback, useEffect, useState } from 'react';
import AuthGuard from '../../../../components/auth/AuthGuard';
import UserRole from '../../../../common/UserRole';
import DataTable from '../../../../components/table/DataTable';
import { useAppSelector } from '../../../../hooks/storeHooks';
import useReports from '../../../../services/Reports/useReports';
import ReportsStrings from '../../ReportsStrings';
import IncidentReportsState from './IncidentReportsState';
import IncidentReportDataExcerpt from '../IncidentReportDataExcerpt';
import IncidentReportsKeys from '../IncidentReportsKeys';
import IncidentReportsTableConfig from './IncidentReportsTableConfig';

interface IIncidentReports {}

const IncidentReports: React.FC<IIncidentReports> = () => {
  const initialState: IncidentReportsState = {
    data: [],
    loading: true
  };
  const [state, setState] = useState<IncidentReportsState>(initialState);
  const user = useAppSelector((store) => store.auth?.user);
  const { getIncidentReports } = useReports();
  const history = useHistory();

  const fetchReports = useCallback(async () => {
    try {
      const reports = await getIncidentReports();
      setState((prevState) => ({
        ...prevState,
        data: reports || [],
        error: !reports || reports.length === 0 ? 'No Incident Reports' : undefined,
        loading: false
      }));
    } catch (error) {
      setState((prevState) => ({
        ...prevState,
        error: 'Failed to fetch Incident Reports',
        loading: false
      }));
    }
  }, [getIncidentReports]);

  useEffect(() => {
    fetchReports();
  }, [fetchReports, getIncidentReports]);

  if (!user?.role) return null;

  const rowEvents = {
    onClick: (_e: React.MouseEvent, row: IncidentReportDataExcerpt) => {
      history.push(`/reports/incidents/${row.correlationId}`);
    }
  };

  return (
    <AuthGuard authorizedRoles={[UserRole.DropSuperAdmin]} userRole={user?.role}>
      <div className='valign-wrapper'>
        <div className='row' style={{ width: '100%' }}>
          <div className='col s12' style={{ padding: '0px', marginTop: '15px' }}>
            <div className='col s12'>
              <PageHeading heading='Incidents' />
            </div>
            <div className='col s4' style={{ justifyContent: 'end', display: 'flex' }}></div>
          </div>
          <>{state.error}</>
          <DataTable<IncidentReportDataExcerpt, IncidentReportsKeys>
            data={state.data}
            loading={state.loading}
            totalSize={state.data.length}
            contextName='Reports'
            dataColumnConfig={IncidentReportsTableConfig()}
            keyField='correlationId'
            rowEvents={rowEvents}
          />
          <p>{ReportsStrings.DatesConvertedToLocalWarning}</p>
        </div>
      </div>
    </AuthGuard>
  );
};

export default IncidentReports;
