import CommandUI from '../FirmwareCommunication/CommandUI';
import CommandCode from '../FirmwareCommunication/Messaging/CommandCode';
import PeripheralIdentifier from '../PeripheralIdentifier';
import LightingAction from './LightingAction';

const LightingCommands: { [key in CommandCode]?: CommandUI } = {
  [CommandCode.Identify]: {
    name: 'Identify',
    identifier: PeripheralIdentifier.Lighting,
    code: CommandCode.Identify,
    args: [],
  },
  [CommandCode.ResetHardware]: {
    name: 'Reset Hardware',
    identifier: PeripheralIdentifier.Lighting,
    code: CommandCode.ResetHardware,
    args: [],
  },
  [CommandCode.ResetProcessor]: {
    name: 'Reset Processor',
    identifier: PeripheralIdentifier.Lighting,
    code: CommandCode.ResetProcessor,
    args: [],
  },
  [CommandCode.Provision]: {
    name: 'Provision',
    identifier: PeripheralIdentifier.Lighting,
    code: CommandCode.Provision,
    args: [
      {
        name: 'PCB Version',
        type: 'decimal',
        options: [],
      },
      {
        name: 'BOM Version',
        type: 'decimal',
        options: [],
      },
      {
        name: 'Serial Number',
        type: 'decimal',
        options: [],
      },
    ],
  },
  [CommandCode.Query]: {
    name: 'Query',
    identifier: PeripheralIdentifier.Lighting,
    code: CommandCode.Query,
    args: [],
  },
  [CommandCode.Action]: {
    name: 'Action',
    identifier: PeripheralIdentifier.Lighting,
    code: CommandCode.Action,
    args: [
      {
        name: 'Action',
        type: 'select',
        options: [
          {
            name: LightingAction[LightingAction.SetLightColors],
            value: LightingAction.SetLightColors,
          },
        ],
      },
      {
        name: 'Deck',
        type: 'rgb',
        options: [],
      },
      {
        name: 'Nozzle',
        type: 'rgb',
        options: [],
      },
      {
        name: 'Reception',
        type: 'rgb',
        options: [],
      },
      {
        name: 'Under',
        type: 'rgb',
        options: [],
      },
    ],
  },
  [CommandCode.EnterBootLoader]: {
    name: 'EnterBootLoader',
    identifier: PeripheralIdentifier.Lighting,
    code: CommandCode.EnterBootLoader,
    args: [],
  },
};

export default LightingCommands;
