enum AdditivesAction {
  StopAll = 0,
  StartDispenseRefill = 1,
  StopDispenseRefillOrPurge = 2,
  ResetFlowMeterTicks = 3,
  StartPurgeFill = 4,
  StartPurgeRefill = 5,
}

export default AdditivesAction;
