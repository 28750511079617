import React from 'react';
import stringWithSpacesUppercase from '../../../utils/stringWithSpacesUppercase';
import HealthStatusIndicator from '../../../components/ui/Icons/HealthStatusIndicator/HealthStatusIndicator';
import { KioskFeatureHealthReport } from '../../../services/Reports/IKiosksHealthReport';

interface IFeaturesHealth {
  reports?: KioskFeatureHealthReport[];
}

const FeaturesHealth: React.FC<IFeaturesHealth> = ({ reports }) => {
  if (!reports) return null;

  return (
    <ul>
      {reports.map((report) => {
        const { feature, enabled, shouldBeEnabled, healthStatus } = report;
        return (
          <li key={feature} style={{ color: shouldBeEnabled && !enabled ? 'red' : '#333' }}>
            <HealthStatusIndicator status={healthStatus} />
            {stringWithSpacesUppercase(feature)}: {enabled ? 'On' : 'Off'}
          </li>
        );
      })}
    </ul>
  );
};

export default FeaturesHealth;
